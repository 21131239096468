import React from 'react';
import PropTypes from 'prop-types';

const ManualModal = ({ modalIsOpen, children, isOpen, setOpen }) => {
    return React.cloneElement(children, {
        isOpen: modalIsOpen && !isOpen,
    });
};

ManualModal.propTypes = {
    modalIsOpen: PropTypes.bool,
};

ManualModal.default = {
    modalIsOpen: false,
};

export default ManualModal;
