import React from 'react';
import PropTypes from 'prop-types';
import { DaButton } from '@paygreen/paygreen-ui';
import IconByName from '../../utils/IconByName';

const ValidateButton = ({ buttonSize, children, icon, onClick, dataCy, ...rest }) => {
    return (
        <button type="submit" onClick={onClick} data-cy={dataCy}>
            <DaButton
                buttonSize={buttonSize}
                colorPallet="status"
                colorStatus="success"
                icon={<IconByName name={icon} />}
                {...rest}
            >
                {children}
            </DaButton>
        </button>
    );
};

ValidateButton.propTypes = {
    onClick: PropTypes.func,
    buttonSize: PropTypes.string,
    icon: PropTypes.string,
    /**
     * Id for Cypress tests
     */
    dataCy: PropTypes.string,
};

ValidateButton.defaultProps = {
    buttonSize: 'sm',
    icon: 'CheckIcon',
};

export default ValidateButton;
