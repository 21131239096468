import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    Box,
    DatePicker,
    InternalGrid,
    Text,
    ScheduleIcon,
} from '@paygreen/paygreen-ui';
import { useUser } from '../../context';
import dayjs from '../../utils/dayjsHelper';
import { startOfMonth, endOfMonth } from '../../utils/constants';
import { IsMobile } from '../../utils/tools';

const FilterTable = ({ t, setEndDate, setStartDate, endDate, startDate }) => {
    const { user } = useUser();

    // we apply as the minimum startDate the first day of the month of subscription of the user
    const minimumStartDate =
        user.createdAt &&
        dayjs(new Date(user.createdAt * 1000)).format('01/MM/YYYY');

    return (
        <Box marginBottom="lg">
            <InternalGrid
                displayType="grid"
                gridTemplateColumns={IsMobile() ? 'auto' : 'auto auto'}
                gridGap="md"
                alignItems="center"
                justifyItems="start"
                childrenMarginTop="sm"
                hasStaticWidth={false}
            >
                <InternalGrid
                    displayType="grid"
                    gridTemplateColumns="auto auto "
                    gridGap="md"
                    alignItems="center"
                >
                    <Text textSize="xs">{t('datePicker.from')}</Text>

                    <DatePicker
                        locale={t('datePicker.locale')}
                        id="startDate"
                        minimumDate={minimumStartDate}
                        maximumDate={endDate || endOfMonth}
                        fieldSize="sm"
                        blockWidth="sm"
                        resetDate={endDate}
                        value={startDate || startOfMonth}
                        onChange={(date) => setStartDate(date)}
                        icon={<ScheduleIcon />}
                    />
                </InternalGrid>

                <InternalGrid
                    displayType="grid"
                    gridTemplateColumns="auto auto"
                    gridGap="md"
                    alignItems="center"
                >
                    <Text textSize="xs">{t('datePicker.to')}</Text>

                    <DatePicker
                        locale={t('datePicker.locale')}
                        id="endDate"
                        minimumDate={startDate || startOfMonth}
                        maximumDate={endOfMonth}
                        fieldSize="sm"
                        blockWidth="sm"
                        resetDate={startDate}
                        value={endDate || endOfMonth}
                        onChange={(date) => setEndDate(date)}
                        icon={<ScheduleIcon />}
                    />
                </InternalGrid>
            </InternalGrid>
        </Box>
    );
};

export default withTranslation('common')(FilterTable);
