import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
    DaButton,
    Text,
    ArrowLongRightIcon,
    InternalGrid,
    CheckIcon,
} from '@paygreen/paygreen-ui';
import { IsMobile } from '../../../utils/tools';

const LoginOrSuscribeButton = ({ t }) => {
    const pathname = useLocation().pathname;
    const isLogin = pathname === '/login';

    return (
        <InternalGrid
            displayType="grid"
            gridTemplateColumns={IsMobile() ? '1fr' : 'auto auto'}
            gridGap="md"
            hasStaticWidth={false}
            alignItems="center"
        >
            <Text textSize="sm" colorWab="white00" htmlTag="strong">
                {!isLogin
                    ? t('footer.alreadyAnAccount')
                    : t('footer.notYetAnAccount')}
            </Text>

            <RouterLink to={!isLogin ? '/login' : '/subscription'}>
                <DaButton
                    buttonSize="lg"
                    gradient="theme"
                    colorType="reverse"
                    colorTheme="quaternary"
                    icon={!isLogin ? <ArrowLongRightIcon /> : <CheckIcon />}
                >
                    {!isLogin ? t('login.button') : t('subscription.button')}
                </DaButton>
            </RouterLink>
        </InternalGrid>
    );
};

export default withTranslation('common')(LoginOrSuscribeButton);
