import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Country } from 'api-green-node';
import { InternalGrid } from '@paygreen/paygreen-ui';
import { LogoPaygreenWithMode } from '../../assets/logos';
import { useAuth } from '../../context';
import { apiPublic } from '../../utils/hooks';
import { IsBigDesktop } from '../../utils/tools';
import { CardClassic, MainLogin } from '../../components';
import SubscriptionForm from './SubscriptionForm';
import SubscriptionSuccessMessage from './SubscriptionSuccessMessage';
import SubscriptionAlternatives from './SubscriptionAlternatives';
import DuplicateAccountMessage from './DuplicateAccountMessage';

const Subscription = ({ t }) => {
    const { checkLocalTokens } = useAuth();

    const [errorMessage, setErrorMessage] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoadingForm, setIsLoadingForm] = useState(false);
    const [newUser, setNewUser] = useState({});

    // initializing all react-hook-form values and methods
    const defaultValues = {
        lastname: '',
        firstname: '',
        password: '',
        passwordVerify: '',
        email: '',
        country: 'FR',
        organisationName: '',
        organisationWebsite: '',
        phone: '',
        cgu: '',
    };

    const countryOptions = Object.keys(Country)
        .slice(0, Object.keys(Country).length / 2)
        .map((option) => {
            return {
                value: Country[option],
                text: t(`countries:${Country[option]}`),
            };
        });

    const { handleSubmit, register, errors, setValue, watch, control } =
        useForm({
            defaultValues: defaultValues,
            mode: 'onTouched',
            reValidateMode: 'onChange',
        });

    // creating a special ref for password input to compare password and passwordVerify inputs
    const password = useRef({});
    password.current = watch('password', '');

    useEffect(() => {
        register({ name: 'organisationName' });
        register({ name: 'organisationWebsite' });
        register({ name: 'lastname' });
        register({ name: 'firstname' });
        register({ name: 'email' });
        register({ name: 'phone' });
        register({ name: 'password' });
        register({ name: 'passwordVerify' });
        register({ name: 'country' });
        register({ name: 'cgu' });
    }, [register]);

    /**
     * Post request to API for new user's subscription
     * @param {object} newUser - An object containing all data of the new user
     * @returns {object} - Object with all infos from new user
     */
    const userSubscription = (newUser) => {
        apiPublic()
            .post('/registration/create', newUser)
            .then((res) => {
                setNewUser(res.data);
                setIsSuccess(true);
            })
            .catch((error) => {
                if (error.response.data.status === 409) {
                    setErrorMessage(<DuplicateAccountMessage />);
                } else {
                    setErrorMessage(error.response.data.detail);
                }
            })
            .finally(() => setIsLoadingForm(false));
    };

    const submitForm = (values) => {
        const finalUser = {
            usesArrondi: 1,
            usesTree: 0,
            organisationName: values.organisationName,
            organisationWebsite: values.organisationWebsite,
            lastname: values.lastname,
            firstname: values.firstname,
            phone: '00' + values.phone.replace(/\(0\)|[\s+]/g, ''),
            password: values.password,
            email: values.email,
            country: values.country,
        };

        setIsLoadingForm(true);
        userSubscription(finalUser);
    };

    if (checkLocalTokens() === 'valid') {
        return <Redirect to="/" />;
    }

    return (
        <MainLogin
            isLoading={isLoadingForm}
            helmetTitle={
                t('subscription.metaTitle') + ' - ' + t('main.metaTitle')
            }
        >
            <LogoPaygreenWithMode
                className={isSuccess ? null : 'hideOnBigScreen'}
            />

            <InternalGrid
                displayType={IsBigDesktop() ? 'grid' : 'flex'}
                gridTemplateColumns={!isSuccess ? '1fr 1fr' : '1fr'}
                childrenMarginBottom="sm"
                alignItems={IsBigDesktop() ? 'flex-start' : 'stretch'}
                gridGap="xl"
                style={{
                    flexDirection: !isSuccess ? 'column-reverse' : 'column',
                }}
            >
                {!isSuccess && <SubscriptionAlternatives />}

                <CardClassic
                    radiusSize="lg"
                    blockWidth="md"
                    paddingLateral="md"
                    paddingTop="md"
                    paddingBottom="md"
                    titleColorWab="grey30"
                    titleAlign={IsBigDesktop() ? 'left' : 'center'}
                    titleSize="md"
                    isShadowWab={true}
                    title={isSuccess ? null : t('subscription.shopTitle')}
                >
                    {!isSuccess ? (
                        <SubscriptionForm
                            errors={errors}
                            setValue={setValue}
                            register={register}
                            password={password}
                            countryOptions={countryOptions}
                            errorMessage={errorMessage}
                            onSubmit={handleSubmit(submitForm)}
                            isLoadingForm={isLoadingForm}
                            control={control}
                        />
                    ) : (
                        <SubscriptionSuccessMessage newUser={newUser} />
                    )}
                </CardClassic>
            </InternalGrid>
        </MainLogin>
    );
};

export default withTranslation('common', 'countries')(Subscription);
