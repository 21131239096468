import React, { createContext } from 'react';
import { UseRefreshToken } from '../utils/authentication';

export const UserContext = createContext();

/**
 * Provider to store basic user informations
 */
const UserProvider = ({ children }) => {
    const { user, setUser } = UseRefreshToken();

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
