import React, { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ButtonGroup } from '@paygreen/paygreen-ui';
import { useAPIError } from '../../../context';
import { apiAuth } from '../../../utils/hooks';
import {
    CancelButton,
    ValidateButton,
    ModalForm,
    NewPasswordForm,
} from '../../../components';

const PasswordEditModal = ({ t, setOpen, isOpen, setIsLoading }) => {
    const { addError } = useAPIError();

    // initializing all react-hook-form values and methods
    const defaultValues = {
        newPassword: '',
        newPasswordVerify: '',
    };

    const { handleSubmit, clearErrors, register, errors, setValue, watch } =
        useForm({
            defaultValues: defaultValues,
        });

    // creating a special ref for password input to compare password and passwordVerify inputs
    const password = useRef({});
    password.current = watch('newPassword', '');

    useEffect(() => {
        register({ name: 'newPassword' });
        register({ name: 'newPasswordVerify' });
    }, [register]);

    /**
     * Post request to API to change user's password
     * @param {object} newPassword - An object containing new password
     */
    const changePassword = (newPassword) => {
        apiAuth()
            .patch('/account/me/user/me', { password: newPassword })
            .then(() => {
                toast(t('form.toastSuccess.passwordUpdate'));
            })
            .catch((error) => {
                addError(
                    error?.response?.data.detail,
                    error?.response?.data.status,
                );
            })
            .finally(() => setIsLoading(false));
    };

    const submitForm = (values) => {
        setIsLoading(true);
        changePassword(values.newPassword);
        cancelCloseModal();
    };

    const cancelCloseModal = () => {
        setOpen(false);

        // we need the reset methods to wait until modal is closed
        setTimeout(() => {
            clearErrors();
            for (const [key] of Object.entries(defaultValues)) {
                setValue(key, '');
            }
        }, 500);
    };

    return (
        <ModalForm
            modalTitle={t('user.editPassword.editPasswordModalTitle')}
            blockWidth="md"
            paddingLateral="md"
            isOpen={isOpen}
            actionOnClose={() => cancelCloseModal()}
        >
            <NewPasswordForm
                errors={errors}
                setValue={setValue}
                register={register}
                onSubmit={handleSubmit(submitForm)}
                password={password}
            >
                <ButtonGroup marginTop="sm" marginBottom="sm">
                    <CancelButton
                        onClick={() => cancelCloseModal()}
                        buttonSize="md"
                    >
                        {t('form.button.cancel')}
                    </CancelButton>

                    <ValidateButton buttonSize="md">
                        {t('form.button.save')}
                    </ValidateButton>
                </ButtonGroup>
            </NewPasswordForm>
        </ModalForm>
    );
};

PasswordEditModal.propTypes = {
    /**
     * @description setOpen and isOpen are automatically received from ModalControl parent
     */
    setOpen: PropTypes.func,
    isOpen: PropTypes.bool,
    setIsLoading: PropTypes.func.isRequired,
};

export default withTranslation('common')(PasswordEditModal);
