import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import {
    Card,
    DataLegend,
    Link,
    SkeletonItem,
    Text,
} from '@paygreen/paygreen-ui';
import { formatCentsMoney } from '../../../utils/tools';

const BillingDetailsCard = ({ t, subscriptionData, isLoading }) => (
    <SkeletonItem
        isLoading={isLoading}
        colorWab="grey10"
        blockWidth="sm"
        radiusSize="lg"
    >
        <Card
            colorPallet="status"
            paddingBottom="sm"
            paddingTop="sm"
            paddingLateral="sm"
            shadowSize="none"
            blockWidth="sm"
            hasBackground={true}
        >
            <Text textSize="xs" colorWab="grey50">
                {t('user.billing.billingDetailsCard.title')}
            </Text>

            {subscriptionData?.mode === 'usage' ? (
                <>
                    <Text colorPallet="status" marginTop="xs">
                        <Trans i18nKey="user.billing.billingDetailsCard.modeDetail">
                            ...
                            <Text
                                htmlTag="span"
                                colorPallet="wab"
                                colorWab="grey50"
                            >
                                {{
                                    usage: t(
                                        'user.billing.billingDetailsCard.usage',
                                    ),
                                }}
                            </Text>
                        </Trans>
                    </Text>
                    <a
                        href="https://paygreen.io/tarifs/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Text textSize="xs">
                            <Link colorTheme="secondary">
                                {t('user.billing.pricesPage')}
                            </Link>
                        </Text>
                    </a>
                </>
            ) : (
                <DataLegend
                    textSize="sm"
                    value={formatCentsMoney(subscriptionData?.price || 0, true)} // value here will creates props warning (will be fixed in PG-UI)
                    unit="€"
                    colorTheme="secondary"
                />
            )}
        </Card>
    </SkeletonItem>
);

BillingDetailsCard.propTypes = {
    subscriptionData: PropTypes.object,
    isLoading: PropTypes.bool,
};

BillingDetailsCard.defaultProps = {
    isLoading: false,
};

export default withTranslation('common')(BillingDetailsCard);
