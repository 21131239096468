import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { makeServer } from './mocks/server';

if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_MOCK === 'TRUE'
) {
    makeServer({ environment: 'development' });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
