import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from '@paygreen/paygreen-ui';
import IconByName from '../../../utils/IconByName';

const ActionBadgeBase = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: ${(props) => props.theme.space.xs}
        ${(props) => props.theme.space.sm};
    border-radius: ${(props) => props.theme.radius.sm};
    text-align: center;
    color: ${(props) => props.theme.status[props.colorStatus].main};
    background-color: ${(props) => props.theme.status[props.colorStatus].light};
`;

/**
 * @description this component validate or alert depending on actions done in form
 */
const ActionBadge = ({ text, colorStatus, icon, isStrong, ...rest }) => (
    <ActionBadgeBase colorStatus={colorStatus} textSize="sm" {...rest}>
        <IconByName
            name={icon}
            marginRight="xs"
            colorPallet="status"
            colorStatus={colorStatus}
        />

        <Text
            colorPallet="status"
            colorStatus={colorStatus}
            textSize="sm"
            htmlTag={isStrong ? 'strong' : 'p'}
        >
            {text}
        </Text>
    </ActionBadgeBase>
);

ActionBadge.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.string,
    colorStatus: PropTypes.string,
    isStrong: PropTypes.bool,
};

ActionBadge.defaultProps = {
    colorStatus: 'success',
    icon: 'CheckIcon',
    isStrong: false,
};

export default ActionBadge;
