import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    Box,
    CoinsIcon,
    HeartIcon,
    InternalGrid,
    SkeletonItem,
} from '@paygreen/paygreen-ui';
import { IsMobile, IsDesktop, formatCentsMoney } from '../../../utils/tools';
import { SmallDataCard, ErrorBoundary } from '../../../components';

const PartnershipDataCards = ({ t, isLoading, data }) => {
    const skeletonStyle = {
        colorWab: 'grey10',
        radiusSize: 'lg',
        isLoading: isLoading,
    };

    return (
        <Box margin="md">
            <InternalGrid
                displayType="grid"
                gridGap="md"
                gridTemplateColumns={IsMobile() ? '1fr' : '1fr 1fr'}
                justifyItems="stretch"
                alignItems="stretch"
            >
                <ErrorBoundary>
                    <SkeletonItem blockWidth="md" {...skeletonStyle}>
                        <SmallDataCard
                            blockWidth={IsDesktop() ? 'md' : 'none'}
                            colorPallet="theme"
                            colorTheme="quaternary"
                            hasEmptyMode={true}
                            icon={<CoinsIcon />}
                            padding="xs"
                            title={t('dashboard.dataCard.donatedTotalTitle')}
                            unit="€"
                            value={
                                data && data.totalDonationAmount
                                    ? formatCentsMoney(data.totalDonationAmount)
                                    : 0
                            }
                            style={
                                data && !data.totalDonationAmount
                                    ? { filter: 'grayscale(1)', opacity: '0.7' }
                                    : null
                            }
                        />
                    </SkeletonItem>
                </ErrorBoundary>

                <ErrorBoundary>
                    <SkeletonItem {...skeletonStyle}>
                        <SmallDataCard
                            blockWidth="none"
                            colorPallet="theme"
                            colorTheme="secondary"
                            hasGreyStyle={true}
                            hasEmptyMode={true}
                            icon={<HeartIcon />}
                            padding="xs"
                            unit="€"
                            title={t('dashboard.dataCard.donationAverageTitle')}
                            value={
                                data && data.averageDonationAmount
                                    ? formatCentsMoney(
                                          data.averageDonationAmount,
                                      )
                                    : 0
                            }
                            style={
                                data && !data.averageDonationAmount
                                    ? { filter: 'grayscale(1)', opacity: '0.7' }
                                    : null
                            }
                        />
                    </SkeletonItem>
                </ErrorBoundary>
            </InternalGrid>
        </Box>
    );
};

PartnershipDataCards.propTypes = {
    isLoading: PropTypes.bool,
    totalDonationAmount: PropTypes.number,
    averageDonationAmount: PropTypes.number,
};

PartnershipDataCards.defaultProps = {
    isLoading: false,
    totalDonationAmount: 0,
    averageDonationAmount: 0,
};

export default withTranslation('common')(PartnershipDataCards);
