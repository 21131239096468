import styled from 'styled-components';
import { ThemeDefault } from '@paygreen/paygreen-ui';

const MainLoginGrid = styled.div`
    min-height: 100vh;
    display: grid;
    justify-items: stretch;
    grid-template-rows: 1fr auto;
    align-items: center;
    background: url(${ThemeDefault.pattern}) repeat;
`;

export default MainLoginGrid;
