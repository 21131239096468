import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { DownloadIcon } from '@paygreen/paygreen-ui';

const ActionsCell = ({ link, t, ...rest }) => {
    // to capture and prevent mousedown event to open DaTableRow when clicking on buttons (DaTableRow open/close on mousedown)
    const handleOnMouseDown = (e) => {
        if (e.stopPropagation) {
            e.stopPropagation();
        }
    };

    return (
        <DownloadIcon
            title={t('user.billing.table.downloadButton')}
            onMouseDown={(e) => handleOnMouseDown(e)}
            iconSize="sm"
            colorPallet="theme"
            hasBackground={true}
            hasHover={true}
            htmlTag="button"
            {...rest}
        />
    );
};

ActionsCell.propTypes = {
    link: PropTypes.string,
};

export default withTranslation('common')(ActionsCell);
