// Call Donations
import { apiAuth } from '../../utils/hooks';

const getAllDonations = (params, mode) => {
    return apiAuth(mode)
        .get('/donation', { params: params })
        .then((data) => data)
        .catch((error) => {
            throw error;
        });
};

const getDonationsExport = (params) => {
    return apiAuth()
        .get('/donation/statistics/export', { params: params })
        .then((data) => data)
        .catch((error) => {
            throw error;
        });
};

export { getAllDonations, getDonationsExport };
