import React from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    ModalControl,
    ModalContent,
    ModalHeader,
    ModalBody,
    Overlay,
    CrossIcon,
    Title,
} from '@paygreen/paygreen-ui';
import ModalControlClose from './ModalControlClose';

/**
 * @description - this component needs absolutely to have <ModalProvider/> as a parent (direct or indirect) to receive modal context and to have ModalControl working to open/close the modal. It is made to use modal 'inside' a component without being obligatory its child
 */
const ModalForm = ({
    blockWidth,
    children,
    hasCloseCross,
    modalTitle,
    paddingLateral,
    paddingBottom,
    actionOnClose,
    isOpen,
}) => (
    <Modal isOpen={isOpen}>
        <ModalControl>
            <ModalControlClose action={actionOnClose}>
                <Overlay
                    gradient="theme"
                    colorTheme="quaternary"
                    opacityValue={60}
                />
            </ModalControlClose>
        </ModalControl>

        <ModalContent blockWidth={blockWidth}>
            {modalTitle || hasCloseCross ? (
                <ModalHeader paddingLateral={paddingLateral} paddingTop="sm">
                    {modalTitle ? (
                        <Title textSize="md" colorWab="grey40">
                            {modalTitle}
                        </Title>
                    ) : null}

                    {hasCloseCross ? (
                        <ModalControl>
                            <ModalControlClose action={actionOnClose}>
                                <CrossIcon
                                    htmlTag="button"
                                    iconSize="md"
                                    colorPallet="wab"
                                />
                            </ModalControlClose>
                        </ModalControl>
                    ) : null}
                </ModalHeader>
            ) : null}

            <ModalBody
                paddingTop={modalTitle || hasCloseCross ? 'none' : 'sm'}
                paddingBottom={paddingBottom}
                paddingLateral={paddingLateral}
            >
                <ModalControl>{children}</ModalControl>
            </ModalBody>
        </ModalContent>
    </Modal>
);

ModalForm.propTypes = {
    blockWidth: PropTypes.string,
    modalTitle: PropTypes.string,
    hasCloseCross: PropTypes.bool,
    paddingLateral: PropTypes.string,
    paddingBottom: PropTypes.string,
    actionOnClose: PropTypes.func,
};

ModalForm.defaultProps = {
    blockWidth: 'sm',
    hasCloseCross: true,
    paddingLateral: 'sm',
    paddingBottom: 'xs',
};

export default ModalForm;