import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, Link as UILink, Text } from '@paygreen/paygreen-ui';
import { timestampToCalendarFormat } from '../../../utils/tools';
import Message from '../../layout/Message';

const BillingMessage = ({ t, expirationDate, mode }) => {
    const statusColor = mode === 'expired' ? 'danger' : 'warning';

    const LinksInMessage = () => (
        <>
            <RouterLink to="/user/company">
                <UILink colorPallet="status" colorStatus={statusColor}>
                    {t('billingMessages.siret')}
                </UILink>
            </RouterLink>
            {' '}
            <RouterLink to="/user/company">
                <UILink colorPallet="status" colorStatus={statusColor}>
                    {t('billingMessages.adress')}
                </UILink>
            </RouterLink>
            {' '}
            {t('billingMessages.and')}
            {' '}
            <RouterLink to="/user/company">
                <UILink colorPallet="status" colorStatus={statusColor}>
                    {t('billingMessages.iban')}
                </UILink>
            </RouterLink>
        </>
    );

    const modeProps = {
        warning: {
            type: 'warning',
            messageContent: (
                <>
                    <Text textSize="sm">
                        <Trans i18nKey="billingMessages.prodBillingWarning">
                            Votre compte CharityKit n’est pas encore validé :
                            pour passer en production et continuer à accéder à
                            la sandbox après le
                            <Text
                                textSize="sm"
                                htmlTag="strong"
                                colorPallet="status"
                                colorStatus="warning"
                            >
                                {{
                                    expirationDate:
                                        timestampToCalendarFormat(
                                            expirationDate,
                                        ),
                                }}
                            </Text>
                            , veuillez renseigner
                        </Trans>

                        <LinksInMessage />
                    </Text>
                </>
            ),
            icon: 'CautionBoldIcon',
        },
        warningLater: {
            type: 'warning',
            messageContent: (
                <>
                    <Text textSize="sm">
                        {t('billingMessages.prodBillingWarningLater')}

                        <LinksInMessage />
                    </Text>
                </>
            ),
            icon: 'CautionBoldIcon',
        },
        expired: {
            type: 'error',
            messageContent: (
                <Text textSize="sm" colorPallet="status" colorStatus="danger">
                    {t('billingMessages.prodBillingExpired')}

                    <LinksInMessage />
                </Text>
            ),
            icon: 'CrossBoldIcon',
        },
    };

    return (
        <Box marginBottom="sm">
            <Card blockWidth="xl" hasBackground={false}>
                <Message
                    icon={modeProps[mode].icon}
                    iconSize="md"
                    textSize="sm"
                    type={modeProps[mode].type}
                    blockWidth="lg"
                    hasStaticWidth={true}
                    paddingLateral="none"
                    marginBottom="md"
                >
                    {modeProps[mode].messageContent}
                </Message>
            </Card>
        </Box>
    );
};

BillingMessage.propTypes = {
    expirationDate: PropTypes.number,
    mode: PropTypes.string,
};

BillingMessage.defaultProps = {
    mode: 'warning',
};

export default withTranslation('common')(BillingMessage);
