import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, withTranslation } from 'react-i18next';
import moment from 'moment';
import { Text } from '@paygreen/paygreen-ui';
import { useAPIError, useCharityList, useUser } from '../../../context';
import {
    ErrorBoundary,
    H1Title,
    ModalBlock,
    NoDataFoundCard,
    SimpleActionButton,
    DeleteButton,
} from '../../../components';
import { partnershipStatus } from '../../../utils/constants';
import { timestampToInternationalFormat } from '../../../utils/tools';
import {
    getFilteredPartnership,
    getDonationsReportDetail,
} from '../shared/partnershipsTools';
import { getDonationReports } from '../../dashboard/service';
import { getAllPartnerships, deletePartnership } from '../service';
import {
    PartnershipDataCards,
    PartnershipCollapsibleCard,
    PartnershipCardGrid,
    PartnershipCardSkeleton,
    PartnershipToastMessage,
    PartnershipModalContent,
} from '../shared';

const MyPartnerships = ({ t }) => {
    const { user } = useUser();
    const { charityListData } = useCharityList();
    const { addError } = useAPIError();

    // we create a ref to detect if component is mounted
    const isMounted = useRef(false);

    const [partnershipsData, setPartnershipsData] = useState();
    const [reportsData, setReportsData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isReportsLoading, setIsReportsLoading] = useState(false);

    // we will request all donations per partnership since the creation of the charity inside API
    const userCreationDate = timestampToInternationalFormat(user.createdAt);

    const getData = useCallback(() => {
        getAllPartnerships()
            .then((res) => {
                isMounted.current &&
                    setPartnershipsData(
                        getFilteredPartnership(
                            res.data._embedded.partnership,
                            partnershipStatus.ACTIVE,
                        ),
                    );
            })
            .catch((error) => {
                addError(
                    error?.response?.data.detail,
                    error?.response?.data.status,
                );
            })
            .finally(() => {
                isMounted.current && setIsLoading(false);
            });

        const params = {
            begin: userCreationDate,
            end: moment().format('YYYY-MM-DD'),
            association:
                charityListData &&
                charityListData[0] &&
                Number(charityListData[0].idAssociation),
        };

        if (params.association) {
            setIsReportsLoading(true);

            getDonationReports(params)
                .then((res) => {
                    isMounted.current && setReportsData(res.data.details);
                })
                .catch((error) => {
                    addError(
                        error?.response?.data.detail,
                        error?.response?.data.status,
                    );
                })
                .finally(() => {
                    isMounted.current && setIsReportsLoading(false);
                });
        }
    }, [addError, charityListData, userCreationDate]);

    useEffect(() => {
        isMounted.current = true;

        getData();

        return () => {
            isMounted.current = false;
        };
    }, [getData, charityListData]);

    const CardMainButton = ({ partnerId, partnerName }) => (
        <ModalBlock
            blockWidth="md"
            closeWithAction={() => getData()}
            modalTitle={t('partnershipButtons.cancelThePartnership')}
            isTitleRed={true}
            button={
                <DeleteButton>
                    {t('partnershipButtons.cancelThePartnership')}
                </DeleteButton>
            }
        >
            <PartnershipModalContent
                closeWithAction={() => getData()}
                sdkPartnershipAction={() => deletePartnership(partnerId)}
                buttonCancelAction={
                    <SimpleActionButton icon="ArrowRoundTopLeftIcon">
                        {t('partnershipButtons.cancel')}
                    </SimpleActionButton>
                }
                buttonConfirmAction={
                    <DeleteButton>
                        {t('partnershipButtons.cancelThisPartnership')}
                    </DeleteButton>
                }
                toastMessage={
                    <PartnershipToastMessage
                        i18nKey="form.toastSuccess.partnershipCancel"
                        partnerName={partnerName}
                    />
                }
            >
                <Text textSize="sm">
                    <Trans i18nKey="CharityPartnerships.myPartnerships.message">
                        Are you sure to stop
                        <Text htmlTag="strong" textSize="sm">
                            {{ partnerName }}
                        </Text>
                        This manipulation is irreversible
                        <Text htmlTag="strong" textSize="sm">
                            {{ partnerName }}
                        </Text>
                        Partner won't be able to collect again
                    </Trans>
                </Text>
            </PartnershipModalContent>
        </ModalBlock>
    );

    return (
        <>
            <Helmet>
                <title>
                    {t('CharityPartnerships.myPartnerships.metaTitle')} -{' '}
                    {t('main.metaTitle')}
                </title>
            </Helmet>

            <H1Title
                marginBottom="sm"
                title={t('CharityPartnerships.myPartnerships.title')}
            />

            {isLoading ? (
                <PartnershipCardSkeleton blockWidth="md" />
            ) : partnershipsData && partnershipsData.length > 0 ? (
                <PartnershipCardGrid>
                    {partnershipsData.map((partnership) => (
                        <ErrorBoundary
                            blockWidth="lg"
                            key={partnership.idPartnership}
                        >
                            <PartnershipCollapsibleCard
                                key={partnership.idPartnership}
                                data={partnership}
                                partnershipStatus={partnershipStatus.ACTIVE}
                                mainButton={
                                    <CardMainButton
                                        partnerId={partnership.idPartnership}
                                        partnerName={partnership.idAccount}
                                    />
                                }
                            >
                                <PartnershipDataCards
                                    isLoading={isReportsLoading}
                                    data={
                                        reportsData &&
                                        getDonationsReportDetail(
                                            reportsData,
                                            partnership.idUser,
                                        )
                                    }
                                />
                            </PartnershipCollapsibleCard>
                        </ErrorBoundary>
                    ))}
                </PartnershipCardGrid>
            ) : (
                <NoDataFoundCard
                    message={t(
                        'CharityPartnerships.myPartnerships.noDataFound',
                    )}
                />
            )}
        </>
    );
};

export default withTranslation('common')(MyPartnerships);
