import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Card, Link, Text, Title } from '@paygreen/paygreen-ui';
import { IsBigDesktop } from '../../../utils/tools';

const WrongInfosCard = ({ t }) => (
    <Card
        blockWidth={IsBigDesktop() ? 'sm' : 'none'}
        hasBackground={true}
        paddingBottom="sm"
        paddingTop="sm"
        paddingLateral="sm"
        isShadowWab={true}
        data-cy="wrong-info-card"
    >
        <Title colorWab="grey40" textSize="base" marginBottom="xs">
            {t('user.billing.wrongInfosCard.title')}
        </Title>

        <Text textSize="sm">
            {t('user.billing.wrongInfosCard.companyText')}

            <RouterLink to="/user/company">
                <Link colorTheme="quaternary">
                    {t('user.billing.wrongInfosCard.companyLink')}
                </Link>
            </RouterLink>
        </Text>
    </Card>
);

export default withTranslation('common')(WrongInfosCard);
