import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Breadcrumb, InternalGrid, SkeletonItem } from '@paygreen/paygreen-ui';
import { useAPIError } from '../../../context';
import { apiAuth } from '../../../utils/hooks';
import { H1Title, HideAndCopy } from '../../../components';
import AdminUserDetailsCards from './AdminUserDetailsCards';

const AdminUserDetails = ({ t }) => {
    const { addError } = useAPIError();
    const { accountId } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [accountData, setAccountData] = useState();

    const baseHost = window.location.origin;

    // we create a ref to detect if component is mounted
    const isMounted = useRef(false);

    /**
     * GET request to API to get user's saccount informations
     * @returns {object} - object with user's infos for account and each space
     */
    const getAccountData = useCallback(() => {
        apiAuth()
            .get(`/account/${accountId}`)
            .then((res) => {
                isMounted.current && setAccountData(res.data);
            })
            .catch((error) =>
                addError(
                    error?.response?.data.detail,
                    error?.response?.data.status,
                ),
            )
            .finally(() => isMounted.current && setIsLoading(false));
    }, [addError, accountId]);

    useEffect(() => {
        isMounted.current = true;

        getAccountData();

        return () => {
            isMounted.current = false;
        };
    }, [getAccountData]);

    return (
        <>
            <Helmet>
                <title>
                    {t('admin.users.metaTitle')} - {t('main.metaTitle')}
                </title>
            </Helmet>

            <Breadcrumb
                elements={[
                    {
                        url: '/admin/users',
                        label: t('admin.users.title'),
                    },
                    {
                        url: '',
                        label: t('admin.user.title'),
                    },
                ]}
            />

            <InternalGrid
                hasStaticWidth={false}
                displayType="grid"
                gridTemplateColumns="1fr auto"
                gridGap="md"
                alignItems={isLoading ? 'flex-start' : 'center'}
                childrenMarginBottom="lg"
            >
                <SkeletonItem
                    blockWidth="md"
                    blockHeight="xs"
                    colorWab="grey10"
                    radiusSize="none"
                    isLoading={isLoading}
                >
                    {/* the no data found alternative guarantees skeleton title length while loading data */}
                    <H1Title
                        marginBottom="none"
                        title={
                            accountData?.organisationName ||
                            t('user.noDataFound')
                        }
                    />
                </SkeletonItem>

                <HideAndCopy
                    title={t('admin.user.copyUrlTitle')}
                    hasHiddenText={false}
                    icon="LinkIcon"
                    iconSize="sm"
                    text={`${baseHost}/admin/users/${accountId}`}
                />
            </InternalGrid>

            <AdminUserDetailsCards
                setIsLoading={setIsLoading}
                accountData={accountData}
                setAccountData={setAccountData}
                isLoading={isLoading}
                refresh={getAccountData}
            />
        </>
    );
};

export default withTranslation('common')(AdminUserDetails);
