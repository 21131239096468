import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Box, Checkbox, InternalGrid } from '@paygreen/paygreen-ui';
import { useCharityList } from '../../../../context';

const AllCharitiesFilter = ({
    t,
    partnershipsData,
    localCharityList,
    setLocalCharityList,
}) => {
    const { charityListData } = useCharityList();
    const [isListFiltered, setListFiltered] = useState(false);

    const filterCharity = (e) => {
        const { checked } = e.target;

        // if we uncheck the checkbox, we go back to full charity list
        if (!checked) {
            setListFiltered(false);
            setLocalCharityList(charityListData);

            // if we check the checkbox, we hide charities we are already partner with, from localCharityList
        } else {
            setListFiltered(true);
            setLocalCharityList(
                localCharityList?.filter(
                    (charity) =>
                        !partnershipsData?.includes(charity.idAssociation),
                ),
            );
        }
    };

    return (
        <Box marginBottom="lg">
            <InternalGrid
                displayType="grid"
                gridTemplateColumns="1fr"
                childrenMarginBottom="md"
                justifyItems="start"
            >
                <Checkbox
                    id="notAvailableCharities"
                    label={t(
                        'ShopPartnerships.allCharities.checkboxHideCharities',
                    )}
                    fieldSize="lg"
                    value={true}
                    checked={isListFiltered}
                    onChange={filterCharity}
                />
            </InternalGrid>
        </Box>
    );
};

AllCharitiesFilter.propTypes = {
    partnershipsData: PropTypes.array,
    localCharityList: PropTypes.array,
    setLocalCharityList: PropTypes.func.isRequired,
};

export default withTranslation('common')(AllCharitiesFilter);
