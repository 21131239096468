import React from 'react';
import PropTypes from 'prop-types';
import { ModalProvider, ModalControl } from '@paygreen/paygreen-ui';
import IconByName from '../../../utils/IconByName';

/**
 * @description - icon to display edit modal with children
 */
const EditModalIcon = ({ title, hasHover, children, ...rest }) => (
    <ModalProvider>
        <ModalControl hasOnClickControl={true}>
            <IconByName
                name="PenIcon"
                hasBackground={true}
                hasHover={hasHover}
                htmlTag="button"
                colorTheme="secondary"
                title={title}
                {...rest}
            />
        </ModalControl>

        <ModalControl>{children}</ModalControl>
    </ModalProvider>
);

EditModalIcon.propTypes = {
    title: PropTypes.string,
    hasHover: PropTypes.bool,
    children: PropTypes.node,
};

EditModalIcon.defaultProps = {
    hasHover: true,
};

export default EditModalIcon;
