import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DaInput, DaSelect, InternalGrid } from '@paygreen/paygreen-ui';
import { FormControlWrapper } from '../../../components';
import { IsMobile } from '../../../utils/tools';
import { billingAddressPatterns, countryOptions } from '../shared';

const UserCompanyBillingAddressForm = ({
    t,
    register,
    errors,
    setValue,
    onSubmit,
    children,
    defaultValues,
}) => {
    const transversalInputProps = {
        errors: errors,
        i18labelBase: 'user.company',
    };

    return (
        <form onSubmit={onSubmit} noValidate>
            <InternalGrid
                displayType="grid"
                gridGap="md"
                alignItems="stretch"
                justifyItems="stretch"
                gridTemplateColumns="1fr"
            >
                {billingAddressPatterns
                    .filter(
                        (element) =>
                            element.name === 'addressLine1' ||
                            element.name === 'addressLine2',
                    )
                    .map((element) => (
                        <FormControlWrapper
                            key={element.name}
                            inputName={element.name}
                            isRequired={element.name === 'addressLine1'}
                            marginBottom={
                                IsMobile() && element.name === 'addressLine2'
                                    ? 'sm'
                                    : null
                            }
                            {...transversalInputProps}
                        >
                            <DaInput
                                onChange={(e) =>
                                    setValue(element.name, e.target.value)
                                }
                                name={element.name}
                                id={element.name}
                                type={element.type}
                                defaultValue={defaultValues[element.name]}
                                inputRef={register({
                                    required:
                                        element.name === 'addressLine1'
                                            ? t('form.error.required')
                                            : null,
                                    pattern: {
                                        value: element.pattern,
                                        message: t(
                                            `form.error.${element.name}`,
                                        ),
                                    },
                                })}
                                blockWidth="lg"
                            />
                        </FormControlWrapper>
                    ))}
            </InternalGrid>

            <InternalGrid
                displayType="grid"
                gridGap="md"
                alignItems="stretch"
                justifyItems="stretch"
                gridTemplateColumns={IsMobile() ? '1fr' : '1fr 3fr'}
            >
                {billingAddressPatterns
                    .filter(
                        (elem) =>
                            elem.name === 'city' || elem.name === 'zipCode',
                    )
                    .map((element) => (
                        <FormControlWrapper
                            key={element.name}
                            inputName={element.name}
                            isRequired={true}
                            marginTop={IsMobile() ? null : 'md'}
                            {...transversalInputProps}
                        >
                            <DaInput
                                onChange={(e) =>
                                    setValue(element.name, e.target.value)
                                }
                                name={element.name}
                                id={element.name}
                                type={element.type}
                                defaultValue={defaultValues[element.name]}
                                inputRef={register({
                                    required: t('form.error.required'),
                                    pattern: {
                                        value: element.pattern,
                                        message: t(
                                            `form.error.${element.name}`,
                                        ),
                                    },
                                })}
                                blockWidth="md"
                            />
                        </FormControlWrapper>
                    ))}
            </InternalGrid>

            <InternalGrid
                displayType="grid"
                gridGap="md"
                alignItems="stretch"
                justifyItems="stretch"
                gridTemplateColumns={IsMobile() ? '1fr' : '1fr 1fr'}
            >
                {billingAddressPatterns
                    .filter((elem) => elem.name === 'region')
                    .map((element) => (
                        <FormControlWrapper
                            key={element.name}
                            inputName={element.name}
                            marginTop={IsMobile() ? null : 'md'}
                            {...transversalInputProps}
                        >
                            <DaInput
                                onChange={(e) =>
                                    setValue(element.name, e.target.value)
                                }
                                name={element.name}
                                id={element.name}
                                type={element.type}
                                defaultValue={defaultValues[element.name]}
                                inputRef={register({
                                    pattern: {
                                        value: element.pattern,
                                        message: t(
                                            `form.error.${element.name}`,
                                        ),
                                    },
                                })}
                                blockWidth="md"
                            />
                        </FormControlWrapper>
                    ))}

                <FormControlWrapper
                    hasStaticWidth={IsMobile()}
                    inputName="country"
                    isRequired={true}
                    marginTop={IsMobile() ? 'sm' : 'md'}
                    {...transversalInputProps}
                >
                    <DaSelect
                        hasStaticWidth={IsMobile()}
                        blockWidth="sm"
                        name="country"
                        id="country"
                        inputRef={register}
                        options={countryOptions(t)}
                        onChange={(e) => setValue('country', e.target.value)}
                        defaultValue={defaultValues.country}
                    />
                </FormControlWrapper>
            </InternalGrid>

            {children}
        </form>
    );
};

UserCompanyBillingAddressForm.propTypes = {
    setValue: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    /**
     * errors passed by react-hook-form for each field (necessary if you want errors management)
     */
    errors: PropTypes.object,
    defaultValues: PropTypes.object,
};

UserCompanyBillingAddressForm.defaultProps = {
    errors: {},
};

export default withTranslation(
    'common',
    'countries',
)(UserCompanyBillingAddressForm);
