import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { Box } from '@paygreen/paygreen-ui';
import { apiAuth } from '../../../utils/hooks';
import { H1Title, ErrorBoundary } from '../../../components';
import { useAPIError } from '../../../context';
import AdminUsersTable from './AdminUsersTable';

const AdminUsers = ({ t }) => {
    const { addError } = useAPIError();
    const fetchIdRef = React.useRef(0);

    // we create a ref to detect if component is mounted
    const isMounted = useRef(false);

    const [isLoading, setIsLoading] = useState(true);
    const [dataUsers, setDataUsers] = useState([]);
    const [pageCount, setPageCount] = useState(0);

    const fetchData = React.useCallback(
        ({
            pageSize,
            pageIndex,
            activationFilter,
            useArrondi,
            useTree,
            accountFilter,
            commercialFilter,
            customerSuccessFilter,
        }) => {
            const fetchId = ++fetchIdRef.current;
            if (fetchId === fetchIdRef.current) {
                isMounted.current && setIsLoading(true);

                const params = {
                    limit: pageSize,
                    page: pageIndex + 1,
                    status: activationFilter || null,
                    usesArrondi: useArrondi || null,
                    usesTree: useTree || null,
                    manager: accountFilter || null,
                    sales: commercialFilter || null,
                    customerSuccess: customerSuccessFilter || null,
                };

                apiAuth()
                    .get('/registration', {
                        params: params,
                    })
                    .then((res) => {
                        if (isMounted.current) {
                            setDataUsers(res.data.registrations);
                            setPageCount(res.data.page_count);
                        }
                    })
                    .catch((error) => {
                        if (error?.response?.data.status === 404) {
                            isMounted.current && setDataUsers([]);
                        } else {
                            addError(
                                error?.response?.data.detail,
                                error?.response?.data.status,
                            );
                        }
                    })
                    .finally(() => {
                        isMounted.current && setIsLoading(false);
                    });
            }
        },
        [addError],
    );

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    {t('admin.users.metaTitle')} - {t('main.metaTitle')}
                </title>
            </Helmet>

            <H1Title title={t('admin.users.title')} />

            <ErrorBoundary>
                <Box marginTop="lg">
                    <AdminUsersTable
                        fetchData={fetchData}
                        data={dataUsers}
                        isLoading={isLoading}
                        pageCount={pageCount}
                    />
                </Box>
            </ErrorBoundary>
        </>
    );
};

export default withTranslation('common')(AdminUsers);
