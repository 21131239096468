import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DaInput } from '@paygreen/paygreen-ui';

const PasswordValidatedInput = ({
    t,
    setValue,
    register,
    password,
    isRequired,
    isDisabled,
    inputName,
    inputType,
    inputPattern,
    blockWidth,
    fieldSize,
    autoComplete,
    defaultValue,
    hasValidator,
}) => (
    <DaInput
        onChange={(e) => setValue(inputName, e.target.value)}
        name={inputName}
        id={inputName}
        blockWidth={blockWidth}
        fieldSize={fieldSize}
        type={inputType}
        inputRef={register({
            required: isRequired ? t('form.error.required') : null,
            pattern: {
                value: inputPattern,
                message: t(`form.error.${inputName}`),
            },
            validate: hasValidator
                ? (value) =>
                      value === password.current ||
                      t('form.error.wrongPassword')
                : null,
        })}
        autoComplete={autoComplete}
        disabled={isDisabled}
        defaultValue={defaultValue}
    />
);

PasswordValidatedInput.propTypes = {
    setValue: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    password: PropTypes.object,
    inputName: PropTypes.string.isRequired,
    inputType: PropTypes.string.isRequired,
    inputPattern: PropTypes.object.isRequired,
    isRequired: PropTypes.bool,
    /**
     * errors passed by react-hook-form for each field (necessary if you want errors management)
     */
    errors: PropTypes.object,
    blockWidth: PropTypes.string,
    fieldSize: PropTypes.string,
    /**
     * @see https://developer.mozilla.org/fr/docs/Web/HTML/Attributes/autocomplete
     */
    autoComplete: PropTypes.string,
    hasValidator: PropTypes.bool,
};

PasswordValidatedInput.defaultProps = {
    errors: {},
    isRequired: false,
    isDisabled: false,
    blockWidth: 'lg',
    fieldSize: 'md',
    hasValidator: false,
};

export default withTranslation('common')(PasswordValidatedInput);
