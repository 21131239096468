import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    Badge,
    Box,
    Card,
    InternalGrid,
    Text,
    WheelsIcon,
    ModalControl,
    ModalProvider,
} from '@paygreen/paygreen-ui';
import { ErrorBoundary, HideAndCopy } from '../../../components';
import { UpdatePartnershipGroupModal } from '../shared';

const PartnershipGroupCard = ({
    t,
    children,
    isDefaultGroup,
    externalId,
    partnershipIds,
    getData,
    setIsPartnershipsGroupUpdating,
}) => {
    return (
        <ErrorBoundary>
            <Card
                hasBackground={true}
                blockWidth="md"
                colorWab="white20"
                paddingLateral="sm"
                paddingTop="sm"
                paddingBottom="xs"
                shadowSize="none"
                radiusSize="sm"
            >
                <InternalGrid
                    displayType="grid"
                    gridGap="md"
                    gridTemplateColumns="1fr auto"
                    childrenMarginBottom="md"
                    justifyItems="start"
                >
                    <InternalGrid
                        flexWrap="wrap"
                    >
                        <Box marginRight="md">
                            <Text
                                htmlTag="strong"
                                colorWab="grey30"
                                textSize="base"
                                marginInternal="none"
                                style={{ wordBreak: 'break-all' }}
                                mar
                            >
                                <HideAndCopy
                                    startLength={50}
                                    endLength={null}
                                    hasCopyButton={false}
                                    text={externalId}
                                    title={
                                        externalId?.length > 50
                                            ? externalId
                                            : ''
                                    }
                                />
                            </Text>
                        </Box>

                        {isDefaultGroup ? (
                            <Box marginTop="xs" marginBottom="xs">
                                <Badge
                                    colorTheme="secondary"
                                    textSize="tiny"
                                    colorStyle="light"
                                >
                                    {t('newPartnershipGroupForm.badge')}
                                </Badge>
                            </Box>
                        ) : null}
                    </InternalGrid>

                    <ModalProvider>
                        <ModalControl hasOnClickControl={true}>
                            <WheelsIcon
                                htmlTag="button"
                                iconSize="sm"
                                colorTheme="quaternary"
                                hasBackground={true}
                                hasHover={true}
                                isActive={true}
                            />
                        </ModalControl>

                        <ModalControl>
                            <UpdatePartnershipGroupModal
                                setIsPartnershipsGroupUpdating={
                                    setIsPartnershipsGroupUpdating
                                }
                                getData={() => getData()}
                                isDefaultGroup={isDefaultGroup}
                                externalId={externalId}
                                partnershipIds={partnershipIds}
                            />
                        </ModalControl>
                    </ModalProvider>
                </InternalGrid>

                <InternalGrid
                    childrenMarginBottom="md"
                    displayType="grid"
                    justifyItems="stretch"
                >
                    {children ? (
                        React.Children.map(children, (child, index) => {
                            if (!child) {
                                return null;
                            }
                            return React.cloneElement(child, {
                                isInPartnershipGroupCard: true,
                                key: index,
                            });
                        })
                    ) : (
                        <Text
                            textSize="sm"
                            align="center"
                            marginLateral="sm"
                            paddingBottom="sm"
                            paddingTop="sm"
                        >
                            {t('newPartnershipGroupButton.noPartnerInside')}
                        </Text>
                    )}
                </InternalGrid>
            </Card>
        </ErrorBoundary>
    );
};

PartnershipGroupCard.propTypes = {
    isDefaultGroup: PropTypes.bool,
    externalId: PropTypes.string,
    partnershipIds: PropTypes.array.isRequired,
    getData: PropTypes.func,
    setIsPartnershipsGroupUpdating: PropTypes.func,
};

PartnershipGroupCard.defaultProps = {
    isDefaultGroup: false,
};

export default withTranslation('common')(PartnershipGroupCard);
