import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import {
    SkeletonItem,
    ModalProvider,
    ModalControl,
} from '@paygreen/paygreen-ui';
import { useAPIError, useMode } from '../../../../context';
import { getFilteredPartnership } from '../../shared/partnershipsTools';
import { partnershipStatus } from '../../../../utils/constants';
import {
    Disabler,
    H1Title,
    H2Title,
    Introduction,
    ToggleMode,
} from '../../../../components';
import {
    PartnershipCardGrid,
    PartnershipGroupCard,
    PartnershipCardSkeleton,
    NewPartnershipGroupButton,
    NewPartnershipGroupModal
} from '../../shared';
import { getAllPartnerships, getAllPartnershipGroups } from '../../service';
import AvailablePartnerCharities from './AvailablePartnerCharities';
import PartnershipList from './PartnershipList';

const CharityPartners = ({ t }) => {
    const { addError } = useAPIError();
    const { mode } = useMode();

    // we create a ref to detect if component is mounted
    const isMounted = useRef(false);

    const [partnershipsData, setPartnershipsData] = useState();
    const [partnershipsGroupData, setPartnershipsGroupData] = useState();

    const [isLoading, setIsLoading] = useState(true);
    const [isPartnershipsGroupUpdating, setIsPartnershipsGroupUpdating] =
        useState(true);
    const [isPartnershipLoading, setIsPartnershipLoading] = useState(true);

    const getData = useCallback(() => {
        getAllPartnerships(mode)
            .then((res) => {
                isMounted.current &&
                    setPartnershipsData(res.data._embedded.partnership);
            })
            .catch((error) => {
                addError(
                    error?.response?.data.detail,
                    error?.response?.data.status,
                );
            })
            .finally(() => {
                isMounted.current && setIsPartnershipLoading(false);
            });

        getAllPartnershipGroups(mode)
            .then((res) => {
                isMounted.current &&
                    setPartnershipsGroupData(
                        res.data._embedded.partnership_group,
                    );
            })
            .catch((error) => {
                addError(
                    error?.response?.data.detail,
                    error?.response?.data.status,
                );
            })
            .finally(() => {
                if (isMounted.current) {
                    setIsLoading(false);
                    setIsPartnershipsGroupUpdating(false);
                }
            });
    }, [addError, mode]);

    useEffect(() => {
        isMounted.current = true;

        setIsLoading(true);
        setIsPartnershipLoading(true);
        getData();

        return () => {
            isMounted.current = false;
        };
    }, [getData, mode]);

    return (
        <>
            <Helmet>
                <title>
                    {t('ShopPartnerships.charityPartners.metaTitle')} -{' '}
                    {t('main.metaTitle')}
                </title>
            </Helmet>

            <H1Title title={t('ShopPartnerships.charityPartners.title')}>
                <ToggleMode />
            </H1Title>

            <H2Title marginTop="md">
                {t('ShopPartnerships.charityPartners.myGroupSubtitle')}
            </H2Title>

            <Introduction>
                {t('ShopPartnerships.charityPartners.myGroupIntroduction')}
            </Introduction>

            {isLoading || isPartnershipLoading ? (
                <PartnershipCardSkeleton blockWidth="md" />
            ) : (
                <Disabler>
                    <PartnershipCardGrid>
                        {partnershipsGroupData &&
                            partnershipsGroupData.map((group) => (
                                <SkeletonItem
                                    key={group.externalId}
                                    isLoading={isPartnershipsGroupUpdating}
                                    colorWab="grey10"
                                    radiusSize="sm"
                                    blockWidth="md"
                                >
                                    <PartnershipGroupCard
                                        isDefaultGroup={
                                            !!Number(group.isDefault)
                                        }
                                        externalId={group.externalId}
                                        partnershipIds={group.partnershipIds}
                                        getData={getData}
                                        setIsPartnershipsGroupUpdating={
                                            setIsPartnershipsGroupUpdating
                                        }
                                    >
                                        <PartnershipList
                                            group={group}
                                            partnershipsData={partnershipsData}
                                            getData={getData}
                                            setIsPartnershipsGroupUpdating={
                                                setIsPartnershipsGroupUpdating
                                            }
                                        />
                                    </PartnershipGroupCard>
                                </SkeletonItem>
                            ))}

                        <ModalProvider>
                            <ModalControl hasOnClickControl={true}>
                                <NewPartnershipGroupButton />
                            </ModalControl>

                            <ModalControl>
                                <NewPartnershipGroupModal
                                    setIsPartnershipsGroupUpdating={
                                        setIsPartnershipsGroupUpdating
                                    }
                                    getData={getData}
                                />
                            </ModalControl>
                        </ModalProvider>
                    </PartnershipCardGrid>
                </Disabler>
            )}

            <AvailablePartnerCharities
                isLoading={isLoading || isPartnershipLoading}
                partnershipsData={
                    partnershipsData &&
                    getFilteredPartnership(
                        partnershipsData,
                        partnershipStatus.ACTIVE,
                    )
                }
                partnershipsGroupData={partnershipsGroupData}
                getData={() => getData()}
                setIsPartnershipsGroupUpdating={setIsPartnershipsGroupUpdating}
            />
        </>
    );
};

export default withTranslation('common')(CharityPartners);
