import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    Box,
    ButtonGroup,
    DaInput,
    DaSelect,
    DaButton,
    Grid,
    Checkbox,
    CheckIcon,
    ThemeDefault,
    Link,
    Text,
    DaLabel,
} from '@paygreen/paygreen-ui';
import {
    Message,
    FormControlWrapper,
    PasswordValidatedInput,
    ControlledDaInput,
} from '../../components';
import { subscriptionInputsPatterns as inputsPatterns } from './subscriptionInputsPatterns';
import { IsDesktop } from '../../utils/tools';

const SubscriptionForm = ({
    t,
    errors,
    setValue,
    register,
    password,
    errorMessage,
    countryOptions,
    onSubmit,
    control,
}) => {
    const transversalInputProps = {
        errors: errors,
        i18labelBase: 'subscription',
        isRequired: true,
    };

    return (
        <>
            <form onSubmit={onSubmit} noValidate>
                <Grid
                    displayType="grid"
                    alignItems="stretch"
                    justifyItems="stretch"
                    gridTemplateColumns="1fr"
                    gridGap="sm"
                    childrenMarginBig="xs"
                    childrenMargin="sm"
                    blockPadding="none"
                    style={{ maxWidth: ThemeDefault.form.inputWidth.lg }}
                >
                    <FormControlWrapper
                        inputName={inputsPatterns[0].name}
                        hasStaticWidth={true}
                        hasChildrenStretched={true}
                        {...transversalInputProps}
                    >
                        <DaInput
                            onChange={(e) =>
                                setValue(inputsPatterns[0].name, e.target.value)
                            }
                            name={inputsPatterns[0].name}
                            id={inputsPatterns[0].name}
                            type={inputsPatterns[0].type}
                            inputRef={register({
                                required: t('form.error.required'),
                                pattern: {
                                    value: inputsPatterns[0].pattern,
                                    message: t(
                                        `form.error.${inputsPatterns[0].name}`,
                                    ),
                                },
                            })}
                            autoComplete="off"
                        />
                    </FormControlWrapper>

                    <Grid
                        displayType="grid"
                        gridGap="md"
                        alignItems="stretch"
                        justifyItems="stretch"
                        gridTemplateColumns="1fr 1fr"
                        childrenMarginBig="none"
                        blockPadding="none"
                    >
                        {inputsPatterns.slice(1, 4).map((element) => (
                            <FormControlWrapper
                                key={element.name}
                                inputName={element.name}
                                hasStaticWidth={true}
                                hasChildrenStretched={true}
                                {...transversalInputProps}
                            >
                                <DaInput
                                    onChange={(e) =>
                                        setValue(element.name, e.target.value)
                                    }
                                    name={element.name}
                                    id={element.name}
                                    type={element.type}
                                    inputRef={register({
                                        required: t('form.error.required'),
                                        pattern: {
                                            value: element.pattern,
                                            message: t(
                                                `form.error.${element.name}`,
                                            ),
                                        },
                                    })}
                                    autoComplete="off"
                                />
                            </FormControlWrapper>
                        ))}

                        <FormControlWrapper
                            inputName="phone"
                            {...transversalInputProps}
                        >
                            <ControlledDaInput
                                control={control}
                                inputName="phone"
                                isRequired={true}
                                inputPattern={inputsPatterns[4].pattern}
                                inputType="tel"
                                mask="+33 (0)9 99 99 99 99"
                                errorMessage={t(`form.error.phone`)}
                            />
                        </FormControlWrapper>

                        {inputsPatterns.slice(5, 7).map((element, index) => (
                            <FormControlWrapper
                                key={element.name}
                                inputName={element.name}
                                hasStaticWidth={true}
                                {...transversalInputProps}
                            >
                                <PasswordValidatedInput
                                    setValue={setValue}
                                    register={register}
                                    password={password}
                                    isRequired={true}
                                    inputName={element.name}
                                    inputType={element.type}
                                    inputPattern={element.pattern}
                                    autoComplete="off"
                                    hasValidator={
                                        element.name === 'passwordVerify'
                                    }
                                />

                                {index === 0 && (
                                    <Text textSize="xxs">
                                        {t('subscription.minLengthPassword')}
                                    </Text>
                                )}
                            </FormControlWrapper>
                        ))}

                        <FormControlWrapper
                            inputName="country"
                            {...transversalInputProps}
                        >
                            <DaSelect
                                blockWidth="lg"
                                name="country"
                                id="country"
                                inputRef={register}
                                options={countryOptions}
                                onChange={(e) =>
                                    setValue('country', e.target.value)
                                }
                            />
                        </FormControlWrapper>
                    </Grid>

                    <FormControlWrapper
                        inputName={
                            inputsPatterns[inputsPatterns.length - 1].name
                        }
                        hasStaticWidth={true}
                        errors={errors}
                        i18labelBase="subscription"
                        hasChildrenStretched={true}
                    >
                        <DaInput
                            onChange={(e) =>
                                setValue(
                                    inputsPatterns[inputsPatterns.length - 1]
                                        .name,
                                    e.target.value,
                                )
                            }
                            name={
                                inputsPatterns[inputsPatterns.length - 1].name
                            }
                            id={inputsPatterns[inputsPatterns.length - 1].name}
                            type={
                                inputsPatterns[inputsPatterns.length - 1].type
                            }
                            inputRef={register({
                                pattern: {
                                    value: inputsPatterns[
                                        inputsPatterns.length - 1
                                    ].pattern,
                                    message: t(
                                        `form.error.${
                                            inputsPatterns[
                                                inputsPatterns.length - 1
                                            ].name
                                        }`,
                                    ),
                                },
                            })}
                            autoComplete="off"
                        />
                    </FormControlWrapper>

                    {errorMessage && (
                        <Box marginLeft="xs" marginRight="xs">
                            <Message
                                message={errorMessage}
                                type="error"
                                paddingLateral="md"
                                marginTop="sm"
                            />
                        </Box>
                    )}

                    <Box paddingTop={!IsDesktop() ? 'sm' : 'xs'}>
                        <FormControlWrapper
                            inputName="cgu"
                            fieldSize="sm"
                            hasLabel={false}
                            {...transversalInputProps}
                        >
                            <DaLabel>
                                {t('user.spaces.modal.cguLabel')}
                                <a
                                    href="https://storage.googleapis.com/cck-pr-public/CGU%20CharityKit%202021%20-%20MAJ%2009.06.2021.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Link colorTheme="secondary">
                                        {t('user.spaces.modal.cguLink')}
                                    </Link>
                                </a>
                            </DaLabel>

                            <Checkbox
                                id="cgu"
                                name="cgu"
                                label={t('user.spaces.modal.checkCgu')}
                                value="0"
                                fieldSize="md"
                                inputRef={register({
                                    required: t('form.error.required'),
                                })}
                            />
                        </FormControlWrapper>
                    </Box>
                </Grid>

                <ButtonGroup marginTop="xs">
                    <button type="submit">
                        <DaButton
                            buttonSize="lg"
                            gradient="theme"
                            colorTheme="quaternary"
                            icon={<CheckIcon />}
                        >
                            {t('subscription.button')}
                        </DaButton>
                    </button>
                </ButtonGroup>
            </form>
        </>
    );
};

SubscriptionForm.propTypes = {
    setValue: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    password: PropTypes.object.isRequired,
    countryOptions: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    /**
     * errors passed by react-hook-form for each field (necessary if you want errors management)
     */
    errors: PropTypes.object,
    /**
     * error message received from Api if request fails
     */
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

SubscriptionForm.defaultProps = {
    errors: {},
    errorMessage: '',
};

export default withTranslation('common')(SubscriptionForm);
