import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { useAPIError, useMode } from '../../../context';
import {
    partnershipStatus,
    apiPartnershipStatus,
} from '../../../utils/constants';
import {
    H1Title,
    Introduction,
    ErrorBoundary,
    NoDataFoundCard,
    ToggleMode,
} from '../../../components';
import { getFilteredPartnership } from '../shared/partnershipsTools';
import { getAllPartnerships } from '../service';
import {
    PartnershipCardGrid,
    PartnershipCardSkeleton,
    PartnershipCollapsibleCard,
} from '../shared';

const CancelledShopPartnerships = ({ t }) => {
    const { addError } = useAPIError();

    const [partnershipsData, setPartnershipsData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const { mode } = useMode();

    useEffect(() => {
        let isMounted = true;
        setIsLoading(true);

        getAllPartnerships(mode)
            .then((res) => {
                isMounted &&
                    setPartnershipsData(
                        getFilteredPartnership(
                            res.data._embedded.partnership,
                            partnershipStatus.CANCELLED,
                        ),
                    );
            })
            .catch((error) => {
                addError(
                    error?.response?.data.detail,
                    error?.response?.data.status,
                );
            })
            .finally(() => {
                isMounted && setIsLoading(false);
            });

        return () => {
            isMounted = false;
        };
    }, [addError, mode]);

    return (
        <>
            <Helmet>
                <title>
                    {t('ShopPartnerships.cancelledPartnerships.metaTitle')} -{' '}
                    {t('main.metaTitle')}
                </title>
            </Helmet>

            <H1Title title={t('ShopPartnerships.cancelledPartnerships.title')}>
                <ToggleMode />
            </H1Title>

            <Introduction>
                {t('ShopPartnerships.cancelledPartnerships.introduction')}
            </Introduction>

            {isLoading ? (
                <PartnershipCardSkeleton blockWidth="md" />
            ) : partnershipsData && partnershipsData.length > 0 ? (
                <PartnershipCardGrid>
                    {partnershipsData.map((partnership, index) => (
                        <ErrorBoundary blockWidth="lg" key={index}>
                            <PartnershipCollapsibleCard
                                key={index}
                                data={partnership}
                                partnershipStatus={
                                    partnership.associationStatus ===
                                    apiPartnershipStatus.DELETE
                                        ? partnershipStatus.CANCELLEDBYCHARITY
                                        : partnershipStatus.CANCELLEDBYSHOP
                                }
                            />
                        </ErrorBoundary>
                    ))}
                </PartnershipCardGrid>
            ) : (
                <NoDataFoundCard
                    message={t(
                        'ShopPartnerships.cancelledPartnerships.noDataFound',
                    )}
                />
            )}
        </>
    );
};

export default withTranslation('common')(CancelledShopPartnerships);
