import dayjs from './dayjsHelper';

/* User's Roles */
const roleOptionsPaygreen = ['ADMIN', 'USER', 'PAYGREEN', 'ASSOCIATION'];
const roleOptions = ['ADMIN', 'USER', 'ASSOCIATION'];
const roleDefault = roleOptions.USER;

/* Dates */
const startOfMonth = dayjs().startOf('month').format('DD/MM/YYYY');
const endOfMonth = dayjs().endOf('month').format('DD/MM/YYYY');

/* Donations Status */
const statusDonations = ['SUCCESS', 'REFUND'];

/* Partnerships Status Values used in BO */
const partnershipStatus = {
    ACTIVE: 'ACTIVE',
    CANCELLEDBYSHOP: 'CANCELLEDBYSHOP',
    CANCELLEDBYCHARITY: 'CANCELLEDBYCHARITY',
    PENDING: 'PENDING',
    REFUSED: 'REFUSED',
};

/* Partnerships Status Values from API */
const apiPartnershipStatus = {
    ACCEPT: 'ACCEPT',
    WAITING: 'WAITING',
    REFUSE: 'REFUSE',
    DELETE: 'DELETE',
};

/* PartnershipGroups Status Values */
const partnershipGroupStatus = {
    none: 'none',
    some: 'some',
    all: 'all',
};

/* PartnershipGroup Form modes */
const partnershipGroupFormModes = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
};

/* Login local status messages type */
const localLoginStatus = {
    success: 'success',
    warning: 'warning',
};

/* Login account error message modes */
const accountErrorModes = {
    notRegistered: 'notRegistered',
    notValidated: 'notValidated',
};

/* Sepa user Status */
const statusSepa = ['SIGNED', 'NOTSIGNED'];

/* Account user Status */
const statusActivation = ['CREATED', 'VALIDATED'];

/* Spaces activated user Status */
const statusSpaces = ['CLIMATE', 'CHARITY', 'CLIMATECHARITY'];

/* Billing messages modes depending on environment and billing status */
const billingDisplayModes = {
    adress: 'adress',
    iban: 'iban',
};

/* Carbon Modes */
const carbonModes = { carbon: 'carbon', price: 'price' };

/* All locale fr languages available in browsers */
const frLangLocale = [
    'fr-FR',
    ['fr'],
    'fr',
    'fr-BE',
    'fr-CA',
    'fr-CH',
    'fr-LU',
    'fr-MC',
];

const MODES = {
    TEST: 'test',
    PROD: 'prod',
};

/* Spaces available */
const spaces = {
    charity: 'charity',
    climate: 'climate',
};

export {
    billingDisplayModes,
    carbonModes,
    roleOptions,
    roleOptionsPaygreen,
    roleDefault,
    startOfMonth,
    endOfMonth,
    statusDonations,
    partnershipStatus,
    partnershipGroupStatus,
    apiPartnershipStatus,
    partnershipGroupFormModes,
    accountErrorModes,
    statusSepa,
    statusActivation,
    statusSpaces,
    frLangLocale,
    MODES,
    spaces,
    localLoginStatus
};
