/**
 * @param {object} dataToken - object received from API after sdk.authentication.login() request, containing string values 'acces_token', 'refresh_token', 'expires_in'.
 * @param {object} dataUser  - object with user informations from login form, containing string values 'accountId' and 'username'
 */
export const setAuthLocalStorage = (dataToken, dataUser) => {
    if (dataToken.access_token) {
        localStorage.setItem(
            'access_token',
            JSON.stringify(dataToken.access_token),
        );
        localStorage.setItem(
            'refresh_token',
            JSON.stringify(dataToken.refresh_token),
        );
        let expiresAt = JSON.stringify(
            dataToken.expires_in + new Date().getTime() / 1000,
        );
        localStorage.setItem('token_expires_at', expiresAt);
        localStorage.setItem('account_id', JSON.stringify(dataUser.accountId));
        localStorage.setItem('username', JSON.stringify(dataUser.username));
    }
};

/**
 * @param {object} dataToken - object received from API after sdk.authentication.login() request, containing string values 'acces_token', 'refresh_token', 'expires_in'.
 */
export const updateAuthLocalStorage = (dataToken) => {
    if (dataToken.access_token) {
        localStorage.setItem(
            'access_token',
            JSON.stringify(dataToken.access_token),
        );
        localStorage.setItem(
            'refresh_token',
            JSON.stringify(dataToken.refresh_token),
        );
        let expiresAt = JSON.stringify(
            dataToken.expires_in + new Date().getTime() / 1000,
        );
        localStorage.setItem('token_expires_at', expiresAt);
    }
};
