import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
    ButtonGroup,
    ModalControl,
    ModalProvider,
} from '@paygreen/paygreen-ui';
import { EditButton } from '../../../components';
import { apiAuth } from '../../../utils/hooks';
import UserCompanyBillingAddressModal from './UserCompanyBillingAddressModal';
import UserDisplayBillingData from './UserDisplayBillingData';
import { useAPIError } from '../../../context';

const UserCompanyBillingAddress = ({ t, account, setHasAdressFilled }) => {
    const { addError } = useAPIError();

    // we create a ref to detect if component is mounted
    const isMounted = useRef(false);

    const [billingAddressData, setBillingAddressData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    /**
     * GET request to API to get user's billing address
     * @returns {object} - object with all billing address data
     */
    const getAccountBillingAddress = useCallback(
        (account) => {
            apiAuth()
                .get(`/account/${account}/postalAddress/default`)
                .then((res) => {
                    if (isMounted.current) {
                        setBillingAddressData(res.data);
                        setHasAdressFilled(true);
                    }
                })
                .catch((error) => {
                    // This is normal, it possible to don't have billingAddress
                    if (
                        isMounted.current &&
                        error?.response?.data.status !== 404
                    ) {
                        addError(
                            error?.response?.data.detail,
                            error?.response?.data.status,
                        );
                    }
                })
                .finally(() => isMounted.current && setIsLoading(false));
        },
        [addError, setHasAdressFilled],
    );

    useEffect(() => {
        isMounted.current = true;

        if (account) {
            getAccountBillingAddress(account);
        }

        return () => {
            isMounted.current = false;
        };
    }, [getAccountBillingAddress, account]);

    return (
        <UserDisplayBillingData
            label={t('user.company.billingAddress')}
            data={billingAddressData}
            isLoading={isLoading}
        >
            <ModalProvider>
                <ButtonGroup>
                    <ModalControl hasOnClickControl={true}>
                        <EditButton buttonSize="sm" buttonStyle="line">
                            {billingAddressData
                                ? t('form.button.edit')
                                : t('form.button.add')}
                        </EditButton>
                    </ModalControl>
                </ButtonGroup>

                <ModalControl>
                    <UserCompanyBillingAddressModal
                        account={account}
                        data={billingAddressData}
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        setHasAdressFilled={setHasAdressFilled}
                        setBillingAddressData={setBillingAddressData}
                    />
                </ModalControl>
            </ModalProvider>
        </UserDisplayBillingData>
    );
};

export default withTranslation('common')(UserCompanyBillingAddress);
