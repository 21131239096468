import React from 'react';
import PropTypes from 'prop-types';
import { DaButton } from '@paygreen/paygreen-ui';
import IconByName from '../../utils/IconByName';

const SimpleActionButton = ({
    buttonSize,
    children,
    icon,
    onClick,
    dataCy,
}) => {
    return (
        <button type="button" onClick={onClick} data-cy={dataCy}>
            <DaButton
                buttonSize={buttonSize}
                buttonStyle="line"
                colorPallet="status"
                colorStatus="default"
                icon={<IconByName name={icon} />}
            >
                {children}
            </DaButton>
        </button>
    );
};

SimpleActionButton.propTypes = {
    onClick: PropTypes.func,
    buttonSize: PropTypes.string,
    icon: PropTypes.string,
    /**
     * Id for Cypress tests
     */
    dataCy: PropTypes.string,
};

SimpleActionButton.defaultProps = {
    buttonSize: 'sm',
    icon: 'OutIcon',
};

export default SimpleActionButton;
