import React, { createContext, useState, useEffect, useMemo } from 'react';
import { useAPIError } from './';
import { partnershipStatus } from '../utils/constants';
import { getFilteredPartnership } from '../domains/partnerships/shared/partnershipsTools';
import {
    getAllCharities,
    getAllPartnerships,
} from '../domains/partnerships/service';
import { useMode } from '.';

/**
 * @description - We create splitted contexts to avoid unecessary re-render between components that share global charity provider
 */
export const CharityListContext = createContext();
export const PendingPartnershipsContext = createContext();

/**
 * @description - We create a global Charity Context Provider for all the data linked to charity activities (list of all charities, list of partnerships, etc...) shared inside BO
 */
const CharityProvider = ({ children }) => {
    const { addError } = useAPIError();
    const { mode } = useMode();

    const [charityListData, setCharityListData] = useState();
    const charityListValue = useMemo(
        () => ({ charityListData, setCharityListData }),
        [charityListData],
    );

    const [pendingPartnershipsData, setPendingPartnershipsData] = useState();
    const pendingPartnershipsValue = useMemo(
        () => ({ pendingPartnershipsData, setPendingPartnershipsData }),
        [pendingPartnershipsData],
    );

    // depending on user's role this request will get either all charities from API (PAYGREEN or ADMIN) or information about the charity linked to username (ASSOCIATION)
    const params = { limit: 100 };

    const getCharityListData = () => {
        getAllCharities(params, mode)
            .then((res) => {
                setCharityListData(res.data._embedded.association);
            })
            .catch((error) => {
                addError(
                    error?.response?.data.detail,
                    error?.response?.data.status,
                );
            });
    };

    const getPendingPartnershipsData = () => {
        getAllPartnerships(mode)
            .then((res) => {
                setPendingPartnershipsData(
                    getFilteredPartnership(
                        res.data._embedded.partnership,
                        partnershipStatus.PENDING,
                    ),
                );
            })
            .catch((error) => {
                addError(
                    error?.response?.data.detail,
                    error?.response?.data.status,
                );
            });
    };

    useEffect(getCharityListData, [mode]);
    useEffect(getPendingPartnershipsData, [mode]);

    return (
        <CharityListContext.Provider value={charityListValue}>
            <PendingPartnershipsContext.Provider
                value={pendingPartnershipsValue}
            >
                {children}
            </PendingPartnershipsContext.Provider>
        </CharityListContext.Provider>
    );
};

export default CharityProvider;
