import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import { Card, InternalGrid, Link, Text, Title } from '@paygreen/paygreen-ui';
import { apiPublic } from '../../utils/hooks';
import { Message, MainLogin } from '../../components';

const Validation = ({ t }) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const urlParams = new URLSearchParams(useLocation().search);

    const paramToken = urlParams.get('token');
    const paramSecret = urlParams.get('secret');

    useEffect(() => {
        let isMounted = true;

        if (paramToken && paramSecret) {
            apiPublic()
                .patch('/registration/validate', {
                    token: paramToken,
                    secret: paramSecret,
                })
                .then((res) => {
                    if (isMounted) {
                        setUserData(res.data);
                        setIsSuccess(true);
                    }
                })
                .catch(
                    (error) =>
                        isMounted &&
                        setErrorMessage(error.response && error.response.data),
                )
                .finally(() => isMounted && setIsLoading(false));
        } else {
            if (isMounted) {
                setErrorMessage({ detail: t('validation.missingTokens') });
                setIsLoading(false);
            }
        }
        return () => {
            isMounted = false;
        };
    }, [t, paramToken, paramSecret]);

    // we wait until loading is done to redirect to login page, if validation is successful or with 422 error from API (already validated message)
    if (
        !isLoading &&
        ((errorMessage && errorMessage.detail.includes('already validated')) ||
            isSuccess)
    ) {
        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: {
                        accountId: userData && userData.accountId,
                        username: userData && userData.username,
                        status: isSuccess ? 'success' : 'warning',
                        messageType: 'validation',
                    },
                }}
            />
        );
    }

    return (
        <MainLogin
            helmetTitle={
                t('validation.metaTitle') + ' - ' + t('main.metaTitle')
            }
            isLoading={isLoading}
            isValidation={true}
        >
            {!isLoading ? (
                <Card
                    blockWidth="lg"
                    paddingLateral="lg"
                    paddingTop="md"
                    paddingBottom="md"
                    isShadowWab={true}
                    radiusSize="lg"
                >
                    <Title
                        textSize="md"
                        marginBottom="sm"
                        marginLateral="xs"
                        align="center"
                        colorWab="grey30"
                    >
                        {t('validation.errorTitle')}
                    </Title>

                    <InternalGrid
                        justifyContent="center"
                        alignItems="center"
                        childrenMarginBottom="md"
                        childrenMarginTop="md"
                        childrenMarginLateral="md"
                    >
                        {/*not using Trans component here because it doesn't work with node element like <a>*/}
                        <Text textSize="sm" align="center" htmlTag="p">
                            {t('validation.errorText')}

                            <Text textSize="sm" htmlTag="strong">
                                <a href="mailto:serviceclient@paygreen.fr">
                                    <Link colorPallet="wab" colorWab="grey60">
                                        {t('validation.supportTeam')}
                                    </Link>
                                </a>
                            </Text>
                        </Text>

                        {errorMessage && errorMessage.detail ? (
                            <Message
                                message={errorMessage.detail}
                                type="error"
                                paddingLateral="lg"
                                alignText="center"
                            />
                        ) : null}
                    </InternalGrid>
                </Card>
            ) : null}
        </MainLogin>
    );
};

export default withTranslation('common')(Validation);
