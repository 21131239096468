import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    InternalGrid,
    Text,
    DaInput,
    SkeletonItem,
} from '@paygreen/paygreen-ui';
import { IsDesktop } from '../../../utils/tools';
import { CardClassic, FormControlWrapper } from '../../../components';
import { userInputsPatterns } from '../shared';

const UserLoginCredentials = ({ t, isLoading, defaultValues }) => (
    <CardClassic
        title={t('user.see.loginId')}
        titleSize="md"
        shadowSize="none"
        colorWab="white20"
        blockWidth={IsDesktop() ? 'sm' : 'md'}
    >
        <InternalGrid
            displayType="grid"
            gridGap="md"
            alignItems="stretch"
            justifyItems="stretch"
            gridTemplateColumns="1fr"
            childrenMarginBottom="none"
            childrenMarginTop="none"
        >
            {userInputsPatterns
                .filter(
                    (elem) =>
                        elem.name === 'accountId' || elem.name === 'username',
                )
                .map((element) => (
                    <FormControlWrapper
                        key={element.name}
                        inputName={element.name}
                        i18labelBase="user"
                    >
                        <SkeletonItem isLoading={isLoading}>
                            <DaInput
                                disabled={true}
                                name={element.name}
                                id={element.name}
                                defaultValue={defaultValues[element.name]}
                                hasStaticWidth={true}
                            />
                        </SkeletonItem>
                    </FormControlWrapper>
                ))}
        </InternalGrid>

        <Text
            colorWab="grey40"
            marginBottom="xs"
            textSize={IsDesktop() ? 'xxs' : 'xs'}
            marginTop="sm"
        >
            {t('user.edit.warningText')}
        </Text>
    </CardClassic>
);

UserLoginCredentials.propTypes = {
    isLoading: PropTypes.bool,
    defaultValues: PropTypes.object.isRequired,
};

UserLoginCredentials.defaultProps = {
    isLoading: false,
};

export default withTranslation('common')(UserLoginCredentials);
