import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { InternalGrid } from '@paygreen/paygreen-ui';
import { useAPIError, useCharityList } from '../../context';
import { formatDateApi, formatCentsMoney, IsDesktop } from '../../utils/tools';
import { startOfMonth, endOfMonth } from '../../utils/constants';
import { getAllPartnerships } from '../partnerships/service';
import DashboardDataCards from './DashboardDataCards';
import { ErrorBoundary, H1Title, NoDataFoundMessage } from '../../components';
import DashboardFilter from './DashboardFilter';
import DashboardCardHistogram from './DashboardCardHistogram';
import { getDonationReports } from './service';

const CharityDashboard = ({ t }) => {
    const { addError } = useAPIError();
    const { charityListData } = useCharityList();

    const [reportsData, setReportsData] = useState();
    const [histogramData, setHistogramData] = useState();
    const [partnershipsData, setPartnershipsData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [noDataMessage, setNoDataMessage] = useState();

    const [startDate, setStartDate] = useState(startOfMonth);
    const [endDate, setEndDate] = useState(endOfMonth);
    const [partner, setPartner] = useState({});

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setIsLoading(true);
            setNoDataMessage();
        }

        const params = {
            begin: formatDateApi(startDate),
            end: formatDateApi(endDate),
            association:
                charityListData && Number(charityListData[0]?.idAssociation),
            user: partner.id || null,
        };

        if (params.association) {
            const fetchGlobalData = getDonationReports(params)
                .then((res) => {
                    if (res.data.donationCount === 0) {
                        isMounted &&
                            setNoDataMessage(t('dashboard.noDataFound'));
                    } else {
                        return res.data;
                    }
                })
                .catch((error) => {
                    addError(
                        error?.response?.data.detail,
                        error?.response?.data.status,
                    );
                });

            const fetchHistogramData = getDonationReports({
                ...params,
                daily: 1,
            })
                .then((res) => {
                    if (res.data.donationCount === 0) {
                        isMounted &&
                            setNoDataMessage(t('dashboard.noDataFound'));
                    } else {
                        return res.data.daily;
                    }
                })
                .catch((error) => {
                    addError(
                        error?.response?.data.detail,
                        error?.response?.data.status,
                    );
                });

            Promise.all([fetchGlobalData, fetchHistogramData]).then((res) => {
                if (isMounted) {
                    setReportsData(res[0] || null);

                    setHistogramData(res[1] || null);
                    setIsLoading(false);
                }
            });
        }

        return () => {
            isMounted = false;
        };
    }, [startDate, endDate, addError, charityListData, partner.id, t]);

    useEffect(() => {
        let isMounted = true;

        getAllPartnerships()
            .then((res) => {
                if (isMounted) {
                    // we keep only associations from partnerships that are or have been active at some point
                    setPartnershipsData(
                        res.data._embedded.partnership
                            .filter(
                                (item) =>
                                    item.associationStatus !== 'REFUSE' &&
                                    item.associationStatus !== 'WAITING',
                            )
                            .map(({ idAccount, idUser }) => ({
                                idAccount,
                                idUser,
                            })),
                    );
                }
            })
            .catch((error) => {
                if (error?.response?.data.status === 404) {
                    isMounted && setPartnershipsData([]);
                } else {
                    addError(
                        error?.response?.data.detail,
                        error?.response?.data.status,
                    );
                }
            });

        return () => {
            isMounted = false;
        };
    }, [addError]);

    return (
        <>
            <Helmet>
                <title>
                    {t('dashboard.metaTitle')} - {t('main.metaTitle')}
                </title>
            </Helmet>

            <H1Title title={t('dashboard.title')} />

            <InternalGrid
                displayType="grid"
                childrenMarginBottom="lg"
                justifyItems="stretch"
                alignItems="stretch"
                gridTemplateColumns="100%"
                hasStaticWidth={IsDesktop() ? false : true}
            >
                <InternalGrid
                    displayType="grid"
                    childrenMarginBottom={noDataMessage ? 'md' : null}
                    justifyItems="stretch"
                    alignItems="stretch"
                    gridTemplateColumns="1fr"
                    hasStaticWidth={false}
                >
                    <DashboardFilter
                        partnershipsData={partnershipsData}
                        setEndDate={setEndDate}
                        setStartDate={setStartDate}
                        startDate={startDate}
                        endDate={endDate}
                        setSelectorId={setPartner}
                    />

                    {noDataMessage && <NoDataFoundMessage />}
                </InternalGrid>

                <DashboardDataCards
                    isLoading={isLoading}
                    totalDonationAmount={
                        reportsData &&
                        formatCentsMoney(reportsData.totalDonationAmount)
                    }
                    donationCount={reportsData && reportsData.donationCount}
                    averageDonationAmount={
                        reportsData &&
                        formatCentsMoney(reportsData.averageDonationAmount)
                    }
                />

                <ErrorBoundary>
                    <DashboardCardHistogram
                        histogramData={histogramData}
                        isLoading={isLoading}
                        endDateValue={endDate}
                        startDateValue={startDate}
                        partner={partner}
                    />
                </ErrorBoundary>
            </InternalGrid>
        </>
    );
};

export default withTranslation('common')(CharityDashboard);
