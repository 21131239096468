import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {
    Box,
    Main,
    Layout as LayoutBO,
    ThemeDefault,
} from '@paygreen/paygreen-ui';
import { IsDesktop } from '../../utils/tools';
import { useUser } from '../../context';
import { AlertSepa } from '../../domains/user/alert';
import { BillingMessage } from '..';
import SideBar from './SideBar';
import Topbar from './Topbar';
import ToastNotification from './ToastNotification';

const Layout = ({ children }) => {
    const { user } = useUser();

    const localSidebarState =
        localStorage.sidebar_state && JSON.parse(localStorage.sidebar_state);
    const sidebarState =
        localSidebarState ??
        window.matchMedia(`${ThemeDefault.screen.min.md}`).matches;
    const [isOpen, toggleIsOpen] = useState(sidebarState);

    const handleSidebarState = (value) => {
        toggleIsOpen(value);
        localStorage.setItem('sidebar_state', JSON.stringify(value));
    };

    // we display alert if user is not ASSOCIATION or PAYGREEN, if sepa is not signed
    const hasAlertDisplayed =
        user?.role &&
        !['ASSOCIATION', 'PAYGREEN'].includes(user.role) &&
        user?.sepaStatus === 'UNSIGNED';

    // we display expiration date in message only if it is soon (in less than 2 months or 5260000 seconds))
    const limit = dayjs(user?.expirationDate);
    const now = dayjs().unix();
    const isExpiredSoon = user && limit.diff(now) < 5260000;

    const billingMessageMode = () => {
        switch (true) {
            case user?.isExpired:
                return 'expired';
            case isExpiredSoon:
                return 'warning';
            default:
                return 'warningLater';
        }
    };

    return (
        <LayoutBO>
            <SideBar isOpen={isOpen} toggleIsOpen={handleSidebarState} />

            <Main isOpen={isOpen}>
                <Topbar isOpen={isOpen} toggleIsOpen={handleSidebarState} />

                {/* Remember to render the ToastContainer once in your application tree. https://fkhadra.github.io/react-toastify/installation */}
                <ToastNotification />

                {/*These imbricated Boxes are necessary to preserve padding in child Box with parent Box with inherited scroll auto */}
                <Box>
                    <Box padding={IsDesktop() ? 'lg' : 'sm'}>
                        {hasAlertDisplayed ? (
                            <BillingMessage
                                expirationDate={user?.expirationDate}
                                mode={billingMessageMode()}
                            />
                        ) : null}

                        {children}
                    </Box>
                </Box>

                {hasAlertDisplayed ? (
                    <AlertSepa isExpired={user?.isExpired} />
                ) : null}
            </Main>
        </LayoutBO>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
