import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Message, Text, ThemeDefault } from '@paygreen/paygreen-ui';
import { partnershipGroupStatus as partnershipGroupStatusEnum } from '../../../utils/constants';
import IconByName from '../../../utils/IconByName';
import { IsMobile } from '../../../utils/tools';

const IconMessageDisplay = styled.div`
    cursor: default;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    @media ${ThemeDefault.screen.max.sm} {
        align-items: flex-end;
    }

    .message-child {
        pointer-events: none;
        z-index: ${ThemeDefault.zindex.front};
        position: absolute;
        min-width: 18ch;
        opacity: 0;
        transition: all ${ThemeDefault.transition.xs};
        transform: translateY(115%);
    }

    &:hover {
        z-index: ${ThemeDefault.zindex.content};

        .message-child {
            pointer-events: initial;
            opacity: 1;
        }
    }
`;

const PartnershipGroupStatus = ({ t, partnershipGroupStatus }) => {
    let iconName = null;
    let colorStatus = null;
    let messageContent = null;

    switch (partnershipGroupStatus) {
        case partnershipGroupStatusEnum.some:
            iconName = 'CheckBoldIcon';
            colorStatus = 'success';
            messageContent = t('newPartnershipGroupStatus.some');
            break;
        case partnershipGroupStatusEnum.all:
            iconName = 'CheckBoldIcon';
            colorStatus = 'success';
            messageContent = t('newPartnershipGroupStatus.all');
            break;
        default:
            iconName = 'EmptyIcon';
            colorStatus = 'warning';
            messageContent = t('newPartnershipGroupStatus.none');
            break;
    }

    const iconStyle = {
        iconSize: 'md',
        colorPallet: 'status',
    };

    return (
        <IconMessageDisplay>
            <IconByName
                name={iconName}
                colorStatus={colorStatus}
                {...iconStyle}
            />

            <Message
                className="message-child"
                arrowBlock={IsMobile() ? 'topRight' : 'center'}
                colorType="reverse"
                colorStatus={colorStatus}
                blockWidth="sm"
                fieldSize="sm"
            >
                <Text align="center" textSize="xxs">
                    {messageContent}
                </Text>
            </Message>
        </IconMessageDisplay>
    );
};

PartnershipGroupStatus.propTypes = {
    partnershipGroupStatus: PropTypes.oneOf(
        Object.values(partnershipGroupStatusEnum),
    ),
};

PartnershipGroupStatus.defaultProps = {
    partnershipGroupStatus: partnershipGroupStatusEnum.none,
};

export default withTranslation('common')(PartnershipGroupStatus);
