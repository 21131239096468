import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ButtonGroup, InternalGrid } from '@paygreen/paygreen-ui';
import { LineKeyValue } from '../../index';
import { IsMobile, displayFrenchPhoneNumber } from '../../../utils/tools';
import styled from 'styled-components';

const DisplayFormWrapper = styled.div`
    box-sizing: border-box;
    height: calc(100% - ${(props) => props.theme.space.lg});
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
`;

const DisplayForm = ({
    t,
    data,
    isLoading,
    inputsNames,
    i18labelBase,
    children,
}) => {
    const internationalPhone = displayFrenchPhoneNumber(data?.phone);

    return (
        <DisplayFormWrapper>
            <InternalGrid
                displayType="grid"
                gridTemplateColumns={IsMobile() ? '1fr' : 'auto 1fr'}
                justifyItems="stretch"
                alignItems={isLoading ? 'stretch' : 'baseline'}
                gridGap="md"
                childrenMarginTop="none"
                childrenMarginBottom="none"
            >
                {inputsNames.map((element) => (
                    <LineKeyValue
                        key={element.name}
                        keyLabel={t(
                            i18labelBase + '.' + element.name + 'Input',
                        )}
                        keyValue={
                            element.name === 'country'
                                ? t(`countries:${data[element.name]}`)
                                : element.name === 'phone'
                                ? internationalPhone
                                : data[element.name]
                        }
                        isLoading={isLoading}
                        dataCy={`lineKey-${element.name}`}
                    />
                ))}
            </InternalGrid>

            {children && (
                <ButtonGroup
                    marginTop="sm"
                    marginBottom="sm"
                    style={{ alignSelf: 'center' }}
                >
                    {children}
                </ButtonGroup>
            )}
        </DisplayFormWrapper>
    );
};

DisplayForm.propTypes = {
    data: PropTypes.object.isRequired,
    inputsNames: PropTypes.array.isRequired,
    i18labelBase: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
};

DisplayForm.defaultProps = {
    isLoading: false,
};

export default withTranslation('common', 'countries')(DisplayForm);
