import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Char, InternalGrid } from '@paygreen/paygreen-ui';
import IconByName from '../../utils/IconByName';
import { useClipboard } from '../../utils/hooks';

/**
 * @description this component hide (truncate) text and copy its value
 */
const HideAndCopy = ({
    text,
    startLength,
    endLength,
    hasCopyButton,
    hasHiddenText,
    iconSize,
    icon,
    title,
    t,
    ...rest
}) => {
    const { hasCopied, onCopy } = useClipboard(text);

    return (
        <InternalGrid
            alignItems="center"
            justifyContent="space-between"
            flexWrap="nowrap"
            style={{ width: '100%' }}
            title={title}
        >
            {hasHiddenText ? (
                <Char
                    text={text || ''}
                    startLength={startLength}
                    endLength={endLength}
                    {...rest}
                />
            ) : null}

            {hasCopyButton && (
                <IconByName
                    name={hasCopied ? 'CheckIcon' : icon ? icon : 'PopinIcon'}
                    onClick={() => onCopy()}
                    marginLeft="sm"
                    hasBackground={true}
                    hasHover={true}
                    htmlTag="button"
                    colorTheme={hasCopied ? 'primary' : 'secondary'}
                    isActive={hasCopied}
                    title={title ? title : t('copyData')}
                    iconSize={iconSize}
                />
            )}
        </InternalGrid>
    );
};

HideAndCopy.propTypes = {
    text: PropTypes.string,
    startLength: PropTypes.number,
    endLength: PropTypes.number,
    hasCopyButton: PropTypes.bool,
    hasHiddenText: PropTypes.bool,
    iconSize: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
};

HideAndCopy.defaultProps = {
    startLength: null,
    endLength: null,
    hasCopyButton: true,
    hasHiddenText: true,
    iconSize: 'sm',
};

export default withTranslation('common')(HideAndCopy);
