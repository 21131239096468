import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@paygreen/paygreen-ui';
import { IsDesktop } from '../../utils/tools';
import { ModeDisplay } from '../../components';
import { LogoPaygreen, CharityKitBadge } from './index';

const LogoPaygreenWithMode = ({ justifyContent, className }) => (
    <Box className={className}>
        <RouterLink to="/">
            <LogoPaygreen badge={<CharityKitBadge hoverDirection="none" />} />
        </RouterLink>

        <ModeDisplay
            hasDot={false}
            justifyContent={IsDesktop() ? justifyContent : 'center'}
        />
    </Box>
);

LogoPaygreenWithMode.propTypes = {
    justifyContent: PropTypes.string,
    className: PropTypes.string,
};

LogoPaygreenWithMode.defaultProps = {
    justifyContent: 'center',
    className: '',
};

export default LogoPaygreenWithMode;
