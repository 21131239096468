import React from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import { Text } from '@paygreen/paygreen-ui';
import { localLoginStatus } from '../../utils/constants';
import { Message } from '../../components';

const LocationMessage = ({ t, locationStatus, location }) => {
    const { messageType, username } = location?.state;

    return (
        <Message
            type={locationStatus}
            marginBottom="md"
            paddingLateral="xs"
            blockWidth="sm"
            hasStaticWidth={false}
        >
            <Text textSize="xs">
                {locationStatus === localLoginStatus.success ? (
                    <>
                        {messageType === 'validation' ? (
                            <Trans i18nKey="validation.success">
                                Thank you
                                <Text
                                    htmlTag="strong"
                                    colorPallet="theme"
                                    textSize="xs"
                                >
                                    {{
                                        username,
                                    }}
                                </Text>
                                validated
                            </Trans>
                        ) : (
                            <Trans i18nKey="resetPassword.newPasswordMessage">
                                <Text
                                    htmlTag="strong"
                                    colorPallet="theme"
                                    textSize="xs"
                                >
                                    {{
                                        passwordSuccess: t(
                                            'resetPassword.passwordSuccess',
                                        ),
                                    }}
                                </Text>
                                warning api
                            </Trans>
                        )}
                    </>
                ) : (
                    t('validation.fail')
                )}
            </Text>
        </Message>
    );
};

LocationMessage.propTypes = {
    locationStatus: PropTypes.oneOf(Object.values(localLoginStatus)),
    location: PropTypes.object,
};

LocationMessage.defaultProps = {
    locationStatus: 'success',
};

export default withTranslation('common')(LocationMessage);
