import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm, FormProvider } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import {
    Box,
    ButtonGroup,
    DaButton,
    InternalGrid,
    CoinsIcon,
    ThemeDefault,
} from '@paygreen/paygreen-ui';
import { useAPIError, useUser } from '../../../context';
import { IsMobile } from '../../../utils/tools';
import { useNodeDimensions } from '../../../utils/hooks';
import {
    ActionBadge,
    CardClassic,
    ErrorBoundary,
    H1Title,
    CancelButton,
    ValidateButton,
} from '../../../components';
import { apiAuth } from '../../../utils/hooks';
import UserCompanyEditForm from './UserCompanyEditForm';
import UserCompanyBillingAddress from './UserCompanyBillingAddress';
import UserCompanySepa from './UserCompanySepa';

const UserCompany = ({ t }) => {
    const { addError } = useAPIError();
    const { user } = useUser();

    // we create a ref to detect if component is mounted
    const isMounted = useRef(false);

    // we create a ref using a special hook to get width from main Grid component
    const [ref, { width }] = useNodeDimensions();

    const [accountData, setAccountData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [hasSiretFilled, setHasSiretFilled] = useState(false);
    const [hasAdressFilled, setHasAdressFilled] = useState(false);

    // needed because contrary to phone number, API expects and sends siret without spaces
    const OrganisationIdNumberWithWhiteSpaces =
        accountData?.organisationIdentificationNumber
            ? accountData?.organisationIdentificationNumber?.slice(0, 3) +
              ' ' +
              accountData?.organisationIdentificationNumber?.slice(3, 6) +
              ' ' +
              accountData?.organisationIdentificationNumber?.slice(6, 9) +
              ' ' +
              accountData?.organisationIdentificationNumber?.slice(9, 15)
            : null;

    // initializing all react-hook-form values and methods
    const defaultValues = {
        organisationName: accountData?.organisationName,
        organisationWebsite: accountData?.organisationWebsite,
        organisationIdentificationNumber: OrganisationIdNumberWithWhiteSpaces,
    };

    const methods = useForm();

    const { register, handleSubmit, reset, clearErrors } = methods;

    // Watch for changes on inputs
    useEffect(() => {
        register({ name: 'organisationName' });
        register({ name: 'organisationWebsite' });
        register({ name: 'organisationIdentificationNumber' });
    }, [register]);

    /**
     * GET request to API to get user's account informations
     * @returns {object} - object with organisationName and organisationWebsite
     */
    const getAccount = useCallback(() => {
        apiAuth()
            .get(`/account/${user?.accountId}`)
            .then((res) => {
                if (isMounted.current) {
                    setAccountData(res.data);
                    res.data.organisationIdentificationNumber && setHasSiretFilled(true);
                }
            })
            .catch(
                (error) =>
                    isMounted.current &&
                    addError(
                        error?.response?.data.detail,
                        error?.response?.data.status,
                    ),
            )
            .finally(() => isMounted.current && setIsLoading(false));
    }, [addError, user.accountId]);

    /**
     * PATCH request to API to update user's account
     * @returns {object} - object with organisationName and organisationWebsite updated
     */
    const updateAccount = useCallback(
        (updatedAccount) => {
            setIsLoading(true);

            apiAuth()
                .patch(`/account/${user?.accountId}`, updatedAccount)
                .then((res) => {
                    setAccountData({
                        ...accountData,
                        organisationName: res.data.organisationName,
                        organisationWebsite: res.data.organisationWebsite,
                        organisationIdentificationNumber:
                            res.data.organisationIdentificationNumber,
                    });
                    res.data.organisationIdentificationNumber && setHasSiretFilled(true);
                    
                    setIsEditing(false);
                    toast(t('form.toastSuccess.accountUpdate'));
                })
                .catch((error) =>
                    addError(
                        error?.response?.data.detail,
                        error?.response?.data.status,
                    ),
                )
                .finally(() => setIsLoading(false));
        },
        [
            addError,
            setAccountData,
            setIsLoading,
            t,
            accountData,
            user.accountId,
        ],
    );

    useEffect(() => {
        isMounted.current = true;

        getAccount();

        return () => {
            isMounted.current = false;
        };
    }, [getAccount]);

    const submitForm = (data) => {
        const updatedAccount = {
            organisationName: data.organisationName,
            organisationWebsite: data.organisationWebsite,
            organisationIdentificationNumber:
                data.organisationIdentificationNumber.replace(/[\s]/g, ''),
        };

        updateAccount(updatedAccount);
    };
    const resetForm = () => {
        clearErrors();
        reset();
        setIsEditing(false);
    };

    return (
        <>
            <Helmet>
                <title>
                    {t('user.company.metaTitle')} - {t('main.metaTitle')}
                </title>
            </Helmet>

            <H1Title title={t('user.company.title')} />

            <div ref={ref}>
                <InternalGrid
                    displayType="grid"
                    gridGap="lg"
                    gridTemplateColumns={
                        width > ThemeDefault.breakpoint.md
                            ? `minmax(${ThemeDefault.blockWidth.sm}, auto) minmax(${ThemeDefault.blockWidth.xs}, auto)` // to guarantee minimum width to avoid too small blocks in page without losing responsiveness
                            : '1fr'
                    }
                    justifyItems="stretch"
                    hasStaticWidth={IsMobile()}
                    childrenMarginTop="md"
                >
                    <ErrorBoundary>
                        <CardClassic
                            title={t('user.company.informations')}
                            titleSize="md"
                            isShadowWab
                            blockWidth="md"
                        >
                            <FormProvider {...methods}>
                                <UserCompanyEditForm
                                    onSubmit={handleSubmit(submitForm)}
                                    defaultValues={defaultValues}
                                    isLoading={isLoading}
                                    setIsEditing={setIsEditing}
                                >
                                    {isEditing && (
                                        <ButtonGroup
                                            marginTop="sm"
                                            marginBottom="sm"
                                        >
                                            <CancelButton
                                                buttonSize="md"
                                                onClick={() => resetForm()}
                                            >
                                                {t('form.button.cancel')}
                                            </CancelButton>

                                            <ValidateButton
                                                buttonSize="md"
                                                isDisabled={isLoading}
                                                data-cy="user-validate"
                                            >
                                                {t(
                                                    'resetPassword.validateButton',
                                                )}
                                            </ValidateButton>
                                        </ButtonGroup>
                                    )}
                                </UserCompanyEditForm>
                            </FormProvider>

                            {!isEditing && (
                                <ButtonGroup marginTop="sm" marginBottom="sm">
                                    <ActionBadge
                                        text={t('user.edit.badgetext')}
                                        data-cy="action-badge-user"
                                    />
                                </ButtonGroup>
                            )}
                        </CardClassic>
                    </ErrorBoundary>

                    <ErrorBoundary>
                        <CardClassic
                            title={t('user.company.billingInformations')}
                            titleSize="md"
                            isShadowWab
                            blockWidth="md"
                        >
                            <UserCompanyBillingAddress
                                account={accountData.client_id}
                                setHasAdressFilled={setHasAdressFilled}
                            />

                            <Box marginTop="md">
                                <UserCompanySepa
                                    account={accountData}
                                    hasSepaFormDisplayed={hasSiretFilled && hasAdressFilled}
                                />
                            </Box>

                            <ButtonGroup marginTop="sm">
                                <RouterLink to="/user/billing">
                                    <DaButton
                                        icon={<CoinsIcon />}
                                        buttonStyle="line"
                                        buttonSize="sm"
                                    >
                                        {t('user.company.seeBillingButton')}
                                    </DaButton>
                                </RouterLink>
                            </ButtonGroup>
                        </CardClassic>
                    </ErrorBoundary>
                </InternalGrid>
            </div>
        </>
    );
};

export default withTranslation('common', 'countries')(UserCompany);
