import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Overlay, Loader as UILoader } from '@paygreen/paygreen-ui';

const LoadingWrapper = styled.div`
    position: fixed;
    height: 100%;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    z-index : 200;
`;

/**
 * @description - this component will display a loader with transparent overlay hover all its parent
 */
const Loader = ({ isLoading }) =>
    isLoading && (
        <LoadingWrapper>
            <Overlay gradient="none" colorWab="white00" opacityValue={70} />

            <UILoader colorTheme="quaternary"/>
        </LoadingWrapper>
    );

Loader.propTypes = {
    isLoading: PropTypes.bool,
};

Loader.defaultProps = {
    isLoading: false,
};

export default Loader;
