import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DaInput, InternalGrid } from '@paygreen/paygreen-ui';
import { FormControlWrapper, ControlledDaInput } from '../../../../components';
import regexForm from '../../../../utils/regexForm';

const AdministratorManagementForm = ({
    t,
    register,
    errors,
    setValue,
    onSubmit,
    children,
    defaultValues,
    control,
}) => {
    const transversalInputProps = {
        errors: errors,
        i18labelBase: 'admin.user.administratorInfos',
        hasChildrenStretched: true,
    };

    return (
        <form onSubmit={onSubmit} noValidate>
            <InternalGrid
                displayType="grid"
                gridGap="md"
                alignItems="stretch"
                justifyItems="stretch"
                gridTemplateColumns="1fr"
                childrenMarginBottom="none"
                childrenMarginTop="none"
            >
                <FormControlWrapper
                    inputName="administrator"
                    {...transversalInputProps}
                >
                    <DaInput
                        disabled={true}
                        name="administrator"
                        id="administrator"
                        defaultValue={defaultValues['administrator']}
                        blockWidth="lg"
                    />
                </FormControlWrapper>

                <FormControlWrapper
                    inputName="phone"
                    {...transversalInputProps}
                >
                    <ControlledDaInput
                        inputName="phone"
                        inputPattern={regexForm.phone}
                        inputType="tel"
                        isDisabled={true}
                        control={control}
                        defaultValue={defaultValues.phone}
                        mask="+33 (0)9 99 99 99 99"
                        hasStaticWidth={true}
                    />
                </FormControlWrapper>

                <FormControlWrapper
                    inputName="email"
                    isRequired={true}
                    {...transversalInputProps}
                >
                    <DaInput
                        onChange={(e) => setValue('email', e.target.value)}
                        name={'email'}
                        id={'email'}
                        type="email"
                        defaultValue={defaultValues['email']}
                        inputRef={register({
                            required: t('form.error.required'),
                            pattern: {
                                value: regexForm.email,
                                message: t(`form.error.email`),
                            },
                        })}
                    />
                </FormControlWrapper>
            </InternalGrid>

            {children}
        </form>
    );
};

AdministratorManagementForm.propTypes = {
    setValue: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    /**
     * errors passed by react-hook-form for each field (necessary if you want errors management)
     */
    errors: PropTypes.object,
    defaultValues: PropTypes.object,
    control: PropTypes.object,
};

AdministratorManagementForm.defaultProps = {
    errors: {},
};

export default withTranslation('common')(AdministratorManagementForm);
