import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useStripe, useElements, IbanElement } from '@stripe/react-stripe-js';
import { ButtonGroup, Text } from '@paygreen/paygreen-ui';
import { setBillingLocalStorage } from '../../../utils/authentication';
import { useUser } from '../../../context';
import {
    ModalForm,
    ValidateButton,
    CancelButton,
    Message,
} from '../../../components';
import { apiAuth } from '../../../utils/hooks';
import UserCompanySepaForm from './UserCompanySepaForm';

const UserCompanySepaModal = ({
    t,
    setOpen,
    isOpen,
    getAccountIban,
    setIsLoading,
    isLoading,
    hasSepaFormDisplayed,
}) => {
    const { user, setUser } = useUser();
    const elements = useElements();
    const stripe = useStripe();

    const [secretKey, setSecretKey] = useState(null);
    const { register, handleSubmit, reset, errors, setError, clearErrors } =
        useForm();

    const getSecretKey = useCallback(
        (user) => {
            setIsLoading(true);
            apiAuth()
                .post(`/account/${user.accountId}/mandates/charity`)
                .then((res) => {
                    if (res.data.data?.secret) {
                        setSecretKey(res.data.data.secret);
                    }
                })
                .catch((error) => {
                    setError('iban', {
                        message: error?.response?.data.detail,
                        status: 'danger',
                    });
                })
                .finally(() => setIsLoading(false));
        },
        [setError, setIsLoading],
    );

    useEffect(() => {
        register({ name: 'iban' });
    }, [register]);

    useEffect(() => {
        if (isOpen) {
            getSecretKey(user);
        }
    }, [isOpen, getSecretKey, user]);

    const submitForm = async () => {
        if (!stripe || !elements || secretKey === '') {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const iban = elements.getElement(IbanElement);

        setIsLoading(true);

        stripe
            .confirmSepaDebitSetup(secretKey, {
                payment_method: {
                    sepa_debit: iban,
                    billing_details: {
                        name: user.lastname + ' ' + user.firstname,
                        email: user.email,
                    },
                },
            })
            .then((data) => {
                if (data.error) {
                    setError('iban', {
                        message: data.error.message,
                        status: 'danger',
                    });
                    setIsLoading(false);
                } else {
                    setOpen(false);

                    // if success we update local storage and user state to hide alert sepa message and modal
                    setUser({
                        ...user,
                        sepaStatus: 'SIGNED',
                        expirationDate: 2147483647, // the highest value possible as timestamp
                        isExpired: false,
                    });
                    setBillingLocalStorage({
                        sepaStatus: 'SIGNED',
                        expirationDate: 2147483647,
                        isExpired: false,
                    });

                    setTimeout(() => getAccountIban(user?.accountId), 2500); // needed to wait for API to receive updated iban data from stripe
                    toast(
                        <Text colorPallet="theme" textSize="xs">
                            {t('form.toastSuccess.signMandatSepa')}
                        </Text>,
                    );
                }
            });
    };

    const cancelCloseModal = () => {
        setOpen(false);

        // we need the reset methods to wait until modal is closed
        setTimeout(() => {
            setSecretKey(null);
            clearErrors();
            reset();
        }, 300);
    };

    return (
        <ModalForm
            modalTitle={t('user.company.signMandateButton')}
            blockWidth="md"
            paddingLateral="sm"
            paddingBottom="sm"
            hasCloseCross={true}
            isOpen={isOpen}
            actionOnClose={() => cancelCloseModal()}
        >
            {hasSepaFormDisplayed ? (
                <UserCompanySepaForm
                    errors={errors}
                    onSubmit={handleSubmit(submitForm)}
                    ready={!!secretKey}
                    clearErrors={clearErrors}
                >
                    <ButtonGroup marginTop="xs" marginBottom="sm">
                        <CancelButton
                            buttonSize="md"
                            onClick={() => cancelCloseModal()}
                        >
                            {t('form.button.cancel')}
                        </CancelButton>

                        <ValidateButton buttonSize="md" isDisabled={isLoading}>
                            {t('user.company.signMandateButton')}
                        </ValidateButton>
                    </ButtonGroup>
                </UserCompanySepaForm>
            ) : (
                <Message type="error">
                    <Text textSize="xs">
                        {t('user.company.noAddressMessage')}
                    </Text>
                </Message>
            )}
        </ModalForm>
    );
};

UserCompanySepaModal.propTypes = {
    /**
     * @description setOpen and isOpen are automatically received from ModalControl parent
     */
    setOpen: PropTypes.func,
    isOpen: PropTypes.bool,
    setIsLoading: PropTypes.func.isRequired,
    getAccountIban: PropTypes.func.isRequired,
    hasSepaFormDisplayed: PropTypes.bool,
};

UserCompanySepaModal.defaultProps = {
    isOpen: false,
    hasSepaFormDisplayed: false,
};

export default withTranslation('common')(UserCompanySepaModal);
