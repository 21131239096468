import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { useUser, useMode } from '../../context';
import { MODES } from '../../utils/constants';

export const DisabledStyle = styled.span`
    & * {
        cursor: ${(props) => (props.isActive ? 'not-allowed' : null)};
        filter: ${(props) => (props.isActive ? 'grayscale(1)' : null)};
        pointer-events: ${(props) => (props.isActive ? 'none' : 'auto')};
    }
`;

const Disabler = ({ t, children }) => {
    const { user } = useUser();
    const { mode } = useMode();

    const isExpired =
        user?.role &&
        !['ASSOCIATION', 'PAYGREEN'].includes(user.role) &&
        user?.isExpired;

    const isUnsignedInProd =
        mode === MODES.PROD && user.sepaStatus === 'UNSIGNED';
    const isActive = isExpired || isUnsignedInProd;
    const titleId = isExpired ? 'isExpired' : 'isUnsignedInProd';

    return (
        <DisabledStyle
            title={isActive ? t(`billingMessages.disabler.${titleId}`) : ''}
            isActive={isActive}
        >
            {children}
        </DisabledStyle>
    );
};

Disabler.propTypes = {
    titleId: PropTypes.string,
};

export default withTranslation('common')(Disabler);
