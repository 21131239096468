import rules from './Rules';
import PropTypes from 'prop-types';

/**
 * @description - check if rules object contains actions for a specific role
 * @param {object} rules - object containing roles & role-based actions
 * @param {string} condition - can be a user's role ('PAYGREEN', 'ADMIN', 'ASSOCIATION' or 'USER') or an environment mode ('RECETTE', 'SANDBOX' or 'PRODUCTION')
 * @param {(string|string[])} action -  action or array of actions authorized for role/mode
 */
const check = (rules, condition, action) => {
    const permissions = rules[condition];

    if (!permissions) {
        return false;
    }

    if (
        typeof action === 'string'
            ? permissions?.static.includes(action)
            : permissions?.static.some((item) => action.includes(item))
    ) {
        return true;
    }

    return false;
};

const Can = (props) =>
    check(rules, props.condition, props.perform) ? props.yes() : props.no();

Can.propTypes = {
    perform: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    condition: PropTypes.string,
    yes: PropTypes.func,
    no: PropTypes.func,
};

Can.defaultProps = {
    yes: () => null,
    no: () => null,
};

export default Can;
