import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    ClickableBlock,
    Dropdown,
    DropdownControl,
    MenuHamburger,
    SkeletonItem,
    Text,
    Topbar as TopbarMain,
    UserIcon,
    ThemeDefault,
    Popin,
} from '@paygreen/paygreen-ui';
import LogoPaygreen from '../../assets/logos/LogoPaygreen';
import { useAuth, useUser } from '../../context';
import { truncateTooLongName } from '../../utils/tools';
import SkeletonTextLine from './SkeletonTextLine';
import PopinUser from './PopinUser';

const TopBar = ({ t, isOpen, toggleIsOpen }) => {
    const { getSdk, checkLocalTokens } = useAuth();
    const { setUser, user } = useUser();
    const [errorUserMessage, setErrorUserMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const logOut = () => {
        localStorage.clear();
        setUser();
    };

    // fill the global state in context with all user's informations
    const localUsername =
        localStorage.username && JSON.parse(localStorage.username);

    const getData = () => {
        getSdk()
            .user.getOne(localUsername)
            .then((res) => {
                if (res.success) {
                    setUser({
                        ...user,
                        username: res.dataInfo.username,
                        accountId: res.dataInfo.accountId,
                        firstname: res.dataInfo.firstname,
                        lastname: res.dataInfo.lastname,
                        email: res.dataInfo.email,
                        role: res.dataInfo.role,
                        publicname: res.dataInfo.publicname,
                        createdAt: res.dataInfo.createdAt,
                    });
                } else {
                    setErrorUserMessage(res.dataInfo.detail);
                }
                setIsLoading(false);
            });
    };

    useEffect(getData, []);

    return (
        <TopbarMain data-cy="topbar">
            <MenuHamburger
                isOpen={isOpen}
                onClick={() => toggleIsOpen(!isOpen)}
                colorPallet="wab"
                marginLeft="xs"
            />

            <RouterLink to="/" className="hideOnDesktop">
                <LogoPaygreen />
            </RouterLink>

            {checkLocalTokens() === 'valid' ? (
                <>
                    <Dropdown hasOverlay={false} data-cy="topbar-user">
                        <ClickableBlock
                            colorWab="grey50"
                            iconSize="md"
                            paddingTop="xs"
                            paddingBottom="xs"
                            paddingLateral="xs"
                            marginLateral="none"
                            style={{ height: ThemeDefault.grid.topbar }} // needed because iconHeight doesn't fit perfectly topbar's height
                        >
                            <SkeletonItem
                                skeletonItemType="circle"
                                colorWab="grey20"
                                radiusSize="none"
                                isLoading={isLoading}
                            >
                                <UserIcon
                                    hasBackground={true}
                                    colorTheme="quaternary"
                                    iconSize="sm"
                                    isActive={true}
                                />
                            </SkeletonItem>

                            <Box
                                marginLeft="sm"
                                marginRight="xs"
                                className="hideOnTablet"
                                style={{ minWidth: '70px' }}
                            >
                                {isLoading ? (
                                    <SkeletonTextLine isLoading={isLoading} />
                                ) : (
                                    <Text textSize="xxs" htmlTag="strong">
                                        {truncateTooLongName(
                                            user.firstname,
                                            15,
                                        )}{' '}
                                        {truncateTooLongName(user.lastname, 15)}
                                    </Text>
                                )}

                                {isLoading ? (
                                    <SkeletonTextLine
                                        marginTop="xs"
                                        isLoading={isLoading}
                                    />
                                ) : (
                                    <Text textSize="xxs">{user.accountId}</Text>
                                )}

                                {errorUserMessage && (
                                    <span>{errorUserMessage}</span>
                                )}
                            </Box>
                        </ClickableBlock>

                        <Popin blockWidth="sm" shadowSize="lg" align="right">
                            <DropdownControl>
                                <PopinUser
                                    user={user}
                                    logOut={logOut}
                                    errorUserMessage={errorUserMessage}
                                />
                            </DropdownControl>
                        </Popin>
                    </Dropdown>
                </>
            ) : (
                <Text htmlTag="strong" textSize="xs">
                    {t('topbar.notLoggedIn')}
                </Text>
            )}
        </TopbarMain>
    );
};

export default withTranslation('common')(TopBar);
