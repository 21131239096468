import React from 'react';
import PropTypes from 'prop-types';
import { Card, InternalGrid, Loader, Text } from '@paygreen/paygreen-ui';

const NoDataFoundCard = ({ message, isLoading, blockWidth, ...rest }) => {
    if (!message) {
        return null;
    }

    return (
        <Card
            blockWidth={blockWidth}
            paddingBottom="lg"
            paddingTop="lg"
            paddingLateral="lg"
            colorWab="white20"
            shadowSize="none"
            radiusSize="sm"
            {...rest}
        >
            {isLoading ? (
                <Loader />
            ) : (
                <InternalGrid
                    displayType="grid"
                    gridTemplateColumns="1fr"
                    alignItems="center"
                    style={{ height: '100%' }}
                >
                    <Text
                        textSize="sm"
                        align="center"
                        marginLateral="sm"
                        paddingBottom="sm"
                        paddingTop="sm"
                    >
                        {message}
                    </Text>
                </InternalGrid>
            )}
        </Card>
    );
};

NoDataFoundCard.propTypes = {
    message: PropTypes.string,
    blockWidth: PropTypes.string,
    isLoading: PropTypes.bool,
};

NoDataFoundCard.defaultProps = {
    message: '',
    blockWidth: 'md',
    isLoading: false,
};

export default NoDataFoundCard;
