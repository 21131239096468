import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { FieldOfAction } from 'api-green-node';
import {
    DaInput,
    DaSelect,
    DaTextarea,
    SkeletonItem,
} from '@paygreen/paygreen-ui';
import regexForm from '../../../utils/regexForm';
import { enumToArray } from '../../../utils/tools';
import { FormControlWrapper } from '../../../components';
import { charityInputsPatterns } from '../shared';

const CharityEditForm = ({
    t,
    onSubmit,
    children,
    defaultValues,
    isLoading,
    setIsEditing,
}) => {
    // All options for field of actions selector
    const selectFieldOptions =
        FieldOfAction &&
        enumToArray(FieldOfAction).map((option, index) => {
            return {
                value: index.toString(),
                text: t(
                    `charity.edit.fieldOfActionValues.${FieldOfAction[index]}`,
                ),
            };
        });

    const { register, errors, setValue } = useFormContext();

    const transversalInputProps = {
        errors: errors,
        i18labelBase: 'charity',
        marginBottom: 'md',
        isRequired: true,
        hasStaticWidth: true,
    };

    return (
        <form onSubmit={onSubmit} noValidate>
            {charityInputsPatterns.slice(0, -2).map((element) => (
                <FormControlWrapper
                    key={element.name}
                    inputName={element.name}
                    {...transversalInputProps}
                >
                    <SkeletonItem isLoading={isLoading} colorWab="grey10">
                        <DaInput
                            onChange={(e) => {
                                setIsEditing(true);
                                setValue(element.name, e.target.value);
                            }}
                            name={element.name}
                            id={element.name}
                            type={element.type}
                            defaultValue={defaultValues[element.name]}
                            inputRef={register({
                                required: t('form.error.required'),
                                pattern: {
                                    value: element.pattern,
                                    message: t(`form.error.${element.name}`),
                                },
                            })}
                            hasStaticWidth={true}
                        />
                    </SkeletonItem>
                </FormControlWrapper>
            ))}

            <FormControlWrapper
                inputName="fieldOfAction"
                {...transversalInputProps}
            >
                <SkeletonItem isLoading={isLoading} colorWab="grey10">
                    <DaSelect
                        name="fieldOfAction"
                        id="fieldOfAction"
                        inputRef={register}
                        defaultValue={defaultValues.fieldOfAction}
                        options={selectFieldOptions}
                        onChange={(e) => {
                            setIsEditing(true);
                            setValue('fieldOfAction', e.target.value);
                        }}
                        hasStaticWidth={true}
                    />
                </SkeletonItem>
            </FormControlWrapper>

            <FormControlWrapper
                inputName="description"
                {...transversalInputProps}
            >
                <SkeletonItem isLoading={isLoading} colorWab="grey10">
                    <DaTextarea
                        name="description"
                        id="description"
                        inputRef={register({
                            required: t('form.error.required'),
                            pattern: {
                                value: regexForm.description,
                                message: t(`form.error.description`),
                            },
                        })}
                        onChange={(e) => {
                            setIsEditing(true);
                            setValue('description', e.target.value);
                        }}
                        value={defaultValues.description}
                        hasStaticWidth={true}
                        hasCounter={true}
                        maxLength={1000}
                        counterText={''}
                    />
                </SkeletonItem>
            </FormControlWrapper>

            {children}
        </form>
    );
};

CharityEditForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    defaultValues: PropTypes.object,
    isLoading: PropTypes.bool,
    setIsEditing: PropTypes.func,
};

CharityEditForm.defaultProps = {
    isLoading: false,
};

export default withTranslation('common')(CharityEditForm);
