import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout } from '../components';
import {
    CharityDashboard,
    ShopDashboard,
    CharityDonations,
    ShopDonations,
    Error404,
} from '../domains';
import {
    AdminRoutes,
    ShopPartnershipsRoutes,
    CharityPartnershipsRoutes,
    UserRoutes,
} from './';
import { useUser } from '../context';
import Can from '../utils/rules/Can';

const AuthenticatedRoutes = () => {
    const { user } = useUser();

    return (
        <Layout>
            <Can
                condition={user && user.role}
                perform="routes:shop"
                yes={() => (
                    <Switch>
                        <Route exact path="/" component={ShopDashboard} />
                        <Route
                            exact
                            path="/donations"
                            component={ShopDonations}
                        />
                        <Route
                            path="/partnerships"
                            component={ShopPartnershipsRoutes}
                        />
                        <Route path="/user" component={UserRoutes} />
                        <Route component={Error404} />
                    </Switch>
                )}
            />

            <Can
                condition={user && user.role}
                perform="routes:charity"
                yes={() => (
                    <Switch>
                        <Route exact path="/" component={CharityDashboard} />
                        <Route
                            exact
                            path="/donations"
                            component={CharityDonations}
                        />
                        <Route
                            path="/partnerships"
                            component={CharityPartnershipsRoutes}
                        />
                        <Route path="/user" component={UserRoutes} />
                        <Route component={Error404} />
                    </Switch>
                )}
            />

            <Can
                condition={user && user.role}
                perform="routes:admin"
                yes={() => (
                    <Switch>
                        <Route exact path="/" component={ShopDashboard} />
                        <Route
                            exact
                            path="/donations"
                            component={ShopDonations}
                        />
                        <Route
                            path="/partnerships"
                            component={ShopPartnershipsRoutes}
                        />
                        <Route path="/user" component={UserRoutes} />
                        <Route path="/admin" component={AdminRoutes} />
                        <Route component={Error404} />
                    </Switch>
                )}
            />
        </Layout>
    );
};

export default AuthenticatedRoutes;
