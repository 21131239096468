import PropTypes from 'prop-types';
import { partnershipStatus as partnershipStatusEnum } from '../../../utils/constants';

/**
 * Choose proper values inside data received for each partnership and depending on user's ROLE
 * @param {object} data
 * @param {string} partnershipStatus
 * @param {object} user
 */
const PartnershipDispatchData = (data, partnershipStatus, user) => {
    let partnerName = null;
    let partnerUrl = null;
    let partnerDescription = null;
    let partnershipDate = null;
    let partnerFieldOfAction = null;
    let picture1 = data?.picture1 || data?.association?.picture1 || null;
    
    switch (partnershipStatus) {
        case partnershipStatusEnum.ACTIVE:
            partnerName =
                user.role === 'ASSOCIATION'
                    ? data.idAccount
                    : data.association.name;
            partnerUrl =
                user.role === 'ASSOCIATION' ? null : data.association.url;
            partnerDescription =
                user.role === 'ASSOCIATION'
                    ? null
                    : data.association.description;
            partnershipDate = data.enabledAt;
            partnerFieldOfAction =
                user.role === 'ASSOCIATION'
                    ? null
                    : data.association.fieldOfAction;        
            break;
        case partnershipStatusEnum.PENDING:
            partnerName =
                user.role === 'ASSOCIATION'
                    ? data.idAccount
                    : data.association.name;
            partnerUrl =
                user.role === 'ASSOCIATION' ? null : data.association.url;
            partnerDescription =
                user.role === 'ASSOCIATION'
                    ? null
                    : data.association.description;
            partnershipDate = data.createdAt;
            partnerFieldOfAction =
                user.role === 'ASSOCIATION'
                    ? null
                    : data.association.fieldOfAction;
            break;
        case partnershipStatusEnum.REFUSED:
            partnerName =
                user.role === 'ASSOCIATION'
                    ? data.idAccount
                    : data.association.name;
            partnerUrl =
                user.role === 'ASSOCIATION' ? null : data.association.url;
            partnerDescription =
                user.role === 'ASSOCIATION'
                    ? null
                    : data.association.description;
            partnershipDate = data.associationAnsweredAt;
            partnerFieldOfAction = null;
            break;
        case partnershipStatusEnum.CANCELLEDBYSHOP:
            partnerName =
                user.role === 'ASSOCIATION'
                    ? data.idAccount
                    : data.association.name;
            partnerUrl =
                user.role === 'ASSOCIATION' ? null : data.association.url;
            partnerDescription =
                user.role === 'ASSOCIATION'
                    ? null
                    : data.association.description;
            partnershipDate = data.accountAnsweredAt;
            partnerFieldOfAction = null;
            break;
        case partnershipStatusEnum.CANCELLEDBYCHARITY:
            partnerName =
                user.role === 'ASSOCIATION'
                    ? data.idAccount
                    : data.association.name;
            partnerUrl =
                user.role === 'ASSOCIATION' ? null : data.association.url;
            partnerDescription =
                user.role === 'ASSOCIATION'
                    ? null
                    : data.association.description;
            partnershipDate = data.associationAnsweredAt;
            partnerFieldOfAction = null;
            break;
        default:
            partnerName = data.name;
            partnerUrl = data.url;
            partnerDescription = data.description;
            partnerFieldOfAction = data.fieldOfAction;
            break;
    }

    return {
        partnerName,
        partnerUrl,
        partnerDescription,
        partnerFieldOfAction,
        partnershipDate,
        picture1
    };
};

PartnershipDispatchData.propTypes = {
    data: PropTypes.object,
    partnershipStatus: PropTypes.oneOf(Object.values(partnershipStatusEnum)),
    user: PropTypes.object,
};
export default PartnershipDispatchData;
