import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, Text } from '@paygreen/paygreen-ui';
import regexForm from '../../../utils/regexForm';
import { FormControlWrapper, PasswordValidatedInput } from '../..';

const NewPasswordForm = ({
    errors,
    setValue,
    register,
    onSubmit,
    password,
    children,
    t,
}) => {
    const passwordInputsPatterns = [
        {
            name: 'newPassword',
            pattern: regexForm.password,
            type: 'password',
            required: true,
        },
        {
            name: 'newPasswordVerify',
            pattern: regexForm.password,
            type: 'password',
            required: true,
        },
    ];

    return (
        <form onSubmit={onSubmit} noValidate>
            <Grid
                displayType="grid"
                alignItems="stretch"
                justifyItems="stretch"
                gridTemplateColumns="1fr"
                gridGap="sm"
                childrenMarginBig="xs"
                childrenMargin="sm"
                blockPadding="none"
            >
                {passwordInputsPatterns.map((element, index) => (
                    <FormControlWrapper
                        key={element.name}
                        inputName={element.name}
                        errors={errors}
                        isRequired={element.required}
                        i18labelBase="subscription"
                        hasStaticWidth={true}
                    >
                        <PasswordValidatedInput
                            setValue={setValue}
                            register={register}
                            password={password}
                            isRequired={element.required}
                            inputName={element.name}
                            inputType={element.type}
                            inputPattern={element.pattern}
                            autoComplete="off"
                            fieldSize="lg"
                            hasValidator={element.name === 'newPasswordVerify'}
                        />

                        {index === 0 && (
                            <Text textSize="xxs">
                                {t('subscription.minLengthPassword')}
                            </Text>
                        )}
                    </FormControlWrapper>
                ))}
            </Grid>

            {children}
        </form>
    );
};

NewPasswordForm.propTypes = {
    setValue: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    password: PropTypes.object,
    /**
     * errors passed by react-hook-form for each field (necessary if you want errors management)
     */
    errors: PropTypes.object,
};

NewPasswordForm.defaultProps = {
    errors: {},
};

export default withTranslation('common')(NewPasswordForm);
