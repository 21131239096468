import { updateAuthLocalStorage } from './AuthLocalStorage';
import { useState } from 'react';
import { Mode, Sdk } from 'api-green-node';
import { setAuthLocalStorage } from './AuthLocalStorage';

const UseRefreshToken = () => {
    const localToken =
        localStorage.access_token && JSON.parse(localStorage.access_token);

    const localRefreshToken =
        localStorage.refresh_token && JSON.parse(localStorage.refresh_token);

    const localTokenExpiresAt =
        localStorage.token_expires_at &&
        JSON.parse(localStorage.token_expires_at);

    const localAccountId =
        localStorage.account_id && JSON.parse(localStorage.account_id);

    const localUsername =
        localStorage.username && JSON.parse(localStorage.username);

    const localExpirationDate =
        localStorage.expiration_date &&
        JSON.parse(localStorage.expiration_date);

    const localIsAccessExpired =
        localStorage.is_access_expired &&
        JSON.parse(localStorage.is_access_expired);

    const localSepaStatus =
        localStorage.sepa_status && JSON.parse(localStorage.sepa_status);

    const localUsesTree =
        localStorage.uses_tree && JSON.parse(localStorage.uses_tree);

    const localUsesArrondi =
        localStorage.uses_arrondi && JSON.parse(localStorage.uses_arrondi);

    const initialUserData = {
        accountId: localAccountId || '',
        username: localUsername || '',
        role: '',
        sepaStatus: localSepaStatus,
        expirationDate: localExpirationDate,
        isExpired: localIsAccessExpired,
        usesTree: localUsesTree,
        usesArrondi: localUsesArrondi,
    };

    const [localConfig, setLocalConfig] = useState({
        token: localToken,
        refreshToken: localRefreshToken,
        mode: Mode[process.env.REACT_APP_SDK_MODE] ?? undefined,
        host: process.env.REACT_APP_SDK_HOST || undefined,
    });

    const checkLocalTokens = () => {
        if (
            localStorage.refresh_token !== undefined &&
            localStorage.token_expires_at > new Date().getTime() / 1000
        ) {
            return 'valid';
        } else if (
            localStorage.refresh_token !== undefined &&
            localStorage.token_expires_at < new Date().getTime() / 1000
        ) {
            return 'expired';
        } else {
            return 'empty';
        }
    };

    const [user, setUser] = useState(initialUserData);

    /**
     *
     * @param {boolean} forceClean allow to remove Header Authorization on Login
     */
    const getSdk = (forceClean = false) => {
        if (forceClean) {
            localConfig.token = null;
            localConfig.refreshToken = null;
        }
        return new Sdk(localConfig);
    };

    /**
     *  Call by login page
     * @param {Object} authData
     * @param {Object} userInfos
     */
    const updateAuthInformations = (authData, userInfos) => {
        setAuthLocalStorage(authData, userInfos);
        setLocalConfig({
            ...localConfig,
            token: authData.access_token,
            refreshToken: authData.refresh_token,
        });
    };

    const [localStorageGlobal] = useState({
        token: localToken,
        refreshToken: localRefreshToken,
        accountId: localAccountId,
        username: localUsername,
        expiresAt: localTokenExpiresAt,
    });

    const autoRefreshToken = () => {
        const sdk = new Sdk(localConfig);

        sdk.authentication
            .refreshToken(localStorageGlobal.accountId)
            .then((res) => {
                if (res.success === true) {
                    updateAuthLocalStorage(res.dataInfo);
                    setUser({
                        ...user,
                        accountId: localStorageGlobal.accountId,
                        username: localStorageGlobal.username,
                    });
                } else {
                    localStorage.clear();
                    setUser();
                }
            });
    };

    return {
        updateAuthInformations,
        checkLocalTokens,
        localStorageGlobal,
        localConfig,
        user,
        setUser,
        autoRefreshToken,
        getSdk,
    };
};

export default UseRefreshToken;
