import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    CoinsIcon,
    HeartIcon,
    InternalGrid,
    SkeletonItem,
    Text,
    UserIcon,
    ThemeDefault,
} from '@paygreen/paygreen-ui';
import { IsBigDesktop, IsMobile } from '../../utils/tools';
import { SmallDataCard, ErrorBoundary } from '../../components';

const DashboardDataCards = ({
    t,
    isLoading,
    totalDonationAmount,
    donationCount,
    averageDonationAmount,
}) => {
    const skeletonStyle = {
        colorWab: 'grey10',
        radiusSize: 'sm',
        isLoading: isLoading,
        blockWidth: 'lg',
    };

    return (
        <InternalGrid
            displayType="grid"
            gridGap="lg"
            gridTemplateColumns={IsMobile() ? '1fr' : '1fr 1fr 1fr'}
            justifyItems="stretch"
            alignItems="stretch"
        >
            <ErrorBoundary>
                <SkeletonItem {...skeletonStyle}>
                    <SmallDataCard
                        blockWidth="none"
                        colorPallet="theme"
                        colorTheme="quaternary"
                        hasEmptyMode={true}
                        icon={<CoinsIcon />}
                        title={t('dashboard.dataCard.donationTotalTitle')}
                        unit="€"
                        value={totalDonationAmount}
                        hasMinWidth={
                            IsBigDesktop()
                                ? ThemeDefault.dataBar.width.xs
                                : null
                        }
                    />
                </SkeletonItem>
            </ErrorBoundary>

            <ErrorBoundary>
                <SkeletonItem {...skeletonStyle}>
                    <SmallDataCard
                        blockWidth="none"
                        colorPallet="theme"
                        colorTheme="primary"
                        hasEmptyMode={true}
                        icon={<UserIcon />}
                        title={t('dashboard.dataCard.donationCountTitle')}
                        value={donationCount}
                        hasMinWidth={IsBigDesktop() ? '250px' : null}
                    >
                        <Text textSize="sm">
                            {t('dashboard.dataCard.donationCountLegend', {
                                count: donationCount,
                            })}
                        </Text>
                    </SmallDataCard>
                </SkeletonItem>
            </ErrorBoundary>

            <ErrorBoundary>
                <SkeletonItem {...skeletonStyle}>
                    <SmallDataCard
                        blockWidth="none"
                        colorPallet="theme"
                        colorTheme="secondary"
                        hasEmptyMode={true}
                        icon={<HeartIcon />}
                        unit="€"
                        title={t('dashboard.dataCard.donationAverageTitle')}
                        value={averageDonationAmount}
                        hasMinWidth={IsBigDesktop() ? '250px' : null}
                    />
                </SkeletonItem>
            </ErrorBoundary>
        </InternalGrid>
    );
};

export default withTranslation('common')(DashboardDataCards);
