import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { Text } from '@paygreen/paygreen-ui';
import { truncateTooLongName } from '../../../utils/tools';

const PartnershipToastMessage = ({ i18nKey, partnerName }) => (
    <Text colorPallet="theme" textSize="xs">
        <Trans i18nKey={i18nKey}>
            ...
            <Text htmlTag="strong" colorPallet="theme" textSize="xs">
                {{
                    partnerName: truncateTooLongName(partnerName, 30),
                }}
            </Text>
            ...
        </Trans>
    </Text>
);

PartnershipToastMessage.propTypes = {
    i18nKey: PropTypes.string.isRequired,
    partnerName: PropTypes.string.isRequired,
};

export default PartnershipToastMessage;
