import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    ArrowCircleLeftBoldIcon,
    ButtonGroup,
    Card,
    CrossIcon,
    DaButton,
    InternalGrid,
    Text,
} from '@paygreen/paygreen-ui';

const APIErrorCard = (props) => {
    const { setIsModalOpen, closeWithAction, message, status, t } = props;

    const cleanError = () => {
        setIsModalOpen(false);
        setTimeout(() => closeWithAction(), 400); // needed to wait for modal closing transition before cleaning message error
    };

    return (
        <InternalGrid
            displayType="grid"
            gridTemplateColumns="1fr"
            justifyItems="stretch"
            alignItems="stretch"
        >
            <Card
                colorPallet="status"
                colorStatus="danger"
                blockWidth="none"
                shadowSize="none"
                paddingBottom="sm"
                paddingLateral="sm"
                paddingTop="sm"
            >
                {status && (
                    <Text
                        align="center"
                        textSize="xs"
                        colorPallet="status"
                        colorStatus="danger"
                    >
                        ERROR {status}
                    </Text>
                )}

                {message && (
                    <Text
                        align="center"
                        textSize="xs"
                        colorPallet="status"
                        colorStatus="danger"
                    >
                        {message}
                    </Text>
                )}
            </Card>

            <ButtonGroup marginTop="sm">
                <button
                    onClick={() => {
                        window.location.reload();
                    }}
                >
                    <DaButton
                        buttonSize="sm"
                        buttonStyle="line"
                        colorPallet="status"
                        colorStatus="danger"
                        icon={<ArrowCircleLeftBoldIcon />}
                    >
                        {t('errorAPI.button')}
                    </DaButton>
                </button>

                <button type="reset" onClick={() => cleanError()}>
                    <DaButton
                        buttonSize="sm"
                        colorPallet="status"
                        colorStatus="danger"
                        icon={<CrossIcon />}
                    >
                        OK
                    </DaButton>
                </button>
            </ButtonGroup>
        </InternalGrid>
    );
};

APIErrorCard.propTypes = {
    message: PropTypes.string,
    status: PropTypes.number,
    closeWithAction: PropTypes.func,
    setIsModalOpen: PropTypes.func,
};

export default withTranslation('common')(APIErrorCard);
