export const activationStatusOptions = {
    CREATED: {
        colorPallet: 'status',
        color: 'danger',
        icon: 'CrossIcon',
    },
    DELETED: {
        colorPallet: 'status',
        color: 'danger',
        icon: 'CrossIcon',
    },
    VALIDATED: {
        colorPallet: 'status',
        color: 'success',
        icon: 'CheckIcon',
    },
};

export const sepaStatusOptions = {
    ERROR: {
        colorPallet: 'status',
        color: 'danger',
        icon: 'CrossIcon',
    },
    SIGNED: {
        colorPallet: 'status',
        color: 'success',
        icon: 'PenIcon',
    },
    UNSIGNED: {
        colorPallet: 'wab',
        color: 'grey50',
        icon: 'EmptyIcon',
    },
};

export const accountStatusOptions = {
    EMPTY: {
        colorPallet: 'status',
        color: 'warning',
        icon: 'EmptyIcon',
    },
};

export const activatedSpacesStatusOptions = {
    NOTACTIVATED: {
        colorPallet: 'wab',
        color: 'grey50',
        icon: 'EmptyIcon',
    },
    ACTIVATED: {
        colorPallet: 'status',
        color: 'success',
        icon: 'CheckIcon',
    },
};

export const expirationDateStatusOptions = {
    EXPIRED: {
        colorPallet: 'status',
        color: 'danger',
        icon: 'CrossIcon',
    },
    NOTEXPIRED: {
        colorPallet: 'status',
        color: 'success',
        icon: 'CheckIcon',
    },
};

export const userInputList = [
    {
        name: 'siret',
        type: 'text',
        value: ['organisationIdentificationNumber'],
    },
    {
        name: 'website',
        type: 'link',
        value: ['organisationWebsite'],
    },
    {
        name: 'subscriptionDate',
        type: 'date',
        value: ['administrator', 'createdAt'],
    },
    {
        name: 'activationStatus',
        type: 'status',
        value: ['administrator', 'enabledAt'],
    },
    {
        name: 'administrator',
        type: 'text',
        value: [
            ['administrator', 'firstname'],
            ['administrator', 'lastname'],
        ],
    },
    {
        name: 'phone',
        type: 'phone',
        value: ['administrator', 'contact', 'phone'],
    },
    {
        name: 'email',
        type: 'link',
        value: ['administrator', 'contact', 'email'],
    },
    {
        name: 'charityKitStatus',
        type: 'status',
        value: ['usesArrondi'],
    },
    {
        name: 'charityKitSEPAStatus',
        type: 'status',
        value: ['charityBilling', 'status'],
    },
    {
        name: 'charityKitExpirationDateMode',
        type: 'date',
        value: ['charityExpirationDate'],
    },
    {
        name: 'charityKitSetupPrice',
        type: 'price',
        value: ['charityBilling', 'setupPrice'],
    },
    {
        name: 'charityKitSubscriptionMode',
        type: 'price',
        value: ['charityBilling', 'setupPrice'],
    },
    {
        name: 'climateKitStatus',
        type: 'status',
        value: ['usesTree'],
    },
    {
        name: 'climateKitSEPAStatus',
        type: 'status',
        value: ['climateBilling', 'status'],
    },
    {
        name: 'climateKitExpirationDateMode',
        type: 'date',
        value: ['climateExpirationDate'],
    },
    {
        name: 'climateKitSetupPrice',
        type: 'price',
        value: ['climateBilling', 'setupPrice'],
    },
    {
        name: 'climateKitSubscriptionMode',
        type: 'price',
        value: ['climateBilling', 'setupPrice'],
    },
];

/**
 * @param {string} name
 * @returns {string} full name with firstname and only first letter of family name
 */
export const shortcutName = (name) => {
    if (/\s/.test(name)) {
        return name.slice(0, name.indexOf(' ') + 2) + '.';
    }
};
