import React, { createContext, useState } from 'react';
import { MODES } from '../utils/constants';
import { useUser } from './useUser';

export const ModeContext = createContext();

const ModeProvider = ({ children }) => {
    const { user } = useUser();
    const localMode = localStorage.mode && JSON.parse(localStorage.mode);

    const [mode, setMode] = useState(
        localMode
            ? localMode
            : user
            ? Number(user.expirationDate) < 0 || user.sepaStatus === 'UNSIGNED'
                ? MODES.TEST
                : MODES.PROD
            : null,
    );

    const handleSetMode = (mode) => {
        // to preserve mode value after refreshing page
        localStorage.setItem('mode', JSON.stringify(mode));

        setMode(mode);
    };

    return (
        <ModeContext.Provider value={{ mode, handleSetMode }}>
            {children}
        </ModeContext.Provider>
    );
};

export default ModeProvider;
