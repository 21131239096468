import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, withTranslation } from 'react-i18next';
import { Text } from '@paygreen/paygreen-ui';
import { useAPIError, useMode } from '../../../context';
import { partnershipStatus } from '../../../utils/constants';
import {
    Disabler,
    H1Title,
    ErrorBoundary,
    Introduction,
    NoDataFoundCard,
    ModalBlock,
    DeleteButton,
    SimpleActionButton,
    ToggleMode,
} from '../../../components';
import { getFilteredPartnership } from '../shared/partnershipsTools';
import { getAllPartnerships, deletePartnership } from '../service';
import {
    PartnershipCardGrid,
    PartnershipModalContent,
    PartnershipCardSkeleton,
    PartnershipCollapsibleCard,
    PartnershipToastMessage,
} from '../shared';

const PendingShopRequests = ({ t }) => {
    const { addError } = useAPIError();

    // we create a ref to detect if component is mounted
    const isMounted = useRef(false);

    const [partnershipsData, setPartnershipsData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const { mode } = useMode();

    const getData = useCallback(() => {
        setIsLoading(true);

        getAllPartnerships(mode)
            .then((res) => {
                isMounted.current &&
                    setPartnershipsData(
                        getFilteredPartnership(
                            res.data._embedded.partnership,
                            partnershipStatus.PENDING,
                        ),
                    );
            })
            .catch((error) => {
                addError(
                    error?.response?.data.detail,
                    error?.response?.data.status,
                );
            })
            .finally(() => {
                isMounted.current && setIsLoading(false);
            });
    }, [addError, mode]);

    useEffect(() => {
        isMounted.current = true;

        getData();

        return () => {
            isMounted.current = false;
        };
    }, [getData]);

    const CardMainButton = ({ partnerId, partnerName }) => (
        <ModalBlock
            blockWidth="md"
            closeWithAction={() => getData()}
            modalTitle={t('ShopPartnerships.pendingRequests.modalTitle')}
            isTitleRed={true}
            button={
                <DeleteButton>
                    {t('partnershipButtons.cancelMyRequest')}
                </DeleteButton>
            }
        >
            <PartnershipModalContent
                closeWithAction={() => getData()}
                sdkPartnershipAction={() => deletePartnership(partnerId)}
                buttonCancelAction={
                    <SimpleActionButton icon="ArrowRoundTopLeftIcon">
                        {t('partnershipButtons.notCancel')}
                    </SimpleActionButton>
                }
                buttonConfirmAction={
                    <DeleteButton>
                        {t('partnershipButtons.yesCancel')}
                    </DeleteButton>
                }
                toastMessage={
                    <PartnershipToastMessage
                        i18nKey="form.toastSuccess.partnershipRequestCancel"
                        partnerName={partnerName}
                    />
                }
            >
                <Text textSize="sm">
                    <Trans i18nKey="ShopPartnerships.pendingRequests.message">
                        Are you sure to cancel
                        <Text htmlTag="strong" textSize="sm">
                            {{ partnerName }}
                        </Text>
                        This manipulation is irreversible
                        <Text htmlTag="strong" textSize="sm">
                            {{ partnerName }}
                        </Text>
                    </Trans>
                </Text>
            </PartnershipModalContent>
        </ModalBlock>
    );

    return (
        <>
            <Helmet>
                <title>
                    {t('ShopPartnerships.pendingRequests.metaTitle')} -{' '}
                    {t('main.metaTitle')}
                </title>
            </Helmet>

            <H1Title title={t('ShopPartnerships.pendingRequests.title')}>
                <ToggleMode />
            </H1Title>

            <Introduction>
                {t('ShopPartnerships.pendingRequests.introduction')}
            </Introduction>

            {isLoading ? (
                <PartnershipCardSkeleton blockWidth="md" />
            ) : partnershipsData && partnershipsData.length > 0 ? (
                <PartnershipCardGrid>
                    {partnershipsData.map((partnership) => (
                        <ErrorBoundary
                            blockWidth="lg"
                            key={partnership.idPartnership}
                        >
                            <PartnershipCollapsibleCard
                                key={partnership.idPartnership}
                                data={partnership}
                                partnershipStatus={partnershipStatus.PENDING}
                                mainButton={
                                    <Disabler>
                                        <CardMainButton
                                            partnerId={
                                                partnership.idPartnership
                                            }
                                            partnerName={
                                                partnership.association.name
                                            }
                                        />
                                    </Disabler>
                                }
                            />
                        </ErrorBoundary>
                    ))}
                </PartnershipCardGrid>
            ) : (
                <NoDataFoundCard
                    message={t('ShopPartnerships.pendingRequests.noDataFound')}
                />
            )}
        </>
    );
};

export default withTranslation('common')(PendingShopRequests);
