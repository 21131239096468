import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DaSelect } from '@paygreen/paygreen-ui';

const StatusFilter = ({
    t,
    column: { filterValue },
    onChange,
    setActivePageReset,
    statusConstants,
    manualOptions,
    i18TextBase,
    defaultValue,
}) => {
    let finalOptions = manualOptions
        ? manualOptions
        : [
              { value: '', text: t(`${i18TextBase}.default`) },
              ...statusConstants.map((option) => {
                  return {
                      value: option,
                      text: t(i18TextBase + '.' + option + '.name'),
                  };
              }),
          ];

    return (
        <DaSelect
            defaultValue={defaultValue || ''}
            options={finalOptions}
            value={filterValue}
            fieldSize="sm"
            onChange={(e) => {
                onChange(e.target.value);
                setActivePageReset(true);
            }}
        />
    );
};

StatusFilter.propTypes = {
    /**
     * method called onChange, mostly used with a setState
     */
    onChange: PropTypes.func,
    /**
     * This part is used by react table
     */
    column: PropTypes.object,
    /**
     * To reset to first page in react table when filtering data
     */
    setActivePageReset: PropTypes.func,
    statusConstants: PropTypes.array,
    /**
     * Overridate the statusConstants
     * It allow you to set your array [{value: yourValue, text: yourText}]
     * without translation system
     */
    manualOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            text: PropTypes.string,
        }),
    ),
    i18TextBase: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
};
export default withTranslation('common')(StatusFilter);
