import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import {
    ButtonGroup,
    InternalGrid,
    ModalControl,
    ModalProvider,
    ThemeDefault,
    DaButton,
    OutIcon,
    CheckBoldIcon,
    ArrowRightIcon,
} from '@paygreen/paygreen-ui';
import { ActionBadge, H1Title } from '../../../components';
import { spaces } from '../../../utils/constants';
import { useNodeDimensions } from '../../../utils/hooks';
import { useUser } from '../../../context';
import { climateEnvLink } from '../shared';
import SpaceCard from './SpaceCard';
import UserSpaceModal from './UserSpaceModal';

const UserSpaces = ({ t }) => {
    const { user } = useUser();

    // we create a ref to detect if component is mounted
    const isMounted = useRef(false);

    // we create a ref using a special hook to get width from InternalGrid child component
    const [ref, { width }] = useNodeDimensions(isMounted.current);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    const ActivatedBadge = (props) => (
        <ActionBadge
            text={t('form.button.activated')}
            icon="CheckBoldIcon"
            colorStatus="success"
            isStrong={true}
            {...props}
        />
    );

    return (
        <>
            <Helmet>
                <title>
                    {t('user.spaces.metaTitle')} - {t('main.metaTitle')}
                </title>
            </Helmet>

            <H1Title title={t('user.spaces.title')} />

            <div ref={ref}>
                <InternalGrid
                    displayType="grid"
                    gridGap="lg"
                    gridTemplateColumns={
                        width < ThemeDefault.breakpoint.md ? '1fr' : '1fr 1fr'
                    }
                    justifyItems="stretch"
                    hasStaticWidth={false}
                    childrenMarginTop="md"
                >
                    <SpaceCard space={spaces.climate}>
                        <ButtonGroup
                            marginBottom="sm"
                            marginTop="xs"
                            style={{ alignItems: 'center' }}
                        >
                            <ModalProvider>
                                <>
                                    {user?.usesTree === '1' ? (
                                        <>
                                            <ActivatedBadge className="activated-climate"/>

                                            <a
                                                href={climateEnvLink()}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="open-climate"
                                            >
                                                <DaButton
                                                    buttonSize="sm"
                                                    gradient="theme"
                                                    icon={<ArrowRightIcon />}
                                                >
                                                    {t('form.button.open')}
                                                </DaButton>
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            <a
                                                href="https://paygreen.io/climatekit/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <DaButton
                                                    buttonSize="sm"
                                                    buttonStyle="line"
                                                    colorPallet="theme"
                                                    gradient="theme"
                                                    icon={<OutIcon />}
                                                >
                                                    {t('form.button.knowMore')}
                                                </DaButton>
                                            </a>

                                            <ModalControl
                                                hasOnClickControl={true}
                                            >
                                                <button type="submit">
                                                    <DaButton
                                                        buttonSize="sm"
                                                        gradient="theme"
                                                        icon={<CheckBoldIcon />}
                                                    >
                                                        {t(
                                                            'form.button.activate',
                                                        )}
                                                    </DaButton>
                                                </button>
                                            </ModalControl>
                                        </>
                                    )}

                                    <ModalControl>
                                        <UserSpaceModal />
                                    </ModalControl>
                                </>
                            </ModalProvider>
                        </ButtonGroup>
                    </SpaceCard>

                    <SpaceCard>
                        <ButtonGroup marginBottom="sm" marginTop="xs">
                            <ActivatedBadge className="activated-charity"/>
                        </ButtonGroup>
                    </SpaceCard>
                </InternalGrid>
            </div>
        </>
    );
};

export default withTranslation('common')(UserSpaces);
