import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ModalForm } from '../../components';
import ForgottenPasswordForm from './ForgottenPasswordForm';

const ForgottenPasswordModal = ({
    setUsername,
    username,
    askPassword,
    title,
    mode,
    isOpen,
    children,
    setOpen,
}) => {
    // initializing all react-hook-form values and methods
    const defaultValues = {
        usernamePassword: username ? username : '',
    };
    const { register, handleSubmit, setValue, errors, clearErrors } = useForm({
        defaultValues: defaultValues,
    });

    // Watch for changes on inputs
    useEffect(() => {
        register({ name: 'usernamePassword' });
    }, [register]);

    const submitForm = (data) => {
        setUsername && setUsername(data.usernamePassword);
        askPassword(data.usernamePassword);
    };

    const cancelForm = () => {
        setOpen(false);

        // we need the reset methods to wait until modal is closed
        setTimeout(() => {
            setValue('usernamePassword', '');
            clearErrors();
        }, 500);
    };

    return (
        <ModalForm
            modalTitle={title}
            blockWidth="md"
            isOpen={isOpen}
            actionOnClose={() => cancelForm()}
        >
            {children}

            <ForgottenPasswordForm
                errors={errors}
                setValue={setValue}
                register={register}
                onSubmit={handleSubmit(submitForm)}
                cancelForm={cancelForm}
                mode={mode}
            />
        </ModalForm>
    );
};

ForgottenPasswordModal.propTypes = {
    title: PropTypes.string,
    setUsername: PropTypes.func,
    askPassword: PropTypes.func,
    mode: PropTypes.oneOf(['send', 'resend']),
};

ForgottenPasswordModal.defaultProps = {
    title: '',
    mode: 'send',
};

export default withTranslation('common')(ForgottenPasswordModal);
