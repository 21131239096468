import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { PenIcon, DaButton } from '@paygreen/paygreen-ui';

const EditButton = ({
    t,
    onClick,
    buttonSize,
    buttonStyle,
    colorStatus,
    children,
    dataCy,
}) => (
    <button type="button" onClick={onClick} data-cy={dataCy}>
        <DaButton
            buttonSize={buttonSize}
            buttonStyle={buttonStyle}
            colorPallet="status"
            colorStatus={colorStatus}
            icon={<PenIcon />}
        >
            {children}
        </DaButton>
    </button>
);

EditButton.propTypes = {
    onClick: PropTypes.func,
    buttonSize: PropTypes.string,
    buttonStyle: PropTypes.string,
    colorStatus: PropTypes.string,
    /**
     * Id for Cypress tests
     */
    dataCy: PropTypes.string,
};

EditButton.defaultProps = {
    colorStatus: 'default',
    buttonStyle: 'fill',
    buttonSize: 'md',
};

export default withTranslation('common')(EditButton);
