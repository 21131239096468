import React from 'react';
import PropTypes from 'prop-types';
import { Title, Dot, InternalGrid, Box } from '@paygreen/paygreen-ui';
import { IsMobile } from '../../utils/tools';

/**
 * @description this component is based on Title component from PG-UI and will be rendered as `<h1>`
 */
const H1Title = ({ title, children, marginBottom, ...rest }) => (
    <>
        {children ? (
            <InternalGrid
                flexWrap="wrap"
                alignItems="center"
                justifyContent="flex-start"
                hasStaticWidth={false}
                displayType={IsMobile() ? 'grid' : 'flex'}
                justifyItems="start"
            >
                <Title
                    htmlTag="h1"
                    textSize="lg"
                    colorWab="grey30"
                    marginBottom={marginBottom}
                    {...rest}
                >
                    {title}
                </Title>

                {!IsMobile() && (
                    <Dot
                        dotStyle="dash"
                        colorPallet="wab"
                        colorWab="grey10"
                        marginBottom="md"
                        marginRight="md"
                        marginLeft="md"
                        style={{ transform: 'rotate(90deg)' }}
                    />
                )}

                <Box marginBottom="sm">{children}</Box>
            </InternalGrid>
        ) : (
            <Title
                htmlTag="h1"
                textSize="lg"
                colorWab="grey30"
                marginBottom={marginBottom}
                {...rest}
            >
                {title}
            </Title>
        )}
    </>
);

H1Title.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    marginBottom: PropTypes.string,
};

H1Title.defaultProps = {
    marginBottom: 'xs',
};

export default H1Title;
