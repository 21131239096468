import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    ButtonGroup,
    CheckIcon,
    DaButton,
    DaInput,
    InternalGrid,
} from '@paygreen/paygreen-ui';
import { SimpleActionButton, FormControlWrapper } from '../../components';
import regexForm from '../../utils/regexForm';

const ForgottenPasswordForm = ({
    t,
    errors,
    setValue,
    register,
    onSubmit,
    cancelForm,
    mode,
}) => {
    return (
        <InternalGrid
            align="center"
            displayType="grid"
            gridTemplateColumns="1fr"
        >
            <form onSubmit={onSubmit} noValidate>
                <FormControlWrapper
                    inputName="usernamePassword"
                    errors={errors}
                    i18labelBase="forgottenPassword"
                    isRequired={true}
                    blockWidth="md"
                    marginTop="md"
                    hasStaticWidth={true}
                >
                    <DaInput
                        onChange={(e) =>
                            setValue('usernamePassword', e.target.value)
                        }
                        name="usernamePassword"
                        id="usernamePassword"
                        type="text"
                        inputRef={register({
                            required: t('form.error.required'),
                            pattern: {
                                value: regexForm.accountUsername,
                                message: t(`form.error.usernamePassword`),
                            },
                        })}
                        autoComplete="off"
                        blockWidth="lg"
                        fieldSize="lg"
                    />
                </FormControlWrapper>

                <ButtonGroup marginTop="sm" marginBottom="xs">
                    {mode === 'send' ? (
                        <SimpleActionButton
                            type="reset"
                            icon="ArrowRoundTopLeftIcon"
                            buttonSize="lg"
                            onClick={() => cancelForm()}
                        >
                            {t('form.button.cancel')}
                        </SimpleActionButton>
                    ) : (
                        <a
                            href="mailto:serviceclient@paygreen.fr"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <DaButton
                                buttonStyle="line"
                                buttonSize="lg"
                                colorTheme="secondary"
                            >
                                {t('forgottenPassword.contactServiceButton')}
                            </DaButton>
                        </a>
                    )}

                    <button type="submit">
                        <DaButton
                            buttonSize="lg"
                            gradient="theme"
                            colorTheme="quaternary"
                            icon={<CheckIcon />}
                        >
                            {mode === 'send'
                                ? t('forgottenPassword.sendButton')
                                : t('forgottenPassword.reSendButton')}
                        </DaButton>
                    </button>
                </ButtonGroup>
            </form>
        </InternalGrid>
    );
};

ForgottenPasswordForm.propTypes = {
    setValue: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    cancelForm: PropTypes.func.isRequired,
    /**
     * errors passed by react-hook-form for each field (necessary if you want errors management)
     */
    errors: PropTypes.object,
    mode: PropTypes.oneOf(['send', 'resend']),
    /**
     * function received from ModalForm parent
     */
    setOpen: PropTypes.func,
};

ForgottenPasswordForm.defaultProps = {
    errors: {},
    mode: 'send',
};

export default withTranslation('common')(ForgottenPasswordForm);
