import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Box, InternalGrid, Text, Toggle } from '@paygreen/paygreen-ui';
import {
    LineKeyValue,
    StatusDisplayTable,
    Message,
} from '../../../../components';
import { sepaStatusOptions, userInputList } from '../../adminUsersUtils';
import { getDataFromObject, timestampToDate } from '../../../../utils/tools';

const SpaceManagementActivation = ({
    t,
    accountData,
    mode,
    active,
    activeChanged,
    spaceName,
    updateActive,
    billingName,
}) => {
    const spacesInputList =
        mode === 'charity'
            ? userInputList.slice(7, 9)
            : userInputList.slice(12, 14);

    return (
        <InternalGrid
            displayType="grid"
            gridTemplateColumns="auto"
            justifyItems="start"
            alignItems="center"
            gridGap="sm"
        >
            {spacesInputList.map((item, index) => (
                <LineKeyValue
                    key={index}
                    hasUppercase={false}
                    keyLabel={t(
                        `admin.user.${mode}KitStatus.` + item.name + 'Input',
                    )}
                    keyValue={
                        (accountData &&
                            getDataFromObject(item.value, accountData)) ||
                        null
                    }
                    textSize="xs"
                >
                    {item.name === mode + 'KitStatus' ? (
                        <InternalGrid childrenMarginBottom="md">
                            <InternalGrid alignItems="center">
                                {accountData?.[spaceName] !== '0' ? (
                                    <Box marginLeft="sm">
                                        <Toggle
                                            id={spaceName + 'space'}
                                            checkedLabel={t(
                                                'admin.users.table.activatedSpacesFilter.ACTIVATED.name',
                                            )}
                                            notCheckedLabel={t(
                                                'admin.users.table.activatedSpacesFilter.NOTACTIVATED.name',
                                            )}
                                            value={active}
                                            checked={active}
                                            onChange={(e) =>
                                                updateActive(e.target.checked)
                                            }
                                        />
                                    </Box>
                                ) : (
                                    <Text
                                        htmlTag="strong"
                                        textSize="xs"
                                        colorPallet="wab"
                                        colorWab="grey50"
                                    >
                                        {t('admin.user.space.deactivated')}
                                    </Text>
                                )}
                            </InternalGrid>

                            {!active && activeChanged ? (
                                <Message
                                    colorStatus="danger"
                                    hasStaticWidth={true}
                                >
                                    <Text textSize="xs">
                                        {t(`admin.user.${mode}Warning`)}
                                    </Text>
                                </Message>
                            ) : null}
                        </InternalGrid>
                    ) : null}

                    {item.name === mode + 'KitSEPAStatus' ? (
                        <StatusDisplayTable
                            status={accountData?.[billingName].status}
                            statusExtension={
                                accountData?.[billingName].status === 'SIGNED'
                                    ? timestampToDate(
                                          accountData?.[billingName]
                                              .subscribedAt,
                                      )
                                    : null
                            }
                            statusOptions={sepaStatusOptions}
                            i18TextBase="admin.users.table.sepaStatusFilter"
                            childrenMarginBottom="md"
                        />
                    ) : null}
                </LineKeyValue>
            ))}
        </InternalGrid>
    );
};

SpaceManagementActivation.propTypes = {
    accountData: PropTypes.object,
    mode: PropTypes.oneOf(['charity', 'climate']),
    active: PropTypes.bool,
    activeChanged: PropTypes.bool,
    spaceName: PropTypes.string,
    updateActive: PropTypes.func,
    billingName: PropTypes.string,
};

export default withTranslation('common')(SpaceManagementActivation);
