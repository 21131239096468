import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Text, ButtonGroup, InternalGrid, Card } from '@paygreen/paygreen-ui';
import { DeleteButton } from '../../../../components';

const DeletePartnershipGroupCard = ({ t, deleteGroup }) => {
    return (
        <InternalGrid
            displayType="grid"
            gridTemplateColumns="1fr"
            justifyItems="stretch"
            alignItems="stretch"
            childrenMarginBottom="sm"
        >
            <Card
                colorPallet="status"
                colorStatus="danger"
                blockWidth="none"
                shadowSize="none"
                paddingBottom="sm"
                paddingLateral="sm"
                paddingTop="sm"
                radiusSize="sm"
            >
                <Text
                    textSize="sm"
                    htmlTag="strong"
                    colorPallet="status"
                    colorStatus="danger"
                >
                    {t('newPartnershipGroupForm.deleteGroup')}
                </Text>

                <Text textSize="xs" marginTop="xs">
                    {t('newPartnershipGroupForm.deleteText')}
                </Text>

                <ButtonGroup marginTop="xs">
                    <DeleteButton onClick={() => deleteGroup()}>
                        {t('partnershipButtons.deleteGroup')}
                    </DeleteButton>
                </ButtonGroup>
            </Card>
        </InternalGrid>
    );
};

DeletePartnershipGroupCard.propTypes = {
    deleteGroup: PropTypes.func,
};

export default withTranslation('common')(DeletePartnershipGroupCard);
