import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    Card,
    Box,
    Text,
    BannerImage,
    InternalGrid,
    Divider,
    List,
    ListItem,
    QuoteRightIcon,
} from '@paygreen/paygreen-ui';
import { spaces } from '../../../utils/constants';
import charityImage from '../../../assets/images/charity.png';
import climateImage from '../../../assets/images/climate.png';
import {
    LogoPaygreen,
    CharityKitBadge,
    ClimateKitBadge,
    LogoArrondi,
} from '../../../assets/logos';
import BannerWrapper from './SpaceCard.styled';

const SpaceCard = ({ t, space, children }) => {
    const isClimate = space === spaces.climate;
    const colorSpace = isClimate ? 'primary' : 'quaternary';

    return (
        <Card isShadowWab={true} blockWidth="sm">
            <BannerWrapper colorTheme={colorSpace}>
                <BannerImage
                    topStyle="none"
                    bottomStyle="left"
                    image={isClimate ? climateImage : charityImage}
                >
                    <Box padding="xs">
                        <InternalGrid
                            childrenMarginTop="md"
                            childrenMarginLateral="md"
                        >
                            <LogoPaygreen
                                isWhite={true}
                                badge={
                                    isClimate ? (
                                        <ClimateKitBadge colorType="reverse" />
                                    ) : (
                                        <CharityKitBadge colorType="reverse" />
                                    )
                                }
                            />
                        </InternalGrid>
                    </Box>
                </BannerImage>
            </BannerWrapper>

            <Box paddingRight="md" paddingLeft="md" paddingTop="sm">
                <Text colorWab="grey40" htmlTag="strong" textSize="sm" className={`card-title-${space}`}>
                    {t(`user.spaces.${space}.cardTitle`)}
                </Text>

                <InternalGrid
                    displayType="grid"
                    gridTemplateColumns="auto 1fr"
                    gridGap="md"
                    childrenMarginTop="sm"
                >
                    {!isClimate ? <LogoArrondi /> : null}

                    <Text textSize="xs" colorWab="grey50">
                        {t(`user.spaces.${space}.cardIntro`)}
                    </Text>
                </InternalGrid>
            </Box>

            <Divider
                colorTheme={colorSpace}
                marginTop="xs"
                marginBottom="none"
            />

            <Box paddingRight="md" paddingLeft="md" paddingBottom="sm">
                <List listStyle="icon" bulletSize="sm">
                    {t(`user.spaces.${space}.advantages`).map(
                        (element, index) => (
                            <ListItem
                                key={index}
                                bulletStyle="icon"
                                bulletSize="sm"
                            >
                                <QuoteRightIcon colorTheme={colorSpace} />

                                <Text textSize="xs" colorWab="grey50">
                                    {element}
                                </Text>
                            </ListItem>
                        ),
                    )}
                </List>
            </Box>

            {children}
        </Card>
    );
};

SpaceCard.propTypes = {
    space: PropTypes.oneOf(Object.values(spaces)),
};

SpaceCard.defaultProps = {
    space: spaces.charity,
};

export default withTranslation('common')(SpaceCard);
