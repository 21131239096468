import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Text, DaLabel } from '@paygreen/paygreen-ui';
import SkeletonTextLine from './SkeletonTextLine';
import { IsMobile } from '../../utils/tools';

const LineKeyValue = ({
    t,
    isLoading,
    keyLabel,
    keyValue,
    dataCy,
    children,
    hasUppercase,
    textSize,
}) => (
    <>
        <SkeletonTextLine
            blockWidth="xl"
            colorWab="grey10"
            isLoading={isLoading}
        >
            <DaLabel>
                <Text
                    hasUppercase={hasUppercase}
                    textSize={textSize}
                    colorWab="grey30"
                    marginTop={IsMobile() ? 'xs' : 'none'}
                >
                    {keyLabel}
                </Text>
            </DaLabel>
        </SkeletonTextLine>

        <SkeletonTextLine
            colorWab="grey10"
            isLoading={isLoading}
            blockWidth="auto"
        >
            {keyValue && children ? (
                children
            ) : (
                <Text
                    textSize="xs"
                    htmlTag={!keyValue ? 'em' : null}
                    colorWab={!keyValue ? 'grey40' : 'grey60'}
                    data-cy={dataCy}
                >
                    {keyValue || t('user.noDataFound')}
                </Text>
            )}
        </SkeletonTextLine>
    </>
);

LineKeyValue.propTypes = {
    keyLabel: PropTypes.string.isRequired,
    keyValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
    ]),
    isLoading: PropTypes.bool,
    hasUppercase: PropTypes.bool,
    /**
     * Id for Cypress tests
     */
    dataCy: PropTypes.string,
    textSize: PropTypes.string,
};

LineKeyValue.defaultProps = {
    isLoading: false,
    hasUppercase: true,
    textSize: 'xxs',
};

export default withTranslation('common')(LineKeyValue);
