import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { CrossBoldIcon } from '@paygreen/paygreen-ui';
import 'react-toastify/dist/ReactToastify.css';

// we override css style with classes as props https://fkhadra.github.io/react-toastify/how-to-style#pass-css-classes-to-props
const StyledToastContainer = styled(ToastContainer).attrs({
    className: 'toast-container',
    toastClassName: 'toast',
    bodyClassName: 'body',
    progressClassName: 'progress-bar',
    closeButton: <CrossBoldIcon iconSize="xs" style={{ flexShrink: 0 }} />,
})`
  

   

    /* .body is passed to bodyClassName */
    .body {
    }

   
`;

const ToastNotification = () => (
    <StyledToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
);

export default withTranslation('common')(ToastNotification);
