import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { DaInput } from '@paygreen/paygreen-ui';

/**
 * @description - specific input with useController to force validation with react-input-mask inside
 */
const ControlledInput = ({
    control,
    name,
    isRequired,
    isDisabled,
    inputPattern,
    mask,
    type,
    defaultValue,
    fieldSize,
    onChange,
    errorMessage,
    minRules,
    t,
    ...rest
}) => {
    const {
        field: { ref, ...inputProps },
    } = useController({
        name,
        control,
        rules: {
            required: isRequired ? t('form.error.required') : null,
            pattern: {
                value: inputPattern,
                message: errorMessage,
            },
            min: {
                value: minRules,
                message: errorMessage,
            },
        },
        defaultValue: defaultValue,
    });

    const onChangeProps = onChange
        ? {
              onChange: onChange,
          }
        : null;

    delete rest.tReady; // clean props from react-i18next
     
    return (
        <DaInput
            {...inputProps}
            type={type}
            inputRef={ref}
            mask={mask}
            autoComplete="off"
            id={name}
            disabled={isDisabled}
            fieldSize={fieldSize}
            {...onChangeProps}
            {...rest}
        />
    );
};

const ControlledDaInput = ({
    t,
    isRequired,
    isDisabled,
    inputName,
    inputPattern,
    mask,
    inputType,
    control,
    fieldSize,
    defaultValue,
    onChange,
    errorMessage,
    minRules,
    ...rest
}) => (
    <ControlledInput
        control={control}
        name={inputName}
        id={inputName}
        mask={mask}
        isRequired={isRequired}
        isDisabled={isDisabled}
        fieldSize={fieldSize}
        inputPattern={inputPattern}
        minRules={minRules}
        defaultValue={defaultValue}
        onChange={onChange}
        t={t}
        type={inputType}
        errorMessage={errorMessage}
        {...rest}
    />
);

ControlledDaInput.propTypes = {
    inputName: PropTypes.string.isRequired,
    inputPattern: PropTypes.object,
    minRules: PropTypes.number,
    /**
     * errors passed by react-hook-form for each field (necessary if you want errors management)
     */
    errors: PropTypes.object,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    fieldSize: PropTypes.string,
    defaultValue: PropTypes.string,
    /**
     * optional, only if a specific external action is needed on change
     */
    onChange: PropTypes.func,
    inputType: PropTypes.string,
    errorMessage: PropTypes.string,
};

ControlledDaInput.defaultProps = {
    errors: {},
    isRequired: false,
    isDisabled: false,
    fieldSize: 'md',
    inputType: 'tel',
};

export default withTranslation('common')(ControlledDaInput);
