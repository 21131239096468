import React from 'react';
import PropTypes from 'prop-types';
import { InternalGrid, SkeletonItem } from '@paygreen/paygreen-ui';
import { CardClassic, ErrorBoundary } from '../../../components';
import { IsMobile } from '../../../utils/tools';

/**
 * @description - card to display customly user info with LineKeyValue component
 */
const CardInfo = ({ isLoading, children, title, actionIcon, hasInput }) => (
    <ErrorBoundary>
        <SkeletonItem
            blockHeight="auto"
            colorWab="grey10"
            radiusSize="sm"
            isLoading={isLoading}
        >
            <CardClassic
                title={title}
                isShadowWab={true}
                titleSize="md"
                actionIcon={actionIcon}
            >
                <InternalGrid
                    displayType="grid"
                    gridTemplateColumns={IsMobile() ? '1fr' : 'auto auto'}
                    justifyItems={hasInput ? 'stretch' : 'start'}
                    alignItems="center"
                    gridGap="sm"
                    childrenMarginTop="none"
                    childrenMarginBottom="none"
                >
                    {children}
                </InternalGrid>
            </CardClassic>
        </SkeletonItem>
    </ErrorBoundary>
);

CardInfo.propTypes = {
    isLoading: PropTypes.bool,
    title: PropTypes.string,
    actionIcon: PropTypes.object,
    hasInput: PropTypes.bool,
};

CardInfo.defaultProps = {
    isLoading: false,
    hasInput: false,
};

export default CardInfo;
