import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { PartnershipGroup } from 'api-green-node';
import { ModalForm } from '../../../../components';
import { useMode } from '../../../../context';
import { partnershipGroupFormModes } from '../../../../utils/constants';
import { updatePartnershipGroup, deletePartnershipGroup } from '../../service';
import PartnershipToastMessage from '../PartnershipToastMessage';
import PartnershipGroupForm from './PartnershipGroupForm';
import DeletePartnershipGroupCard from './DeletePartnershipGroupCard';

const UpdatePartnershipGroupModal = ({
    t,
    setOpen,
    isOpen,
    externalId,
    isDefaultGroup,
    partnershipIds,
    setIsPartnershipsGroupUpdating,
    getData,
}) => {
    const { register, handleSubmit, setValue, errors, clearErrors } = useForm();
    const [errorUpdateMessage, setErrorUpdateMessage] = useState(null);
    const { mode } = useMode();

    const customIsDefault = 'isDefault' + externalId;
    const customExternalId = 'externalId' + externalId;

    // Watch for changes on inputs when form is mounted
    useEffect(() => {
        register({ name: customIsDefault });
        register({ name: customExternalId });
    }, [register, customIsDefault, customExternalId]);

    const submitForm = (data) => {
        const newPartnershipGroup = new PartnershipGroup();
        newPartnershipGroup.externalId = data[customExternalId];
        newPartnershipGroup.isDefault = data[customIsDefault];
        newPartnershipGroup.partnershipIds = partnershipIds;

        updatePartnershipGroup(externalId, newPartnershipGroup, mode)
            .then(() => {
                setIsPartnershipsGroupUpdating(true);
                getData();
                toast(
                    <PartnershipToastMessage
                        i18nKey="form.toastSuccess.partnershipGroupEdit"
                        partnerName={
                            externalId === 'default'
                                ? 'default'
                                : newPartnershipGroup.externalId
                        }
                    />,
                );
            })
            .catch((error) => {
                setErrorUpdateMessage(
                    error?.response?.data.detail +
                        ' / ' +
                        error?.response?.data.title,
                );
            })
            .finally(
                () =>
                    errorUpdateMessage && setIsPartnershipsGroupUpdating(false),
            );
    };

    const deleteGroup = () => {
        deletePartnershipGroup(externalId, mode)
            .then(() => {
                setIsPartnershipsGroupUpdating(true);
                getData();
                toast(
                    <PartnershipToastMessage
                        i18nKey="form.toastSuccess.partnershipGroupDelete"
                        partnerName={externalId}
                    />,
                );
            })
            .catch((error) => {
                setErrorUpdateMessage(error?.response?.data.detail);
            })
            .finally(
                () =>
                    errorUpdateMessage && setIsPartnershipsGroupUpdating(false),
            );
    };

    const cancelCloseModal = () => {
        setOpen(false);

        // we need the reset methods to wait until modal is closed
        setTimeout(() => {
            clearErrors();

            setValue(customIsDefault, isDefaultGroup);
            setValue(customExternalId, externalId);

            setErrorUpdateMessage();
        }, 500);
    };

    return (
        <ModalForm
            modalTitle={t('newPartnershipGroupButton.modifyTitle')}
            blockWidth="md"
            paddingLateral="md"
            isOpen={isOpen}
            actionOnClose={() => cancelCloseModal()}
        >
            <PartnershipGroupForm
                mode={partnershipGroupFormModes.UPDATE}
                cancelForm={cancelCloseModal}
                onSubmit={handleSubmit(submitForm)}
                errors={errors}
                errorMessage={errorUpdateMessage}
                register={register}
                setValue={setValue}
                isDefaultGroup={isDefaultGroup}
                externalId={externalId}
            >
                {!isDefaultGroup && externalId !== 'default' ? (
                    <DeletePartnershipGroupCard
                        deleteGroup={() => deleteGroup()}
                    />
                ) : null}
            </PartnershipGroupForm>
        </ModalForm>
    );
};

UpdatePartnershipGroupModal.propTypes = {
    /**
     * @description setOpen and isOpen are automatically received from ModalControl parent
     */
    setOpen: PropTypes.func,
    isOpen: PropTypes.bool,
    externalId: PropTypes.string.isRequired,
    isDefaultGroup: PropTypes.bool.isRequired,
    partnershipIds: PropTypes.array.isRequired,
    setIsPartnershipsGroupUpdating: PropTypes.func,
};

export default withTranslation('common')(UpdatePartnershipGroupModal);
