import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
    CancelledCharityPartnerships,
    MyPartnerships,
    PendingCharityRequests,
    RefusedCharityRequests,
    Error404,
} from '../domains';

const CharityPartnershipsRoutes = () => {
    return (
        <Switch>
            <Route exact path="/partnerships" component={MyPartnerships} />
            <Route
                exact
                path="/partnerships/cancelled-partnerships"
                component={CancelledCharityPartnerships}
            />
            <Route
                exact
                path="/partnerships/pending-requests"
                component={PendingCharityRequests}
            />
            <Route
                exact
                path="/partnerships/refused-requests"
                component={RefusedCharityRequests}
            />
            <Route component={Error404} />
        </Switch>
    );
};

export default CharityPartnershipsRoutes;
