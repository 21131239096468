import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import { ApiResponse, FieldOfAction } from 'api-green-node';
import { ButtonGroup } from '@paygreen/paygreen-ui';
import {
    CardClassic,
    ErrorBoundary,
    ActionBadge,
    CancelButton,
    ValidateButton,
} from '../../../components';
import {
    useAuth,
    useUser,
    useCharityList,
    useAPIError,
} from '../../../context';
import { toast } from 'react-toastify';
import CharityEditForm from './CharityEditForm';

const UserCharityInfo = (props) => {
    const { t } = props;
    const { getSdk } = useAuth();
    const { addError } = useAPIError();
    const { user } = useUser();
    const { charityListData, setCharityListData } = useCharityList();

    // we create a ref to detect if component is mounted
    const isMounted = useRef(false);

    const [charityDataLocal, setCharityDataLocal] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);

    // initializing all react-hook-form values and methods
    const defaultValues = {
        name: charityDataLocal?.name,
        description: charityDataLocal?.description,
        url: charityDataLocal?.url,
        fieldOfAction: t('charity.edit.fieldOfActionOptions')
            .indexOf(charityDataLocal?.fieldOfAction)
            .toString(),
    };

    const methods = useForm({
        defaultValues: defaultValues,
        mode: 'onTouched',
        reValidateMode: 'onChange',
    });

    const { register, handleSubmit, reset, clearErrors } = methods;

    // Watch for changes on inputs
    useEffect(() => {
        register({ name: 'name' });
        register({ name: 'description' });
        register({ name: 'url' });
        register({ name: 'fieldOfAction' });
    }, [register]);

    // Update selector default values in form when data is retrieved and form mounted
    useEffect(() => {
        reset({
            name: charityDataLocal?.name,
            url: charityDataLocal?.url,
            fieldOfAction: t('charity.edit.fieldOfActionOptions')
                .indexOf(charityDataLocal?.fieldOfAction)
                .toString(),
            description: charityDataLocal?.description,
        });
    }, [charityDataLocal, reset, t]);

    useEffect(() => {
        isMounted.current = true;

        if (
            user?.role === 'ASSOCIATION' &&
            charityListData &&
            charityListData[0]
        ) {
            let tempRes = charityListData[0];

            if (isMounted.current) {
                setCharityDataLocal({
                    ...tempRes,
                    fieldOfAction: t(
                        `charity.edit.fieldOfActionValues.${charityListData[0].fieldOfAction}`,
                    ),
                });
                // we simulate minimal loading for better UX and synchronization with user form loading
                setTimeout(() => setIsLoading(false), 500);
            }
        }

        return () => {
            isMounted.current = false;
        };
    }, [getSdk, user, t, charityListData]);

    /**
     * PATCH request to API to update user's data
     * @returns {object} - object with user's data updated
     */
    const updateCharity = useCallback(
        (updatedCharity) => {
            setIsLoading(true);

            getSdk()
                .charity.update(updatedCharity, charityDataLocal.idAssociation)
                .then((res) => {
                    if (ApiResponse.isSuccessful(res)) {
                        setCharityListData([res.dataInfo]);
                        setIsEditing(false);
                        toast(t('form.toastSuccess.charityUpdate'));
                    } else {
                        addError(
                            ApiResponse.getErrorMessage(res),
                            ApiResponse.getStatus(res),
                        );
                    }
                })
                .finally(() => setIsLoading(false));
        },
        [
            addError,
            getSdk,
            setIsLoading,
            setCharityListData,
            charityDataLocal.idAssociation,
            t,
        ],
    );

    const submitForm = (data) => {
        const updatedCharity = {
            idAssociation: charityDataLocal.idAssociation,
            name: data.name,
            description: data.description,
            url: data.url,
            fieldOfAction: FieldOfAction[data.fieldOfAction],
        };

        updateCharity(updatedCharity);
    };

    const resetForm = () => {
        clearErrors();
        reset();
        setIsEditing(false);
    };

    return (
        <ErrorBoundary>
            <CardClassic
                title={t('charity.see.formTitle')}
                titleSize="md"
                isShadowWab
                blockWidth="md"
            >
                <FormProvider {...methods}>
                    <CharityEditForm
                        onSubmit={handleSubmit(submitForm)}
                        defaultValues={defaultValues}
                        isLoading={isLoading}
                        setIsEditing={setIsEditing}
                    >
                        {isEditing && (
                            <ButtonGroup marginTop="sm" marginBottom="sm">
                                <CancelButton
                                    buttonSize="md"
                                    onClick={() => resetForm()}
                                    dataCy="charity-cancel"
                                >
                                    {t('form.button.cancel')}
                                </CancelButton>

                                <ValidateButton
                                    buttonSize="md"
                                    isDisabled={isLoading}
                                    dataCy="charity-validate"
                                >
                                    {t('resetPassword.validateButton')}
                                </ValidateButton>
                            </ButtonGroup>
                        )}
                    </CharityEditForm>
                </FormProvider>

                {!isEditing && (
                    <ButtonGroup marginTop="sm" marginBottom="sm">
                        <ActionBadge text={t('user.edit.badgetext')} />
                    </ButtonGroup>
                )}
            </CardClassic>
        </ErrorBoundary>
    );
};

export default withTranslation('common')(UserCharityInfo);
