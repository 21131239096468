import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link, Text } from '@paygreen/paygreen-ui';
import { LineKeyValue } from '../../../../components';
import {
    getDataFromObject,
    displayFrenchPhoneNumber,
} from '../../../../utils/tools';
import { userInputList } from '../../adminUsersUtils';
import { CardInfo, EditModalIcon } from '../../shared';
import AdministratorManagementModal from './AdministratorManagementModal';

const AdministratorManagementCard = ({
    t,
    isLoading,
    setIsLoading,
    accountData,
    setAccountData,
}) => (
    <CardInfo
        isLoading={isLoading}
        title={t('admin.user.administratorInfos.title')}
        actionIcon={
            <EditModalIcon title={t('user.edit.title')}>
                <AdministratorManagementModal
                    accountData={accountData}
                    setAccountData={setAccountData}
                    setIsLoading={setIsLoading}
                />
            </EditModalIcon>
        }
    >
        {userInputList.slice(4, 7).map((item, index) => (
            <LineKeyValue
                key={index}
                hasUppercase={false}
                keyLabel={t(
                    'admin.user.administratorInfos.' + item.name + 'Input',
                )}
                keyValue={
                    accountData &&
                    (item.type === 'phone'
                        ? displayFrenchPhoneNumber(
                              getDataFromObject(item.value, accountData),
                          )
                        : getDataFromObject(item.value, accountData)[0] +
                          ' ' +
                          getDataFromObject(item.value, accountData)[1])
                }
                textSize="xs"
            >
                {item.type === 'link' && (
                    <a
                        href={`mailto:${getDataFromObject(
                            item.value,
                            accountData,
                        )}`}
                    >
                        <Text textSize="xs">
                            <Link colorTheme="secondary">
                                {getDataFromObject(item.value, accountData)}
                            </Link>
                        </Text>
                    </a>
                )}
            </LineKeyValue>
        ))}
    </CardInfo>
);

AdministratorManagementCard.propTypes = {
    setIsLoading: PropTypes.func,
    isLoading: PropTypes.bool,
    accountData: PropTypes.object,
    setAccountData: PropTypes.func,
};

AdministratorManagementCard.defaultProps = {
    isLoading: false,
};

export default withTranslation('common')(AdministratorManagementCard);
