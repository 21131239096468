import React from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import { Corner, CrossIcon, Text } from '@paygreen/paygreen-ui';
import {
    ModalBlock,
    DeleteButton,
    SimpleActionButton,
} from '../../../components';
import PartnershipModalContent from './PartnershipModalContent';

const CornerRemoveFromPartnershipGroup = ({
    t,
    removeFromPartnershipGroup,
    isLastPartnership,
    partnerName,
    partnershipsGroupName,
}) => {
    return (
        <>
            {!isLastPartnership ? (
                <Corner
                    as="button"
                    label={<CrossIcon iconSize="xs" />}
                    position="right"
                    colorPallet="status"
                    colorStatus="warning"
                    radiusSize="sm"
                    colorStyle="light"
                    onClick={() => removeFromPartnershipGroup()}
                />
            ) : (
                <ModalBlock
                    blockWidth="md"
                    closeWithAction={() => removeFromPartnershipGroup()}
                    modalTitle={t(
                        'ShopPartnerships.charityPartners.emptyGroupModalTitle',
                    )}
                    isTitleRed={true}
                    button={
                        <Corner
                            as="button"
                            label={<CrossIcon iconSize="xs" />}
                            position="right"
                            colorPallet="status"
                            colorStatus="warning"
                            radiusSize="sm"
                            colorStyle="light"
                        />
                    }
                >
                    <PartnershipModalContent
                        partnershipsGroupAction={removeFromPartnershipGroup}
                        buttonCancelAction={
                            <SimpleActionButton icon="ArrowRoundTopLeftIcon">
                                {t('partnershipButtons.cancel')}
                            </SimpleActionButton>
                        }
                        buttonConfirmAction={
                            <DeleteButton>
                                {t('partnershipButtons.emptyGroup')}
                            </DeleteButton>
                        }
                    >
                        <Text textSize="sm">
                            <Trans i18nKey="ShopPartnerships.charityPartners.emptyGroupMessage">
                                Are you sure to remove
                                <Text htmlTag="strong" textSize="sm">
                                    {{ partnerName }}
                                </Text>
                                from
                                <Text htmlTag="strong" textSize="sm">
                                    {{ partnershipsGroupName }}
                                </Text>
                                This manipulation has impact
                            </Trans>
                        </Text>
                    </PartnershipModalContent>
                </ModalBlock>
            )}
        </>
    );
};

CornerRemoveFromPartnershipGroup.propTypes = {
    removeFromPartnershipGroup: PropTypes.func.isRequired,
    partnershipsGroupName: PropTypes.string.isRequired,
    partnerName: PropTypes.string.isRequired,
    isLastPartnership: PropTypes.bool.isRequired,
};

export default withTranslation('common')(CornerRemoveFromPartnershipGroup);
