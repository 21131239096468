export const statusOptions = {
    SUCCESS: {
        colorPallet: 'status',
        color: 'success',
        icon: 'CheckBoldIcon',
    },
    PLEDGED: {
        colorPallet: 'wab',
        color: 'grey50',
        icon: 'ClockBoldIcon',
    },
    REFUND: {
        colorPallet: 'status',
        color: 'danger',
        icon: 'ArrowCircleLeftBoldIcon',
    },
};
