import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Can from '../utils/rules/Can';
import { useUser } from '../context';
import { UserProfile, UserCompany, UserBilling, UserSpaces, Error404 } from '../domains';

const UsersRoutes = () => {
    const { user } = useUser();

    return (
        <Can
            condition={user && user.role}
            perform={["routes:shop", 'routes:admin']}
            yes={() => (
                <Switch>
                    <Route exact path="/user/profile" component={UserProfile} />
                    <Route exact path="/user/company" component={UserCompany} />
                    <Route exact path="/user/billing" component={UserBilling} />
                    <Route exact path="/user/spaces" component={UserSpaces} />
                    <Route component={Error404} />
                </Switch>
            )}
            no={() => (
                <Switch>
                    <Route exact path="/user/profile" component={UserProfile} />
                    <Route component={Error404} />
                </Switch>
            )}
        />
    );
};

export default UsersRoutes;
