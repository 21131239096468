import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    ButtonGroup,
    DaButton,
    Card,
    Text,
    ArrowCircleLeftBoldIcon,
} from '@paygreen/paygreen-ui';

const ErrorBoundaryCard = ({ t, message, ...rest }) => {
    if (!message) return null;

    return (
        <div>
            <Card
                colorPallet="status"
                colorStatus="danger"
                shadowSize="none"
                {...rest}
            >
                <Text
                    textSize="xs"
                    align="center"
                    paddingLateral="lg"
                    marginLateral="lg"
                    paddingBottom="sm"
                    paddingTop="lg"
                    colorPallet="status"
                    colorStatus="danger"
                >
                    {message}
                </Text>

                <ButtonGroup marginBottom="lg">
                    <button
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        <DaButton
                            buttonSize="sm"
                            colorPallet="status"
                            colorStatus="danger"
                            icon={<ArrowCircleLeftBoldIcon />}
                        >
                            {t('errorAPI.button')}
                        </DaButton>
                    </button>
                </ButtonGroup>
            </Card>
        </div>
    );
};

ErrorBoundaryCard.propTypes = {
    message: PropTypes.string,
};

export default withTranslation('common')(ErrorBoundaryCard);
