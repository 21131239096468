import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from '@paygreen/paygreen-ui';
import {
    IsDesktop,
    IsBigDesktop,
    convertCarbonValue,
    convertPriceValueToEuros,
} from '../../utils/tools';
import { carbonModes } from '../../utils/constants';

export const DataTableGrid = styled.span`
    width: ${(props) => (props.isInTable ? '100%' : 'auto')};
    display: grid;
    grid-template-columns: ${(props) => props.grid};
    grid-template-rows: 1fr;
    align-items: center;
    grid-gap: 4px;
    white-space: nowrap;
`;

/**
 * Component to display carbon data, you can choose to convert with adapted unit depending on value received (mode carbon or price) or to display specific data without conversion
 */
const CarbonDataDisplay = ({
    value,
    unit,
    mode,
    textSize,
    isInTable,
    hasConversionMode,
    customGrid,
    isApproximated,
    hasZeroDisplayed,
    ...rest
}) => {
    const { convertedValue, convertedUnit } =
        mode === carbonModes.carbon
            ? convertCarbonValue(value, hasZeroDisplayed || !isInTable)
            : convertPriceValueToEuros(value, hasZeroDisplayed || !isInTable);

    return (
        <DataTableGrid
            isInTable={isInTable}
            grid={
                !isInTable
                    ? 'auto auto'
                    : IsBigDesktop()
                    ? customGrid || '3fr 1fr'
                    : '3fr auto'
            }
            {...rest}
        >
            <Text
                textSize={textSize ? textSize : IsDesktop() ? 'xs' : 'sm'}
                htmlTag="strong"
                align="right"
            >
                {isApproximated && '≃ '}

                {hasConversionMode ? convertedValue : value || '––'}
            </Text>

            <Text
                textSize={textSize ? textSize : IsDesktop() ? 'xs' : 'sm'}
                align={IsBigDesktop() ? 'left' : 'right'}
                htmlTag="span"
            >
                {hasConversionMode ? convertedUnit : unit}
            </Text>
        </DataTableGrid>
    );
};

CarbonDataDisplay.propTypes = {
    /**
     * between 'carbon' | 'price'
     */
    mode: PropTypes.oneOf(Object.values(carbonModes)),
    /**
     * carbon weight | price
     */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * choose to convert value and unit if needed or to use this component only for better carbon data UI display
     */
    hasConversionMode: PropTypes.bool,
    /**
     * choose the unit you want to display when you don't use conversionMode
     */
    unit: PropTypes.string,
    /**
     * adapt UI when not in table
     */
    isInTable: PropTypes.bool,
    /**
     * to display 0 instead of -- when needed when value = 0
     */
    hasZeroDisplayed: PropTypes.bool,
    customGrid: PropTypes.string,
    textSize: PropTypes.string,
    isApproximated: PropTypes.bool,
};

CarbonDataDisplay.defaultProps = {
    mode: carbonModes.carbon,
    value: 0,
    hasConversionMode: true,
    isInTable: true,
    isApproximated: false,
    hasZeroDisplayed: false,
};

export default CarbonDataDisplay;
