import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    DaLabel,
    DaInput,
    Text,
    Message as UIMessage,
    FormControl,
    ButtonGroup,
    CheckboxGroup,
    InternalGrid,
} from '@paygreen/paygreen-ui';
import regexForm from '../../../../utils/regexForm';
import { Message, CancelButton, ValidateButton } from '../../../../components';
import { partnershipGroupFormModes } from '../../../../utils/constants';

const PartnershipGroupForm = ({
    t,
    mode,
    externalId,
    isDefaultGroup,
    errors,
    register,
    errorMessage,
    onSubmit,
    cancelForm,
    children,
    setValue,
}) => {
    const checkboxOptions = [
        {
            label: t('newPartnershipGroupForm.checkboxLabel'),
            value: '1',
        },
    ];

    const customIsDefault = 'isDefault' + externalId;
    const customExternalId = 'externalId' + externalId;

    return (
        <form onSubmit={onSubmit} noValidate>
            <FormControl
                required={true}
                marginBottom="md"
                colorStatus={errors[customExternalId] ? 'warning' : 'default'}
            >
                <DaLabel htmlFor={customExternalId}>
                    {t('newPartnershipGroupForm.label')}
                </DaLabel>

                <DaInput
                    disabled={mode === 'UPDATE' && externalId === 'default'} // we can't change name of initial default group
                    onChange={(e) => setValue(customExternalId, e.target.value)}
                    name={customExternalId}
                    id={customExternalId}
                    placeholder={t('newPartnershipGroupForm.placeholder')}
                    defaultValue={externalId}
                    blockWidth="md"
                    fieldSize="md"
                    inputRef={register({
                        required: t('form.error.required'),
                        pattern: {
                            value: regexForm.partnershipGroup,
                            message: t('form.error.partnershipGroup'),
                        },
                    })}
                />

                {!errors[customExternalId] && (
                    <Text textSize="xxs">
                        {t('newPartnershipGroupForm.onlyAlphanumeric')}
                    </Text>
                )}

                {errors[customExternalId] && (
                    <UIMessage arrowBlock="topLeft" style={{ width: '100%' }}>
                        <Text textSize="xs">
                            {errors[customExternalId].message}
                        </Text>
                    </UIMessage>
                )}
            </FormControl>

            <CheckboxGroup
                id={customIsDefault}
                name={customIsDefault}
                legend={t('newPartnershipGroupForm.defaultLabel')}
                value={isDefaultGroup ? '1' : '0'}
                disabled={mode === 'UPDATE' && isDefaultGroup}
                fieldSize="md"
                options={checkboxOptions}
                inputRef={register()}
            />

            <InternalGrid justifyContent="center">
                <Message
                    message={errorMessage}
                    type="error"
                    marginTop="md"
                    marginBottom="md"
                    paddingLateral="lg"
                />
            </InternalGrid>

            {mode === 'UPDATE' &&
            externalId === 'default' &&
            isDefaultGroup ? null : (
                <ButtonGroup marginTop="sm" marginBottom="sm">
                    <CancelButton buttonSize="md" onClick={() => cancelForm()}>
                        {t('partnershipButtons.cancel')}
                    </CancelButton>

                    <ValidateButton buttonSize="md">
                        {mode === 'UPDATE'
                            ? t('partnershipButtons.modifyGroup')
                            : t('partnershipButtons.createGroup')}
                    </ValidateButton>
                </ButtonGroup>
            )}

            {children}
        </form>
    );
};

PartnershipGroupForm.propTypes = {
    mode: PropTypes.oneOf(Object.values(partnershipGroupFormModes)),
    externalId: PropTypes.string,
    isDefaultGroup: PropTypes.bool,
    register: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    cancelForm: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    /**
     * errors passed by react-hook-form for each field (necessary if you want errors management)
     */
    errors: PropTypes.object,
    /**
     * error message received from Api if request fails
     */
    errorMessage: PropTypes.string,
};

PartnershipGroupForm.defaultProps = {
    mode: partnershipGroupFormModes.CREATE,
    isDefaultGroup: false,
    externalId: '',
    errors: {},
    errorMessage: '',
};

export default withTranslation('common')(PartnershipGroupForm);
