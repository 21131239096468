import { css, keyframes } from 'styled-components';

const sunset = keyframes`
    from {
        fill: #ffd34d;
    }

    to {
        fill: #ffbc9b;
        transform: translate(-50%, 100%) scale(90%);
    }
`;

const sun = css`
    .sun {
        animation-name: ${sunset};
        animation-duration: 20s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in;
    }
`;

const wind = {
    1: keyframes`
        from,
        20% {
            transform: scale(97%, 100%) rotate(2deg);
        }

        80%,
        to {
            transform: scale(100%, 98%) rotate(0deg);
        }
    `,
    2: keyframes`
        from,
        20% {
            transform: scale(100%, 98%) rotate(0deg) translate(0%, 0%);
        }

        80%,
        to {
            transform: scale(97%, 100%) rotate(2deg) translate(2%, -3%);
        }
    `,
    3: keyframes`
        from,
        20% {
            transform: scale(100%, 98%) rotate(0deg) translate(0%, 0%);
        }

        80%,
        to {
            transform: scale(97%, 100%) rotate(2deg) translate(0.7%, -0.5%);
        }
    `,
    4: keyframes`
        from,
        20% {
            transform: scale(100%, 98%) rotate(0deg) translate(0%, 0%);
        }

        80%,
        to {
            transform: scale(96%, 100%) rotate(2.5deg) translate(1.8%, -2%);
        }
    `,
};

const trees = css`
    .tree1 {
        path {
            animation-name: ${wind[1]};
            animation-duration: 1s;
        }
    }

    .tree2 {
        path {
            animation-name: ${wind[2]};
            animation-duration: 1.1s;
            animation-delay: 0.2s;
        }
    }

    .tree3 {
        path {
            animation-name: ${wind[3]};
            animation-duration: 0.9s;
            animation-delay: 0.1s;
        }
    }

    .tree4 {
        path {
            animation-name: ${wind[4]};
            animation-duration: 1s;
        }
    }
`;

const hiddenE = css`
    .main {
        transform-origin: bottom;
        transition-property: all;
        transition-duration: 1.3s;
        transition-delay: 1s;
        transition-timing-function: ease;
    }

    &:hover {
        .main {
            transform: translate(5%, 1%);
            transition-duration: 0.7s;
            transition-delay: 0s;
            transition-timing-function: cubic-bezier(0.9, -0.6, 0, 2);

            &:hover {
                transform: translate(5%, 1%) rotate(-12deg);
                transition-timing-function: ease;
            }
        }
    }
`;

export { sun, trees, hiddenE };
