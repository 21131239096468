import React from 'react';
import PropTypes from 'prop-types';
import { Banner, Box, InternalGrid } from '@paygreen/paygreen-ui';
import { IsDesktop } from '../../../utils/tools';

const Footer = ({ children, showButtons }) => (
    <Banner
        topStyle="none"
        bottomStyle="none"
        gradient="theme"
        colorTheme="quaternary"
    >
        <Box
            paddingLeft="lg"
            paddingRight="lg"
            paddingTop="md"
            paddingBottom="md"
        >
            <InternalGrid
                alignItems="center"
                justifyContent={
                    showButtons && IsDesktop() ? 'space-between' : 'center'
                }
            >
                {children}
            </InternalGrid>
        </Box>
    </Banner>
);

Footer.propTypes = {
    showButtons: PropTypes.bool,
    children: PropTypes.node,
};

Footer.defaultProps = {
    showButtons: false,
    children: null,
};

export default Footer;
