import React from 'react';
import PropTypes from 'prop-types';

const Illu = ({ gradientBase, gradientShade }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1050 780">
            <circle className="sun" cx="799.5" cy="189.4" r="64" />

            <g className="ground">
                <ellipse
                    className="base"
                    cx="519.1"
                    cy="688.9"
                    rx="512.1"
                    ry="28"
                />
                <ellipse
                    className="base"
                    cx="814.4"
                    cy="735.2"
                    rx="95"
                    ry="4.8"
                />
                <ellipse
                    className="base"
                    cx="558.1"
                    cy="767.1"
                    rx="95"
                    ry="4.8"
                />
                <ellipse
                    className="base"
                    cx="273"
                    cy="739.7"
                    rx="178"
                    ry="11"
                />
            </g>

            <g className="tree tree1">
                <path
                    className="light"
                    d="M446,351.5c0,151-89.8,203.7-200.5,203.7S44.9,502.5,44.9,351.5S245.5,8.4,245.5,8.4S446,200.5,446,351.5z"
                />

                <polygon
                    className="base"
                    points="238.2,532.2 240.2,405.8 325.7,249.4 240.5,386 241.5,329.1 300.4,216 241.7,314.1 241.7,314.1 243.4,211.9 306.4,121.8 243.6,195.8 244.7,8.4 238.7,246.3 174.5,148.1 237.6,265.9 232.2,378 157.6,273.7 231.3,387.7 215.3,693 235.5,693 238,543.4 311.5,429.7"
                />
            </g>

            <g className="tree tree2">
                <path
                    className="light"
                    d="M1042.6,413.4c0,123.6-73.5,166.8-164.1,166.8S714.3,537,714.3,413.4s164.1-280.9,164.1-280.9 S1042.6,289.8,1042.6,413.4z"
                />

                <polygon
                    className="base"
                    points="872.5,561.2 874.2,457.8 944.1,329.8 874.4,441.6 875.2,395 923.4,302.4 875.4,382.7 875.4,382.7 876.7,299.1 928.4,225.4 877,285.9 877.8,132.5 872.9,327.2 820.4,246.9 871.5,342.2 867.5,434.9 806.5,349.7 866.8,443 853.8,692.9 870.3,692.9 872.3,570.4 932.5,477.4"
                />
            </g>

            <g className="tree tree3">
                <linearGradient
                    id="404AnimationLinearGradient1"
                    gradientUnits="userSpaceOnUse"
                    x1="377.9319"
                    y1="503.2235"
                    x2="377.9319"
                    y2="681.0939"
                >
                    <stop offset="0" style={{ stopColor: gradientBase }} />
                    <stop offset="1" style={{ stopColor: gradientShade }} />
                </linearGradient>

                <path
                    style={{ fill: 'url(#404AnimationLinearGradient1)' }}
                    d="M443.2,614.8c0,49.1-29.2,66.3-65.2,66.3c-0.8,0-5.8-0.1-7.5-0.3c-32.5-2.3-57.8-20.3-57.8-66 c0-47.3,60.4-106.9,64.9-111.3l0,0c0.2-0.2,0.3-0.3,0.3-0.3S443.2,565.7,443.2,614.8z"
                />

                <polygon
                    className="base"
                    points="376,662 376.6,623.9 402.3,576.9 376.7,618 377,600.9 394.7,566.8 377,596.3 377,596.3 377.5,565.6 396.5,538.5 377.6,560.7 377.9,504.3 376.1,575.9 356.8,546.4 375.8,581.8 374.2,615.6 351.7,584.2 373.9,618.5 369.1,684.9 375.2,684.9 375.9,665.4 398,631.1"
                />
            </g>

            <path
                className="main"
                d="M653.1,614.5c0,0,0.2-4.3,0.2-6.3c0-39.3-22.7-70.8-62.3-70.8c-36.9,0-64,31.1-64,69.7 c0,41.7,30,69.7,66.6,69.7c25.3,0,41.7-10.1,55.1-25.1l-12.3-11.2c-10.8,11.4-23.7,18.8-42,18.8c-23.5,0-44.3-16.2-47.1-45.2 C547.2,614.5,586.4,651.8,653.1,614.5z M564.3,595.6c-3.4-3.6-6.8-6.8-9.5-9.5c7.7-12.3,21.6-20.7,37.2-20.7 c16.8,0,31.5,9.5,38.8,23.5l-11.3,6.5c-5.2-10.2-15.6-17.1-27.7-17.1C579.8,578.5,569.3,585.4,564.3,595.6z"
            />

            <g className="tree tree4">
                <linearGradient
                    id="404AnimationLinearGradient2"
                    gradientUnits="userSpaceOnUse"
                    x1="669.384"
                    y1="504.5755"
                    x2="669.384"
                    y2="704.0222"
                >
                    <stop offset="0" style={{ stopColor: gradientBase }} />
                    <stop offset="1" style={{ stopColor: gradientShade }} />
                </linearGradient>

                <path
                    style={{ fill: 'url(#404AnimationLinearGradient2)' }}
                    d="M742.5,629.7c0,55.1-32.7,74.3-73.1,74.3c-0.9,0-6.5-0.2-8.4-0.3c-36.5-2.6-64.8-22.8-64.8-74 c0-53,67.7-119.9,72.8-124.8l0,0c0.2-0.2,0.3-0.3,0.3-0.3S742.5,574.6,742.5,629.7z"
                />

                <polygon
                    className="base"
                    points="666.8,684.7 667.5,642.6 695.9,590.7 667.6,636.1 667.9,617.2 687.5,579.6 668,612.2 668,612.2 668.6,578.2 689.5,548.3 668.6,572.8 669,510.5 667,589.6 645.7,557 666.7,596.1 664.9,633.4 640,598.7 664.5,636.6 659.2,710 666,710 666.8,688.4 691.2,650.6"
                />
            </g>
        </svg>
    );
};

Illu.propTypes = {
    gradientBase: PropTypes.string,
    gradientShade: PropTypes.string,
};

export default Illu;
