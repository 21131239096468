import React from 'react';
import ErrorBoundaryCard from './ErrorBoundaryCard';
import { withTranslation } from 'react-i18next';

/**
 * @description this component catches errors that happen during rendering and prevents errors in its children from crashing all the app!
 * @see https://fr.reactjs.org/docs/error-boundaries.html
 */
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        // we display details in console
        console.log('this is the error', error + info);
    }

    render() {
        const { t, ...rest } = this.props;

        if (this.state.hasError) {
            // we choose an alternative UI to display
            return (
                <ErrorBoundaryCard {...rest} message={t('errorAPI.title')} />
            );
        }
        return this.props.children;
    }
}

export default withTranslation('common')(ErrorBoundary);
