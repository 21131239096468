import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    DatePicker,
    DaSelect,
    InternalGrid,
    Text,
    ScheduleIcon,
} from '@paygreen/paygreen-ui';
import { useCharityList, useUser } from '../../context';
import dayjs from '../../utils/dayjsHelper';
import { startOfMonth, endOfMonth } from '../../utils/constants';
import { IsMobile } from '../../utils/tools';

const DashboardFilter = ({
    t,
    setEndDate,
    setStartDate,
    endDate,
    startDate,
    partnershipsData,
    setSelectorId,
}) => {
    const { charityListData } = useCharityList();
    const { user } = useUser();

    // we apply as the minimum startDate the first day of the month of subscription of the user
    const minimumStartDate =
        user.createdAt &&
        dayjs(new Date(user.createdAt * 1000)).format('01/MM/YYYY');

    // we create selector for all partnerships availables for user as SHOP or ASSOCIATION
    let partnershipOptions = [
        { value: '', text: t('dashboard.partnershipsSelect') },
    ];

    if (user && user.role === 'ASSOCIATION') {
        partnershipsData &&
            partnershipsData.forEach((option) => {
                partnershipOptions.push({
                    value: option.idUser,
                    text: option.idAccount,
                });
            });
    } else {
        charityListData &&
            charityListData.forEach((option) => {
                if (
                    partnershipsData &&
                    partnershipsData.includes(option.idAssociation)
                ) {
                    partnershipOptions.push({
                        value: option.idAssociation,
                        text: option.name,
                    });
                }
            });
    }

    const getPartnerName = (id) => {
        const tempData = partnershipOptions.filter((item) => item.value === id);
        return tempData[0].text;
    };

    return (
        <InternalGrid
            displayType="grid"
            gridTemplateColumns={IsMobile() ? 'auto' : 'auto auto auto'}
            gridGap="lg"
            alignItems="center"
            justifyItems="start"
            childrenMarginTop="sm"
            hasStaticWidth={false}
        >
            <InternalGrid
                displayType="grid"
                gridTemplateColumns="auto auto"
                gridGap="md"
                alignItems="center"
            >
                <Text textSize="xs">{t('datePicker.from')}</Text>

                <DatePicker
                    locale={t('datePicker.locale')}
                    id="startDate"
                    minimumDate={minimumStartDate}
                    maximumDate={endDate || endOfMonth}
                    fieldSize="sm"
                    blockWidth="sm"
                    resetDate={endDate}
                    value={startDate || startOfMonth}
                    onChange={(date) => setStartDate(date)}
                    icon={<ScheduleIcon />}
                />
            </InternalGrid>

            <InternalGrid
                displayType="grid"
                gridTemplateColumns="auto auto"
                gridGap="md"
                alignItems="center"
            >
                <Text textSize="xs">{t('datePicker.to')}</Text>

                <DatePicker
                    locale={t('datePicker.locale')}
                    id="endDate"
                    minimumDate={startDate || startOfMonth}
                    maximumDate={endOfMonth}
                    fieldSize="sm"
                    blockWidth="sm"
                    resetDate={startDate}
                    value={endDate || endOfMonth}
                    onChange={(date) => setEndDate(date)}
                    icon={<ScheduleIcon />}
                />
            </InternalGrid>

            <DaSelect
                id="partnerSelector"
                blockWidth="md"
                fieldSize="sm"
                options={partnershipOptions && partnershipOptions}
                onChange={(e) =>
                    setSelectorId({
                        id: e.target.value,
                        name: getPartnerName(e.target.value),
                    })
                }
            />
        </InternalGrid>
    );
};

DashboardFilter.propTypes = {
    setEndDate: PropTypes.func.isRequired,
    setStartDate: PropTypes.func.isRequired,
    endDate: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    /**
     * data received from API to build selector options values
     */
    partnershipsData: PropTypes.array,
    setSelectorId: PropTypes.func.isRequired,
};

export default withTranslation('common')(DashboardFilter);
