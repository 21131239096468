import styled from 'styled-components';

// to improve image position and colors in banner
export const BannerWrapper = styled.div`
    width: 100%;
    position: relative;
    z-index: 1;

    & > :first-child {
        padding-bottom: ${(props) => props.theme.space.lg};
    }

    & :before,
    & :after {
        border-top-left-radius: ${(props) => props.theme.radius.lg};
        border-top-right-radius: ${(props) => props.theme.radius.lg};
    }

    & :before {
        opacity: 1;
        background-position: 0 60%;
        filter: contrast(140%);
    }

    & :after {
        opacity: 0.7;
        background-image: linear-gradient(
            to bottom right,
            ${(props) => props.theme.color[props.colorTheme].gradientBase},
            ${(props) => props.theme.color[props.colorTheme].gradientShade}
        );
    }
`;
export default BannerWrapper;
