import React from 'react';
import PropTypes from 'prop-types';

/**
 * @description - this component is needed to provide specific function to its children to do specific actions and based on setOpen function received from ModalControl => will be integrated to PG-UI
 */
const ModalControlClose = ({ action, children, setOpen }) => {
    const finalAction = () => {
        if (action) {
            action();
        }

        setOpen(false);
    };

    return (
        children &&
        React.cloneElement(children, {
            onClick: () => finalAction(),
        })
    );
};

ModalControlClose.propTypes = {
    action: PropTypes.func.isRequired,
    setOpen: PropTypes.func,
};

export default ModalControlClose;
