import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { ApiResponse, Country } from 'api-green-node';
import {
    ButtonGroup,
    InternalGrid,
    ModalControl,
    ModalProvider,
} from '@paygreen/paygreen-ui';
import Can from '../../../utils/rules/Can';
import {
    ActionBadge,
    CardClassic,
    ErrorBoundary,
    H1Title,
    CancelButton,
    ValidateButton,
    EditButton,
} from '../../../components';
import { IsDesktop, IsMobile } from '../../../utils/tools';
import { useAuth, useUser, useAPIError } from '../../../context';
import { countryOptions } from '../shared';
import UserProfileForm from './UserProfileForm';
import UserLoginCredentials from './UserLoginCredentials';
import UserCharityInfo from './UserCharityInfo';
import PasswordEditModal from './PasswordEditModal';

const UserProfile = (props) => {
    const { t } = props;
    const { getSdk } = useAuth();
    const { addError } = useAPIError();
    const { user, setUser } = useUser();

    // we create a ref to detect if component is mounted
    const isMounted = useRef(false);

    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);

    // initializing all react-hook-form values and methods
    const defaultValues = {
        accountId: userData?.accountId,
        username: userData?.username,
        lastname: userData?.lastname,
        firstname: userData?.firstname,
        email: userData?.email,
        phone: userData?.phone?.slice(4),
        country: countryOptions(t)[Country[userData?.country]]?.value,
        role: userData?.role,
    };

    const methods = useForm({
        defaultValues: defaultValues,
    });

    const { register, handleSubmit, clearErrors, reset } = methods;

    // Watch for changes on inputs
    useEffect(() => {
        register({ name: 'lastname' });
        register({ name: 'firstname' });
        register({ name: 'phone' });
        register({ name: 'email' });
        register({ name: 'country' });
    }, [register]);

    useEffect(() => {
        // update default values for selector and controlled input in form when data is retrieved
        reset({
            phone: userData?.phone?.slice(4),
            country: userData.country,
        });
    }, [userData, reset]);

    /**
     * GET request to API to get user's informations
     * @returns {object} - object data about user
     */
    const getUser = useCallback(() => {
        getSdk()
            .user.getOne('me')
            .then((res) => {
                if (ApiResponse.isSuccessful(res)) {
                    isMounted.current && setUserData(res.dataInfo);
                } else {
                    isMounted.current &&
                        addError(
                            ApiResponse.getErrorMessage(res),
                            ApiResponse.getStatus(res),
                        );
                }
            })
            .finally(() => isMounted.current && setIsLoading(false));
    }, [getSdk, addError]);

    useEffect(() => {
        isMounted.current = true;

        getUser();

        return () => {
            isMounted.current = false;
        };
    }, [getUser]);

    /**
     * PATCH request to API to update user's data
     * @returns {object} - object with user's data updated
     */
    const updateUser = useCallback(
        (updatedUser) => {
            setIsLoading(true);

            getSdk()
                .user.update(updatedUser, 'me')
                .then((res) => {
                    if (ApiResponse.isSuccessful(res)) {
                        setUser({
                            ...user,
                            firstname: updatedUser.firstname,
                            lastname: updatedUser.lastname,
                        });

                        setUserData({
                            username: res.dataInfo.username,
                            accountId: res.dataInfo.accountId,
                            role: res.dataInfo.role,
                            email: updatedUser.email,
                            firstname: updatedUser.firstname,
                            lastname: updatedUser.lastname,
                            phone: updatedUser.phone,
                            country: updatedUser.country,
                        });

                        setIsEditing(false);

                        toast(t('form.toastSuccess.userUpdate'));
                    } else {
                        addError(
                            ApiResponse.getErrorMessage(res),
                            ApiResponse.getStatus(res),
                        );
                    }
                })
                .finally(() => setIsLoading(false));
        },
        [addError, getSdk, setUser, user, setUserData, setIsLoading, t],
    );

    const submitForm = (data) => {
        const updatedUser = {
            lastname: data.lastname,
            firstname: data.firstname,
            phone: '0033' + data.phone.replace(/\(0\)|[\s]|\+33 /g, ''),
            email: data.email,
            country: data.country,
        };

        updateUser(updatedUser);
    };

    const resetForm = () => {
        reset();
        clearErrors();
        setIsEditing(false);
    };

    return (
        <>
            <Helmet>
                <title>
                    {t('user.see.metaTitle')} - {t('main.metaTitle')}
                </title>
            </Helmet>

            <H1Title title={t('user.see.title')} />

            <InternalGrid
                displayType="grid"
                gridGap="lg"
                gridTemplateColumns={IsDesktop() ? '1fr 1fr' : '1fr'}
                justifyItems="stretch"
                hasStaticWidth={false}
                childrenMarginTop="md"
            >
                <ErrorBoundary>
                    <CardClassic
                        title={t('user.see.information')}
                        titleSize="md"
                        isShadowWab
                        blockWidth="md"
                    >
                        <FormProvider {...methods}>
                            <UserProfileForm
                                onSubmit={handleSubmit(submitForm)}
                                defaultValues={defaultValues}
                                isLoading={isLoading}
                                setIsEditing={setIsEditing}
                            >
                                {isEditing && (
                                    <ButtonGroup
                                        marginTop="sm"
                                        marginBottom="sm"
                                    >
                                        <CancelButton
                                            buttonSize="md"
                                            onClick={() => resetForm()}
                                            dataCy="user-cancel"
                                        >
                                            {t('form.button.cancel')}
                                        </CancelButton>

                                        <ValidateButton
                                            buttonSize="md"
                                            isDisabled={isLoading}
                                            type="submit"
                                            dataCy="user-validate"
                                        >
                                            {t('resetPassword.validateButton')}
                                        </ValidateButton>
                                    </ButtonGroup>
                                )}
                            </UserProfileForm>
                        </FormProvider>

                        {!isEditing && (
                            <ButtonGroup marginTop="sm" marginBottom="sm">
                                <ActionBadge
                                    text={t('user.edit.badgetext')}
                                    data-cy="action-badge-user"
                                />
                            </ButtonGroup>
                        )}
                    </CardClassic>
                </ErrorBoundary>

                <InternalGrid
                    displayType="grid"
                    gridGap="lg"
                    gridTemplateColumns="1fr"
                    hasStaticWidth={false}
                    alignItems="stretch"
                    justifyItems="stretch"
                    childrenMarginBottom={!IsDesktop() ? 'md' : 'none'}
                >
                    <ErrorBoundary>
                        <CardClassic
                            title={t('user.editPassword.editPasswordCardTitle')}
                            isShadowWab={true}
                            titleSize="md"
                            blockWidth="md"
                        >
                            <ModalProvider>
                                <ButtonGroup
                                    marginTop={IsMobile() ? 'sm' : 'none'}
                                >
                                    <ModalControl hasOnClickControl={true}>
                                        <EditButton buttonStyle="line">
                                            {t(
                                                'user.editPassword.modifyPasswordButton',
                                            )}
                                        </EditButton>
                                    </ModalControl>
                                </ButtonGroup>

                                <ModalControl>
                                    <PasswordEditModal
                                        setIsLoading={setIsLoading}
                                    />
                                </ModalControl>
                            </ModalProvider>
                        </CardClassic>
                    </ErrorBoundary>

                    <ErrorBoundary>
                        <UserLoginCredentials
                            isLoading={isLoading}
                            defaultValues={defaultValues}
                        />
                    </ErrorBoundary>
                </InternalGrid>

                <Can
                    condition={user?.role}
                    perform="editForm:charity"
                    yes={() => <UserCharityInfo />}
                />
            </InternalGrid>
        </>
    );
};

export default withTranslation('common', 'countries')(UserProfile);
