import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Tools } from 'api-green-node';
import {
    Box,
    HistogramBar,
    Histogram,
    SkeletonItem,
    Text,
    ThemeDefault,
} from '@paygreen/paygreen-ui';
import { CollapsibleCard } from '../../components';
import {
    IsDesktop,
    IsMobile,
    getNumberFromThemeValue,
} from '../../utils/tools';
import { useNodeDimensions } from '../../utils/hooks';

const DashboardCardHistogram = ({
    t,
    histogramData,
    isLoading,
    endDateValue,
    startDateValue,
    partner,
}) => {
    const [filteredData, setFilteredData] = useState();
    const [data, setData] = useState();

    // first when we want to display daily donations for all partners, we need to group by day, donations collected on the same day but sent by API separately on daily mode
    useEffect(() => {
        let filteredData = [];
        let tempDailyData = {
            date: '',
            total: 0,
        };

        histogramData &&
            histogramData.forEach((item, index, array) => {
                if (tempDailyData.date === '') {
                    tempDailyData.date = item.date;
                }

                tempDailyData.total =
                    Number(tempDailyData.total) + Number(item.total);

                // if the following date is different we save to filteredData array else we keep adding values together
                if (array[index + 1]?.date !== item.date) {
                    filteredData.push({
                        date: tempDailyData.date,
                        total: tempDailyData.total,
                    });

                    tempDailyData.date = '';
                    tempDailyData.total = 0;
                }
            });

        setFilteredData(filteredData);
    }, [histogramData]);

    // we base histogram YaxisValues on received values
    const maxValueReceived =
        filteredData && filteredData.length
            ? Math.max(...filteredData.map((value) => value.total), 0)
            : 0;

    const YMaxValue = maxValueReceived
        ? Number(Tools.eurosCentstoEuros(maxValueReceived))
        : 100;

    useEffect(() => {
        /**
         * we build data array and complete missing days from API with values at 0
         * @param {integer} values
         */
        const fillMissedDays = (values) => {
            let datesArray = [];
            const endDate = moment(endDateValue, 'DD/MM/YYYY');

            const startDate = moment(startDateValue, 'DD/MM/YYYY');

            while (startDate <= endDate) {
                datesArray.push({
                    value: 0,
                    label: moment(startDate).format('DD/MM/YYYY'),
                });

                if (values && values.length > 0) {
                    values.forEach((value) => {
                        if (
                            moment(value.date).format('DD/MM/YYYY') ===
                            moment(startDate).format('DD/MM/YYYY')
                        ) {
                            const valueIndex = datesArray.findIndex(
                                (object) =>
                                    object.label ===
                                    moment(startDate).format('DD/MM/YYYY'),
                            );
                            datesArray[valueIndex] = {
                                ...datesArray[valueIndex],
                                value: Number(
                                    Tools.eurosCentstoEuros(value.total),
                                ),
                            };
                        }
                    });
                }

                startDate.add(1, 'days');
            }
            return datesArray;
        };

        setData(fillMissedDays(filteredData));
    }, [histogramData, endDateValue, startDateValue, filteredData]);

    // we create a ref using a special hook to get width from CollapsibleCard component
    const [ref, { width }] = useNodeDimensions();

    const responsiveHistogramBar = {
        blockWidth: IsMobile() ? 'xs' : data && data.length < 15 ? 'md' : 'sm',
        paddingLateral:
            width > getNumberFromThemeValue(ThemeDefault.blockWidth.lg) ||
            (data && data.length < 15)
                ? 'sm'
                : 'xs',
    };

    return (
        <Box marginBottom={IsDesktop() ? 'lg' : 'sm'}>
            <CollapsibleCard
                title={t('dashboard.donationHistogramCard.title')}
                blockWidth="none"
                paddingLateral="sm"
                paddingTop="sm"
                paddingBottom="sm"
            >
                <Text textSize="xs" colorWab="grey30">
                    {data &&
                        data.length > 0 &&
                        t('dashboard.donationHistogramCard.subtitle', {
                            startDate: moment(
                                data[0].label,
                                'DD/MM/YYYY',
                            ).format('DD/MM/YYYY'),
                            endDate: moment(
                                data[data.length - 1].label,
                                'DD/MM/YYYY',
                            ).format('DD/MM/YYYY'),
                            partner: partner?.id?.length
                                ? partner.name
                                : t(
                                      'dashboard.donationHistogramCard.allPartners',
                                  ),
                        })}
                </Text>

                <div ref={ref} className="reference">
                    {!isLoading && data?.length && YMaxValue !== undefined ? (
                        <Histogram
                            isRelativeYaxis={true}
                            yaxisUnit="€"
                            yaxisValues={[25, 50, 75]}
                            blockHeight="sm"
                            marginTop="md"
                            maxValue={YMaxValue === 0 ? 50 : YMaxValue}
                            blockWidth="lg"
                        >
                            {data.map((sample, index) => (
                                <HistogramBar
                                    key={index}
                                    value={Number(sample.value)}
                                    legend={`${sample.value}€ ${t(
                                        'dashboard.donationHistogramCard.label',
                                    )} ${sample.label}`}
                                    isLabelVisible={false}
                                    colorTheme="quaternary"
                                    {...responsiveHistogramBar}
                                />
                            ))}
                        </Histogram>
                    ) : (
                        <SkeletonItem
                            blockWidth="xl"
                            blockHeight="sm"
                            colorWab="grey10"
                            radiusSize="sm"
                            marginTop="md"
                        />
                    )}
                </div>
            </CollapsibleCard>
        </Box>
    );
};

DashboardCardHistogram.propTypes = {
    histogramData: PropTypes.array,
    isLoading: PropTypes.bool,
    endDateValue: PropTypes.string.isRequired,
    startDateValue: PropTypes.string.isRequired,
    partner: PropTypes.object,
};

DashboardCardHistogram.defaultProps = {
    isLoading: false,
    partner: {},
};

export default withTranslation('common')(DashboardCardHistogram);
