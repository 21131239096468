import React from 'react';
import { withTranslation } from 'react-i18next';
import { useAPIError } from '../../../context/useApiError';
import { ModalBlock } from '../..';
import APIErrorCard from './APIErrorCard';

const APIError = (props) => {
    const { t } = props;
    const { error, removeError } = useAPIError();

    return (
        <>
            <ModalBlock
                openingCondition={!!error}
                modalTitle={t('errorAPI.title')}
                closeWithAction={removeError}
                blockWidth="md"
                hasCloseCross={false}
            >
                <APIErrorCard
                    message={error && error.message}
                    status={error && error.status}
                />
            </ModalBlock>
        </>
    );
};

export default withTranslation('common')(APIError);
