import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { InternalGrid, Text, Link } from '@paygreen/paygreen-ui';
import { Message } from '../../components';

const ResetPasswordErrorMessage = ({ t, type, errorMessage }) => (
    <InternalGrid
        justifyContent="center"
        alignItems="center"
        childrenMarginBottom="md"
        childrenMarginTop="md"
        childrenMarginLateral="md"
    >
        {/*not using Trans component here because it doesn't work with node element like <a>*/}
        <Text textSize="sm" align="center" htmlTag="p">
            {type === 'wrongToken'
                ? t('resetPassword.wrongTokenText')
                : t('resetPassword.wrongPasswordText')}

            <Text textSize="sm" htmlTag="strong">
                <a href="mailto:serviceclient@paygreen.fr">
                    <Link colorPallet="wab" colorWab="grey60">
                        {t('validation.supportTeam')}
                    </Link>
                </a>
            </Text>
        </Text>

        {errorMessage && (
            <Message message={errorMessage} type="error" paddingLateral="lg" />
        )}
    </InternalGrid>
);

ResetPasswordErrorMessage.propTypes = {
    type: PropTypes.oneOf(['wrongToken', 'wrongPassword']),
    errorMessage: PropTypes.string,
};

ResetPasswordErrorMessage.defaultProps = {
    type: 'wrongToken',
};

export default withTranslation('common')(ResetPasswordErrorMessage);
