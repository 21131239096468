import styled from 'styled-components';

const CustomLogoSize = styled.div`
    .logo {
        height: ${(props) => props.blockHeight};
        width: ${(props) => props.blockWidth};
    }
`;

export default CustomLogoSize;
