import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card, Text } from '@paygreen/paygreen-ui';

const NewPartnershipGroupButton = (props) => {
    const { t, onClick } = props;

    return (
        <Card
            onClick={onClick}
            hasBackground={true}
            blockWidth="md"
            colorWab="white20"
            colorTheme="quaternary"
            paddingLateral="sm"
            paddingTop="sm"
            paddingBottom="sm"
            shadowSize="none"
            radiusSize="sm"
            htmlTag="button"
            hasDashedBorder={true}
        >
            <Text colorWab="grey30">
                <i>{t('newPartnershipGroupButton.title')}</i>
            </Text>

            <Text colorWab="grey30" textSize="sm" marginTop="xs">
                {t('newPartnershipGroupButton.text')}
            </Text>
        </Card>
    );
};

export default withTranslation('common')(NewPartnershipGroupButton);
