import regexForm from '../../utils/regexForm';

const subscriptionInputsPatterns = [
    {
        name: 'organisationName',
        pattern: regexForm.organisationName,
        type: 'text',
        required: true,
    },
    {
        name: 'lastname',
        pattern: regexForm.lastname,
        type: 'text',
        required: true,
    },
    {
        name: 'firstname',
        pattern: regexForm.firstname,
        type: 'text',
        required: true,
    },
    {
        name: 'email',
        pattern: regexForm.email,
        type: 'email',
        required: true,
    },
    {
        name: 'phone',
        pattern: regexForm.phone,
        type: 'tel',
        required: true,
    },
    {
        name: 'password',
        pattern: regexForm.password,
        type: 'password',
        required: true,
    },
    {
        name: 'passwordVerify',
        pattern: regexForm.password,
        type: 'password',
        required: true,
    },
    {
        name: 'country',
        pattern: regexForm.country,
        type: 'text',
        required: true,
    },
    {
        name: 'organisationWebsite',
        pattern: regexForm.url,
        type: 'url',
        required: false,
    },
];

export { subscriptionInputsPatterns };
