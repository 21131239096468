import { useEffect, useState } from 'react';
import { apiAuth } from '../../../utils/hooks';
import { useAPIError } from '../../../context';

/**
 * Call the api to get all Team Members
 * @returns TeamMembers returning 4 array, all, by sales, by Ams and by Csm
 */
const useTeamMembers = () => {
    const [teamMembers, setTeamMembers] = useState([]);
    const [teamAMs, setTeamAMs] = useState([]);
    const [teamCSMs, setTeamCSMs] = useState([]);
    const [teamSales, setTeamSales] = useState([]);
    const { addError } = useAPIError();

    useEffect(() => {
        apiAuth()
            .get('/teams')
            .then((res) => {
                setTeamMembers(res.data._embedded?.teams);
            })
            .catch((error) => {
                if (error?.response?.data.status === 404) {
                    setTeamMembers([]);
                } else {
                    addError(
                        error?.response?.data.detail,
                        error?.response?.data.status,
                    );
                }
            });
    }, [addError]);

    useEffect(() => {
        setTeamSales(teamMembers.filter((tm) => tm.type === 'SALES'));
        setTeamAMs(teamMembers.filter((tm) => tm.type === 'ACCOUNT_MANAGER'));
        setTeamCSMs(
            teamMembers.filter((tm) => tm.type === 'CUSTOMER_SUCCESS_MANAGER'),
        );
    }, [teamMembers]);

    return { teamMembers, teamAMs, teamSales, teamCSMs };
};

export default useTeamMembers;
