import React, { useEffect, useCallback, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Country } from 'api-green-node';
import { useForm } from 'react-hook-form';
import { ButtonGroup } from '@paygreen/paygreen-ui';
import { useAPIError } from '../../../context';
import { ModalForm, ValidateButton, CancelButton } from '../../../components';
import { apiAuth } from '../../../utils/hooks';
import { countryOptions } from '../shared';
import UserCompanyBillingAddressForm from './UserCompanyBillingAddressForm';

const UserCompanyBillingAddressModal = ({
    t,
    setOpen,
    isOpen,
    data,
    setBillingAddressData,
    setIsLoading,
    isLoading,
    account,
    setHasAdressFilled,
}) => {
    const { addError } = useAPIError();
    const [isNew, setIsNew] = useState();

    useEffect(() => {
        setIsNew(data ? false : true);
    }, [data]);

    // initializing all react-hook-form values and methods
    const defaultValues = {
        addressLine1: data?.addressLine1,
        addressLine2: data?.addressLine2,
        city: data?.city,
        region: data?.region,
        zipCode: data?.zipCode,
        country: countryOptions(t)[Country[data?.country]]?.value || 'FR',
    };

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        errors,
        clearErrors,
        control,
        getValues,
    } = useForm();

    // Watch for changes on inputs
    useEffect(() => {
        register({ name: 'addressLine1' });
        register({ name: 'addressLine2' });
        register({ name: 'city' });
        register({ name: 'zipCode' });
        register({ name: 'region' });
        register({ name: 'country' });
    }, [register]);

    /**
     * POST or PUT request to API to update billing address data
     * @returns {object} - object with billing address data updated
     */
    const updateBillingAddress = useCallback(
        (account, isNew, updatedBillingAddress) => {
            setIsLoading(true);
            let request = null;

            if (isNew) {
                request = apiAuth().post(
                    `/account/${account}/postalAddress`,
                    updatedBillingAddress,
                );
            } else {
                request = apiAuth().put(
                    `/account/${account}/postalAddress/default`,
                    updatedBillingAddress,
                );
            }

            request
                .then((res) => {
                    setBillingAddressData({
                        addressLine1: res.data.addressLine1,
                        addressLine2: res.data.addressLine2,
                        city: res.data.city,
                        zipCode: res.data.zipCode,
                        region: res.data.region,
                        country: res.data.country,
                    });
                    setHasAdressFilled(true);

                    if (isNew) {
                        toast(t('form.toastSuccess.billingAdressCreated'));
                    } else {
                        toast(t('form.toastSuccess.billingAdressUpdate'));
                    }
                })
                .catch((error) =>
                    addError(
                        error?.response?.data.detail,
                        error?.response?.data.status,
                    ),
                )
                .finally(() => setIsLoading(false));
        },
        [addError, setBillingAddressData, setIsLoading, t, setHasAdressFilled],
    );

    const submitForm = (data) => {
        const updatedBillingAddress = {
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            city: data.city,
            zipCode: data.zipCode,
            region: data.region,
            country: data.country,
        };

        setBillingAddressData(updatedBillingAddress); // to simulate data submit until API is ready
        updateBillingAddress(account, isNew, updatedBillingAddress);

        setOpen(false);
    };

    const cancelCloseModal = () => {
        setOpen(false);

        // we need the reset methods to wait until modal is closed
        setTimeout(() => {
            clearErrors();
            reset(defaultValues);
        }, 500);
    };

    return (
        <ModalForm
            modalTitle={t('user.company.billingAddress')}
            blockWidth="md"
            paddingLateral="sm"
            hasCloseCross={true}
            isOpen={isOpen}
            actionOnClose={() => cancelCloseModal()}
        >
            <UserCompanyBillingAddressForm
                getValues={getValues}
                errors={errors}
                setValue={setValue}
                register={register}
                control={control}
                onSubmit={handleSubmit(submitForm)}
                defaultValues={defaultValues}
            >
                <ButtonGroup marginTop="sm" marginBottom="sm">
                    <CancelButton
                        buttonSize="md"
                        onClick={() => cancelCloseModal()}
                    >
                        {t('form.button.cancel')}
                    </CancelButton>

                    <ValidateButton
                        type="submit"
                        buttonSize="md"
                        isDisabled={isLoading}
                    >
                        {t('form.button.save')}
                    </ValidateButton>
                </ButtonGroup>
            </UserCompanyBillingAddressForm>
        </ModalForm>
    );
};

UserCompanyBillingAddressModal.propTypes = {
    /**
     * @description setOpen and isOpen are automatically received from ModalControl parent
     */
    setOpen: PropTypes.func,
    isOpen: PropTypes.bool,
    data: PropTypes.object,
    setIsLoading: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    setBillingAddressData: PropTypes.func.isRequired,
    account: PropTypes.string,
    setHasAdressFilled: PropTypes.func,
};

UserCompanyBillingAddressModal.defaultProps = {
    isOpen: false,
    isLoading: false,
};

export default withTranslation('common')(UserCompanyBillingAddressModal);
