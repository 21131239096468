import React from 'react';
import PropTypes from 'prop-types';
import { ArrowBottomIcon, Badge } from '@paygreen/paygreen-ui';
import { IsDesktop } from '../../utils/tools';

const ClimateKitBadge = ({ textSize, hasArrow, isShorten, ...rest }) => {
    return (
        <Badge textSize={textSize || (IsDesktop() ? 'tiny' : 'xxs')} {...rest}>
            {!isShorten ? 'climate' : 'cli'}

            {hasArrow ? <ArrowBottomIcon marginLeft="xs" /> : null}
        </Badge>
    );
};

ClimateKitBadge.propTypes = {
    hasArrow: PropTypes.bool,
    textSize: PropTypes.string,
    isShorten: PropTypes.bool,
};

ClimateKitBadge.defaultProps = {
    hasArrow: false,
    textSize: 'tiny',
    isShorten: false,
};

export default ClimateKitBadge;
