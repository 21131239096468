import { useState, useCallback, useEffect } from 'react';
import copy from 'copy-to-clipboard';

/**
 * React hook to copy content to clipboard based on chakra-ui hook https://github.com/chakra-ui/chakra-ui/blob/main/packages/hooks/src/use-clipboard.ts
 *
 * @param {string | number} value - the value to copy
 * @param {number} duration  - the delay (in ms) to switch back to initial state once copied, default is 3000 ms
 */
export const useClipboard = (value, duration = 3000) => {
    const [hasCopied, setHasCopied] = useState(false);

    const onCopy = useCallback(() => {
        copy(value);
        setHasCopied(true);
    }, [value]);

    useEffect(() => {
        let timeoutId = null;

        if (hasCopied) {
            timeoutId = window.setTimeout(() => {
                setHasCopied(false);
            }, duration);
        }

        return () => {
            if (timeoutId) {
                window.clearTimeout(timeoutId);
            }
        };
    }, [duration, hasCopied]);

    return { value, onCopy, hasCopied };
};
