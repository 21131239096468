import React from 'react';
import PropTypes from 'prop-types';
import { Card, Text } from '@paygreen/paygreen-ui';

/**
 * @description this component is used as introduction with H1Title and H2Title and has a specific maximum width
 */
const Introduction = ({ children, marginBottom,blockWidth, ...rest }) => {
    return (
        <Card blockWidth={blockWidth} hasBackground={false}>
            <Text
                colorWab="grey50"
                marginBottom={marginBottom}
                textSize="sm"
                {...rest}
            >
                {children}
            </Text>
        </Card>
    );
};

Introduction.propTypes = {
    children: PropTypes.node.isRequired,
    marginBottom: PropTypes.string,
    blockWidth: PropTypes.string,
};

Introduction.defaultProps = {
    children: PropTypes.node.isRequired,
    marginBottom: 'sm',
    blockWidth: 'xl',
};

export default Introduction;
