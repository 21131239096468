import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Text } from '@paygreen/paygreen-ui';
import { LineKeyValue, StatusDisplayTable } from '../../../../components';
import {
    getDataFromObject,
    timestampToDate,
    formatCentsMoney,
} from '../../../../utils/tools';
import { CardInfo, EditModalIcon } from '../../shared';
import {
    activatedSpacesStatusOptions,
    sepaStatusOptions,
    expirationDateStatusOptions,
} from '../../adminUsersUtils';
import { apiAuth } from '../../../../utils/hooks';
import dayjs from '../../../../utils/dayjsHelper';
import { useAPIError } from '../../../../context';
import { UnsubscribedWarning } from '../../shared';
import useSpacesPricing from '../../hooks/useSpacesPricing';
import SpaceManagementModal from './SpaceManagementModal';

const SpaceManagementCard = ({
    t,
    isLoading,
    setIsLoading,
    accountData,
    mode,
    inputList,
    refresh,
}) => {
    const billingName = mode + 'Billing';
    const spaceName = mode === 'charity' ? 'usesArrondi' : 'usesTree';
    const expirationDate = accountData?.[mode + 'ExpirationDate'];

    // we create a ref to detect if component is mounted
    const isMounted = useRef(false);

    const { accountId } = useParams();
    const { addError } = useAPIError();
    const { spacePrices } = useSpacesPricing(mode, isMounted);
    const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(true);
    const [subscriptionData, setsubscriptionData] = useState(null);

    const isEditable = accountData?.[spaceName] === '1';

    /**
     * GET request to API to get user's subscription informations
     * @returns {object} - object with price and mode
     */
    const getUserSubscription = useCallback(() => {
        setIsSubscriptionLoading(true);

        apiAuth()
            .get(`/account/${accountId}/billing/subscription-tariff`, {
                params: { service: mode.toUpperCase() },
            })
            .then((res) => {
                isMounted.current && setsubscriptionData(res.data.data);
            })
            .catch((error) => {
                if (
                    error?.response?.data.status === 422 ||
                    error?.response?.data.status === 404
                ) {
                    return [];
                } else {
                    addError(
                        error?.response?.data.detail,
                        error?.response?.data.status,
                    );
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setIsLoading(false);
                    setIsSubscriptionLoading(false);
                }
            });
    }, [addError, mode, accountId, setIsLoading]);

    useEffect(() => {
        isMounted.current = true;

        if (spaceName) {
            getUserSubscription();
        }

        return () => {
            isMounted.current = false;
        };
    }, [getUserSubscription, spaceName]);

    /**
     * @param {string} name of label
     * @returns all data properly displayed if this account has billing data linked to a specific space
     */
    const WithBillingData = ({ name }) => {
        switch (name) {
            case mode + 'KitSetupPrice':
                return (
                    <Text textSize="xs">
                        {formatCentsMoney(
                            subscriptionData?.['setup_price'] || 0,
                            true,
                        )}
                        €&nbsp;HT
                    </Text>
                );
            case mode + 'KitSubscriptionMode':
                return (
                    <Text textSize="xs">
                        {subscriptionData?.mode &&
                            t(
                                `admin.user.subscriptionMode.${subscriptionData.mode}`,
                            )}
                        &nbsp;
                        {subscriptionData?.price !== null ? (
                            <Text htmlTag="span" textSize="xs">
                                (
                                {formatCentsMoney(
                                    subscriptionData?.price,
                                    true,
                                )}
                                €&nbsp;HT)
                            </Text>
                        ) : null}
                    </Text>
                );
            default:
                return null;
        }
    };

    return (
        <CardInfo
            isLoading={isLoading || isSubscriptionLoading}
            title={mode.charAt(0).toUpperCase() + mode.slice(1) + 'kit'}
            actionIcon={
                <EditModalIcon
                    title={
                        isEditable
                            ? t('user.space.edit.title')
                            : t('user.space.edit.notEditableTitle')
                    }
                    htmlTag="button"
                    hasHover={isEditable}
                    style={{
                        filter: isEditable ? null : 'grayscale(1)',
                    }}
                >
                    {isEditable && (
                        <SpaceManagementModal
                            accountData={accountData}
                            subscriptionData={subscriptionData}
                            setIsLoading={setIsLoading}
                            mode={mode}
                            refreshAccount={refresh}
                            refreshSubscription={getUserSubscription}
                            spacePrices={spacePrices}
                        />
                    )}
                </EditModalIcon>
            }
        >
            {!accountData?.[billingName] ||
            accountData?.[spaceName] === '1' ||
            accountData?.[billingName]?.unsubscribedAt === '0' ? (
                inputList.map((item, index) => (
                    <LineKeyValue
                        key={index}
                        hasUppercase={false}
                        keyLabel={t(
                            `admin.user.${mode}KitStatus` +
                                '.' +
                                item.name +
                                'Input',
                        )}
                        keyValue={
                            (accountData &&
                                getDataFromObject(item.value, accountData)) ||
                            null
                        }
                        textSize="xs"
                    >
                        {item.name === mode + 'KitStatus' ? (
                            <StatusDisplayTable
                                status={
                                    accountData?.[spaceName] !== '0'
                                        ? 'ACTIVATED'
                                        : 'NOTACTIVATED'
                                }
                                statusOptions={activatedSpacesStatusOptions}
                                i18TextBase="admin.users.table.activatedSpacesFilter"
                            />
                        ) : null}

                        {item.name === mode + 'KitSEPAStatus' ? (
                            <StatusDisplayTable
                                status={accountData?.[billingName].status}
                                statusExtension={
                                    accountData?.[billingName].status ===
                                    'SIGNED'
                                        ? timestampToDate(
                                              accountData?.[billingName]
                                                  .subscribedAt,
                                          )
                                        : null
                                }
                                statusOptions={sepaStatusOptions}
                                i18TextBase="admin.users.table.sepaStatusFilter"
                            />
                        ) : null}

                        {item.name === mode + 'KitExpirationDateMode' ? (
                            <StatusDisplayTable
                                status={
                                    expirationDate === 0 ||
                                    expirationDate < dayjs().unix()
                                        ? 'EXPIRED'
                                        : 'NOTEXPIRED'
                                }
                                statusOptions={expirationDateStatusOptions}
                                customValue={dayjs
                                    .unix(
                                        accountData?.[mode + 'ExpirationDate'],
                                    )
                                    .format('DD/MM/YYYY')}
                            />
                        ) : null}

                        {isEditable ? (
                            <WithBillingData name={item.name} />
                        ) : null}
                    </LineKeyValue>
                ))
            ) : (
                <UnsubscribedWarning />
            )}
        </CardInfo>
    );
};

SpaceManagementCard.propTypes = {
    accountData: PropTypes.object,
    isLoading: PropTypes.bool,
    setIsLoading: PropTypes.func,
    mode: PropTypes.oneOf(['charity', 'climate']),
    inputList: PropTypes.array,
    refresh: PropTypes.func,
};

SpaceManagementCard.defaultProps = {
    isLoading: false,
};

export default withTranslation('common')(SpaceManagementCard);
