import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    DaLabel,
    Text,
    InternalGrid,
    SkeletonItem,
    Box,
} from '@paygreen/paygreen-ui';
import { useUser } from '../../../context';
import { billingDisplayModes } from '../../../utils/constants';
import { IsMobile } from '../../../utils/tools';
import dayjs from '../../../utils/dayjsHelper';
import { ActionBadge } from '../../../components';

const UserDisplayBillingData = ({ mode, data, isLoading, children, t }) => {
    const { user } = useUser();

    const limit = dayjs(user?.expirationDate);
    const now = dayjs().unix();
    const isExpiredSoon = user && limit.diff(now) < 5260000; // expiration is soon in less than 2 months (or 5260000 seconds)

    return (
        <InternalGrid
            displayType="grid"
            gridTemplateColumns="1fr auto"
            gridGap="md"
            alignItems="flex-end"
            justifyItems="start"
        >
            <div>
                <DaLabel>
                    {mode === billingDisplayModes.adress
                        ? t('user.company.billingAddress')
                        : t('user.company.SEPAMandate')}
                </DaLabel>

                <SkeletonItem isLoading={isLoading} colorWab="grey10">
                    {data?.addressLine1 || data?.iban ? (
                        <InternalGrid
                            displayType="grid"
                            gridTemplateColumns="1fr"
                            justifyItems="start"
                            gridGap="xs"
                            style={{ wordBreak: 'break-all' }}
                        >
                            {mode === billingDisplayModes.adress ? (
                                <>
                                    <Text textSize="xs">
                                        {data?.addressLine1}
                                    </Text>

                                    {data?.addressLine2 && (
                                        <Text textSize="xs">
                                            {data?.addressLine2}
                                        </Text>
                                    )}

                                    <Text textSize="xs">
                                        {data?.zipCode} {data?.city},
                                    </Text>

                                    <Text textSize="xs">
                                        {data?.region && data?.region + ' - '}

                                        {t(
                                            `countries:${data?.country}`,
                                        ).toUpperCase()}
                                    </Text>
                                </>
                            ) : (
                                <Text textSize="xs" marginTop="xs">
                                    **** **** **** **** **** **** {data?.iban}
                                </Text>
                            )}
                        </InternalGrid>
                    ) : (
                        <div>
                            <Box marginTop="xs" marginBottom="xs">
                                <ActionBadge
                                    text={
                                        mode === billingDisplayModes.adress
                                            ? t('user.company.noAddress')
                                            : t('user.company.noIban')
                                    }
                                    icon="EmptyIcon"
                                    colorStatus="danger"
                                />
                            </Box>

                            {mode !== billingDisplayModes.adress &&
                                isExpiredSoon && (
                                    <Text textSize={IsMobile() ? 'xs' : 'xxs'}>
                                        {t('user.company.signBefore')}{' '}
                                        {dayjs
                                            .unix(user?.expirationDate)
                                            .format('LL')}
                                    </Text>
                                )}
                        </div>
                    )}
                </SkeletonItem>
            </div>

            {children}
        </InternalGrid>
    );
};

UserDisplayBillingData.propTypes = {
    isLoading: PropTypes.bool,
    data: PropTypes.object,
    mode: PropTypes.oneOf(Object.values(billingDisplayModes)),
};

UserDisplayBillingData.defaultProps = {
    isLoading: false,
    mode: billingDisplayModes.adress,
};

export default withTranslation('common', 'countries')(UserDisplayBillingData);
