import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Text } from '@paygreen/paygreen-ui';
import { setBillingLocalStorage } from '../../../utils/authentication';
import { useUser, useAPIError } from '../../../context';
import { ModalForm, Loader } from '../../../components';
import { patchAccount } from '../service';
import UserSpaceForm from './UserSpaceForm';

const UserSpaceModal = ({ t, setOpen, isOpen }) => {
    const { user, setUser } = useUser();
    const { addError } = useAPIError();

    const [isLoading, setIsLoading] = useState(false);

    // initializing all react-hook-form values and methods
    const defaultValues = {
        cgu: '',
    };

    const { register, handleSubmit, reset, errors, clearErrors, setValue } =
        useForm({
            defaultValues: defaultValues,
        });

    useEffect(() => {
        register({ name: 'cgu' });
    }, [register]);

    /**
     * Patch request to API for new user's space activation
     */
    const activateClimate = useCallback(() => {
        setIsLoading(true);

        patchAccount(user?.accountId, { usesTree: '1' })
            .then(() => {
                // if success we update local storage and user state with space activated
                setUser({
                    ...user,
                    usesTree: '1',
                });

                setBillingLocalStorage({
                    usesTree: '1',
                });

                toast(
                    <Text colorPallet="theme" textSize="xs">
                        {t('form.toastSuccess.spaceActivation')}
                    </Text>,
                );
            })
            .catch((error) => {
                addError(
                    error?.response?.data.detail,
                    error?.response?.data.status,
                );
            })
            .finally(() => setIsLoading(false));
    }, [addError, setUser, t, user]);

    const submitForm = () => {
        activateClimate();
        setOpen(false);
    };

    const cancelCloseModal = () => {
        setOpen(false);

        // we need the reset methods to wait until modal is closed
        setTimeout(() => {
            clearErrors();
            reset();
        }, 500);
    };

    return (
        <ModalForm
            modalTitle={t('user.spaces.modal.title')}
            blockWidth="sm"
            paddingLateral="sm"
            paddingBottom="sm"
            hasCloseCross={true}
            isOpen={isOpen}
            actionOnClose={cancelCloseModal}
        >
            <Text marginBottom="sm" textSize="xs" colorWab="grey50">
                {t('user.spaces.modal.intro')}
            </Text>

            <UserSpaceForm
                isLoading={isLoading}
                onSubmit={handleSubmit(submitForm)}
                errors={errors}
                setValue={setValue}
                register={register}
            />

            {/* loader when updating and closing modal */}
            {isLoading && <Loader isLoading={isLoading} />}
        </ModalForm>
    );
};

UserSpaceModal.propTypes = {
    /**
     * @description setOpen and isOpen are automatically received from ModalControl parent
     */
    setOpen: PropTypes.func,
    isOpen: PropTypes.bool,
};

UserSpaceModal.defaultProps = {
    isOpen: false,
};

export default withTranslation('common')(UserSpaceModal);
