import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    DatePicker,
    DaLabel,
    InternalGrid,
    Text,
    ScheduleIcon,
} from '@paygreen/paygreen-ui';
import dayjs from '../../utils/dayjsHelper';
import { useUser } from '../../context';
import { endOfMonth } from '../../utils/constants';

const ExportDonationsForm = ({
    t,
    setEndDate,
    setStartDate,
    endDate,
    startDate,
    children,
}) => {
    const { user } = useUser();

    // we apply as the minimum startDate the first day of the month of subscription of the user
    const minimumStartDate =
        user.createdAt &&
        dayjs(new Date(user.createdAt * 1000)).format('01/MM/YYYY');
    const maximumEndDate = endOfMonth;

    return (
        <>
            <DaLabel>{t('shopDonations.exports.periodLabel')}</DaLabel>

            <InternalGrid
                displayType="grid"
                gridTemplateColumns="auto auto"
                gridGap="md"
                alignItems="center"
                justifyItems="start"
                childrenMarginTop="sm"
                hasStaticWidth={false}
            >
                <InternalGrid
                    displayType="grid"
                    gridTemplateColumns="auto auto "
                    gridGap="md"
                    alignItems="center"
                >
                    <Text textSize="xs">{t('datePicker.from')}</Text>

                    <DatePicker
                        locale={t('datePicker.locale')}
                        id="startExportDate"
                        minimumDate={minimumStartDate}
                        maximumDate={endDate}
                        fieldSize="md"
                        blockWidth="sm"
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                        icon={<ScheduleIcon />}
                    />
                </InternalGrid>

                <InternalGrid
                    displayType="grid"
                    gridTemplateColumns="auto auto"
                    gridGap="md"
                    alignItems="center"
                >
                    <Text textSize="xs">{t('datePicker.to')}</Text>

                    <DatePicker
                        locale={t('datePicker.locale')}
                        id="endExportDate"
                        minimumDate={startDate}
                        maximumDate={maximumEndDate}
                        fieldSize="md"
                        blockWidth="sm"
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                        icon={<ScheduleIcon />}
                    />
                </InternalGrid>
            </InternalGrid>

            {children}
        </>
    );
};

ExportDonationsForm.propTypes = {
    setEndDate: PropTypes.func.isRequired,
    setStartDate: PropTypes.func.isRequired,
    endDate: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default withTranslation('common')(ExportDonationsForm);
