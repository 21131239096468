import {
    partnershipGroupStatus,
    partnershipStatus,
    apiPartnershipStatus,
} from '../../../utils/constants';

/**
 * @description to get object with all infos about one charity entity among all charities data from API
 * @param {Array} data - list of charities from API
 * @param {string} id - id of charity entity
 */
const getCharityEntityInfo = (data, id) => {
    const tempData =
        data && data.filter((charity) => charity.idAssociation === id);
    return tempData && tempData[0];
};

/**
 * @description to get object with all details about the donations of one shop among all data from API
 * @param {Array} data - list of donations report details from API
 * @param {Array} id - id of the shop that made the donations
 */
const getDonationsReportDetail = (data, id) => {
    const tempData = data && data.filter((donation) => donation.idUser === id);
    return tempData && tempData[0];
};

/**
 * @description to filter partnerships by type among all partnerships data from API
 * @param {Array} data - list of partnerships from API
 * @param {string} type - ACTIVE, PENDING, REFUSED,or default will englobe CANCELLEDBYSHOP and CANCELLEDBYCHARITY
 */
const getFilteredPartnership = (data, type) => {
    let condition = null;

    switch (type) {
        case partnershipStatus.ACTIVE:
            condition = (partnership) =>
                partnership.accountStatus === apiPartnershipStatus.ACCEPT &&
                partnership.associationStatus === apiPartnershipStatus.ACCEPT;
            break;
        case partnershipStatus.PENDING:
            condition = (partnership) =>
                partnership.accountStatus === apiPartnershipStatus.ACCEPT &&
                partnership.associationStatus === apiPartnershipStatus.WAITING;
            break;
        case partnershipStatus.REFUSED:
            condition = (partnership) =>
                partnership.accountStatus === apiPartnershipStatus.ACCEPT &&
                partnership.associationStatus === apiPartnershipStatus.REFUSE;
            break;
        default:
            condition = (partnership) =>
                partnership.accountStatus === apiPartnershipStatus.DELETE ||
                partnership.associationStatus === apiPartnershipStatus.DELETE;
            break;
    }

    return data.filter(condition);
};

/**
 * @description to know if one partnership is used in none, some or all partnership groups
 * @param {Array} data - list of partnership groups from API
 * @param {Array} string - id of a partnership
 */
const getPartnershipGroupStatus = (data, idPartnership) => {
    const status =
        data &&
        data.filter((partnershipGroup) =>
            partnershipGroup?.partnershipIds
                .map((id) => id.trim()) // we clean partnerships ids list to prevent errors because of possible whitespace :(
                .includes(idPartnership),
        );

    switch (status && status.length) {
        case data && data.length:
            return partnershipGroupStatus.all;
        case 0:
            return partnershipGroupStatus.none;
        default:
            return partnershipGroupStatus.some;
    }
};

export {
    getFilteredPartnership,
    getCharityEntityInfo,
    getPartnershipGroupStatus,
    getDonationsReportDetail,
};
