import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, withTranslation } from 'react-i18next';
import { ButtonGroup, Text } from '@paygreen/paygreen-ui';
import { useAPIError, usePendingPartnerships } from '../../../context';
import {
    partnershipStatus,
    apiPartnershipStatus,
} from '../../../utils/constants';
import {
    Introduction,
    H1Title,
    ErrorBoundary,
    NoDataFoundCard,
    ModalBlock,
    DeleteButton,
    SimpleActionButton,
    ValidateButton,
} from '../../../components';
import { getFilteredPartnership } from '../shared/partnershipsTools';
import { getAllPartnerships, updatePartnershipAsCharity } from '../service';
import {
    PartnershipCard,
    PartnershipCardGrid,
    PartnershipCardSkeleton,
    PartnershipModalContent,
    PartnershipToastMessage,
} from '../shared';

const PendingCharityRequests = ({ t }) => {
    const { addError } = useAPIError();
    const { setPendingPartnershipsData } = usePendingPartnerships();

    // we create a ref to detect if component is mounted
    const isMounted = useRef(false);

    const [partnershipsDataLocal, setPartnershipsDataLocal] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const getData = useCallback(() => {
        getAllPartnerships()
            .then((res) => {
                isMounted.current &&
                    setPartnershipsDataLocal(
                        getFilteredPartnership(
                            res.data._embedded.partnership,
                            partnershipStatus.PENDING,
                        ),
                    );
                isMounted.current &&
                    setPendingPartnershipsData(
                        getFilteredPartnership(
                            res.data._embedded.partnership,
                            partnershipStatus.PENDING,
                        ),
                    );
            })
            .catch((error) => {
                addError(
                    error?.response?.data.detail,
                    error?.response?.data.status,
                );
            })
            .finally(() => {
                isMounted.current && setIsLoading(false);
            });
    }, [addError, setPendingPartnershipsData]);

    useEffect(() => {
        isMounted.current = true;

        getData();

        return () => {
            isMounted.current = false;
        };
    }, [getData]);

    const CardMainButton = ({ partnerId, partnerName }) => (
        <ModalBlock
            blockWidth="md"
            closeWithAction={() => getData()}
            modalTitle={t('partnershipButtons.refuseThisPartnership')}
            isTitleRed={true}
            button={
                <DeleteButton>
                    {t('partnershipButtons.refuseThisRequest')}
                </DeleteButton>
            }
        >
            <PartnershipModalContent
                closeWithAction={() => getData()}
                sdkPartnershipAction={() =>
                    updatePartnershipAsCharity(
                        partnerId,
                        apiPartnershipStatus.REFUSE,
                    )
                }
                buttonCancelAction={
                    <SimpleActionButton icon="ArrowRoundTopLeftIcon">
                        {t('partnershipButtons.cancel')}
                    </SimpleActionButton>
                }
                buttonConfirmAction={
                    <DeleteButton>
                        {t('partnershipButtons.refuseThisPartnership')}
                    </DeleteButton>
                }
                toastMessage={
                    <PartnershipToastMessage
                        i18nKey="form.toastSuccess.partnershipRefuse"
                        partnerName={partnerName}
                    />
                }
            >
                <Text textSize="sm">
                    <Trans i18nKey="CharityPartnerships.pendingRequests.refuseMessage">
                        Are you sure to refuse
                        <Text htmlTag="strong" textSize="sm">
                            {{ partnerName }}
                        </Text>
                        This manipulation is irreversible
                        <Text htmlTag="strong" textSize="sm">
                            {{ partnerName }}
                        </Text>
                        Partner won't be able to ask again
                    </Trans>
                </Text>
            </PartnershipModalContent>
        </ModalBlock>
    );

    const CardSecondaryButton = ({ partnerId, partnerName }) => (
        <ModalBlock
            blockWidth="md"
            closeWithAction={() => getData()}
            modalTitle={t('partnershipButtons.becomePartners')}
            button={
                <ValidateButton>
                    {t('partnershipButtons.becomePartners')}
                </ValidateButton>
            }
        >
            <PartnershipModalContent
                closeWithAction={() => getData()}
                sdkPartnershipAction={() =>
                    updatePartnershipAsCharity(
                        partnerId,
                        apiPartnershipStatus.ACCEPT,
                    )
                }
                buttonCancelAction={
                    <SimpleActionButton icon="ArrowRoundTopLeftIcon">
                        {t('partnershipButtons.notYet')}
                    </SimpleActionButton>
                }
                buttonConfirmAction={
                    <ValidateButton>
                        {t('partnershipButtons.becomePartners')}
                    </ValidateButton>
                }
                toastMessage={
                    <PartnershipToastMessage
                        i18nKey="form.toastSuccess.partnershipAccept"
                        partnerName={partnerName}
                    />
                }
            >
                <Text textSize="sm">
                    <Trans i18nKey="CharityPartnerships.pendingRequests.message">
                        Are you sure to validate
                        <Text htmlTag="strong" textSize="sm">
                            {{ partnerName }}
                        </Text>
                        This manipulation is irreversible
                    </Trans>
                </Text>
            </PartnershipModalContent>
        </ModalBlock>
    );

    return (
        <>
            <Helmet>
                <title>
                    {t('CharityPartnerships.pendingRequests.metaTitle')} -{' '}
                    {t('main.metaTitle')}
                </title>
            </Helmet>

            <H1Title title={t('CharityPartnerships.pendingRequests.title')} />

            <Introduction>
                {t('CharityPartnerships.pendingRequests.introduction')}
            </Introduction>

            {isLoading ? (
                <PartnershipCardSkeleton blockWidth="md" />
            ) : partnershipsDataLocal && partnershipsDataLocal.length > 0 ? (
                <PartnershipCardGrid>
                    {partnershipsDataLocal.map((partnership) => (
                        <ErrorBoundary
                            blockWidth="lg"
                            key={partnership.idPartnership}
                        >
                            <PartnershipCard
                                key={partnership.idPartnership}
                                data={partnership}
                                partnershipStatus={partnershipStatus.PENDING}
                            >
                                <ButtonGroup marginTop="sm" marginBottom="md">
                                    <CardMainButton
                                        partnerId={partnership.idPartnership}
                                        partnerName={partnership.idAccount}
                                    />

                                    <CardSecondaryButton
                                        partnerId={partnership.idPartnership}
                                        partnerName={partnership.idAccount}
                                    />
                                </ButtonGroup>
                            </PartnershipCard>
                        </ErrorBoundary>
                    ))}
                </PartnershipCardGrid>
            ) : (
                <NoDataFoundCard
                    message={t(
                        'CharityPartnerships.pendingRequests.noDataFound',
                    )}
                />
            )}
        </>
    );
};

export default withTranslation('common')(PendingCharityRequests);
