import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ButtonGroup } from '@paygreen/paygreen-ui';
import { useAPIError } from '../../../../context';
import { apiAuth } from '../../../../utils/hooks';
import { formatCentsMoney } from '../../../../utils/tools';
import dayjs from '../../../../utils/dayjsHelper';
import {
    CancelButton,
    ValidateButton,
    ModalForm,
} from '../../../../components';
import SpaceManagementForm from './SpaceManagementForm';
import SpaceManagementActivation from './SpaceManagementActivation';

const SpaceManagementModal = ({
    t,
    setOpen,
    isOpen,
    accountData,
    setIsLoading,
    mode,
    refreshAccount,
    refreshSubscription,
    spacePrices,
    subscriptionData,
}) => {
    const { addError } = useAPIError();
    const billingName = mode + 'Billing';
    const spaceName = mode === 'charity' ? 'usesArrondi' : 'usesTree';
    const initialActive = accountData?.[spaceName] !== '0';
    const [hasUnlogWarning, setHasUnlogWarning] = useState();

    const [isActive, setIsActive] = useState(initialActive);
    const [activeChanged, setActiveChanged] = useState(false);

    const updateActive = (activeState) => {
        setIsActive(activeState);
        setActiveChanged(true);
    };
    const expirationDate = accountData?.[mode + 'ExpirationDate'];
    const expirationDateMode =
        expirationDate === 0 || expirationDate < dayjs().unix()
            ? 'NOW'
            : expirationDate < 2147483647 // maximum timestamp available 19/01/2038
            ? 'CUSTOM'
            : 'NEVER';

    // initializing all react-hook-form values and methods
    const defaultValues = {
        [mode + 'KitSetupPrice']: formatCentsMoney(
            subscriptionData?.['setup_price'],
            true,
        ),
        [mode + 'KitSubscriptionPrice']: subscriptionData?.price,
        [mode + 'KitSubscriptionMode']: subscriptionData?.mode,
        [mode + 'KitExpirationDate']: accountData?.[mode + 'ExpirationDate'],
        [mode + 'KitExpirationDateMode']: expirationDateMode,
    };

    const {
        register,
        handleSubmit,
        setValue,
        errors,
        clearErrors,
        watch,
        reset,
        control,
    } = useForm();

    // Watch for changes on inputs
    useEffect(() => {
        register({ name: mode + 'KitSetupPrice' });
        register({ name: mode + 'KitSubscriptionPrice' });
        register({ name: mode + 'KitSubscriptionMode' });
        register({ name: mode + 'KitExpirationDate' });
        register({ name: mode + 'KitExpirationDateMode' });
    }, [register, mode]);

    useEffect(() => {
        reset({
            [mode + 'KitSetupPrice']: formatCentsMoney(
                subscriptionData?.['setup_price'],
                true,
            ),
            [mode + 'KitSubscriptionPrice']: subscriptionData?.price,
            [mode + 'KitSubscriptionMode']: subscriptionData?.mode,
            [mode + 'KitExpirationDate']:
                accountData?.[mode + 'ExpirationDate'],
        });
    }, [reset, subscriptionData, accountData, mode]);

    /**
     * PATCH request to API to update setup price's for each  space
     * @param {object} updatedPrice - object with price updated
     */
    const updatePrice = useCallback(
        (updatedPrice) => {
            setIsLoading(true);
            apiAuth()
                .patch(
                    `/account/${accountData.client_id}/billing/setup-price`,
                    updatedPrice,
                )
                .then(() => {
                    refreshSubscription();
                    toast(t('form.toastSuccess.setupPriceUpdate'));
                })
                .catch((error) =>
                    addError(
                        error?.response?.data.detail,
                        error?.response?.data.status,
                    ),
                )
                .finally(() => setIsLoading(false));
        },
        [addError, setIsLoading, accountData, t, refreshSubscription],
    );

    /**
     * PATCH request to API to update subscription's user choices for each space
     * @param {object} updatedSubscription - object with subscription updated
     */
    const updateSubscription = useCallback(
        (updatedSubscription) => {
            setIsLoading(true);

            apiAuth()
                .patch(
                    `/account/${accountData.client_id}/billing/subscription-tariff`,
                    updatedSubscription,
                )
                .then(() => {
                    refreshSubscription();
                    toast(t('form.toastSuccess.subscriptionUpdate'));
                })
                .catch((error) =>
                    addError(
                        error?.response?.data.detail,
                        error?.response?.data.status,
                    ),
                )
                .finally(() => setIsLoading(false));
        },
        [addError, accountData, t, refreshSubscription, setIsLoading],
    );

    /**
     * PATCH request to API to update expiration date for each space
     * @param {string} updatedExpirationDateMode - updated mode "NEVER" | "CUSTOM" | "NOW"
     * @param {string} updatedExpirationDate - updated expiration date format DD/MM/YYYY
     */
    const updateExpirationDate = (
        updatedExpirationDateMode,
        updatedExpirationDate,
    ) => {
        setIsLoading(true);

        // we check type of updatedExpirationDate, if user select date manually from datepicker it will be 'DD/MM/YYYY' format else if user keep default value received from API it will be timestamp format
        const finalCustomDate = dayjs(
            updatedExpirationDate,
            'DD/MM/YYYY',
        ).isValid()
            ? dayjs(updatedExpirationDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : dayjs.unix(updatedExpirationDate).format('YYYY-MM-DD');

        const expirationValues = {
            idAccount: accountData.client_id,
            expiresAt:
                updatedExpirationDateMode === 'CUSTOM'
                    ? finalCustomDate
                    : updatedExpirationDateMode,
            service: mode.toUpperCase(), // New property to specify which service is concerned with expiration date
        };

        apiAuth()
            .put(`/registration/setExpiration`, expirationValues)
            .then(() => {
                refreshAccount();
                toast(t('form.toastSuccess.expirationDateUpdated'));
            })
            .catch((error) =>
                addError(
                    error?.response?.data.detail,
                    error?.response?.data.status,
                ),
            )
            .finally(() => setIsLoading(false));
    };

    /**
     *
     * @param {bool} activate
     */
    const deactivate = (activate) => {
        let patchObject = null;

        switch (mode) {
            case 'climate':
                patchObject = {
                    usesTree: activate ? 1 : 0,
                };
                break;
            default:
                patchObject = {
                    usesArrondi: activate ? 1 : 0,
                };
                break;
        }

        if (patchObject) {
            apiAuth()
                .patch(`/account/${accountData.client_id}`, patchObject)
                .then(() => {
                    refreshAccount();
                    toast(t('form.toastSuccess.accountDeactivated'));
                })
                .catch((error) =>
                    addError(
                        error?.response?.data.detail,
                        error?.response?.data.status,
                    ),
                );
        }
    };

    const submitForm = (data) => {
        setOpen(false);
        setHasUnlogWarning(false);

        // if space is still active we can update data for setup price, subscription and expiration date
        if (isActive) {
            const updatedPrice = {
                price: data[mode + 'KitSetupPrice'] * 100,
                service: mode.toUpperCase(),
            };

            const isDateExpirationDifferent =
                data[mode + 'KitExpirationDateMode'] !== expirationDateMode ||
                (data[mode + 'KitExpirationDateMode'] === expirationDateMode &&
                    data[mode + 'KitExpirationDate'] !==
                        accountData?.[mode + 'ExpirationDate']);

            isDateExpirationDifferent &&
                updateExpirationDate(
                    data[mode + 'KitExpirationDateMode'],
                    data[mode + 'KitExpirationDate'],
                );

            // setup price and subcription can be modified only if sepa is signed
            if (accountData?.[mode + 'Billing'].status === 'SIGNED') {
                const updatedSubscription = {
                    service: mode.toUpperCase(),
                    mode: data[mode + 'KitSubscriptionMode'].toUpperCase(),
                    price:
                        data[mode + 'KitSubscriptionMode'] !== 'usage'
                            ? data[mode + 'KitSubscriptionPrice']
                            : null,
                };

                const isPriceDifferent =
                    Number(data[mode + 'KitSetupPrice'] * 100) !==
                    Number(subscriptionData?.['setup_price']);

                const isSubscriptionDifferent =
                    data[mode + 'KitSubscriptionMode'] !==
                        subscriptionData?.mode ||
                    Number(data[mode + 'KitSubscriptionPrice']) !==
                        Number(subscriptionData?.price);

                // we update price and expiration date only if they have been modified
                isPriceDifferent && updatePrice(updatedPrice);
                isSubscriptionDifferent &&
                    updateSubscription(updatedSubscription);
            }
        } else if (initialActive !== isActive) {
            deactivate(false);
        }
    };

    const cancelCloseModal = () => {
        setOpen(false);

        // we need the reset methods to wait until modal is closed
        setTimeout(() => {
            clearErrors();
            setIsActive(initialActive);
            setActiveChanged(false);
            setHasUnlogWarning(false);

            reset({
                [mode + 'KitSetupPrice']: formatCentsMoney(
                    subscriptionData?.['setup_price'],
                    true,
                ),
                [mode + 'KitSubscriptionPrice']: subscriptionData?.price,
                [mode + 'KitSubscriptionMode']: subscriptionData?.mode,
                [mode + 'KitExpirationDate']:
                    accountData?.[mode + 'ExpirationDate'],
            });
        }, 500);
    };

    return (
        <ModalForm
            modalTitle={mode.charAt(0).toUpperCase() + mode.slice(1) + 'kit'}
            blockWidth="md"
            paddingLateral="md"
            isOpen={isOpen}
            actionOnClose={() => cancelCloseModal()}
        >
            <SpaceManagementActivation
                activeChanged={activeChanged}
                accountData={accountData}
                mode={mode}
                active={isActive}
                spaceName={spaceName}
                billingName={billingName}
                updateActive={updateActive}
            />

            <SpaceManagementForm
                errors={errors}
                setValue={setValue}
                register={register}
                onSubmit={handleSubmit(submitForm)}
                defaultValues={defaultValues}
                mode={mode}
                disabledInputs={!isActive}
                spacePrices={spacePrices}
                watch={watch}
                control={control}
                accountData={accountData}
                hasUnlogWarning={hasUnlogWarning}
                setHasUnlogWarning={setHasUnlogWarning}
            >
                <ButtonGroup marginTop="sm" marginBottom="sm">
                    <CancelButton
                        onClick={() => cancelCloseModal()}
                        buttonSize="md"
                    >
                        {t('form.button.cancel')}
                    </CancelButton>

                    <ValidateButton buttonSize="md">
                        {t('form.button.save')}
                    </ValidateButton>
                </ButtonGroup>
            </SpaceManagementForm>
        </ModalForm>
    );
};

SpaceManagementModal.propTypes = {
    /**
     * @description setOpen and isOpen are automatically received from ModalControl parent
     */
    setOpen: PropTypes.func,
    isOpen: PropTypes.bool,
    accountData: PropTypes.object,
    subscriptionData: PropTypes.object,
    setIsLoading: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(['charity', 'climate']),
    refreshAccount: PropTypes.func,
    refreshSubscription: PropTypes.func,
    spacePrices: PropTypes.array,
};

export default withTranslation('common')(SpaceManagementModal);
