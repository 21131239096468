import React from 'react';
import PropTypes from 'prop-types';
import { DaButton } from '@paygreen/paygreen-ui';
import IconByName from '../../utils/IconByName';

const DeleteButton = ({ buttonSize, onClick, icon, dataCy, children }) => {
    return (
        <button type="button" onClick={onClick} data-cy={dataCy}>
            <DaButton
                buttonSize={buttonSize}
                colorPallet="status"
                colorStatus="danger"
                icon={<IconByName name={icon} />}
            >
                {children}
            </DaButton>
        </button>
    );
};

DeleteButton.propTypes = {
    onClick: PropTypes.func,
    buttonSize: PropTypes.string,
    icon: PropTypes.string,
    /**
     * Id for Cypress tests
     */
    dataCy: PropTypes.string,
};

DeleteButton.defaultProps = {
    buttonSize: 'sm',
    icon: 'CrossIcon',
};

export default DeleteButton;
