import React, { useState } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import {
    DaButton,
    Card,
    ButtonGroup,
    ModalControl,
    ModalProvider,
    Text,
} from '@paygreen/paygreen-ui';
import { CardClassic, MainLogin, SimpleActionButton } from '../../components';
import { useAuth } from '../../context';
import { apiPublic } from '../../utils/hooks';
import ForgottenPasswordModal from './ForgottenPasswordModal';

const ForgottenPassword = ({ t }) => {
    const { checkLocalTokens } = useAuth();

    const location = useLocation();
    const locationUsername = location.state && location.state.username;

    const [isLoading, setIsLoading] = useState(false);
    const [isAskPasswordSuccess, setIsAskPasswordSuccess] = useState(false);
    const [username, setUsername] = useState(locationUsername);

    if (!locationUsername || checkLocalTokens() === 'valid') {
        return <Redirect to="/" />;
    }

    /**
     * Post request to API for forgotten password
     * @param {string} username
     */
    const askPassword = (username) => {
        setIsLoading(true);

        const usernameObject = { username: username };
        setUsername(username);

        apiPublic()
            .post('/password/forgot', usernameObject)
            .then(() => {
                setIsAskPasswordSuccess(true);
            })
            .catch(() => {
                // we validate this step even in case of error from API
                setIsAskPasswordSuccess(true);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <MainLogin
            helmetTitle={
                t('forgottenPassword.metaTitle') + ' - ' + t('main.metaTitle')
            }
            isLoading={isLoading}
        >
            <ModalProvider>
                <CardClassic
                    blockWidth="lg"
                    paddingLateral="lg"
                    paddingTop="md"
                    paddingBottom="md"
                    title={
                        !isAskPasswordSuccess
                            ? t('forgottenPassword.cardForgottenPasswortTitle')
                            : t('forgottenPassword.cardEmailResentTitle')
                    }
                    titleColorWab="grey30"
                    titleAlign="center"
                    titleSize="md"
                    isShadowWab={true}
                    radiusSize="lg"
                >
                    {!isAskPasswordSuccess ? (
                        <>
                            <Text textSize="sm">
                                {t('forgottenPassword.resetLinkText')}

                                <span role="img" aria-label="party popper">
                                    🎉
                                </span>
                            </Text>

                            <Text
                                textSize="sm"
                                marginTop="xs"
                                marginBottom="sm"
                            >
                                <Trans i18nKey="forgottenPassword.emailSentText">
                                    email sent
                                    <Text textSize="sm" htmlTag="strong">
                                        {{
                                            locationUsername,
                                        }}
                                    </Text>
                                </Trans>
                            </Text>

                            <Card
                                paddingLateral="sm"
                                paddingTop="sm"
                                paddingBottom="sm"
                                hasBackground={true}
                                shadowSize="none"
                                colorPallet="wab"
                                colorWab="white20"
                                blockWidth="lg"
                            >
                                <Text textSize="xs">
                                    {t('forgottenPassword.notReceivedText')}
                                </Text>

                                <ButtonGroup marginTop="sm" marginBottom="xs">
                                    <ModalControl hasOnClickControl={true}>
                                        <SimpleActionButton
                                            buttonStyle="line"
                                            buttonSize="md"
                                            type="button"
                                            icon="LetterIcon"
                                        >
                                            {t(
                                                'forgottenPassword.reSendMeButton',
                                            )}
                                        </SimpleActionButton>
                                    </ModalControl>
                                </ButtonGroup>
                            </Card>
                        </>
                    ) : (
                        <>
                            <Text
                                textSize="sm"
                                marginTop="xs"
                                marginBottom="sm"
                            >
                                <Trans i18nKey="forgottenPassword.emailReSentText">
                                    email resent
                                    <Text textSize="sm" htmlTag="strong">
                                        {{
                                            username,
                                        }}
                                    </Text>
                                </Trans>
                            </Text>

                            <Text
                                textSize="sm"
                                marginTop="xs"
                                marginBottom="sm"
                            >
                                {t('forgottenPassword.stillNotReceivedText')}
                            </Text>

                            <ButtonGroup marginTop="sm">
                                <a
                                    href="mailto:serviceclient@paygreen.fr"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <DaButton gradient="theme" buttonSize="lg">
                                        {t(
                                            'forgottenPassword.contactServiceButton',
                                        )}
                                    </DaButton>
                                </a>
                            </ButtonGroup>
                        </>
                    )}
                </CardClassic>

                {!isLoading && !isAskPasswordSuccess && (
                    <ModalControl>
                        <ForgottenPasswordModal
                            askPassword={askPassword}
                            username={locationUsername && locationUsername}
                            title={t('forgottenPassword.reSendMailModal.title')}
                            mode="resend"
                        >
                            <Text textSize="sm" marginBottom="sm">
                                <Trans i18nKey="forgottenPassword.reSendMailModal.introText">
                                    not received email
                                    <Text htmlTag="strong" textSize="sm">
                                        {{
                                            locationUsername,
                                        }}
                                    </Text>
                                    send again
                                </Trans>
                            </Text>

                            <Text htmlTag="em" textSize="sm">
                                {t(
                                    'forgottenPassword.reSendMailModal.problemText',
                                )}
                            </Text>
                        </ForgottenPasswordModal>
                    </ModalControl>
                )}
            </ModalProvider>
        </MainLogin>
    );
};

export default withTranslation('common')(ForgottenPassword);
