import React from 'react';
import { InternalGrid, Text, Toggle } from '@paygreen/paygreen-ui';
import { useMode } from '../../context/useMode';
import { useTranslation } from 'react-i18next';
import { MODES } from '../../utils/constants';

const ToggleMode = () => {
    const { mode, handleSetMode } = useMode();
    const { t } = useTranslation();

    return (
        <InternalGrid
            displayType="grid"
            gridTemplateColumns="auto auto"
            gridGap="md"
            alignItems="center"
            childrenMarginBottom="xs"
        >
            <Text colorWab="grey30">{t('mode.displayedData')}</Text>

            <Toggle
                id="toggle"
                checkedLabel={t('mode.prod')}
                notCheckedLabel={t('mode.test')}
                checked={mode === MODES.PROD}
                value={mode === MODES.PROD}
                onChange={(e) => {
                    handleSetMode(e.target.checked ? MODES.PROD : MODES.TEST);
                }}
            />
        </InternalGrid>
    );
};

ToggleMode.propTypes = {};

export default ToggleMode;
