import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { useAPIError } from '../../../context';
import {
    apiPartnershipStatus,
    partnershipStatus,
} from '../../../utils/constants';
import {
    H1Title,
    Introduction,
    ErrorBoundary,
    NoDataFoundCard,
} from '../../../components';
import { getFilteredPartnership } from '../shared/partnershipsTools';
import { getAllPartnerships } from '../service';
import {
    PartnershipCard,
    PartnershipCardGrid,
    PartnershipCardSkeleton,
} from '../shared';

const CancelledCharityPartnerships = ({ t }) => {
    const { addError } = useAPIError();

    const [partnershipsData, setPartnershipsData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isMounted = true;

        getAllPartnerships()
            .then((res) => {
                isMounted &&
                    setPartnershipsData(
                        getFilteredPartnership(
                            res.data._embedded.partnership,
                            partnershipStatus.CANCELLED,
                        ),
                    );
            })
            .catch((error) => {
                addError(
                    error?.response?.data.detail,
                    error?.response?.data.status,
                );
            })
            .finally(() => {
                isMounted && setIsLoading(false);
            });

        return () => {
            isMounted = false;
        };
    }, [addError]);

    return (
        <>
            <Helmet>
                <title>
                    {t('CharityPartnerships.cancelledPartnerships.metaTitle')} -{' '}
                    {t('main.metaTitle')}
                </title>
            </Helmet>

            <H1Title
                title={t('CharityPartnerships.cancelledPartnerships.title')}
            />

            <Introduction>
                {t('CharityPartnerships.cancelledPartnerships.introduction')}
            </Introduction>

            {isLoading ? (
                <PartnershipCardSkeleton blockWidth="md" />
            ) : partnershipsData && partnershipsData.length > 0 ? (
                <PartnershipCardGrid>
                    {getFilteredPartnership(partnershipsData).map(
                        (partnership, index) => (
                            <ErrorBoundary blockWidth="lg" key={index}>
                                <PartnershipCard
                                    key={index}
                                    data={partnership}
                                    partnershipStatus={
                                        partnership.associationStatus ===
                                        apiPartnershipStatus.DELETE
                                            ? partnershipStatus.CANCELLEDBYCHARITY
                                            : partnershipStatus.CANCELLEDBYSHOP
                                    }
                                />
                            </ErrorBoundary>
                        ),
                    )}
                </PartnershipCardGrid>
            ) : (
                <NoDataFoundCard
                    message={t(
                        'CharityPartnerships.cancelledPartnerships.noDataFound',
                    )}
                />
            )}
        </>
    );
};

export default withTranslation('common')(CancelledCharityPartnerships);
