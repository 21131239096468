import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Text, InternalGrid } from '@paygreen/paygreen-ui';
import { withTranslation } from 'react-i18next';
import IconByName from '../../../utils/IconByName';
import { IsMobile } from '../../../utils/tools';

/**
 * Component to display a status in color with an Icon
 * Used in offsets table
 */
const StatusDisplayTable = ({
    statusOptions,
    status,
    statusExtension,
    textSize,
    iconSize,
    i18TextBase,
    hasIcon,
    customValue,
    t,
    ...rest
}) => {
    if (!statusOptions.hasOwnProperty(status)) {
        throw new Error(`The status "${status}" is not supported`);
    }

    const colorProps = {
        colorPallet: statusOptions[status].colorPallet,
        colorStatus:
            statusOptions[status].colorPallet === 'status'
                ? statusOptions[status].color
                : null,
        colorWab:
            statusOptions[status].colorPallet === 'wab'
                ? statusOptions[status].color
                : null,
    };

    return (
        <InternalGrid
            alignItems="center"
            justifyContent="flex-end"
            flexWrap="nowrap"
            title={
                i18next.exists(i18TextBase + '.' + status + '.title')
                    ? t(i18TextBase + '.' + status + '.title')
                    : ''
            }
            {...rest}
        >
            {hasIcon && (
                <IconByName
                    name={statusOptions[status].icon}
                    marginRight="sm"
                    iconSize={iconSize}
                    {...colorProps}
                />
            )}

            <Text textSize={textSize} htmlTag="strong" {...colorProps}>
                {customValue
                    ? customValue
                    : IsMobile() &&
                      hasIcon &&
                      i18next.exists(i18TextBase + '.' + status + '.nameShort')
                    ? t(i18TextBase + '.' + status + '.nameShort')
                    : t(i18TextBase + '.' + status + '.name')}

                {statusExtension}
            </Text>
        </InternalGrid>
    );
};

StatusDisplayTable.propTypes = {
    status: PropTypes.string.isRequired,
    statusOptions: PropTypes.object.isRequired,
    i18TextBase: PropTypes.string,
    iconSize: PropTypes.string,
    textSize: PropTypes.string,
    hasicon: PropTypes.bool,
    statusExtension: PropTypes.string,
    customValue: PropTypes.string,
};

StatusDisplayTable.defaultProps = {
    iconSize: 'sm',
    textSize: 'xs',
    hasIcon: true,
};

export default withTranslation('common')(StatusDisplayTable);
