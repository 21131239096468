import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Text, InternalGrid } from '@paygreen/paygreen-ui';
import IconByName from '../../utils/IconByName';
import { IsMobile } from '../../utils/tools';
import Can from '../../utils/rules/Can';

const ModeDisplay = ({ t, justifyContent }) => {
    const mode = process.env.REACT_APP_ACTIVE_MODE;
    const iconName = mode === 'SANDBOX' ? 'BeakerIcon' : 'WheelsIcon';

    return (
        <Can
            condition={mode}
            perform={['routes:sandbox', 'routes:dev']}
            yes={() => (
                <InternalGrid
                    align="center"
                    alignItems="center"
                    justifyContent={justifyContent}
                >
                    <IconByName
                        name={iconName}
                        colorPallet="wab"
                        iconSize="sm"
                        hasBackground={true}
                        style={{
                            paddingLeft:
                                justifyContent === 'flex-start' ? '0' : null,
                        }}
                    />

                    <Text
                        textSize="tiny"
                        hasUppercase={true}
                        colorWab="grey40"
                        style={{ letterSpacing: IsMobile() ? '0.1rem' : null }}
                    >
                        {t(`sidebar.mode.${mode.toLowerCase()}`)}
                    </Text>
                </InternalGrid>
            )}
        />
    );
};

ModeDisplay.propTypes = {
    justifyContent: PropTypes.string,
};

ModeDisplay.defaultProps = {
    justifyContent: 'center',
};

export default withTranslation('common')(ModeDisplay);
