/**
 * @param {object} dataUser  - object with account informations with values for current back-office space : 'expirationDate' , 'sepaStatus', 'isExpired' and for activation info for both spaces "usesTree" and "usesArrondi"
 */
export const setBillingLocalStorage = (dataAccount) => {
    dataAccount?.expirationDate &&
        localStorage.setItem(
            'expiration_date',
            JSON.stringify(dataAccount?.expirationDate),
        );
    dataAccount?.isExpired &&
        localStorage.setItem(
            'is_access_expired',
            JSON.stringify(dataAccount?.isExpired),
        );

    dataAccount?.sepaStatus &&
        localStorage.setItem(
            'sepa_status',
            JSON.stringify(dataAccount?.sepaStatus),
        );
    dataAccount?.usesTree &&
        localStorage.setItem(
            'uses_tree',
            JSON.stringify(dataAccount?.usesTree),
        );
    dataAccount?.usesArrondi &&
        localStorage.setItem(
            'uses_arrondi',
            JSON.stringify(dataAccount?.usesArrondi),
        );
};
