import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../../context';
import { Loader } from '@paygreen/paygreen-ui';

const RefreshTokenRoute = ({ referer }) => {
    const { autoRefreshToken } = useAuth();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const abortController = new AbortController();

        const refreshToken = async () => {
            await autoRefreshToken();
            setIsLoading(false);
        };

        refreshToken();

        return () => {
            abortController.abort();
        };
    }, [autoRefreshToken]);

    if (!isLoading) {
        return <Redirect to={referer} />;
    } else {
        return (
            <div style={{ height: '100%', display: 'flex' }}>
                <Loader style={{ margin: 'auto' }} />
            </div>
        );
    }
};

RefreshTokenRoute.propTypes = {
    referer: PropTypes.string,
};

RefreshTokenRoute.defaultProps = {
    referer: '/',
};

export default RefreshTokenRoute;
