import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { math } from 'polished';

const size = (props) => props.theme.icon.size.sm;

const BadgeContainer = styled.span`
    box-sizing: border-box;
    display: inline-block;
    min-width: ${size};
    height: ${size};
    padding: 0 ${(props) => props.theme.space.xs};
    margin-left: ${(props) => props.theme.space.sm};
    border-radius: ${(props) => math(props.theme.icon.size.sm + '/2')};
    background-color: ${(props) => props.theme.color[props.colorTheme].main};
    color: ${(props) => props.theme.wab.white00};
    font-size: ${(props) => props.theme.font.size.xxs};
    font-weight: bold;
    line-height: ${size};
    text-align: center;
`;

const BadgeAlert = ({ value, colorTheme }) =>
    value ? (
        <BadgeContainer colorTheme={colorTheme}>{value}</BadgeContainer>
    ) : null;

BadgeAlert.propTypes = {
    value: PropTypes.number,
    colorTheme: PropTypes.string,
};

BadgeAlert.defaultProps = {
    value: 0,
    colorTheme: 'primary',
};

export default BadgeAlert;
