import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import {
    ModalControl,
    ModalProvider,
    InternalGrid,
} from '@paygreen/paygreen-ui';
import {
    H1Title,
    ErrorBoundary,
    ToggleMode,
    SimpleActionButton,
} from '../../components';
import { useAPIError, useMode } from '../../context';
import { formatDateApi, IsBigDesktop, IsDesktop } from '../../utils/tools';
import { endOfMonth, startOfMonth, MODES } from '../../utils/constants';
import DonationsTable from './DonationsTable';
import ExportDonationsModal from './ExportDonationsModal';
import FilterTable from './FilterTable';
import { getAllDonations } from './service';

const ShopDonations = ({ t }) => {
    const { addError } = useAPIError();
    const { mode } = useMode();
    const fetchIdRef = useRef(0);

    // we create a ref to detect if component is mounted
    const isMounted = useRef(false);

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalItems, setTotalItems] = useState(0);

    const [startDate, setStartDate] = useState(startOfMonth);
    const [endDate, setEndDate] = useState(endOfMonth);

    const fetchData = useCallback(
        ({ pageSize, pageIndex, statusFilter, startDate, endDate }) => {
            const fetchId = ++fetchIdRef.current;
            if (fetchId === fetchIdRef.current) {
                isMounted.current && setIsLoading(true);

                const params = {
                    limit: pageSize,
                    page: pageIndex + 1,
                    status: statusFilter,
                    begin: startDate && formatDateApi(startDate),
                    end: endDate && formatDateApi(endDate),
                };

                getAllDonations(params, mode)
                    .then((res) => {
                        if (isMounted.current) {
                            setData(res.data._embedded.donation);
                            setPageCount(res.data.page_count);
                            setTotalItems(res.data.total_items);
                        }
                    })
                    .catch((error) => {
                        if (error?.response?.data.status === 404) {
                            isMounted.current && setData([]);
                        } else {
                            addError(
                                error?.response?.data.detail,
                                error?.response?.data.status,
                            );
                        }
                    })
                    .finally(() => {
                        isMounted.current && setIsLoading(false);
                    });
            }
        },
        [addError, mode],
    );

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    {t('shopDonations.metaTitle')} - {t('main.metaTitle')}
                </title>
            </Helmet>

            <H1Title title={t('shopDonations.title')}>
                <InternalGrid
                    displayType="grid"
                    gridTemplateColumns="auto auto"
                    gridGap="md"
                >
                    <ToggleMode />

                    <ModalProvider>
                        {IsDesktop() && mode === MODES.PROD && (
                            <ModalControl hasOnClickControl={true}>
                                <SimpleActionButton icon="DownloadIcon">
                                    {t('form.button.export')}
                                </SimpleActionButton>
                            </ModalControl>
                        )}

                        <ModalControl>
                            <ExportDonationsModal
                                startDate={startDate}
                                endDate={endDate}
                            />
                        </ModalControl>
                    </ModalProvider>
                </InternalGrid>
            </H1Title>

            <InternalGrid
                displayType="grid"
                gridGap="md"
                gridTemplateColumns="1fr"
                blockPadding="none"
                justifyItems="stretch"
                hasStaticWidth={IsBigDesktop() ? false : true}
            >
                <FilterTable
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                    startDate={startDate}
                    endDate={endDate}
                />

                <ErrorBoundary>
                    <DonationsTable
                        fetchData={fetchData}
                        data={data}
                        loading={isLoading}
                        pageCount={pageCount}
                        startDate={startDate}
                        endDate={endDate}
                        totalItems={totalItems}
                    />
                </ErrorBoundary>
            </InternalGrid>
        </>
    );
};

export default withTranslation('common')(ShopDonations);
