import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from '@paygreen/paygreen-ui';
import { climateEnvLink } from '../user/shared';

/**
 * @description if organizationName already exists in base
 */
const DuplicateAccountMessage = ({ t }) => (
    <>
        {t('subscription.duplicateEntityMessage')}

        <a href="mailto:serviceclient@paygreen.fr">
            <Link colorPallet="status" colorStatus="danger">
                {t('subscription.support')}
            </Link>
        </a>

        {t('subscription.activateSpaceMessage')}

        <a
            href={climateEnvLink() + '/user/spaces'}
            target="_blank"
            rel="noopener noreferrer"
        >
            <Link colorPallet="status" colorStatus="danger">
                {t('subscription.mySpaces')}
            </Link>
        </a>
    </>
);
export default withTranslation('common')(DuplicateAccountMessage);
