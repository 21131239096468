import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import {
    ArrowLongRightIcon,
    Box,
    ButtonGroup,
    DaButton,
    ModalControl,
    ModalProvider,
    Text,
} from '@paygreen/paygreen-ui';
import { Link } from 'react-router-dom';
import { ManualModal, ModalForm } from '../../../components';
import { useUser } from '../../../context';
import { timestampToInternationalFormat } from '../../../utils/tools';
import dayjs from '../../../utils/dayjsHelper';

// Diff nb days before expiration date to alert the user about missing SEPA informations
const nbDays = [0, 1, 3, 10, 19, 28];

const AlertSepa = ({ t, isExpired }) => {
    const { user } = useUser();
    const [remainingDays, setRemainingDays] = useState(null);
    const [condition, setCondition] = useState(false);

    // Check if the user seen today has a SepaAlert saved in localStorage when user hide the modal
    const dateSeenSepaAlert = localStorage.getItem('seenSepaAlert')
        ? dayjs(localStorage.getItem('seenSepaAlert'), 'DD-MM-YYYY')
        : null;

    const expirationDate = dayjs(
        timestampToInternationalFormat(user?.expirationDate),
        'YYYY-MM-DD',
    );

    const now = dayjs();

    useEffect(() => {
        // The user didn't see the alert
        if (isExpired && (dateSeenSepaAlert && !now.isSame(dateSeenSepaAlert, 'day'))) {
            setCondition(true);
        } else 
        if (
            dateSeenSepaAlert === null ||
            (dateSeenSepaAlert && !now.isSame(dateSeenSepaAlert, 'day'))
        ) {
            const diffMonth = now.diff(expirationDate, 'M');
            if (diffMonth <= 1) {
                const remainingDaysTmp = Math.ceil(
                    expirationDate.diff(now, 'd', true),
                );

                setRemainingDays(remainingDaysTmp);
                if (nbDays.includes(remainingDaysTmp) || isExpired) {
                    setCondition(true);
                }
            }
        }
    }, [expirationDate, dateSeenSepaAlert, now, isExpired]);

    const cancelCloseModal = () => {
        setCondition(false);
        localStorage.setItem('seenSepaAlert', now.format('DD-MM-YYYY'));
    };

    return (
        <ModalProvider>
            <ModalControl>
                <ManualModal modalIsOpen={condition}>
                    <ModalForm
                        modalTitle={
                            isExpired
                                ? t('alert.sepa.titleExpired')
                                : t('alert.sepa.title', {
                                      nbDays: remainingDays,
                                      count: remainingDays,
                                  })
                        }
                        blockWidth="md"
                        paddingLateral="sm"
                        hasCloseCross={true}
                        actionOnClose={() => cancelCloseModal()}
                    >
                        <Box>
                            <Text htmlTag="div">
                                {t('alert.sepa.content')}

                                <ul>
                                    <li>
                                        <Trans i18nKey="alert.sepa.list.one">
                                            ...
                                            <strong>
                                                <i>
                                                    {{
                                                        raisonSociale:
                                                            user.accountId,
                                                    }}
                                                </i>
                                            </strong>
                                            ...
                                        </Trans>
                                    </li>

                                    <li>{t('alert.sepa.list.two')}</li>

                                    <li>{t('alert.sepa.list.three')}</li>
                                </ul>

                                {isExpired ? (
                                    <Text>
                                        {t('alert.sepa.finalContentExpired')}
                                    </Text>
                                ) : (
                                    <Trans i18nKey="alert.sepa.finalContent">
                                        ...
                                        <strong>
                                            {{
                                                expirationDate:
                                                    expirationDate.format(
                                                        'DD/MM/YYYY',
                                                    ),
                                            }}
                                        </strong>
                                        ...
                                    </Trans>
                                )}
                            </Text>

                            <ButtonGroup marginTop="xs" marginBottom="xs">
                                <Link
                                    to="/user/company"
                                    onClick={() => cancelCloseModal()}
                                >
                                    <DaButton
                                        buttonSize="lg"
                                        gradient="theme"
                                        icon={<ArrowLongRightIcon />}
                                    >
                                        {t('alert.sepa.cta')}
                                    </DaButton>
                                </Link>
                            </ButtonGroup>
                        </Box>
                    </ModalForm>
                </ManualModal>
            </ModalControl>
        </ModalProvider>
    );
};

AlertSepa.propTypes = {
    isExpired: PropTypes.bool,
};

AlertSepa.defaultProps = {
    isExpired: false,
};

export default withTranslation('common')(AlertSepa);
