import React from 'react';
import { withTranslation } from 'react-i18next';
import { Text } from '@paygreen/paygreen-ui';
import { Message } from '../../../components';

/**
 * @description a specific message to warning AM that this space has been deactivated on purpose and need technical team intervention to be reactivated
 */
const UnsubscribedWarning = ({ t }) => (
    <Message
        type="error"
        paddingLateral="sm"
        icon="CrossBoldIcon"
        blockWidth="xs"
        hasStaticWidth={false}
    >
        <Text textSize="xxs">{t('admin.user.unsubscribedWarning')}</Text>
    </Message>
);

export default withTranslation('common')(UnsubscribedWarning);
