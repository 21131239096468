import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    DaLabel,
    DaHelp,
    FormControl,
    Message,
    Text,
} from '@paygreen/paygreen-ui';

/**
 * Wrapper of Input
 * Allow to use FormControl from paygreen-ui and manage DaHelp Message and Error Message without rerender all form
 */
const FormControlWrapper = ({
    t,
    errors,
    isRequired,
    inputName,
    i18labelBase,
    hasStaticWidth,
    blockWidth,
    hasMessage,
    children,
    fieldSize,
    hasLongMessage,
    hasLabel,
    ...rest
}) => {
    const [displayMessage, setDisplayMessage] = useState(false);

    // allow to hide help message without having to click on DaHelp button
    useEffect(() => {
        !hasMessage && setDisplayMessage(false);
    }, [hasMessage]);

    return (
        <FormControl
            required={isRequired}
            colorStatus={
                errors[inputName]
                    ? errors[inputName].status
                        ? errors[inputName].status
                        : 'warning'
                    : 'default'
            }
            className="input-control"
            hasStaticWidth={hasStaticWidth}
            {...rest}
        >
            {!hasLabel ? null : (
                <DaLabel
                    htmlFor={inputName}
                    title={isRequired ? t('user.requiredInput') : null}
                >
                    {t(i18labelBase + '.' + inputName + 'Input')}
                </DaLabel>
            )}

            {React.Children.map(children, (child, index) => {
                if (!child) {
                    return null;
                }

                if (hasMessage && child.type === DaHelp) {
                    return React.cloneElement(child, {
                        onClick: (e) => {
                            e.preventDefault();
                            setDisplayMessage(!displayMessage);
                        },
                        key: index,
                    });
                }

                if (child.type === Message) {
                    return (
                        <>
                            {displayMessage && !errors[inputName] ? (
                                <>{child}</>
                            ) : null}
                        </>
                    );
                }

                if (child.type === Text) {
                    return (
                        <>
                            {!displayMessage && !errors[inputName] ? (
                                <>{child}</>
                            ) : null}
                        </>
                    );
                }

                return child;
            })}

            {errors[inputName] && (
                <Message
                    data-cy={'error-' + inputName}
                    arrowBlock="topLeft"
                    blockWidth={blockWidth}
                    fieldSize={fieldSize}
                    colorStatus={
                        errors[inputName].status
                            ? errors[inputName].status
                            : 'warning'
                    }
                    style={{ wordBreak: hasLongMessage && 'break-all' }}
                >
                    <Text align="center" textSize="xs">
                        {errors[inputName].message}
                    </Text>
                </Message>
            )}
        </FormControl>
    );
};

FormControlWrapper.propTypes = {
    inputName: PropTypes.string.isRequired,
    /**
     * to identify correct translation for label
     */
    i18labelBase: PropTypes.string,
    /**
     * errors passed by react-hook-form for each field (necessary if you want errors management)
     */
    errors: PropTypes.object,
    /**
     * Indicate to the component if there is a DaHelp with Message to manage
     * with a state
     */
    hasMessage: PropTypes.bool,
    isRequired: PropTypes.bool,
    blockWidth: PropTypes.string,
    hasStaticWidth: PropTypes.bool,
    fieldSize: PropTypes.string,
    hasLongMessage: PropTypes.bool,
    hasLabel: PropTypes.bool,
};

FormControlWrapper.defaultProps = {
    errors: {},
    isRequired: false,
    hasMessage: false,
    blockWidth: 'lg',
    hasStaticWidth: true,
    fieldSize: 'md',
    hasLongMessage: false,
    hasLabel: true,
};

export default withTranslation('common')(FormControlWrapper);
