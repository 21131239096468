import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AdminUsers, AdminUserDetails, Error404 } from '../domains';

const AdminRoutes = () => {
    return (
        <Switch>
            <Route exact path="/admin/users" component={AdminUsers} />
            <Route
                exact
                path="/admin/users/:accountId"
                component={AdminUserDetails}
            />
            {/* <Route exact path="/admin/my-users" component={AdminMyUsers} /> STAND BY */}
            <Route component={Error404} />
        </Switch>
    );
};

export default AdminRoutes;
