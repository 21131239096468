import { useState, useLayoutEffect } from 'react';

/**
 * React hook that tracks state of a CSS media query
 * @description based on https://usehooks.com/useMedia/
 * @param {array} queries - array of media queries
 * @param {array} values - array of values returned by hook if queries match windows.media
 * @param {string | number} defaultValue - defaultValue returned by hook if no match
 */
export const useMediaQuery = (queries, values, defaultValue) => {
    const isSupported =
        typeof window !== 'undefined' &&
        typeof window.matchMedia !== 'undefined';

    // Array containing a media query list for each query
    const mediaQueryList = queries.map((query) => window.matchMedia(query));

    // Function that gets value based on first matching media query
    const getValue = () => {
        const index = mediaQueryList.findIndex((mql) => mql.matches);

        return typeof values[index] !== 'undefined'
            ? values[index]
            : defaultValue;
    };

    // State and setter for matched value
    const [value, setValue] = useState(getValue);

    useLayoutEffect(() => {
        if (!isSupported) {
            return undefined;
        }

        const handler = () => setValue(getValue);

        // Set a listener for each media query with above handler as callback.
        mediaQueryList.forEach((mediaQuery) => {
            // if supported by browser we use addEventListener to avoid deprecacy issues (https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener)
            if (mediaQuery.addEventListener) {
                mediaQuery.addEventListener('change', handler);
            } else {
                // only when not supported (in Safari before v.14) we use addListener
                mediaQuery.addListener(handler);
            }
        });

        // Remove listeners on cleanup
        return () =>
            mediaQueryList.forEach((mediaQuery) => {
                if (mediaQuery.removeEventListener) {
                    mediaQuery.removeEventListener('change', handler);
                } else {
                    mediaQuery.removeListener(handler);
                }
            });
    }, []); // eslint-disable-line

    return value;
};
