import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, withTranslation } from 'react-i18next';
import { Text } from '@paygreen/paygreen-ui';
import { useAPIError, useCharityList } from '../../../../context';
import {
    Disabler,
    H1Title,
    Introduction,
    ErrorBoundary,
    NoDataFoundCard,
    ModalBlock,
    SimpleActionButton,
    ValidateButton,
    ToggleMode,
} from '../../../../components';
import { useMode } from '../../../../context/useMode';
import {
    PartnershipCardSkeleton,
    PartnershipCardGrid,
    PartnershipModalContent,
    PartnershipCollapsibleCard,
    PartnershipToastMessage,
} from '../../shared';
import { getAllPartnerships, createPartnership } from '../../service';
import AllCharitiesFilter from './AllCharitiesFilter';

const AllCharities = ({ t }) => {
    const { charityListData } = useCharityList();
    const { addError } = useAPIError();
    const { mode } = useMode();
    // we create a ref to detect if component is mounted
    const isMounted = useRef(false);

    const [partnershipsData, setPartnershipsData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [localCharityList, setLocalCharityList] = useState(charityListData);

    const getData = useCallback(() => {
        setIsLoading(true);

        getAllPartnerships(mode)
            .then((res) => {
                let partnershipsArray = [];
                res.data._embedded.partnership.map((partnership) =>
                    partnershipsArray.push(
                        partnership.association.idAssociation,
                    ),
                );
                isMounted.current && setPartnershipsData(partnershipsArray);
            })
            .catch((error) => {
                addError(
                    error?.response?.data.detail,
                    error?.response?.data.status,
                );
            })
            .finally(() => {
                isMounted.current && setIsLoading(false);
            });
    }, [addError, mode]);

    useEffect(() => {
        isMounted.current = true;
        getData();

        return () => {
            isMounted.current = false;
        };
    }, [getData, mode]);

    useEffect(() => {
        isMounted.current = true;
        setLocalCharityList(charityListData);

        return () => {
            isMounted.current = false;
        };
    }, [charityListData]);

    const CardMainButton = ({ partnerId, partnerName }) => (
        <ModalBlock
            blockWidth="md"
            closeWithAction={() => getData()}
            modalTitle={t('partnershipButtons.becomePartner')}
            button={
                <ValidateButton icon="HeartIcon">
                    {t('partnershipButtons.becomePartner')}
                </ValidateButton>
            }
        >
            <PartnershipModalContent
                closeWithAction={() => getData()}
                sdkPartnershipAction={() => createPartnership(partnerId)}
                buttonCancelAction={
                    <SimpleActionButton icon="ArrowRoundTopLeftIcon">
                        {t('partnershipButtons.notYet')}
                    </SimpleActionButton>
                }
                buttonConfirmAction={
                    <ValidateButton icon="LetterIcon">
                        {t('partnershipButtons.sendRequest')}
                    </ValidateButton>
                }
                toastMessage={
                    <PartnershipToastMessage
                        i18nKey="form.toastSuccess.partnershipRequest"
                        partnerName={partnerName}
                    />
                }
            >
                <Text textSize="sm">
                    <Trans i18nKey="ShopPartnerships.allCharities.message">
                        Are you sure to send request
                        <Text htmlTag="strong" textSize="sm">
                            {{ partnerName }}
                        </Text>
                        This manipulation is irreversible
                    </Trans>
                </Text>

                <Text textSize="sm" marginTop="xs">
                    {t('ShopPartnerships.allCharities.ready')}
                </Text>
            </PartnershipModalContent>
        </ModalBlock>
    );

    return (
        <>
            <Helmet>
                <title>
                    {t('ShopPartnerships.allCharities.metaTitle')} -{' '}
                    {t('main.metaTitle')}
                </title>
            </Helmet>

            <H1Title title={t('ShopPartnerships.allCharities.title')}>
                <ToggleMode />
            </H1Title>

            <Introduction>
                {t('ShopPartnerships.allCharities.introduction')}
            </Introduction>

            <AllCharitiesFilter
                partnershipsData={partnershipsData}
                localCharityList={localCharityList}
                setLocalCharityList={setLocalCharityList}
            />

            {isLoading ? (
                <PartnershipCardSkeleton blockWidth="md" />
            ) : localCharityList?.length ? (
                <PartnershipCardGrid>
                    {localCharityList.map((charity) => (
                        <ErrorBoundary
                            blockWidth="md"
                            key={charity.idAssociation}
                        >
                            <PartnershipCollapsibleCard
                                key={charity.idAssociation}
                                data={charity}
                                mainButton={
                                    !partnershipsData?.includes(
                                        charity.idAssociation,
                                    ) ? (
                                        <Disabler>
                                            <CardMainButton
                                                partnerId={
                                                    charity.idAssociation
                                                }
                                                partnerName={charity.name}
                                            />
                                        </Disabler>
                                    ) : null
                                }
                            />
                        </ErrorBoundary>
                    ))}
                </PartnershipCardGrid>
            ) : (
                <NoDataFoundCard
                    message={t('ShopPartnerships.allCharities.noDataFound')}
                />
            )}
        </>
    );
};

export default withTranslation('common')(AllCharities);
