// Call Partnerships
import { apiAuth } from '../../utils/hooks';

const patchAccount = (accountId, updatedAccount) => {
    return apiAuth()
        .patch(`/account/${accountId}`, updatedAccount)
        .then((data) => data)
        .catch((error) => {
            throw error;
        });
};

export { patchAccount };
