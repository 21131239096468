import React from 'react';
import PropTypes from 'prop-types';
import { ArrowBottomIcon, Badge } from '@paygreen/paygreen-ui';
import { IsDesktop } from '../../utils/tools';

const CharityKitBadge = ({ textSize, hasArrow, isShorten, ...rest }) => {
    return (
        <Badge
            colorTheme="quaternary"
            textSize={textSize || (IsDesktop() ? 'tiny' : 'xxs')}
            {...rest}
        >
            {!isShorten ? 'charity' : 'cha'}

            {hasArrow ? <ArrowBottomIcon marginLeft="xs" /> : null}
        </Badge>
    );
};

CharityKitBadge.propTypes = {
    hasArrow: PropTypes.bool,
    textSize: PropTypes.string,
    isShorten: PropTypes.bool,
};

CharityKitBadge.defaultProps = {
    hasArrow: false,
    textSize: 'tiny',
    isShorten: false,
};

export default CharityKitBadge;
