import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { ButtonGroup } from '@paygreen/paygreen-ui';
import { useAPIError } from '../../../context';

const PartnershipModalContent = ({
    setIsModalOpen,
    sdkPartnershipAction,
    partnershipsGroupAction,
    closeWithAction,
    buttonCancelAction,
    buttonConfirmAction,
    toastMessage,
    children,
}) => {
    const { addError } = useAPIError();

    const mainAction = () => {
        sdkPartnershipAction
            ? sdkPartnershipAction()
                  .then((res) => {
                      closeWithAction();
                      toastMessage && toast(toastMessage);

                      setIsModalOpen(false);
                  })
                  .catch((error) => {
                      addError(
                          error?.response?.data.detail,
                          error?.response?.data.status,
                      );
                  })
            : partnershipsGroupAction();
    };

    return (
        <>
            {children}

            <ButtonGroup marginTop="sm" marginBottom="xs">
                {buttonCancelAction &&
                    React.cloneElement(buttonCancelAction, {
                        onClick: () => setIsModalOpen(false),
                    })}

                {buttonConfirmAction &&
                    React.cloneElement(buttonConfirmAction, {
                        onClick: () => mainAction(),
                    })}
            </ButtonGroup>
        </>
    );
};

PartnershipModalContent.propTypes = {
    setIsModalOpen: PropTypes.func,
    /**
     * function to handle partnerships that contains first part of sdk request
     */
    sdkPartnershipAction: PropTypes.func,
    /**
     * function to handle partnershipsgroup that contains full request inherited
     */
    partnershipsGroupAction: PropTypes.func,
    closeWithAction: PropTypes.func,
    buttonCancelAction: PropTypes.node,
    buttonConfirmAction: PropTypes.node,
    toastMessage: PropTypes.object,
};

export default PartnershipModalContent;
