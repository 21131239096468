import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link, Text } from '@paygreen/paygreen-ui';
import {
    getDataFromObject,
    timestampToDateWithHour,
    fixExternalUrl,
} from '../../../../utils/tools';
import { LineKeyValue, StatusDisplayTable } from '../../../../components';
import { CardInfo } from '../../shared';
import { userInputList, activationStatusOptions } from '../../adminUsersUtils';

const CompanyManagementCard = ({ t, isLoading, accountData }) => (
    <CardInfo isLoading={isLoading} title={t('admin.user.companyInfos.title')}>
        {userInputList.slice(0, 4).map((item, index) => (
            <LineKeyValue
                key={index}
                hasUppercase={false}
                keyLabel={t('admin.user.companyInfos.' + item.name + 'Input')}
                keyValue={
                    accountData &&
                    (item.type === 'status' || item.type === 'date'
                        ? timestampToDateWithHour(
                              getDataFromObject(item.value, accountData),
                          )
                        : getDataFromObject(item.value, accountData))
                }
                textSize="xs"
            >
                {item.type === 'link' ? (
                    <a
                        href={fixExternalUrl(
                            getDataFromObject(item.value, accountData),
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Text textSize="xs">
                            <Link colorTheme="secondary">
                                {fixExternalUrl(
                                    getDataFromObject(item.value, accountData),
                                )}
                            </Link>
                        </Text>
                    </a>
                ) : item.type === 'status' &&
                  getDataFromObject(item.value, accountData) === '0' ? (
                    <StatusDisplayTable
                        status="CREATED"
                        statusOptions={activationStatusOptions}
                        i18TextBase="admin.users.table.activationStatusFilter"
                    />
                ) : null}
            </LineKeyValue>
        ))}
    </CardInfo>
);

CompanyManagementCard.propTypes = {
    setIsLoading: PropTypes.func,
    isLoading: PropTypes.bool,
    accountData: PropTypes.object,
    setAccountData: PropTypes.func,
};

CompanyManagementCard.defaultProps = {
    isLoading: false,
};

export default withTranslation('common')(CompanyManagementCard);
