import regexForm from '../../../utils/regexForm';

const {
    adress,
    accountUsername,
    cityRegion,
    country,
    description,
    email,
    fieldOfAction,
    firstname,
    lastname,
    name,
    organisationName,
    phone,
    role,
    siret,
    url,
    zipCode,
} = regexForm;

const accountInputsPatterns = [
    {
        name: 'organisationName',
        pattern: organisationName,
        type: 'text',
        required: true,
    },
    {
        name: 'organisationIdentificationNumber',
        pattern: siret,
        type: 'text',
        required: false,
    },
    {
        name: 'organisationWebsite',
        pattern: url,
        type: 'url',
        required: false,
    },
];

const charityInputsPatterns = [
    {
        name: 'name',
        pattern: name,
        type: 'text',
    },
    {
        name: 'url',
        pattern: url,
        type: 'text',
    },
    {
        name: 'fieldOfAction',
        pattern: fieldOfAction,
        type: 'text',
    },
    {
        name: 'description',
        pattern: description,
        type: 'text',
    },
];

const userInputsPatterns = [
    {
        name: 'accountId',
        pattern: accountUsername,
        type: 'text',
    },
    {
        name: 'username',
        pattern: accountUsername,
        type: 'text',
    },
    { name: 'role', pattern: role, type: 'text' },
    {
        name: 'firstname',
        pattern: firstname,
        type: 'text',
        required: true,
    },
    {
        name: 'lastname',
        pattern: lastname,
        type: 'text',
        required: true,
    },
    {
        name: 'email',
        pattern: email,
        type: 'email',
        required: true,
    },
    {
        name: 'phone',
        pattern: phone,
        type: 'tel',
        required: true,
    },
    {
        name: 'country',
        pattern: country,
        type: 'text',
        required: true,
    },
];

const billingAddressPatterns = [
    {
        name: 'addressLine1',
        pattern: adress,
        type: 'text',
        required: true,
    },
    {
        name: 'addressLine2',
        pattern: adress,
        type: 'text',
        required: false,
    },
    {
        name: 'zipCode',
        pattern: zipCode,
        type: 'text',
        required: true,
    },
    {
        name: 'city',
        pattern: cityRegion,
        type: 'text',
        required: true,
    },
    {
        name: 'region',
        pattern: cityRegion,
        type: 'text',
        required: true,
    },
    {
        name: 'country',
        pattern: country,
        type: 'text',
        required: true,
    },
];

export {
    accountInputsPatterns,
    billingAddressPatterns,
    userInputsPatterns,
    charityInputsPatterns,
};
