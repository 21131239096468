import React from 'react';
import PropTypes from 'prop-types';
import { DaButton } from '@paygreen/paygreen-ui';
import IconByName from '../../utils/IconByName';

const CancelButton = ({ buttonSize, children, icon, onClick, dataCy }) => (
    <button type="reset" onClick={onClick} data-cy={dataCy}>
        <DaButton
            buttonSize={buttonSize}
            buttonStyle="line"
            colorPallet="status"
            colorStatus="warning"
            icon={<IconByName name={icon} />}
        >
            {children}
        </DaButton>
    </button>
);

CancelButton.propTypes = {
    onClick: PropTypes.func,
    buttonSize: PropTypes.string,
    icon: PropTypes.string,
    /**
     * Id for Cypress tests
     */
    dataCy: PropTypes.string,
};

CancelButton.defaultProps = {
    buttonSize: 'sm',
    icon: 'ArrowRoundTopLeftIcon',
};

export default CancelButton;
