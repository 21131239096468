import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    ArrowLongRightIcon,
    ButtonGroup,
    DaButton,
    Link,
    ModalControl,
    Text,
    DaInput,
} from '@paygreen/paygreen-ui';
import { FormControlWrapper, Message } from '../../components';
import regexForm from '../../utils/regexForm';

const LoginForm = ({
    t,
    errors,
    setValue,
    register,
    errorMessage,
    onSubmit,
    accountErrorMessage,
}) => {
    const inputsNames = [
        {
            name: 'accountId',
            pattern: regexForm.accountUsername,
            type: 'text',
        },
        {
            name: 'username',
            pattern: regexForm.accountUsername,
            type: 'text',
        },
        {
            name: 'password',
            pattern: regexForm.password,
            type: 'password',
        },
    ];

    return (
        <form onSubmit={onSubmit} noValidate>
            {inputsNames.map((element, index) => (
                <FormControlWrapper
                    key={element.name}
                    i18labelBase="login"
                    inputName={element.name}
                    isRequired={true}
                    errors={errors}
                    blockWidth="sm"
                    marginBottom={index !== 2 ? 'md' : 'xs'}
                >
                    <DaInput
                        onChange={(e) => setValue(element.name, e.target.value)}
                        name={element.name}
                        id={element.name}
                        type={element.type}
                        blockWidth="sm"
                        fieldSize="lg"
                        inputRef={register({
                            required: t('form.error.required'),
                            pattern: {
                                value: element.pattern,
                                message: t(`form.error.${element.name}`),
                            },
                        })}
                    />
                </FormControlWrapper>
            ))}

            <ModalControl>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a>
                    <Text textSize="xxs">
                        <Link colorTheme="quaternary">
                            {t('login.forgottenPasswordLink')}
                        </Link>
                    </Text>
                </a>
            </ModalControl>

            {(accountErrorMessage || errorMessage) && (
                <Message
                    message={errorMessage}
                    type={errorMessage ? "error" : "warning"}
                    paddingLateral="sm"
                    marginTop="lg"
                    blockWidth="sm"
                    hasStaticWidth={false}
                    data-cy="error-login-message"
                    icon={accountErrorMessage ? "CautionBoldIcon" : null}
                    alignText="center"
                >
                    <Text textSize="xs" align="left">
                        {accountErrorMessage}
                    </Text>
                </Message>
            )}

            <ButtonGroup marginTop="sm">
                <button type="submit" className="login-button">
                    <DaButton
                        buttonSize="lg"
                        gradient="theme"
                        colorTheme="quaternary"
                        icon={<ArrowLongRightIcon />}
                    >
                        {t('login.button')}
                    </DaButton>
                </button>
            </ButtonGroup>
        </form>
    );
};

LoginForm.propTypes = {
    setValue: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    /**
     * errors passed by react-hook-form for each field (necessary if you want errors management)
     */
    errors: PropTypes.object,
    /**
     * error message received from Api if request fails
     */
    errorMessage: PropTypes.string,
    /**
     * error message received from Api if account is not valid
     */
    notValidatedMessage: PropTypes.object,
};

LoginForm.defaultProps = {
    errors: {},
    errorMessage: '',
};

export default withTranslation('common')(LoginForm);
