// Call Donation Reports
import { apiAuth } from '../../utils/hooks';

const getDonationReports = (params, mode) => {
    return apiAuth(mode)
        .get(`/donation/statistics/reports`, { params: params })
        .then((data) => data)
        .catch((error) => {
            throw error;
        });
};

export { getDonationReports };
