import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { fr, en } from '.';

const options = {
    interpolation: {
        escapeValue: false,
    },
    debug: true,
    resources: {
        fr: {
            common: fr.common,
            countries: fr.countries,
        },
        en: {
            common: en.common,
            countries: en.countries,
        },
    },
    returnObjects: true,
    fallbackLng: 'en',
    ns: ['common','countries'],
    defaultNS: 'common',
};

i18n.use(LanguageDetector).use(initReactI18next).init(options);

export default i18n;


