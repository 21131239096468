import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import {
    Box,
    InternalGrid,
    Link,
    MenuHamburger,
    OrganizationIcon,
    Sidebar as SidebarBO,
    SidebarItem,
    SidebarList,
    SidebarMenu,
    SidebarMenuCategory,
    ShopIcon,
    Text,
    Topbar,
    SupportIcon,
} from '@paygreen/paygreen-ui';
import { IsDesktop } from '../../utils/tools';
import Can from '../../utils/rules/Can';
import IconByName from '../../utils/IconByName';
import { useUser, usePendingPartnerships } from '../../context';
import { LogoPaygreen, CharityKitBadge } from '../../assets/logos';
import { ModeDisplay } from '../index';
import BadgeAlert from './BadgeAlert';

const ContactContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
`;

const SideBar = (props) => {
    const { t, isOpen, toggleIsOpen } = props;
    const { user } = useUser();
    const { pendingPartnershipsData } = usePendingPartnerships();

    const SidebarItemWrapper = (props) => {
        const linksProps = {
            colorPallet: props.isInsideList ? 'wab' : 'theme',
            colorWab: 'grey60',
            colorTheme: 'quaternary',
            hasUnderline: false,
        };

        return (
            <RouterLink
                to={props.to}
                onClick={IsDesktop() ? null : () => toggleIsOpen(!isOpen)}
                value={props.value}
            >
                <SidebarItem
                    isActive={window.location.pathname === props.to}
                    paddingLateral="md"
                    colorTheme="quaternary"
                >
                    {!props.isInsideList && (
                        <IconByName
                            name={props.icon}
                            iconSize="lg"
                            colorTheme="quaternary"
                        />
                    )}

                    <Link {...linksProps}>
                        {t(`sidebar.${props.translationId}`)}

                        <BadgeAlert
                            colorTheme="quaternary"
                            value={props.value}
                        />
                    </Link>
                </SidebarItem>
            </RouterLink>
        );
    };

    return (
        <SidebarBO>
            <Topbar className="hideOnDesktop">
                <InternalGrid childrenMarginLateral="sm" alignItems="center">
                    <CharityKitBadge />

                    <ModeDisplay hasDot={false} />
                </InternalGrid>

                <MenuHamburger
                    arrowDirection="left"
                    colorPallet="wab"
                    isOpen={true}
                    onClick={() => toggleIsOpen(!isOpen)}
                />
            </Topbar>

            <Box margin="md" className="hideOnTablet">
                <RouterLink to="/">
                    <LogoPaygreen badge={<CharityKitBadge />} />
                </RouterLink>

                <ModeDisplay hasDot={false} justifyContent="flex-start" />
            </Box>

            <SidebarMenuCategory>
                <Can
                    condition={user && user.role}
                    perform="sidebar:charity"
                    yes={() => (
                        <>
                            <SidebarMenu>
                                <SidebarItemWrapper
                                    to="/"
                                    icon="MeterIcon"
                                    translationId="dashboardTitle"
                                />
                            </SidebarMenu>

                            <SidebarMenu>
                                <SidebarItemWrapper
                                    to="/donations"
                                    icon="CoinsIcon"
                                    translationId="charityDonationsTitle"
                                />
                            </SidebarMenu>

                            <SidebarMenu>
                                <SidebarItem // is necessary here because SidebarMenu automatically passes function to open/close SidebarList to SidebarItem from PG-UI only
                                    htmlTag="button"
                                    isOpen={true}
                                    paddingLateral="md"
                                    colorTheme="quaternary"
                                >
                                    <ShopIcon
                                        iconSize="lg"
                                        colorTheme="quaternary"
                                    />

                                    <Link
                                        hasUnderline={false}
                                        colorTheme="quaternary"
                                    >
                                        {t('sidebar.partnershipsTitle')}
                                    </Link>
                                </SidebarItem>

                                <SidebarList isOpen={true}>
                                    <SidebarItemWrapper
                                        to="/partnerships"
                                        translationId="myPartnershipsTitle"
                                        isInsideList
                                    />

                                    <SidebarItemWrapper
                                        to="/partnerships/pending-requests"
                                        translationId="pendingRequestsTitle"
                                        isInsideList
                                        value={
                                            pendingPartnershipsData &&
                                            pendingPartnershipsData.length
                                        }
                                    />

                                    <SidebarItemWrapper
                                        to="/partnerships/refused-requests"
                                        translationId="refusedRequestsTitle"
                                        isInsideList
                                    />

                                    <SidebarItemWrapper
                                        to="/partnerships/cancelled-partnerships"
                                        translationId="cancelledPartnershipsTitle"
                                        isInsideList
                                    />
                                </SidebarList>
                            </SidebarMenu>
                        </>
                    )}
                />

                <Can
                    condition={user && user.role}
                    perform="sidebar:shop"
                    yes={() => (
                        <>
                            <SidebarMenu>
                                <SidebarItemWrapper
                                    to="/"
                                    icon="MeterIcon"
                                    translationId="dashboardTitle"
                                />
                            </SidebarMenu>

                            <SidebarMenu>
                                <SidebarItemWrapper
                                    to="/donations"
                                    icon="CoinsIcon"
                                    translationId="shopDonationsTitle"
                                />
                            </SidebarMenu>

                            <SidebarMenu>
                                <SidebarItem // is necessary here because SidebarMenu automatically passes function to open/close SidebarList to SidebarItem from PG-UI only
                                    htmlTag="button"
                                    isOpen={true}
                                    paddingLateral="md"
                                    colorTheme="quaternary"
                                >
                                    <OrganizationIcon
                                        iconSize="lg"
                                        colorTheme="quaternary"
                                    />

                                    <Link
                                        hasUnderline={false}
                                        colorTheme="quaternary"
                                    >
                                        {t('sidebar.charitiesTitle')}
                                    </Link>
                                </SidebarItem>

                                <SidebarList isOpen={true}>
                                    <SidebarItemWrapper
                                        to="/partnerships"
                                        translationId="charityPartnersTitle"
                                        isInsideList
                                    />

                                    <SidebarItemWrapper
                                        to="/partnerships/pending-requests"
                                        translationId="pendingRequestsTitle"
                                        isInsideList
                                    />

                                    <SidebarItemWrapper
                                        to="/partnerships/refused-requests"
                                        translationId="refusedRequestsTitle"
                                        isInsideList
                                    />

                                    <SidebarItemWrapper
                                        to="/partnerships/cancelled-partnerships"
                                        translationId="cancelledPartnershipsTitle"
                                        isInsideList
                                    />

                                    <SidebarItemWrapper
                                        to="/partnerships/all-charities"
                                        translationId="charitiesAllTitle"
                                        isInsideList
                                    />
                                </SidebarList>
                            </SidebarMenu>
                        </>
                    )}
                />

                <Can
                    condition={user && user.role}
                    perform="sidebar:admin"
                    yes={() => (
                        <>
                            <SidebarMenu>
                                <SidebarItem // is necessary here because SidebarMenu automatically passes function to open/close SidebarList to SidebarItem from PG-UI only
                                    htmlTag="button"
                                    isOpen={true}
                                    paddingLateral="md"
                                    colorTheme="quaternary"
                                >
                                    <SupportIcon
                                        iconSize="lg"
                                        colorTheme="quaternary"
                                    />

                                    <Link
                                        hasUnderline={false}
                                        colorTheme="quaternary"
                                    >
                                        {t('sidebar.adminTitle')}
                                    </Link>
                                </SidebarItem>

                                <SidebarList isOpen={true}>
                                    <SidebarItemWrapper
                                        to="/admin/users"
                                        translationId="adminUsersTitle"
                                        isInsideList
                                    />

                                    {/* <SidebarItemWrapper // STAND BY
                                        to="/admin/my-users"
                                        translationId="adminMyUsersTitle"
                                        isInsideList
                                    /> */}
                                </SidebarList>
                            </SidebarMenu>
                        </>
                    )}
                />
            </SidebarMenuCategory>

            <ContactContainer>
                <Text
                    align="center"
                    textSize="xs"
                    colorWab="grey40"
                    marginBottom="lg"
                >
                    {t('sidebar.questions')}{' '}
                    <a href="mailto:serviceclient@paygreen.fr">
                        <Link
                            colorPallet="theme"
                            colorTheme="quaternary"
                            hasUnderline={false}
                        >
                            {t('login.helpLink')}
                        </Link>
                    </a>
                </Text>
            </ContactContainer>
        </SidebarBO>
    );
};

export default withTranslation('common')(SideBar);
