import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    ModalProvider,
    Modal,
    ModalControl,
    ModalContent,
    ModalHeader,
    ModalBody,
    Overlay,
    CrossIcon,
    Title,
} from '@paygreen/paygreen-ui';

const ModalBlock = ({
    blockWidth,
    children,
    button,
    hasCloseCross,
    isTitleRed,
    modalTitle,
    closeWithAction,
    openingCondition,
    paddingLateral,
}) => {
    const [isOpen, setIsOpen] = useState(openingCondition || false);

    const cross = (
        <CrossIcon htmlTag="button" iconSize="md" colorPallet="wab" />
    );

    useEffect(() => {
        setIsOpen(openingCondition);
    }, [openingCondition]);

    if (closeWithAction) {
        return (
            <>
                {button &&
                    React.cloneElement(button, {
                        onClick: () => setIsOpen(true),
                    })}

                <Modal isOpen={isOpen}>
                    <Overlay
                        gradient="theme"
                        colorTheme="quaternary"
                        opacityValue={60}
                        onClick={() => setIsOpen(false)}
                    />

                    <ModalContent blockWidth={blockWidth}>
                        {modalTitle ? (
                            <ModalHeader
                                paddingLateral={paddingLateral}
                                paddingTop="sm"
                            >
                                <Title
                                    textSize="md"
                                    colorPallet={isTitleRed ? 'status' : 'wab'}
                                    colorStatus="danger"
                                    colorWab="grey40"
                                >
                                    {modalTitle}
                                </Title>

                                {hasCloseCross &&
                                    React.cloneElement(cross, {
                                        onClick: () => setIsOpen(false),
                                    })}
                            </ModalHeader>
                        ) : null}

                        <ModalBody
                            paddingTop={modalTitle ? 'none' : 'sm'}
                            paddingBottom="xs"
                            paddingLateral={paddingLateral}
                        >
                            {children &&
                                React.cloneElement(children, {
                                    closeWithAction: closeWithAction,
                                    setIsModalOpen: setIsOpen,
                                })}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </>
        );
    } else {
        return (
            <ModalProvider>
                {button && <ModalControl>{button}</ModalControl>}

                <Modal isOpen={isOpen}>
                    <ModalControl>
                        <Overlay
                            gradient="theme"
                            colorTheme="quaternary"
                            opacityValue={60}
                        />
                    </ModalControl>

                    <ModalContent blockWidth={blockWidth}>
                        {modalTitle || hasCloseCross ? (
                            <ModalHeader
                                paddingLateral={paddingLateral}
                                paddingTop="sm"
                            >
                                {modalTitle ? (
                                    <Title
                                        colorPallet={
                                            isTitleRed ? 'status' : 'wab'
                                        }
                                        textSize="md"
                                        colorStatus="danger"
                                        colorWab="grey40"
                                    >
                                        {modalTitle}
                                    </Title>
                                ) : null}

                                {hasCloseCross ? (
                                    <ModalControl>
                                        <CrossIcon
                                            htmlTag="button"
                                            iconSize="md"
                                            colorPallet="wab"
                                        />
                                    </ModalControl>
                                ) : null}
                            </ModalHeader>
                        ) : null}

                        <ModalBody
                            paddingTop={
                                modalTitle || hasCloseCross ? 'none' : 'sm'
                            }
                            paddingBottom="xs"
                            paddingLateral={paddingLateral}
                        >
                            {children}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </ModalProvider>
        );
    }
};

ModalBlock.propTypes = {
    blockWidth: PropTypes.string,
    button: PropTypes.node,
    modalTitle: PropTypes.string,
    hasCloseCross: PropTypes.bool,
    isTitleRed: PropTypes.bool,
    /**
     * Indicate to parent that the modal need action after close
     */
    closeWithAction: PropTypes.func,
    openingCondition: PropTypes.bool,
    paddingLateral: PropTypes.string,
};

ModalBlock.defaultProps = {
    blockWidth: 'sm',
    hasCloseCross: true,
    isTitleRed: false,
    paddingLateral: 'sm',
};

export default ModalBlock;
