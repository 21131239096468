import React, { useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Box, Card, InternalGrid, ThemeDefault } from '@paygreen/paygreen-ui';
import { getNumberFromMediaQuery, timestampToDate } from '../../../utils/tools';
import { partnershipStatus } from '../../../utils/constants';
import { useNodeDimensions } from '../../../utils/hooks';
import { useUser } from '../../../context';
import { ErrorBoundary } from '../../../components';
import PartnershipCardHeader from './PartnershipCardHeader';
import PartnershipDispatchData from './PartnershipDispatchData';
import CornerRemoveFromPartnershipGroup from './CornerRemoveFromPartnershipGroup';

const BorderBottom = styled.div`
    height: ${(props) => props.theme.radius.lg};
    border-radius: 0 0 ${(props) => props.theme.radius.md}
        ${(props) => props.theme.radius.md};

    background-color: ${(props) => props.theme.color.quaternary.light};
`;

export const PartnershipCardGrid = (props) => {
    // we create a ref to detect if component is mounted
    const isMounted = useRef(false);

    // we create a ref using a special hook to get width from InternalGrid component
    const [ref, { width }] = useNodeDimensions(isMounted.current);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <div ref={ref}>
            <InternalGrid
                displayType="grid"
                gridGap="lg"
                justifyItems="stretch"
                childrenMarginTop="xs"
                childrenMarginBottom="xs"
                gridTemplateColumns={
                    width > getNumberFromMediaQuery(ThemeDefault.screen.max.lg)
                        ? 'repeat(3, 30%)'
                        : width >
                          getNumberFromMediaQuery(ThemeDefault.screen.max.md)
                        ? 'repeat(2, 45%)'
                        : '1fr'
                }
            >
                {props.children}
            </InternalGrid>
        </div>
    );
};

const PartnershipCard = ({
    children,
    data,
    isLastPartnership,
    partnershipStatus,
    partnershipGroupStatus,
    partnershipsGroupName,
    removeFromPartnershipGroup,
    isInPartnershipGroupCard,
    blockWidth,
    hasBorderBottom,
}) => {
    const { user } = useUser();

    const formattedData = useMemo(
        () => PartnershipDispatchData(data, partnershipStatus, user),
        [data, partnershipStatus, user],
    );

    return (
        <ErrorBoundary>
            <Card
                hasBackground={true}
                blockWidth={blockWidth}
                radiusSize="sm"
                colorTheme="quaternary"
                isShadowWab={true}
            >
                {isInPartnershipGroupCard ? (
                    <CornerRemoveFromPartnershipGroup
                        removeFromPartnershipGroup={removeFromPartnershipGroup}
                        isLastPartnership={isLastPartnership}
                        partnerName={formattedData.partnerName}
                        partnershipsGroupName={partnershipsGroupName}
                    />
                ) : null}

                <Box marginRight="sm">
                    <PartnershipCardHeader
                        partnerName={formattedData.partnerName}
                        partnershipDate={timestampToDate(
                            formattedData.partnershipDate,
                        )}
                        partnershipGroupStatus={partnershipGroupStatus}
                        partnershipStatus={partnershipStatus}
                        isInPartnershipGroupCard={isInPartnershipGroupCard}
                        partnershipImage={formattedData.picture1}
                    />
                </Box>

                {children}

                {hasBorderBottom ? <BorderBottom /> : null}
            </Card>
        </ErrorBoundary>
    );
};

PartnershipCard.propTypes = {
    blockWidth: PropTypes.string,
    hasBorderBottom: PropTypes.bool,
    removeFromPartnershipGroup: PropTypes.func,
    partnershipGroupStatus: PropTypes.string,
    partnershipsGroupName: PropTypes.string,
    partnershipStatus: PropTypes.oneOf(Object.values(partnershipStatus)),
    isInPartnershipGroupCard: PropTypes.bool,
    isLastPartnership: PropTypes.bool,
    data: PropTypes.object,
};

PartnershipCard.defaultProps = {
    blockWidth: 'md',
    hasBorderBottom: true,
    isInPartnershipGroupCard: false,
    isLastPartnership: false,
};

export default withTranslation('common')(PartnershipCard);
