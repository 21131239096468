import React from 'react';
import PropTypes from 'prop-types';
import { SkeletonItem } from '@paygreen/paygreen-ui';

const SkeletonTextLine = ({ blockWidth, colorWab, isLoading, ...rest }) => {
    return (
        <SkeletonItem
            skeletonItemType="text"
            blockWidth={blockWidth}
            blockHeight="tiny"
            colorWab={colorWab}
            radiusSize="none"
            isLoading={isLoading}
            {...rest}
        />
    );
};

SkeletonTextLine.propTypes = {
    blockWidth: PropTypes.string,
    colorWab: PropTypes.string,
    isLoading: PropTypes.bool,
};

SkeletonTextLine.defaultProps = {
    blockWidth: 'auto',
    colorWab: 'grey20',
    isLoading: false,
};

export default SkeletonTextLine;
