import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    DaSelect,
    InternalGrid,
    RadioGroup,
    Text,
    DatePicker,
    ScheduleIcon,
} from '@paygreen/paygreen-ui';
import {
    FormControlWrapper,
    ControlledDaInput,
    Message,
} from '../../../../components';
import { formatCentsMoney } from '../../../../utils/tools';
import dayjs from '../../../../utils/dayjsHelper';

// to temporarily allow datepicker to be displayed above input in modal, will be fixed in PG-UI
export const DisplayAbove = styled.span`
    div {
        div {
            & > :last-child {
                top: auto;
                bottom: 100%;
            }
        }
    }
`;

const SpaceManagementForm = ({
    t,
    register,
    errors,
    setValue,
    onSubmit,
    children,
    defaultValues,
    mode,
    disabledInputs,
    spacePrices,
    watch,
    control,
    accountData,
    hasUnlogWarning,
    setHasUnlogWarning,
}) => {
    // All options for subscription prices selector and modes radio
    const selectPricesOptions = [
        { value: '', text: t('admin.user.choosePrice') },
        ...spacePrices?.map((option, index) => {
            return {
                value: option.price.toString(),
                text: formatCentsMoney(option.price, true),
            };
        }),
    ];

    const radioSubscriptionOptions = [
        {
            label: t('admin.user.subscriptionMode.fixed'),
            value: 'fixed',
        },
        {
            label: t('admin.user.subscriptionMode.usage'),
            value: 'usage',
        },
    ];

    const radioExpirationDateOptions = [
        {
            label: t('admin.user.expirationDateMode.now'),
            value: 'NOW',
        },
        {
            label: t('admin.user.expirationDateMode.never'),
            value: 'NEVER',
        },
        {
            label: t('admin.user.expirationDateMode.custom'),
            value: 'CUSTOM',
        },
    ];

    const ExpirationDateInput = mode + 'KitExpirationDate';
    const ExpirationDateMode = mode + 'KitExpirationDateMode';
    const setupPriceInput = mode + 'KitSetupPrice';
    const subscriptionModeInput = mode + 'KitSubscriptionMode';
    const subscriptionPriceInput = mode + 'KitSubscriptionPrice';

    // we watch value of subscription mode input to display or not subscription price input and expirationDateMode to display or not custom date
    const watchMode = watch(subscriptionModeInput);
    const watchExpirationDateMode = watch(ExpirationDateMode);

    return (
        <form onSubmit={onSubmit} noValidate>
            <InternalGrid
                displayType="grid"
                gridGap="md"
                alignItems="stretch"
                justifyItems="start"
                gridTemplateColumns="1fr"
            >
                <div>
                    <FormControlWrapper
                        inputName={ExpirationDateMode}
                        errors={errors}
                        i18labelBase={`admin.user.${mode}KitStatus`}
                        marginTop="sm"
                        hasStaticWidth={false}
                        blockWidth="xs"
                    >
                        <InternalGrid
                            displayType="grid"
                            gridTemplateColumns="auto auto"
                            alignItems="baseline"
                            justifyItems="start"
                        >
                            <RadioGroup
                                name={ExpirationDateMode}
                                value={defaultValues[ExpirationDateMode]}
                                inputRef={register}
                                options={radioExpirationDateOptions}
                                onChange={(e) => {
                                    setValue('test', e.target.value);
                                    setHasUnlogWarning(true);
                                }}
                                disabled={disabledInputs}
                            />
                        </InternalGrid>
                    </FormControlWrapper>

                    {watchExpirationDateMode === 'CUSTOM' ? (
                        <FormControlWrapper
                            inputName={ExpirationDateInput}
                            errors={errors}
                            i18labelBase={`admin.user.${mode}KitStatus`}
                            hasStaticWidth={false}
                            blockWidth="xs"
                            hasLabel={false}
                        >
                            <DisplayAbove>
                                <DatePicker
                                    value={dayjs
                                        .unix(
                                            defaultValues[ExpirationDateInput],
                                        )
                                        .format('DD/MM/YYYY')}
                                    onChange={(date) => {
                                        setValue(ExpirationDateInput, date);
                                        setHasUnlogWarning(true);
                                    }}
                                    minimumDate={dayjs
                                        .unix(
                                            accountData?.[mode + 'Billing']
                                                ?.subscribedAt,
                                        )
                                        .format('DD/MM/YYYY')} // minimum date cannot be before sepa signature's date
                                    maximumDate="19/01/2038" // API cannot handle later date based on timestamp
                                    placeholder={'DD/MM/YYYY'}
                                    disabled={disabledInputs}
                                    icon={<ScheduleIcon />}
                                />
                            </DisplayAbove>
                        </FormControlWrapper>
                    ) : null}

                    {hasUnlogWarning && (
                        <Message
                            marginBottom="sm"
                            marginTop="sm"
                            alignText="left"
                            type="warning"
                            message={t(
                                'admin.user.expirationDateMode.unlogWarning',
                            )}
                        />
                    )}
                </div>

                {accountData?.[mode + 'Billing'].status === 'SIGNED' ? ( // setup price and subscription price can be updated only if sepa is signed
                    <>
                        <FormControlWrapper
                            inputName={setupPriceInput}
                            errors={errors}
                            i18labelBase={`admin.user.${mode}KitStatus`}
                            marginTop="sm"
                            hasStaticWidth={false}
                            blockWidth="xs"
                        >
                            <InternalGrid
                                displayType="grid"
                                gridTemplateColumns="auto auto"
                                alignItems="baseline"
                                justifyItems="start"
                            >
                                <ControlledDaInput
                                    inputName={setupPriceInput}
                                    control={control}
                                    defaultValue={
                                        defaultValues[setupPriceInput]
                                    }
                                    inputType="number"
                                    blockWidth="sm"
                                    onChange={(e) => {
                                        setValue(
                                            setupPriceInput,
                                            e.target.value,
                                        );
                                    }}
                                    min={0}
                                    step={0.01}
                                    minRules={!disabledInputs ? 0 : null}
                                    disabled={disabledInputs}
                                    errorMessage={t(`form.error.setupPrice`)}
                                    style={{ textAlign: 'right' }} //to align text inside input to the right
                                />

                                <Text textSize="xs" marginLateral="xs">
                                    €&nbsp;HT
                                </Text>
                            </InternalGrid>
                        </FormControlWrapper>

                        <FormControlWrapper
                            inputName={subscriptionModeInput}
                            errors={errors}
                            i18labelBase={`admin.user.${mode}KitStatus`}
                            marginTop="sm"
                        >
                            <RadioGroup
                                name={subscriptionModeInput}
                                value={defaultValues[subscriptionModeInput]}
                                inputRef={register}
                                options={radioSubscriptionOptions}
                                onChange={(e) => {
                                    setValue(
                                        subscriptionModeInput,
                                        e.target.value,
                                    );
                                }}
                                disabled={disabledInputs}
                            />
                        </FormControlWrapper>

                        {watchMode !== 'usage' && (
                            <FormControlWrapper
                                inputName={subscriptionPriceInput}
                                errors={errors}
                                i18labelBase={`admin.user.${mode}KitStatus`}
                                marginTop="sm"
                                hasStaticWidth={false}
                                blockWidth="xs"
                            >
                                <InternalGrid
                                    displayType="grid"
                                    gridTemplateColumns="auto auto"
                                    alignItems="baseline"
                                    justifyItems="start"
                                >
                                    <DaSelect
                                        name={subscriptionPriceInput}
                                        id={subscriptionPriceInput}
                                        inputRef={register}
                                        defaultValue={
                                            defaultValues[
                                                subscriptionPriceInput
                                            ]?.toString() || 0
                                        }
                                        options={selectPricesOptions}
                                        onChange={(e) => {
                                            setValue(
                                                subscriptionPriceInput,
                                                e.target.value,
                                            );
                                        }}
                                        blockWidth="md"
                                        disabled={disabledInputs}
                                        style={{ direction: 'rtl' }} //to align text inside selector to the right
                                    />

                                    <Text textSize="xs" marginLateral="xs">
                                        €&nbsp;HT
                                    </Text>
                                </InternalGrid>
                            </FormControlWrapper>
                        )}
                    </>
                ) : null}
            </InternalGrid>

            {children}
        </form>
    );
};

SpaceManagementForm.propTypes = {
    setValue: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    /**
     * errors passed by react-hook-form for each field (necessary if you want errors management)
     */
    errors: PropTypes.object,
    defaultValues: PropTypes.object,
    mode: PropTypes.oneOf(['charity', 'climate']),
    disabledInputs: PropTypes.bool,
    spacePrices: PropTypes.array,
    accountData: PropTypes.object,
    setHasUnlogWarning: PropTypes.func,
    hasUnlogWarning: PropTypes.bool,
};

SpaceManagementForm.defaultProps = {
    errors: {},
    hasUnlogWarning: false,
};

export default withTranslation('common')(SpaceManagementForm);
