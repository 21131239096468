import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    Card,
    DaButton,
    ButtonGroup,
    Text,
    Title,
} from '@paygreen/paygreen-ui';
import { IsBigDesktop } from '../../../utils/tools';

const ClimateCard = ({ t }) => (
    <Card
        blockWidth={IsBigDesktop() ? 'sm' : 'none'}
        hasBackground={true}
        colorPallet="theme"
        paddingBottom="sm"
        paddingTop="sm"
        paddingLateral="sm"
        shadowSize="none"
        data-cy="climateKit-card"
    >
        <Title colorPallet="theme" textSize="base" marginBottom="xs">
            {t('user.billing.charityCard.title')}
        </Title>

        <Text colorPallet="theme" textSize="sm">
            {t('user.billing.charityCard.charityText')}
        </Text>

        <ButtonGroup marginTop="sm">
            <a
                href="https://www.paygreen.io/climatekit/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <DaButton gradient="theme">
                    {t('user.billing.charityCard.charityLink')}
                </DaButton>
            </a>
        </ButtonGroup>
    </Card>
);

export default withTranslation('common')(ClimateCard);
