import React from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    Card,
    Text,
    ButtonGroup,
    DaButton,
    ArrowRoundTopLeftIcon,
} from '@paygreen/paygreen-ui';
import { H1Title } from '../../components/';
import Error404Animation from './Error404Animation';

const Error404 = ({ t }) => {
    return (
        <>
            <Helmet>
                <title>
                    {t('error404.metaTitle')} - {t('main.metaTitle')}
                </title>
            </Helmet>

            <Error404Animation colorTheme="quaternary" />

            <Card
                paddingLateral="sm"
                paddingTop="sm"
                paddingBottom="xs"
                isShadowWab={true}
            >
                <H1Title title={t('error404.title')} />

                <Text data-cy="404-text">{t('error404.text')}</Text>

                <ButtonGroup marginTop="sm" data-cy="404-button-back">
                    <Link to="/">
                        <DaButton
                            gradient="theme"
                            colorTheme="quaternary"
                            icon={<ArrowRoundTopLeftIcon />}
                        >
                            {t('main.backHome')}
                        </DaButton>
                    </Link>
                </ButtonGroup>
            </Card>
        </>
    );
};

export default withTranslation('common')(Error404);
