import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    useExpanded,
    useFilters,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
} from 'react-table';
import { withTranslation } from 'react-i18next';
import {
    DaInput,
    DaTable,
    DaTableBody,
    DaTableCell,
    DaTableHeadCell,
    DaTableHead,
    DaTableRow,
    EyeIcon,
    SearchIcon,
    Text,
    OutIcon,
    InternalGrid,
} from '@paygreen/paygreen-ui';
import { useQueryState } from 'react-router-use-location-state';
import {
    timestampToDateWithHour,
    IsBigDesktop,
    fixExternalUrl,
} from '../../../utils/tools';
import { statusSpaces, statusActivation } from '../../../utils/constants';
import {
    PaginationTable,
    StatusDisplayTable,
    StatusFilterTable,
    HideAndCopy,
} from '../../../components';
import { ClimateKitBadge, CharityKitBadge } from '../../../assets/logos';
import {
    activationStatusOptions,
    accountStatusOptions,
    shortcutName,
} from '../adminUsersUtils';
import useTeamMembers from '../hooks/useTeamMembers';

const accountTeamManagerMap = (am) => {
    return {
        value: am.idAccountTeam,
        text: shortcutName(am.displayName),
    };
};

const AdminUsersTable = ({
    t,
    data,
    fetchData,
    isLoading,
    pageCount: controlledPageCount,
}) => {
    const { teamSales, teamAMs, teamMembers, teamCSMs } = useTeamMembers();
    const [activationFilter, setActivationFilter] = useQueryState(
        'activation',
        '',
    );
    const [accountFilter, setAccountFilter] = useQueryState('account', '');
    const [commercialFilter, setCommercialFilter] = useQueryState('sales', '');
    const [customerSuccessFilter, setCustomerSuccessFilter] = useQueryState(
        'csm',
        '',
    );
    const [spacesFilter, setSpacesFilter] = useState('');
    const [activePageReset, setActivePageReset] = useState(false);
    const [useArrondi, setUseArrondi] = useQueryState('use-arrondi', '');
    const [useTree, setUseTree] = useQueryState('use-tree', '');

    /**
     * @description to apply correct values for each space query parameters based on values names
     * @param {string} value - one of CHARITY | CLIMATE | CLIMATECHARITY
     */
    const handleActivatedSpaces = useCallback(
        (value) => {
            setSpacesFilter(value);

            switch (value) {
                case 'CHARITY':
                    setUseArrondi('1');
                    setUseTree('');
                    break;
                case 'CLIMATE':
                    setUseArrondi('');
                    setUseTree('1');
                    break;
                case 'CLIMATECHARITY':
                    setUseArrondi('1');
                    setUseTree('1');
                    break;
                default:
                    setUseArrondi('');
                    setUseTree('');
            }
        },
        [setUseArrondi, setUseTree],
    );

    const iconProps = (condition) => {
        return {
            iconSize: 'sm',
            colorPallet: 'status',
            hasBackground: true,
            hasHover: condition,
            htmlTag: condition ? 'button' : 'span',
            style: condition
                ? null
                : {
                      filter: 'grayscale(1)',
                  },
        };
    };

    // needed to guarantee vertical alignment on tablet for last cell in table
    const actionCellboxProps = {
        style: {
            display: IsBigDesktop() ? null : 'flex',
            justifyContent: IsBigDesktop() ? null : 'flex-end',
            overflow: 'visible',
        },
    };

    /**
     *
     * @param {string|number} idAccountTeam
     * @returns {string|null} name of manager
     */
    const getNameIdAccountTeam = (idAccountTeam) => {
        const value = teamMembers.find(
            (tm) => Number(tm.idAccountTeam) === idAccountTeam,
        );
        return value ? value.displayName : null;
    };

    const columns = useMemo(
        () => [
            {
                Header: t('admin.users.table.organisationName'),
                id: 'organisation',
                accessor: (d) => [
                    d.organisationName,
                    fixExternalUrl(d.organisationWebsite),
                    d.client_id,
                ],
            },
            {
                Header: t('admin.users.table.subscriptionDate'),
                id: 'createdAt',
                accessor: (d) =>
                    timestampToDateWithHour(d.administrator.createdAt),
            },
            {
                Header: t('admin.users.table.activationDate'),
                accessor: 'accountEnabled',
                Filter: (props) => (
                    <StatusFilterTable
                        onChange={setActivationFilter}
                        setActivePageReset={setActivePageReset}
                        statusConstants={statusActivation}
                        i18TextBase="admin.users.table.activationStatusFilter"
                        defaultValue={activationFilter}
                        {...props}
                    />
                ),
            },
            {
                Header: t('admin.users.table.activatedSpaces'),
                id: 'spaces',
                accessor: (d) => [d.usesTree, d.usesArrondi],
                Filter: (props) => (
                    <StatusFilterTable
                        onChange={handleActivatedSpaces}
                        setActivePageReset={setActivePageReset}
                        statusConstants={statusSpaces}
                        i18TextBase="admin.users.table.spacesStatusFilter"
                        defaultValue={spacesFilter}
                        {...props}
                    />
                ),
            },
            {
                Header: t('admin.users.table.user'),
                id: 'user',
                accessor: (d) => [
                    d.administrator.firstname,
                    d.administrator.lastname,
                    d.administrator.phone,
                    d.administrator.email,
                ],
            },
            {
                Header: t('admin.users.table.accountManager'),
                accessor: 'manager',
                Filter: (props) => (
                    <StatusFilterTable
                        onChange={setAccountFilter}
                        setActivePageReset={setActivePageReset}
                        manualOptions={[
                            { value: '', text: t('admin.users.table.all') },
                        ].concat(teamAMs.map(accountTeamManagerMap))}
                        i18TextBase="matching.table.accountManager"
                        defaultValue={accountFilter}
                        {...props}
                    />
                ),
            },
            {
                Header: t('admin.users.table.commercial'),
                accessor: 'commercial',
                Filter: (props) => (
                    <StatusFilterTable
                        onChange={setCommercialFilter}
                        setActivePageReset={setActivePageReset}
                        manualOptions={[
                            { value: '', text: t('admin.users.table.all') },
                        ].concat(teamSales.map(accountTeamManagerMap))}
                        i18TextBase="matching.table.commercial"
                        defaultValue={commercialFilter}
                        {...props}
                    />
                ),
            },
            {
                Header: t('admin.users.table.csm'),
                accessor: 'customerSuccess',
                Filter: (props) => (
                    <StatusFilterTable
                        onChange={setCustomerSuccessFilter}
                        setActivePageReset={setActivePageReset}
                        manualOptions={[
                            { value: '', text: t('admin.users.table.all') },
                        ].concat(teamCSMs.map(accountTeamManagerMap))}
                        i18TextBase="matching.table.csm"
                        defaultValue={customerSuccessFilter}
                        {...props}
                    />
                ),
            },
            {
                Header: 'Actions',
            },
        ],
        [
            t,
            setActivePageReset,
            teamAMs,
            teamSales,
            teamCSMs,
            commercialFilter,
            setAccountFilter,
            setActivationFilter,
            setCustomerSuccessFilter,
            setCommercialFilter,
            accountFilter,
            activationFilter,
            spacesFilter,
            customerSuccessFilter,
            handleActivatedSpaces,
        ],
    );

    const defaultColumn = useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: ({ column: { filterValue, setFilter } }) => {
                return (
                    <DaInput
                        value={filterValue || ''}
                        onChange={(e) => {
                            setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                        }}
                        placeholder={t('admin.users.table.searchInput')}
                        fieldSize="sm"
                        icon={<SearchIcon />}
                    />
                );
            },
        }),
        [t],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageCount,
        setPageSize,
        gotoPage,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: {
                pageIndex: 0,
                pageSize: 25,
            },
            manualPagination: true,
            pageCount: controlledPageCount,
            manualSortBy: true,
            autoResetPage: false,
            // we ponctually override table internal state to resetPage index with component SelectColumnFilter when needed
            useControlledState: (state) => {
                if (activePageReset) {
                    return { ...state, pageIndex: 0 };
                } else {
                    return state;
                }
            },
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
    );

    useEffect(() => {
        fetchData({
            pageIndex,
            pageSize,
            activationFilter,
            useArrondi,
            useTree,
            accountFilter,
            commercialFilter,
            customerSuccessFilter,
        });
    }, [
        fetchData,
        pageIndex,
        pageSize,
        activationFilter,
        useArrondi,
        useTree,
        accountFilter,
        commercialFilter,
        customerSuccessFilter,
    ]);

    useEffect(() => {
        // we update spaces filter dynamically each time query params change is detected
        const getActivatedSpacesLabel = () => {
            switch (true) {
                case useTree === '1' && useArrondi === '1':
                    setSpacesFilter('CLIMATECHARITY');
                    break;
                case useArrondi === '1':
                    setSpacesFilter('CHARITY');
                    break;
                case useTree === '1':
                    setSpacesFilter('CLIMATE');
                    break;
                default:
                    setSpacesFilter('');
            }
        };

        getActivatedSpacesLabel();
    }, [useTree, useArrondi]);

    return (
        <InternalGrid
            displayType="grid"
            gridGap="md"
            gridTemplateColumns="1fr"
            blockPadding="none"
            justifyItems="stretch"
            hasStaticWidth={IsBigDesktop() ? false : true}
        >
            <DaTable
                {...getTableProps()}
                blockWidth="none"
                isLoading={isLoading}
                loadingRowNumber={30}
                loadingMainCellNumber={3}
            >
                {headerGroups.map((headerGroup) => (
                    <DaTableHead
                        blockTitle={t('table.title')}
                        {...headerGroup.getHeaderGroupProps()}
                    >
                        {headerGroup.headers.map((column, index) => (
                            <DaTableHeadCell
                                key={index}
                                label={column.render('Header')}
                            >
                                {[0, 2, 3, 5, 6, 7].includes(index)
                                    ? column.render('Filter')
                                    : null}
                            </DaTableHeadCell>
                        ))}
                    </DaTableHead>
                ))}

                <DaTableBody {...getTableBodyProps()}>
                    {!isLoading && data.length && page.length ? (
                        page.map((row, index) => {
                            prepareRow(row);

                            return (
                                <DaTableRow {...row.getRowProps()} key={index}>
                                    <DaTableCell
                                        label={t(
                                            'admin.users.table.organisationName',
                                        )}
                                        title={row.values.organisation[0]}
                                    >
                                        {row.values.organisation[0]}

                                        {row.values.organisation[1] && (
                                            <a
                                                href={
                                                    row.values.organisation[1]
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="hideOnMobile"
                                            >
                                                <OutIcon
                                                    marginLeft="sm"
                                                    title={
                                                        row.values
                                                            .organisation[1]
                                                    }
                                                    {...iconProps(true)}
                                                />
                                            </a>
                                        )}
                                    </DaTableCell>

                                    <DaTableCell
                                        label={t(
                                            'admin.users.table.subscriptionDate',
                                        )}
                                        isMain={false}
                                    >
                                        {row.values.createdAt}
                                    </DaTableCell>

                                    <DaTableCell
                                        label={t(
                                            'admin.users.table.activationDate',
                                        )}
                                        isMain={false}
                                    >
                                        <StatusDisplayTable
                                            status={row.values.accountEnabled}
                                            statusOptions={
                                                activationStatusOptions
                                            }
                                            i18TextBase="admin.users.table.activationStatusFilter"
                                        />
                                    </DaTableCell>

                                    <DaTableCell
                                        label={t(
                                            'admin.users.table.activatedSpaces',
                                        )}
                                        isMain={false}
                                    >
                                        <InternalGrid
                                            displayType="grid"
                                            gridGap="sm"
                                            gridTemplateColumns="auto auto"
                                        >
                                            {!!row.values.spaces[0] && (
                                                <ClimateKitBadge
                                                    hasArrow={false}
                                                    isShorten={true}
                                                    title={t(
                                                        'admin.users.table.spacesStatusFilter.CLIMATE.title',
                                                    )}
                                                />
                                            )}

                                            {!!row.values.spaces[1] && (
                                                <CharityKitBadge
                                                    isShorten={true}
                                                    title={t(
                                                        'admin.users.table.spacesStatusFilter.CHARITY.title',
                                                    )}
                                                />
                                            )}
                                        </InternalGrid>
                                    </DaTableCell>

                                    <DaTableCell
                                        label={t('admin.users.table.user')}
                                        isMain={false}
                                    >
                                        {row.values.user[0]}{' '}
                                        {row.values.user[1]}
                                        <InternalGrid
                                            displayType="grid"
                                            gridTemplateColumns="auto auto"
                                        >
                                            {row.values.user[2] && (
                                                <HideAndCopy
                                                    title={
                                                        t(
                                                            'admin.users.table.copyPhone',
                                                        ) + row.values.user[2]
                                                    }
                                                    hasHiddenText={false}
                                                    icon="PhoneIcon"
                                                    text={row.values.user[2]}
                                                />
                                            )}

                                            {row.values.user[3] && (
                                                <HideAndCopy
                                                    title={
                                                        t(
                                                            'admin.users.table.copyEmail',
                                                        ) + row.values.user[3]
                                                    }
                                                    hasHiddenText={false}
                                                    icon="LetterIcon"
                                                    text={row.values.user[3]}
                                                />
                                            )}
                                        </InternalGrid>
                                    </DaTableCell>

                                    <DaTableCell
                                        label={t(
                                            'admin.users.table.accountManager',
                                        )}
                                        isMain={false}
                                    >
                                        {row.values.manager ? (
                                            getNameIdAccountTeam(
                                                row.values.manager,
                                            )
                                        ) : (
                                            <StatusDisplayTable
                                                status="EMPTY"
                                                statusOptions={
                                                    accountStatusOptions
                                                }
                                                i18TextBase="admin.users.table.accountStatusFilter"
                                            />
                                        )}
                                    </DaTableCell>

                                    <DaTableCell
                                        label={t(
                                            'admin.users.table.commercial',
                                        )}
                                        isMain={false}
                                    >
                                        {row.values.commercial ? (
                                            getNameIdAccountTeam(
                                                row.values.commercial,
                                            )
                                        ) : (
                                            <StatusDisplayTable
                                                status="EMPTY"
                                                statusOptions={
                                                    accountStatusOptions
                                                }
                                                i18TextBase="admin.users.table.commercialStatusFilter"
                                            />
                                        )}
                                    </DaTableCell>

                                    <DaTableCell
                                        label={t('admin.users.table.csm')}
                                        isMain={false}
                                    >
                                        {row.values.customerSuccess ? (
                                            getNameIdAccountTeam(
                                                row.values.customerSuccess,
                                            )
                                        ) : (
                                            <StatusDisplayTable
                                                status="EMPTY"
                                                statusOptions={
                                                    accountStatusOptions
                                                }
                                                i18TextBase="admin.users.table.commercialStatusFilter"
                                            />
                                        )}
                                    </DaTableCell>

                                    <DaTableCell
                                        label="Actions"
                                        {...actionCellboxProps}
                                    >
                                        <RouterLink
                                            to={{
                                                pathname: `/admin/users/${row.values.organisation[2]}`,
                                            }}
                                        >
                                            <EyeIcon
                                                title={t(
                                                    'admin.users.table.titleDetails',
                                                )}
                                                {...iconProps(true)}
                                            />
                                        </RouterLink>
                                    </DaTableCell>
                                </DaTableRow>
                            );
                        })
                    ) : (
                        <Text
                            align="center"
                            marginTop="xl"
                            marginBottom="xl"
                            textSize="sm"
                        >
                            {t('noDataFound')}
                        </Text>
                    )}
                </DaTableBody>
            </DaTable>

            <PaginationTable
                setPageSize={setPageSize}
                pageCount={pageCount || 1}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                setActivePageReset={setActivePageReset}
                colorTheme="quaternary"
            />
        </InternalGrid>
    );
};

export default withTranslation('common')(AdminUsersTable);
