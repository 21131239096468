import React, { useState, useEffect } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { ButtonGroup, Message as UIMessage, Text } from '@paygreen/paygreen-ui';
import {
    ModalForm,
    ValidateButton,
    CancelButton,
    Loader,
    Message,
} from '../../components';
import { formatDateApi } from '../../utils/tools';
import dayjs from '../../utils/dayjsHelper';
import ExportDonationsForm from './ExportDonationsForm';
import { getDonationsExport } from './service';
import { CSVDownloader } from 'react-papaparse';

const ExportDonationsModal = ({
    t,
    setOpen,
    isOpen,
    startDate: donationsStartDate,
    endDate: donationsEndDate,
}) => {
    // we define maximum end date that will include donations in export (donations older than 14 days)
    const validEndDate = dayjs().subtract(14, 'day').format('DD/MM/YYYY');

    // we detect if at least, end date received is too late and adapt ux message for user
    const isTooLate =
        dayjs(validEndDate, 'DD/MM/YYYY').diff(
            dayjs(donationsEndDate, 'DD/MM/YYYY'),
        ) < 0;
    const messageColor = isTooLate ? 'warning' : 'default';

    const [startDate, setStartDate] = useState(donationsStartDate);
    const [endDate, setEndDate] = useState(donationsEndDate);

    const [isLoading, setIsLoading] = useState(false);
    const [errorExportMessage, setErrorExportMessage] = useState();
    const [donationsExportData, setDonationsExportData] = useState(null);

    // To update dates dynamically if dates are changed for donations table
    useEffect(() => {
        setStartDate(donationsStartDate);
        setEndDate(donationsEndDate);
    }, [donationsStartDate, donationsEndDate]);

    /**
     * GET request to get donations date on specific dates
     * @returns {object} - object with donations data
     */
    const getExports = () => {
        const params = {
            begin: formatDateApi(startDate),
            end: formatDateApi(endDate),
        };

        setIsLoading(true);

        getDonationsExport(params)
            .then((res) => {
                toast(
                    <Text colorPallet="theme" textSize="xs">
                        {t('form.toastSuccess.donationsExport')}
                    </Text>,
                );
                setDonationsExportData(res.data);
                setOpen(false);
                document.getElementsByClassName('csv-download')[0].click();
            })
            .catch((error) => {
                setErrorExportMessage(
                    error?.response?.data.detail +
                        ' / ' +
                        error?.response?.data.title,
                );
            })
            .finally(() => setIsLoading(false));
    };

    const cancelCloseModal = () => {
        setOpen(false);

        // we need the reset methods to wait until modal is closed
        setTimeout(() => {
            setErrorExportMessage();
            setStartDate(donationsStartDate);
            setEndDate(donationsEndDate);
        }, 500);
    };

    return (
        <ModalForm
            modalTitle={t('shopDonations.exports.modalTitle')}
            blockWidth="md"
            paddingLateral="sm"
            hasCloseCross={true}
            isOpen={isOpen}
            actionOnClose={() => cancelCloseModal()}
        >
            <ExportDonationsForm
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                endDate={endDate}
                startDate={startDate}
                errorMessage={errorExportMessage}
            >
                <Text textSize="sm" marginBottom="xs" marginTop="sm">
                    <Trans i18nKey="shopDonations.exports.exportsIntro">
                        You can export
                        <Text
                            htmlTag="strong"
                            colorPallet="theme"
                            textSize="sm"
                        >
                            {{
                                registered: t(
                                    'shopDonations.exports.registered',
                                ),
                            }}
                        </Text>
                        done in
                        <Text htmlTag="strong" textSize="sm">
                            {{
                                inProduction: t(
                                    'shopDonations.exports.inProduction',
                                ),
                            }}
                        </Text>
                    </Trans>
                </Text>

                <UIMessage hasStaticWidth={true} colorStatus={messageColor}>
                    <Text textSize="xs">
                        <Trans i18nKey="shopDonations.exports.14DaysMessage">
                            only donations
                            <Text
                                htmlTag="strong"
                                colorPallet="status"
                                colorStatus={messageColor}
                                textSize="xs"
                            >
                                {{
                                    more14Days: t(
                                        'shopDonations.exports.more14Days',
                                    ),
                                }}
                            </Text>
                            be included
                        </Trans>
                    </Text>
                </UIMessage>

                {errorExportMessage && (
                    <Message
                        message={errorExportMessage}
                        type="error"
                        marginTop="md"
                        marginBottom="md"
                        paddingLateral="lg"
                    />
                )}

                <ButtonGroup marginTop="sm" marginBottom="sm">
                    <CancelButton
                        buttonSize="md"
                        type="reset"
                        onClick={() => cancelCloseModal()}
                    >
                        {t('form.button.cancel')}
                    </CancelButton>

                    <CSVDownloader
                        filename={`CHARITYKIT_EXPORTS_${startDate}_${endDate}`}
                        data={donationsExportData}
                        type="button"
                        bom={true}
                        className="csv-download"
                    />

                    <ValidateButton
                        buttonSize="md"
                        isDisabled={isLoading}
                        icon="DownloadIcon"
                        onClick={getExports}
                    >
                        {t('form.button.export')}
                    </ValidateButton>
                </ButtonGroup>
            </ExportDonationsForm>

            {isLoading && (
                <Loader colorTheme="quaternary" isLoading={isLoading} />
            )}
        </ModalForm>
    );
};

ExportDonationsModal.propTypes = {
    /**
     * @description setOpen and isOpen are automatically received from ModalControl parent
     */
    setOpen: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
};

ExportDonationsModal.defaultProps = {
    isOpen: false,
};

export default withTranslation('common')(ExportDonationsModal);
