import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { DaInput, InternalGrid, SkeletonItem } from '@paygreen/paygreen-ui';
import { FormControlWrapper, ControlledDaInput } from '../../../components';
import regexForm from '../../../utils/regexForm';
import { IsMobile } from '../../../utils/tools';
import { accountInputsPatterns } from '../shared';

const UserCompanyEditForm = ({
    t,
    onSubmit,
    children,
    defaultValues,
    isLoading,
    setIsEditing,
}) => {
    const { register, errors, setValue, control } = useFormContext();

    const transversalInputProps = {
        errors: errors,
        i18labelBase: 'user',
        hasStaticWidth: IsMobile(),
    };

    const siretPlaceHolder = 'Ex: 123 456 789 12345';

    return (
        <form onSubmit={onSubmit} noValidate>
            <InternalGrid
                displayType="grid"
                gridGap="md"
                alignItems="stretch"
                justifyItems="stretch"
                gridTemplateColumns="1fr"
            >
                {accountInputsPatterns.map((element) =>
                    element.name !== 'organisationIdentificationNumber' ? (
                        <FormControlWrapper
                            key={element.name}
                            inputName={element.name}
                            {...transversalInputProps}
                            isRequired={element.name === 'organisationName'}
                        >
                            <SkeletonItem
                                isLoading={isLoading}
                                colorWab="grey10"
                            >
                                <DaInput
                                    onChange={(e) => {
                                        setIsEditing(true);
                                        setValue(element.name, e.target.value);
                                    }}
                                    name={element.name}
                                    id={element.name}
                                    type={element.type}
                                    defaultValue={defaultValues[element.name]}
                                    inputRef={register({
                                        required:
                                            element.name === 'organisationName'
                                                ? t('form.error.required')
                                                : null,
                                        pattern: {
                                            value: element.pattern,
                                            message: t(
                                                `form.error.${element.name}`,
                                            ),
                                        },
                                    })}
                                    blockWidth="lg"
                                    mask={
                                        element.name ===
                                        'organisationIdentificationNumber'
                                            ? '999 999 999 99999'
                                            : null
                                    }
                                />
                            </SkeletonItem>
                        </FormControlWrapper>
                    ) : (
                        <FormControlWrapper
                            key="organisationIdentificationNumber"
                            inputName="organisationIdentificationNumber"
                            {...transversalInputProps}
                        >
                            <SkeletonItem
                                isLoading={isLoading}
                                colorWab="grey10"
                            >
                                <ControlledDaInput
                                    inputPattern={regexForm.siret}
                                    inputName="organisationIdentificationNumber"
                                    inputType="text"
                                    control={control}
                                    defaultValue={
                                        defaultValues?.organisationIdentificationNumber ||
                                        ''
                                    }
                                    onChange={(e) => {
                                        setIsEditing(true);
                                        setValue(
                                            'organisationIdentificationNumber',
                                            e.target.value,
                                        );
                                    }}
                                    mask="999 999 999 99999"
                                    errorMessage={t(
                                        `form.error.organisationIdentificationNumber`,
                                    )}
                                    placeholder={siretPlaceHolder}
                                    blockWidth="lg"
                                />
                            </SkeletonItem>
                        </FormControlWrapper>
                    ),
                )}
            </InternalGrid>

            {children}
        </form>
    );
};

UserCompanyEditForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    defaultValues: PropTypes.object,
    setIsEditing: PropTypes.func,
    isLoading: PropTypes.bool,
};

UserCompanyEditForm.defaultProps = {
    isLoading: false,
};

export default withTranslation('common', 'countries')(UserCompanyEditForm);
