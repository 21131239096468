import React, { useState, useRef, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Redirect, useLocation } from 'react-router-dom';
import { ButtonGroup, CheckIcon, DaButton } from '@paygreen/paygreen-ui';
import { useAuth } from '../../context';
import { apiPublic } from '../../utils/hooks';
import { CardClassic, MainLogin, NewPasswordForm } from '../../components';
import ResetPasswordErrorMessage from './ResetPasswordErrorMessage';

const ResetPassword = ({ t }) => {
    const { checkLocalTokens } = useAuth();

    // we create a ref to detect if component is mounted
    const isMounted = useRef(false);

    const [isLoading, setIsLoading] = useState(true);
    const [isTokenSuccess, setIsTokenSuccess] = useState(false);
    const [isNewPasswordSuccess, setIsNewPasswordSuccess] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const urlParams = new URLSearchParams(useLocation().search);

    // initializing all react-hook-form values and methods
    const defaultValues = {
        newPassword: '',
        newPasswordVerify: '',
    };

    const { handleSubmit, register, errors, setValue, watch } = useForm({
        defaultValues: defaultValues,
        mode: 'onTouched',
        reValidateMode: 'onChange',
    });

    // creating a special ref for password input to compare password and passwordVerify inputs
    const password = useRef({});
    password.current = watch('newPassword', '');

    useEffect(() => {
        register({ name: 'newPassword' });
        register({ name: 'newPasswordVerify' });
    }, [register]);

    /**
     * Post request to API to change user's password
     * @param {object} newPassword - An object containing new password and validation token of the user
     */
    const resetPassword = (newPassword) => {
        const finalPasswordObject = {
            token: urlParams.get('token'),
            password: newPassword,
        };

        apiPublic()
            .patch('/password/change', finalPasswordObject)
            .then(() => {
                isMounted.current && setIsNewPasswordSuccess(true);
            })
            .catch(() => {
                if (isMounted.current) {
                    setErrorMessage(t('resetPassword.wrongTokenMessage'));
                    setIsNewPasswordSuccess(false);
                }
            })
            .finally(() => isMounted.current && setIsLoading(false));
    };

    const submitForm = (values) => {
        isMounted.current && setIsLoading(true);
        resetPassword(values.newPassword);
    };

    /**
     * Get request to API to validate token before password reset
     */
    const validateToken = () => {
        if (urlParams.get('token')) {
            apiPublic()
                .get(`/password/check?token=${urlParams.get('token')}`)
                .then(() => {
                    if (isMounted.current) {
                        setIsTokenSuccess(true);
                    }
                })
                .catch(
                    () =>
                        isMounted.current &&
                        setErrorMessage(t('resetPassword.wrongTokenMessage')),
                )
                .finally(() => isMounted.current && setIsLoading(false));
        } else if (isMounted.current) {
            setErrorMessage(t('validation.missingTokens'));
            setIsLoading(false);
        }
    };

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(validateToken, []);

    if (isNewPasswordSuccess) {
        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: {
                        status: 'success',
                        messageType: 'newPassword',
                    },
                }}
            />
        );
    } else if (checkLocalTokens() === 'valid') {
        return <Redirect to="/" />;
    }

    return (
        <MainLogin
            helmetTitle={
                t('resetPassword.metaTitle') + ' - ' + t('main.metaTitle')
            }
            isValidation={true}
            isLoading={isLoading}
        >
            {!isLoading && (
                <CardClassic
                    blockWidth="md"
                    paddingLateral="lg"
                    paddingTop="md"
                    paddingBottom="md"
                    title={
                        !isTokenSuccess
                            ? t('resetPassword.cardWrongTokenTitle')
                            : t('resetPassword.cardCreatePasswordTitle')
                    }
                    titleColorWab="grey30"
                    titleAlign="center"
                    titleSize="md"
                    isShadowWab={true}
                >
                    {!isTokenSuccess ? (
                        <ResetPasswordErrorMessage
                            type="wrongToken"
                            errorMessage={errorMessage}
                        />
                    ) : isNewPasswordSuccess === undefined ? (
                        <NewPasswordForm
                            errors={errors}
                            setValue={setValue}
                            register={register}
                            onSubmit={handleSubmit(submitForm)}
                            password={password}
                        >
                            <ButtonGroup marginTop="sm">
                                <button type="submit">
                                    <DaButton
                                        buttonSize="lg"
                                        gradient="theme"
                                        colorTheme="quaternary"
                                        icon={<CheckIcon />}
                                    >
                                        {t('resetPassword.validateButton')}
                                    </DaButton>
                                </button>
                            </ButtonGroup>
                        </NewPasswordForm>
                    ) : (
                        <ResetPasswordErrorMessage
                            type="wrongPassword"
                            errorMessage={errorMessage}
                        />
                    )}
                </CardClassic>
            )}
        </MainLogin>
    );
};

export default withTranslation('common')(ResetPassword);
