import React, { createContext } from 'react';
import { setAuthLocalStorage, UseRefreshToken } from '../utils/authentication';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const {
        checkLocalTokens,
        localStorageGlobal,
        localConfig,
        updateAuthInformations,
        autoRefreshToken,
        getSdk,
    } = UseRefreshToken();

    return (
        <AuthContext.Provider
            value={{
                checkLocalTokens,
                updateAuthInformations,
                setAuthLocalStorage,
                localStorageGlobal,
                localConfig,
                autoRefreshToken,
                getSdk,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
