import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    Box,
    Corner,
    DaSelect,
    PlusIcon,
    Popin,
    Text,
} from '@paygreen/paygreen-ui';
import { truncateTooLongName } from '../../../utils/tools';
import { partnershipGroupStatus as partnershipGroupStatusEnum } from '../../../utils/constants';

const CornerAddToPartnershipGroup = ({
    t,
    addToPartnershipGroup,
    partnershipId,
    partnershipGroupStatus,
    partnershipsGroupData,
}) => {
    // we create a selector for all partnershipGroups availables for one entity, a group is disabled (full) if it contains already 3 partnerships.
    let partnershipGroupOptions = [
        {
            value: '',
            text: t('partnershipGroupPopin.partnershipGroupOptionsDefault'),
        },
    ];
    partnershipsGroupData &&
        partnershipsGroupData.forEach((option) => {
            if (!option.partnershipIds.includes(partnershipId)) {
                partnershipGroupOptions.push({
                    value: option.externalId,
                    text: `${
                        truncateTooLongName(option.externalId, 30) + // in case externalId is too long we truncate in selector to preserve layout
                        (option.partnershipIds.length === 3
                            ? t('ShopPartnerships.charityPartners.fullGroup')
                            : '')
                    }`,
                    disabled: option.partnershipIds.length === 3,
                });
            }
        });

    const resetDaSelect = () => {
        const select_box = document.getElementById(partnershipId);
        select_box.selectedIndex = '0';
    };

    return (
        <>
            {partnershipGroupStatus && partnershipGroupStatus !== 'all' ? (
                <Corner
                    label={<PlusIcon />}
                    position="right"
                    colorPallet="status"
                    colorStatus="success"
                    radiusSize="sm"
                    colorStyle="light"
                >
                    <Popin align="topRight">
                        <Box margin="md">
                            <Text
                                marginTop="xs"
                                marginBottom="xs"
                                textSize="sm"
                                colorWab="grey30"
                            >
                                <strong>
                                    {t('partnershipGroupPopin.label')}
                                </strong>
                            </Text>

                            <DaSelect
                                id={partnershipId}
                                defaultValue=""
                                options={partnershipGroupOptions}
                                fieldSize="sm"
                                blockWidth="sm"
                                onChange={(e) => {
                                    addToPartnershipGroup(
                                        e.target.value,
                                        partnershipId,
                                    );
                                    resetDaSelect();
                                }}
                            />
                        </Box>
                    </Popin>
                </Corner>
            ) : null}
        </>
    );
};

CornerAddToPartnershipGroup.propTypes = {
    addToPartnershipGroup: PropTypes.func.isRequired,
    partnershipGroupStatus: PropTypes.oneOf(
        Object.values(partnershipGroupStatusEnum),
    ).isRequired,
    partnershipsGroupData: PropTypes.array.isRequired,
    partnershipId: PropTypes.string.isRequired,
};

export default withTranslation('common')(CornerAddToPartnershipGroup);
