import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    ButtonGroup,
    Message,
    ModalControl,
    ModalProvider,
    Text,
} from '@paygreen/paygreen-ui';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useAPIError } from '../../../context';
import { billingDisplayModes } from '../../../utils/constants';
import { EditButton } from '../../../components';
import { apiAuth } from '../../../utils/hooks';
import UserCompanySepaModal from './UserCompanySepaModal';
import UserDisplayBillingData from './UserDisplayBillingData';

const StripeKey = process.env.REACT_APP_STRIPE_KEY;
const StripeAccountid = process.env.REACT_APP_STRIPE_ACCOUNT_ID;

const UserCompanyBillingSepa = ({ t, account, hasSepaFormDisplayed }) => {
    const { addError } = useAPIError();
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
        const getStripeData = async () => {
            return await loadStripe(StripeKey, {
                stripeAccount: StripeAccountid,
            });
        };

        setStripePromise(getStripeData());
    }, []);

    // we create a ref to detect if component is mounted
    const isMounted = useRef(false);

    const [ibanData, setIbanData] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    /**
     * GET request to API to get user's sepa infos
     * @returns {object} - object with all billing adress data
     */
    const getAccountIban = useCallback(
        (client_id) => {
            setIsLoading(true);

            apiAuth()
                .get(`/account/${client_id}/mandates/charity`)
                .then((res) => {
                    if (isMounted.current) {
                        setIbanData(res.data.last4);
                    }
                })
                .catch((error) => {
                    // This is normal, it possible to don't have billingAddress
                    if (
                        isMounted.current &&
                        error?.response?.data.status !== 404
                    ) {
                        addError(
                            error?.response?.data.detail,
                            error?.response?.data.status,
                        );
                    }
                })
                .finally(() => isMounted.current && setIsLoading(false));
        },
        [addError],
    );

    useEffect(() => {
        isMounted.current = true;
        if (account?.client_id) {
            getAccountIban(account?.client_id);
        }
        return () => {
            isMounted.current = false;
        };
    }, [getAccountIban, account.client_id]);

    return (
        <>
            {account?.usesTree === '0' &&
            account?.climateBilling?.unsubscribedAt === '0' ? (
                <Message colorStatus="warning">
                    <Text textSize="xs">{t('user.company.deactivated')}</Text>
                </Message>
            ) : (
                <UserDisplayBillingData
                    data={{
                        iban: ibanData,
                        expirationDate: account.expirationDate,
                    }}
                    isLoading={isLoading}
                    mode={billingDisplayModes.iban}
                >
                    <ModalProvider>
                        <ButtonGroup>
                            <ModalControl hasOnClickControl={true}>
                                <EditButton buttonSize="sm" buttonStyle="line">
                                    {ibanData
                                        ? t('form.button.replace')
                                        : t('form.button.sign')}
                                </EditButton>
                            </ModalControl>
                        </ButtonGroup>

                        {stripePromise ? (
                            <Elements stripe={stripePromise}>
                                <ModalControl>
                                    <UserCompanySepaModal
                                        setIsLoading={setIsLoading}
                                        isLoading={isLoading}
                                        getAccountIban={getAccountIban}
                                        hasSepaFormDisplayed={
                                            hasSepaFormDisplayed
                                        }
                                    />
                                </ModalControl>
                            </Elements>
                        ) : null}
                    </ModalProvider>
                </UserDisplayBillingData>
            )}
        </>
    );
};

UserCompanySepaModal.propTypes = {
    account: PropTypes.object,
    hasSepaFormDisplayed: PropTypes.bool,
};

UserCompanySepaModal.defaultProps = {
    hasSepaFormDisplayed: false,
};

export default withTranslation('common')(UserCompanyBillingSepa);
