import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import {
    Box,
    ButtonGroup,
    Card,
    InternalGrid,
    MenuClose,
    Text,
} from '@paygreen/paygreen-ui';
import { partnershipStatus } from '../../../utils/constants';
import { timestampToDate } from '../../../utils/tools';
import { SimpleActionButton } from '../../../components';
import { useUser } from '../../../context';
import PartnershipCardHeader from './PartnershipCardHeader';
import PartnershipDispatchData from './PartnershipDispatchData';

const Wrapper = styled.div`
    overflow: hidden;
`;

const Wrapped = styled.div`
    &.example-enter {
        opacity: 0;
        transform: translateY(-100%);
    }

    &.example-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: 300ms ease-in;
    }

    &.example-exit {
        opacity: 1;
        transform: translateY(0);
    }

    &.example-exit-active {
        opacity: 0;
        transform: translateY(-100%);
        transition: 300ms ease-out;
    }
`;

const PartnershipCollapsibleCard = ({
    t,
    blockWidth,
    children,
    data,
    mainButton,
    secondaryButton,
    partnershipStatus,
    partnershipGroupStatus,
}) => {
    const { user } = useUser();

    const [isOpen, toggleIsOpen] = useState(false);

    const formattedData = useMemo(
        () => PartnershipDispatchData(data, partnershipStatus, user),
        [data, partnershipStatus, user],
    );

    return (
        <Card
            hasBackground={true}
            blockWidth={blockWidth}
            radiusSize="sm"
            colorTheme="quaternary"
            isShadowWab={true}
        >
            <PartnershipCardHeader
                partnerName={formattedData.partnerName}
                partnershipDate={timestampToDate(formattedData.partnershipDate)}
                partnershipStatus={partnershipStatus}
                partnerFieldOfAction={
                    formattedData.partnerFieldOfAction || 'OTHER'
                }
                partnershipGroupStatus={partnershipGroupStatus}
                partnershipImage={formattedData.picture1}
            />

            {children}

            <Wrapper>
                <CSSTransition
                    classNames="example"
                    in={isOpen}
                    timeout={200}
                    unmountOnExit
                >
                    <Wrapped>
                        <Box marginLeft="md" marginRight="md" marginBottom="md">
                            {partnershipStatus &&
                                formattedData.partnerFieldOfAction && (
                                    <Text
                                        textSize="xxs"
                                        colorPallet="theme"
                                        colorTheme="quaternary"
                                        hasUppercase={true}
                                        paddingBottom="xs"
                                    >
                                        {t(
                                            `charity.edit.fieldOfActionValues.${formattedData.partnerFieldOfAction}`,
                                        )}
                                    </Text>
                                )}

                            {formattedData.partnerDescription && (
                                <Text textSize="xs">
                                    {formattedData.partnerDescription}
                                </Text>
                            )}

                            <ButtonGroup marginTop="xs">
                                {formattedData.partnerUrl && (
                                    <a
                                        href={formattedData.partnerUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <SimpleActionButton>
                                            {t('partnershipButtons.website')}
                                        </SimpleActionButton>
                                    </a>
                                )}

                                {mainButton && mainButton}

                                {secondaryButton && secondaryButton}
                            </ButtonGroup>
                        </Box>
                    </Wrapped>
                </CSSTransition>
            </Wrapper>

            <InternalGrid justifyContent="center">
                <MenuClose
                    onClick={() => toggleIsOpen(!isOpen)}
                    isOpen={isOpen}
                    colorTheme="quaternary"
                />
            </InternalGrid>
        </Card>
    );
};

PartnershipCollapsibleCard.propTypes = {
    blockWidth: PropTypes.string,
    partnershipGroupStatus: PropTypes.string,
    partnershipStatus: PropTypes.oneOf(Object.values(partnershipStatus)),
    data: PropTypes.object,
    mainButton: PropTypes.node,
    secondaryButton: PropTypes.node,
};

PartnershipCollapsibleCard.defaultProps = {
    blockWidth: 'md',
};

export default withTranslation('common')(PartnershipCollapsibleCard);
