import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    InternalGrid,
    Text,
    ThemeDefault,
    SkeletonItem,
} from '@paygreen/paygreen-ui';
import { IbanElement } from '@stripe/react-stripe-js';
import dayjs from '../../../utils/dayjsHelper';
import { FormControlWrapper } from '../../../components';
import '../../../assets/css/IbanFormStyles.css';

// Custom styling can be passed as options when creating an Element.
const ibanStyle = {
    base: {
        color: ThemeDefault.wab.grey40,
        fontSize: '16px',
        '::placeholder': {
            color: ThemeDefault.wab.grey40,
        },
        ':-webkit-autofill': {
            color: ThemeDefault.wab.grey40,
        },
    },
    invalid: {
        color: ThemeDefault.status.danger.main,
        iconColor: ThemeDefault.status.danger.main,
        ':-webkit-autofill': {
            color: ThemeDefault.status.danger.main,
        },
    },
};

const IbanElementOptions = {
    supportedCountries: ['SEPA'],
    placeholderCountry: 'FR',
    style: ibanStyle,
};

const UserCompanySepaForm = ({
    t,
    errors,
    onSubmit,
    ready,
    children,
    clearErrors,
}) => {
    const textProps = {
        textSize: 'sm',
        colorWab: 'grey50',
    };

    let oneMonthLater = dayjs();
    oneMonthLater = oneMonthLater.add(1, 'month');

    return (
        <>
            <Text {...textProps}>{t('user.company.SEPAText.intro')}</Text>

            <ul>
                <li style={{ color: `${ThemeDefault.wab.grey50}` }}>
                    <Text {...textProps}>
                        <Trans i18nKey="user.company.SEPAText.monthlyBilling">
                            de vos factures mensuelles (à partir du
                            <Text htmlTag="strong" {...textProps}>
                                {{
                                    date: oneMonthLater.format('LL'),
                                }}
                            </Text>
                            ).
                        </Trans>
                    </Text>
                </li>

                <li style={{ color: `${ThemeDefault.wab.grey50}` }}>
                    <Text {...textProps}>
                        {t('user.company.SEPAText.charityContribution')}
                    </Text>
                </li>
            </ul>

            <form onSubmit={onSubmit} noValidate>
                <InternalGrid
                    displayType="grid"
                    gridGap="md"
                    alignItems="stretch"
                    justifyItems="stretch"
                    gridTemplateColumns="1fr"
                >
                    <FormControlWrapper
                        hasStaticWidth={true}
                        inputName="iban"
                        errors={errors}
                        i18labelBase="user.company"
                        hasLongMessage={true}
                    >
                        {ready ? (
                            <IbanElement
                                options={IbanElementOptions}
                                onChange={() => clearErrors()}
                            />
                        ) : (
                            <SkeletonItem
                                blockHeight="xxs"
                                blockWidth="md"
                                isLoading={true}
                                skeletonItemType="text"
                                hasResponsiveMargin={true}
                            />
                        )}
                    </FormControlWrapper>
                    {children}
                </InternalGrid>
            </form>
        </>
    );
};

UserCompanySepaForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    /**
     * errors passed by react-hook-form for each field (necessary if you want errors management)
     */
    errors: PropTypes.object,
    /**
     * Indicate when display the iban form
     */
    ready: PropTypes.bool,
};

UserCompanySepaForm.defaultProps = {
    errors: {},
};

export default withTranslation('common', 'countries')(UserCompanySepaForm);
