import axios from 'axios';
import { MODES } from '../constants';

const host = process.env.REACT_APP_SDK_HOST || undefined;

if (!host) {
    throw new Error(
        'host must be provided in .env file to properly perform request to API',
    );
}

/**
 * @description - axios request for all API requests that need token
 * @param {string} mode - test or prod to indicate api to add header to axios request for api
 * @param {string} token - specific token to pass to connect instead of local token
 */
export const apiAuth = (mode, token) => {
    const axiosInstance = axios.create({
        baseURL: host,
    });

    const localToken =
        localStorage.access_token && JSON.parse(localStorage.access_token);

    axiosInstance.interceptors.request.use(function (config) {
        config.headers = {
            Authorization: `Bearer ${token ? token : localToken}`,
        };

        if (mode && mode === MODES.TEST) {
            config.headers['X-TEST-MODE'] = 1;
        }

        return config;
    });

    return axiosInstance;
};

/**
 * @description - axios request for all public API requests
 */
export const apiPublic = () => {
    const axiosInstance = axios.create({
        baseURL: host,
    });

    return axiosInstance;
};
