import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {
    useAuth,
    UserProvider,
    CharityProvider,
    ModeProvider,
} from '../../context';
import RefreshTokenRoute from './RefreshTokenRoute';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { checkLocalTokens } = useAuth();

    if (checkLocalTokens() === 'expired') {
        return (
            <Route
                {...rest}
                render={(props) => (
                    <RefreshTokenRoute referer={props.location.pathname} />
                )}
            />
        );
    } else if (checkLocalTokens() === 'valid') {
        return (
            <UserProvider>
                <ModeProvider>
                    <CharityProvider>
                        <Route
                            {...rest}
                            render={(props) => <Component {...props} />}
                        />
                    </CharityProvider>
                </ModeProvider>
            </UserProvider>
        );
    } else {
        return (
            <Route
                {...rest}
                render={(props) => (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { referer: props.location.pathname },
                        }}
                    />
                )}
            />
        );
    }
};

export default PrivateRoute;
