import React from 'react';
import PropTypes from 'prop-types';
import { Title } from '@paygreen/paygreen-ui';

/**
 * @description this component is based on Title component from PG-UI and will be rendered as `<h2>`
 */
const H2Title = ({ children, marginBottom, ...rest }) => {
    return (
        <Title
            htmlTag="h2"
            textSize="md"
            colorWab="grey50"
            marginBottom={marginBottom}
            {...rest}
        >
            {children}
        </Title>
    );
};

H2Title.propTypes = {
    children: PropTypes.node.isRequired,
    marginBottom: PropTypes.string,
};

H2Title.defaultProps = {
    children: PropTypes.node.isRequired,
    marginBottom: 'xs',
};

export default H2Title;
