import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ModalForm } from '../../../../components';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { PartnershipGroup } from 'api-green-node';
import { useMode } from '../../../../context';
import { partnershipGroupFormModes } from '../../../../utils/constants';
import { createPartnershipGroup } from '../../service';
import PartnershipToastMessage from '../PartnershipToastMessage';
import PartnershipGroupForm from './PartnershipGroupForm';

const NewPartnershipGroupModal = ({
    t,
    setOpen,
    isOpen,
    setIsPartnershipsGroupUpdating,
    getData,
}) => {
    const { mode } = useMode();
    const { register, handleSubmit, setValue, errors, clearErrors, reset } =
        useForm();
    const [errorUpdateMessage, setErrorUpdateMessage] = useState(null);

    // Watch for changes on inputs
    useEffect(() => {
        register({ name: 'externalId' });
        register({ name: 'isDefault' });
    }, [register]);

    const submitForm = (data) => {
        const newPartnershipGroup = new PartnershipGroup();
        newPartnershipGroup.externalId = data['externalId'];
        newPartnershipGroup.isDefault = data['isDefault'];
        newPartnershipGroup.partnershipIds = [];

        setIsPartnershipsGroupUpdating(true);

        createPartnershipGroup(newPartnershipGroup, mode)
            .then(() => {
                getData();
                setValue('externalId', '');
                setValue('isDefault', false);
                toast(
                    <PartnershipToastMessage
                        i18nKey="form.toastSuccess.partnershipGroupCreation"
                        partnerName={data['externalId']}
                    />,
                );

                setOpen(false);
            })
            .catch((error) => {
                setErrorUpdateMessage(
                    error?.response?.data.detail +
                        ' / ' +
                        error?.response?.data.title,
                );
            })
            .finally(() => setIsPartnershipsGroupUpdating(false));
    };
    const cancelCloseModal = () => {
        setOpen(false);

        // we need the reset methods to wait until modal is closed
        setTimeout(() => {
            clearErrors();
            reset();
            setErrorUpdateMessage();
        }, 500);
    };

    return (
        <ModalForm
            modalTitle={t('newPartnershipGroupButton.title')}
            blockWidth="md"
            paddingLateral="md"
            isOpen={isOpen}
            actionOnClose={() => cancelCloseModal()}
        >
            <PartnershipGroupForm
                mode={partnershipGroupFormModes.CREATE}
                cancelForm={cancelCloseModal}
                onSubmit={handleSubmit(submitForm)}
                errors={errors}
                errorMessage={errorUpdateMessage}
                register={register}
                setValue={setValue}
            />
        </ModalForm>
    );
};

NewPartnershipGroupModal.propTypes = {
    /**
     * @description setOpen and isOpen are automatically received from ModalControl parent
     */
    setOpen: PropTypes.func,
    isOpen: PropTypes.bool,
    getData: PropTypes.func,
    setIsPartnershipsGroupUpdating: PropTypes.func,
};

NewPartnershipGroupModal.defaultProps = {
    isOpen: false,
};

export default withTranslation('common')(NewPartnershipGroupModal);
