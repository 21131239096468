import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    Box,
    InternalGrid,
    Text,
    Image,
    ThemeDefault,
} from '@paygreen/paygreen-ui';
import { useUser } from '../../../context';
import {
    partnershipStatus as partnershipStatusEnum,
    partnershipGroupStatus as partnershipGroupStatusEnum,
} from '../../../utils/constants';
import { IsMobile } from '../../../utils/tools';
import IconByName from '../../../utils/IconByName';
import PartnershipGroupStatus from './PartnershipGroupStatus';
import assoImage from '../../../assets/images/defaultAsso.png';

const PartnershipCardHeader = ({
    t,
    partnershipStatus,
    partnershipGroupStatus,
    partnerName,
    partnerFieldOfAction,
    isInPartnershipGroupCard,
    partnershipDate,
    partnershipImage,
}) => {
    const { user } = useUser();
    const [urlImage, setUrlImage] = useState(partnershipImage); // to give backup image for charity icon if API  provides a broken link

    let messageMain = null;
    let messageSecondary = null;

    switch (partnershipStatus) {
        case partnershipStatusEnum.ACTIVE:
            messageMain = t('partnershipStatus.active');
            break;
        case partnershipStatusEnum.PENDING:
            messageMain = t('partnershipStatus.pending');
            break;
        case partnershipStatusEnum.REFUSED:
            messageMain = t('partnershipStatus.refused');
            break;
        case partnershipStatusEnum.CANCELLEDBYSHOP:
            messageMain = t('partnershipStatus.cancelled');
            messageSecondary =
                user.role === 'ASSOCIATION'
                    ? t('partnershipStatus.partnerInitiative')
                    : t('partnershipStatus.yourInitiative');
            break;
        case partnershipStatusEnum.CANCELLEDBYCHARITY:
            messageMain = t('partnershipStatus.cancelled');
            messageSecondary =
                user.role === 'ASSOCIATION'
                    ? t('partnershipStatus.yourInitiative')
                    : t('partnershipStatus.partnerInitiative');
            break;
        default:
            messageMain = t(
                `charity.edit.fieldOfActionValues.${partnerFieldOfAction}`,
            );
            break;
    }

    useEffect(() => {
        setUrlImage(partnershipImage);
    }, [partnershipImage]);

    return (
        <Box margin="md" marginRight="md">
            <InternalGrid
                displayType="grid"
                gridTemplateColumns="auto 1fr"
                justifyItems="start"
                alignItems="center"
            >
                {partnershipImage ? (
                    <Image
                        style={{
                            // needed because blockwidth values in Image component are too large
                            width: IsMobile()
                                ? `${ThemeDefault.blockSpace.md}`
                                : `${ThemeDefault.blockSpace.xl}`,
                        }}
                        marginRight="sm"
                    >
                        <img
                            src={urlImage}
                            alt="Organization logo"
                            onError={() => setUrlImage(assoImage)}
                        />
                    </Image>
                ) : (
                    <IconByName
                        name={
                            user.role === 'ASSOCIATION'
                                ? 'ShopIcon'
                                : 'OrganizationIcon'
                        }
                        colorTheme="quaternary"
                        marginRight="sm"
                        iconSize="xl"
                    />
                )}

                <InternalGrid
                    displayType="grid"
                    gridTemplateColumns="auto"
                    justifyItems="start"
                    alignItems="center"
                >
                    <Text
                        htmlTag="strong"
                        textSize="sm"
                        style={{
                            lineHeight: '1.2',
                            marginRight: `${ThemeDefault.space.md}`,
                        }}
                    >
                        {partnerName}
                    </Text>

                    <InternalGrid
                        displayType="grid"
                        gridTemplateColumns="auto auto"
                        justifyItems="start"
                        alignItems="center"
                        gridGap="sm"
                    >
                        {!partnershipStatus ? (
                            <Text
                                textSize="xxs"
                                colorPallet="theme"
                                colorTheme="quaternary"
                                hasUppercase={true}
                            >
                                {messageMain}
                            </Text>
                        ) : (
                            <Text
                                htmlTag="strong"
                                colorWab="grey30"
                                textSize="xs"
                            >
                                {messageMain} {partnershipDate}
                            </Text>
                        )}

                        {!isInPartnershipGroupCard && partnershipGroupStatus ? (
                            <PartnershipGroupStatus
                                partnershipGroupStatus={partnershipGroupStatus}
                            />
                        ) : null}
                    </InternalGrid>

                    {messageSecondary && (
                        <Text htmlTag="em" colorWab="grey30" textSize="xs">
                            {messageSecondary}
                        </Text>
                    )}
                </InternalGrid>
            </InternalGrid>
        </Box>
    );
};

PartnershipCardHeader.propTypes = {
    partnershipStatus: PropTypes.oneOf(Object.values(partnershipStatusEnum)),
    partnershipGroupStatus: PropTypes.oneOf(
        Object.values(partnershipGroupStatusEnum),
    ),
    partnerName: PropTypes.string.isRequired,
    partnerFieldOfAction: PropTypes.string,
    partnershipDate: PropTypes.string,
    isInPartnershipGroupCard: PropTypes.bool,
    partnershipImage: PropTypes.string,
};

PartnershipCardHeader.defaultProps = {
    isInPartnershipGroupCard: false,
};

export default withTranslation('common')(PartnershipCardHeader);
