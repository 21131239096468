import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { InternalGrid, Link, ThemeDefault } from '@paygreen/paygreen-ui';
import { useAPIError, useUser } from '../../../context';
import { IsBigDesktop } from '../../../utils/tools';
import { apiAuth, useNodeDimensions } from '../../../utils/hooks';
import {
    H1Title,
    ErrorBoundary,
    Introduction,
    Loader,
} from '../../../components/';
import BillingTable from './BillingTable';
import WrongInfosCard from './WrongInfosCard';
import ClimateCard from './ClimateCard';
import BillingDetailsCard from './BillingDetailsCard';

const UserBilling = ({ t }) => {
    const { addError } = useAPIError();
    const { user } = useUser();
    const fetchIdRef = useRef(0);

    // we create a ref to detect if component is mounted
    const isMounted = useRef(false);

    const [isLoading, setIsLoading] = useState(true);
    const [dataBilling, setDataBilling] = useState([]);
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [isDownloading, setIsDownloading] = useState(false);

    // we create a ref using a special hook to get width from Main Internal Grid component
    const [ref, { width }] = useNodeDimensions();

    const fetchData = useCallback(
        ({ pageSize, pageIndex }) => {
            const fetchId = ++fetchIdRef.current;
            if (fetchId === fetchIdRef.current) {
                isMounted.current && setIsLoading(true);

                const params = {
                    limit: pageSize,
                    page: pageIndex + 1,
                };

                apiAuth()
                    .get(`/account/${user.accountId}/invoices/charity`, {
                        params: params,
                    })
                    .then((res) => {
                        if (isMounted.current) {
                            setDataBilling(res.data.invoices);
                            setPageCount(res.data.page_count);
                        }
                    })
                    .catch((error) => {
                        if (
                            error?.response?.data.status === 404 ||
                            error?.response?.data.status === 422
                        ) {
                            isMounted.current && setDataBilling([]);
                        } else {
                            addError(
                                error?.response?.data.detail,
                                error?.response?.data.status,
                            );
                        }
                    })
                    .finally(() => {
                        isMounted.current && setIsLoading(false);
                    });
            }
        },
        [addError, user.accountId],
    );

    /**
     * GET request to API to get user's subscription informations
     * @returns {object} - object with price and mode
     */
    const getUserSubscription = useCallback(() => {
        apiAuth()
            .get(`/account/${user.accountId}/billing/subscription-tariff`, {
                params: { service: 'CHARITY' },
            })
            .then((res) => {
                isMounted.current && setSubscriptionData(res.data.data);
            })
            .catch((error) => {
                if (
                    error?.response?.data.status === 404 ||
                    error?.response?.data.status === 422
                ) {
                    isMounted.current && setSubscriptionData('no mandate'); // this route will send back a 404 if no mandate signed
                } else {
                    addError(
                        error?.response?.data.detail,
                        error?.response?.data.status,
                    );
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setIsLoading(false);
                }
            });
    }, [addError, setIsLoading, user.accountId]);

    useEffect(() => {
        isMounted.current = true;

        getUserSubscription();

        return () => {
            isMounted.current = false;
        };
    }, [getUserSubscription]);

    return (
        <>
            <Helmet>
                <title>
                    {t('user.billing.metaTitle')} - {t('main.metaTitle')}
                </title>
            </Helmet>

            <H1Title title={t('user.billing.title')} />

            <div ref={ref}>
                <InternalGrid
                    hasStaticWidth={true}
                    displayType={IsBigDesktop() ? 'flex' : 'grid'}
                    gridGap="md"
                    gridTemplateColumns="1fr"
                    justifyItems="stretch"
                >
                    <InternalGrid
                        displayType="grid"
                        gridTemplateColumns="1fr"
                        gridGap="lg"
                        justifyItems="stretch"
                    >
                        {subscriptionData !== 'no mandate' ? (
                            <BillingDetailsCard
                                subscriptionData={subscriptionData}
                                isLoading={isLoading || !subscriptionData}
                            />
                        ) : (
                            <Introduction blockWidth="xl" marginBottom="md">
                                {t('user.billing.introduction')}

                                <a
                                    href="https://paygreen.io/tarifs/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Link colorTheme="secondary">
                                        {t('user.billing.pricesPageLink')}
                                    </Link>
                                </a>
                            </Introduction>
                        )}

                        <ErrorBoundary>
                            <BillingTable
                                data={dataBilling}
                                fetchData={fetchData}
                                loading={isLoading}
                                pageCount={pageCount}
                                setIsDownloading={setIsDownloading}
                            />
                        </ErrorBoundary>
                    </InternalGrid>

                    <InternalGrid
                        displayType="grid"
                        gridTemplateColumns={
                            IsBigDesktop() && width < ThemeDefault.breakpoint.lg
                                ? '1fr 1fr'
                                : '1fr'
                        }
                        justifyItems="stretch"
                        alignItems="stretch"
                        gridGap="lg"
                        childrenMarginBottom="sm"
                    >
                        <WrongInfosCard />

                        <ClimateCard />
                    </InternalGrid>
                </InternalGrid>
            </div>

            {isDownloading && <Loader isLoading={isDownloading} />}
        </>
    );
};

export default withTranslation('common')(UserBilling);
