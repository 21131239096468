import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link, ListHorizontal, Text } from '@paygreen/paygreen-ui';

const FooterInfos = ({ t, isInFooter }) => {
    const itemListColor = isInFooter ? 'white00' : 'grey60';
    const itemSize = isInFooter ? 'sm' : 'xs';

    return (
        <ListHorizontal
            colorWab={isInFooter ? 'white00' : 'grey30'}
            align="center"
            marginTop="none"
        >
            <Text textSize={itemSize}>
                <a
                    href="https://www.paygreen.io/arrondi-en-ligne"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Link
                        colorPallet="wab"
                        colorWab={itemListColor}
                        hasUnderline={false}
                    >
                        {t('login.infoLink')}
                    </Link>
                </a>
            </Text>

            <Text textSize={itemSize}>
                <a href="mailto:serviceclient@paygreen.fr">
                    <Link
                        colorPallet="wab"
                        colorWab={itemListColor}
                        hasUnderline={false}
                    >
                        {t('login.helpLink')}
                    </Link>
                </a>
            </Text>
        </ListHorizontal>
    );
};

FooterInfos.propTypes = {
    isInFooter: PropTypes.bool,
};

FooterInfos.defaultProps = {
    isInFooter: true,
};

export default withTranslation('common')(FooterInfos);
