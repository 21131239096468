import React from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import { PartnershipGroup } from 'api-green-node';
import { Text } from '@paygreen/paygreen-ui';
import { useAPIError, useMode } from '../../../../context';
import {
    Disabler,
    DeleteButton,
    H2Title,
    Introduction,
    ModalBlock,
    SimpleActionButton,
    Message,
    NoDataFoundCard,
} from '../../../../components';
import { partnershipStatus } from '../../../../utils/constants';
import { getPartnershipGroupStatus } from '../../shared/partnershipsTools';
import {
    CornerAddToPartnershipGroup,
    PartnershipCardGrid,
    PartnershipModalContent,
    PartnershipCardSkeleton,
    PartnershipCollapsibleCard,
    PartnershipToastMessage,
} from '../../shared';
import { updatePartnershipGroup, deletePartnership } from '../../service';

const AvailablePartnerCharities = ({
    t,
    isLoading,
    partnershipsData,
    partnershipsGroupData,
    getData,
    setIsPartnershipsGroupUpdating,
}) => {
    const { addError } = useAPIError();
    const { mode } = useMode();

    const addToPartnershipGroup = (groupExternalId, partnershipId) => {
        const currentGroup = partnershipsGroupData.filter((item) => {
            return item.externalId === groupExternalId;
        });
        const idList = currentGroup[0].partnershipIds;
        idList.push(partnershipId);
        const updatedGroup = new PartnershipGroup(idList);

        setIsPartnershipsGroupUpdating(true);

        updatePartnershipGroup(groupExternalId, updatedGroup, mode)
            .then(() => {
                getData();
            })
            .catch((error) => {
                addError(
                    error?.response?.data.detail,
                    error?.response?.data.status,
                );
            })
            .finally(() => {
                setIsPartnershipsGroupUpdating(false);
            });
    };

    const CardMainButton = ({
        partnerId,
        partnerName,
        isInPartnershipGroup,
    }) => (
        <ModalBlock
            blockWidth="md"
            closeWithAction={() => getData()}
            modalTitle={t('partnershipButtons.cancelThePartnership')}
            isTitleRed={true}
            button={
                <DeleteButton>
                    {t('partnershipButtons.cancelThePartnership')}
                </DeleteButton>
            }
        >
            <PartnershipModalContent
                closeWithAction={() => getData()}
                sdkPartnershipAction={() => deletePartnership(partnerId, mode)}
                buttonCancelAction={
                    <SimpleActionButton icon="ArrowRoundTopLeftIcon">
                        {t('partnershipButtons.cancel')}
                    </SimpleActionButton>
                }
                buttonConfirmAction={
                    <DeleteButton>
                        {t('partnershipButtons.cancelThisPartnership')}
                    </DeleteButton>
                }
                toastMessage={
                    <PartnershipToastMessage
                        i18nKey="form.toastSuccess.partnershipCancel"
                        partnerName={partnerName}
                    />
                }
            >
                <Text textSize="sm">
                    <Trans i18nKey="ShopPartnerships.charityPartners.message">
                        Are you sure to cancel
                        <Text htmlTag="strong" textSize="sm">
                            {{ partnerName }}
                        </Text>
                        This manipulation is irreversible
                        <Text htmlTag="strong" textSize="sm">
                            {{ partnerName }}
                        </Text>
                    </Trans>
                </Text>

                {partnershipsData.length === 1 && (
                    <Message type="error" marginTop="md" paddingLateral="xs">
                        <Text textSize="xs">
                            {t(
                                'ShopPartnerships.charityPartners.lastPartnershipMessage',
                            )}
                        </Text>
                    </Message>
                )}

                {isInPartnershipGroup && partnershipsData.length > 1 && (
                    <Message type="error" marginTop="md" paddingLateral="xs">
                        <Text textSize="xs">
                            <Trans i18nKey="ShopPartnerships.charityPartners.inGroupMessage">
                                Are you sure to cancel
                                <Text
                                    htmlTag="strong"
                                    textSize="xs"
                                    colorPallet="status"
                                    colorStatus="danger"
                                >
                                    {{ partnerName }}
                                </Text>
                                This manipulation is irreversible
                            </Trans>

                            <Text
                                htmlTag="strong"
                                textSize="xs"
                                colorPallet="status"
                                colorStatus="danger"
                            >
                                {t(
                                    'ShopPartnerships.charityPartners.updateYourGroup',
                                )}
                            </Text>
                        </Text>
                    </Message>
                )}
            </PartnershipModalContent>
        </ModalBlock>
    );

    return (
        <>
            <H2Title marginTop="md">
                {t(
                    'ShopPartnerships.charityPartners.myCharityPartnersSubtitle',
                )}
            </H2Title>

            <Introduction>
                {t(
                    'ShopPartnerships.charityPartners.myCharityPartnersIntroduction',
                )}
            </Introduction>

            {isLoading ? (
                <PartnershipCardSkeleton blockWidth="md" />
            ) : partnershipsData && partnershipsData.length > 0 ? (
                <PartnershipCardGrid>
                    {partnershipsData.map((partnership) => (
                        <Disabler key={partnership.idPartnership}>
                            <PartnershipCollapsibleCard
                                key={partnership.idPartnership}
                                data={partnership}
                                partnershipGroupStatus={getPartnershipGroupStatus(
                                    partnershipsGroupData,
                                    partnership.idPartnership,
                                )}
                                partnershipStatus={partnershipStatus.ACTIVE}
                                mainButton={
                                    <CardMainButton
                                        partnerId={partnership.idPartnership}
                                        partnerName={
                                            partnership.association.name
                                        }
                                        isInPartnershipGroup={
                                            getPartnershipGroupStatus(
                                                partnershipsGroupData,
                                                partnership.idPartnership,
                                            ) !== 'none'
                                        }
                                    />
                                }
                            >
                                {partnershipsGroupData && (
                                    <CornerAddToPartnershipGroup
                                        partnershipId={
                                            partnership.idPartnership
                                        }
                                        partnershipsGroupData={
                                            partnershipsGroupData
                                        }
                                        partnershipGroupStatus={getPartnershipGroupStatus(
                                            partnershipsGroupData,
                                            partnership.idPartnership,
                                        )}
                                        addToPartnershipGroup={
                                            addToPartnershipGroup
                                        }
                                    />
                                )}
                            </PartnershipCollapsibleCard>
                        </Disabler>
                    ))}
                </PartnershipCardGrid>
            ) : (
                <NoDataFoundCard
                    message={t('ShopPartnerships.charityPartners.noDataFound')}
                />
            )}
        </>
    );
};

AvailablePartnerCharities.propTypes = {
    isLoading: PropTypes.bool,
    partnershipsData: PropTypes.array,
    partnershipsGroupData: PropTypes.array,
    getData: PropTypes.func,
    setIsPartnershipsGroupUpdating: PropTypes.func,
};

AvailablePartnerCharities.defaultProps = {
    isLoading: false,
};

export default withTranslation('common')(AvailablePartnerCharities);
