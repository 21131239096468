import _ from 'lodash';
import { ThemeDefault } from '@paygreen/paygreen-ui';
import { useMediaQuery } from './hooks/useMediaQuery';
import dayjs from './dayjsHelper';

/**
 * @description it gets value from dimensions constants from PG-UI theme
 */
const getNumberFromThemeValue = (value) => {
    return Number(value.slice(0, -2));
};

/**
 * @description it gets value from mediaQuery from PG-UI theme
 */
const getNumberFromMediaQuery = (themeQuery) => {
    return Number(themeQuery.replace(/[^.\d]/g, ''));
};

/**
 * @description to detect dynamically screen size above 960px width
 */
const IsDesktop = () => {
    const isDesktop = useMediaQuery(
        [`${ThemeDefault.screen.min.md}`],
        [true],
        false,
    );

    return isDesktop;
};

/**
 * @description to detect dynamically screen size above 1200px width
 */
const IsBigDesktop = () => {
    const isBigDesktop = useMediaQuery(
        [`${ThemeDefault.screen.min.lg}`],
        [true],
        false,
    );

    return isBigDesktop;
};

/**
 * @description to detect dynamically screen size under 549px width
 */
const IsMobile = () => {
    const isMobile = useMediaQuery(
        [`${ThemeDefault.screen.max.sm}`],
        [true],
        false,
    );

    return isMobile;
};

/**
 * @description to convert date for API compatibility
 * @param {string} date - DD/MM/YYYY format
 *
 */
const formatDateApi = (date) => {
    return dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
};

/**
 * @description to convert date for API compatibility
 * @param {string} date - DD/MM/YYYY format
 *
 */
const internationalFormatToCalendarFormat = (date) => {
    return dayjs(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
};

/**
 * @description to convert timestamp received from API to filter with query paramaters by date
 * @param {Number} date - timestamp
 */
const timestampToInternationalFormat = (date) => {
    return dayjs.unix(date).format('YYYY-MM-DD');
};

/**
 * @description to convert timestamp received from API to 'DD/MM/YYYY'
 * @param {Number} date - timestamp
 */
const timestampToCalendarFormat = (date) => {
    return dayjs.unix(date).format('DD/MM/YYYY');
};

/**
 * @description to convert into date timestamp from API
 * @param {Number} date - timestamp
 */
const timestampToDate = (date) =>
    new Date(date * 1000).toLocaleDateString('FR', {
        hour12: true,
    });

/**
 * @description to convert into date (with hour specified) timestamp from API
 * @param {Number} date - timestamp
 */
const timestampToDateWithHour = (date) =>
    new Date(date * 1000).toLocaleString('en-GB', {
        hour12: false,
    });

/**
 * @description to convert ISO 8601 date to DD/MM/YYYY format
 * @param {string} date - ISO 8601 format
 *
 */
const formatISODate = (date) => {
    return dayjs(date).format('DD/MM/YYYY');
};

/**
 * @description to convert enum from SDK to array
 * @param {Enumerator} value
 */
const enumToArray = (value) => {
    return _.filter(_.toArray(value), (item) => {
        return !_.isNumber(item);
    });
};

/**
 * transform the amount in cents to normal
 * @param {number} amount
 * @param {boolean} withZero - to keep 0 after comma
 * @returns {number | string} will returns a number except to keep 0 will return a string
 */
const formatCentsMoney = (amount, withZero) =>
    withZero ? (amount / 100).toFixed(2) : Number((amount / 100).toFixed(2));

/**
 * Display an amount in cents in decimals with currency € by default
 * @param {Number} amountInCents amount in cents
 * @param {String} currency € by default
 */
const displayCurrency = (amountInCents, currency = '€') =>
    `${formatCentsMoney(amountInCents)} ${currency}`;

/**
 * Display phone number received from API into french format +33 (0)9 99 99 99 99
 * @param {String} phone
 */
const displayFrenchPhoneNumber = (phone) => {
    const shortedPhone = phone?.slice(4);
    const phoneWithSpaces =
        shortedPhone &&
        [...shortedPhone]
            .map((letter, index) => (index % 2 !== 0 ? ' ' + letter : letter))
            .join('')
            .trim();
    const internationalPhone = phone ? '+33 (0)' + phoneWithSpaces : null; // in case there is no phone provided, we return null

    return internationalPhone;
};

/**
 * Access dynamically to object property and return retrieved data, property can be on first level or nested, you can get one single property or an array of properties
 * @param {Array} propertyArray - list of simple or nested properties to build path access to data inside object
 * @param {Object} data - data we want to extract properties from
 */
const getDataFromObject = (propertyArray, data) => {
    let retrievedData = [];

    if (data) {
        propertyArray.forEach((item) => {
            // we wait for data to be defined
            if (typeof item === 'string') {
                // only one property to retrieve
                data = data && data[item] ? data[item] : null;
                retrievedData = data;
            } else {
                // array of properties to retrieve
                let newObj = data;
                item.forEach((key) => {
                    newObj = newObj && newObj[key] ? newObj[key] : null;
                });

                retrievedData.push(newObj);
            }
        });
    }

    return retrievedData;
};

/**
 * @description it adapts url to guarantee functional external redirection
 * @param {string} url website url to be adapted
 */
const fixExternalUrl = (url) => {
    if (url) {
        return /^https?:\/\//.test(url) ? url : 'https://' + url;
    }
};

/**
 * @description to adjust value length and limit up to 3
 * @param {number} value
 */
const adjustDecimalNumber = (value) => {
    switch (true) {
        case value < 10:
            return Number(value.toFixed(2));
        case value < 100:
            return Number(value.toFixed(1));
        default:
            return Number(value.toFixed(0));
    }
};

/**
 * @description to convert carbon data received from API to the most accurate value and unit
 * @param {string | number} value amount of carbon received in ton
 * @param {boolean} hasZero to return 0 or '--' when needed for null values
 */
const convertCarbonValue = (value, hasZero) => {
    const newValue = Number(value);
    let convertedValue;
    let convertedUnit;

    switch (true) {
        case !newValue:
            convertedValue = hasZero ? 0 : '––';
            convertedUnit = hasZero ? 'g' : '';
            break;

        case newValue >= 1:
            convertedValue = adjustDecimalNumber(newValue);
            convertedUnit = 't';
            break;

        case newValue >= 0.001 && newValue < 1:
            convertedValue = adjustDecimalNumber(newValue * 1000);
            convertedUnit = 'kg';
            break;

        default:
            convertedValue = adjustDecimalNumber(newValue * 1000000);
            convertedUnit = 'g';
    }

    return { convertedValue, convertedUnit };
};

/**
 * @description to convert price carbon data received from API to the most accurate display and value in euros
 * @param {string | number} value price of carbon received in euros cents
 * @param {boolean} hasZero to return 0 or '--' when needed for null values
 */
const convertPriceValueToEuros = (value, hasZero) => {
    const newValue = Number(value);
    let convertedValue;
    let convertedUnit = '€';

    switch (true) {
        case newValue === null || newValue === 0:
            convertedValue = hasZero ? 0 : '––';
            convertedUnit = hasZero ? '€' : '';
            break;

        case newValue >= 1:
            convertedValue = (newValue / 100).toFixed(2);
            break;

        default:
            convertedValue = '< 0.01';
    }

    return { convertedValue, convertedUnit };
};

/**
 * @description to truncate too long value
 * @param {string} value
 * @param {number} limit
 */
const truncateTooLongName = (value, limit) => {
    return value?.length > limit ? value?.substring(0, limit) + '...' : value;
};

export {
    enumToArray,
    getNumberFromThemeValue,
    getNumberFromMediaQuery,
    IsBigDesktop,
    IsDesktop,
    IsMobile,
    formatDateApi,
    formatCentsMoney,
    displayCurrency,
    timestampToDate,
    timestampToInternationalFormat,
    timestampToDateWithHour,
    internationalFormatToCalendarFormat,
    getDataFromObject,
    displayFrenchPhoneNumber,
    fixExternalUrl,
    formatISODate,
    convertPriceValueToEuros,
    convertCarbonValue,
    timestampToCalendarFormat,
    truncateTooLongName,
};
