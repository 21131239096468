import React, { useEffect, useState, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import {
    useExpanded,
    useFilters,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
} from 'react-table';
import {
    Box,
    DaTable,
    DaTableBody,
    DaTableCell,
    DaTableHeadCell,
    DaTableHead,
    DaTableRow,
    Text,
} from '@paygreen/paygreen-ui';
import {
    CarbonDataDisplay,
    PaginationTable,
    StatusDisplayTable,
    StatusFilterTable,
} from '../../components';
import { timestampToDate, IsBigDesktop } from '../../utils/tools';
import { statusDonations } from '../../utils/constants';
import { useUser } from '../../context';
import { statusOptions } from './statusOptions';

const DonationsTable = ({
    t,
    data,
    fetchData,
    loading,
    pageCount: controlledPageCount,
    startDate,
    endDate,
    totalItems,
}) => {
    const { user } = useUser();

    const [statusFilter, setStatusFilter] = useState('');
    const [activePageReset, setActivePageReset] = useState(false);

    // needed to guarantee vertical alignment on mobile for status cell in table
    const statusCellboxStyle = {
        display: 'flex',
        justifyContent: IsBigDesktop() ? 'flex-start' : 'flex-end',
    };

    const columns = useMemo(
        () => [
            {
                Header: t('shopDonations.table.heads.idDonation'),
                accessor: 'idDonation',
            },
            {
                Header: t('shopDonations.table.heads.createdAt'),
                id: 'createdAt',
                accessor: (d) => timestampToDate(d.createdAt),
            },
            {
                Header: t('shopDonations.table.heads.donationAmount'),
                accessor: 'donationAmount',
            },
            {
                Header: t('shopDonations.table.heads.totalAmount'),
                accessor: 'totalAmount',
            },
            {
                Header: t('shopDonations.table.heads.donationReference'),
                accessor: 'donationReference',
            },
            {
                Header:
                    user.role === 'ASSOCIATION'
                        ? t('charityDonations.table.heads.idDonor')
                        : t('shopDonations.table.heads.idBuyer'),
                accessor:
                    user.role === 'ASSOCIATION' ? 'donorEmail' : 'buyerEmail',
            },
            {
                Header: '',
                accessor: 'status',
                Filter: ({ filter, ...rest }) => (
                    <StatusFilterTable
                        onChange={setStatusFilter}
                        setActivePageReset={setActivePageReset}
                        statusConstants={statusDonations}
                        i18TextBase="shopDonations.table.status"
                        {...rest}
                    />
                ),
                filter: 'includes',
            },
        ],
        [t, user.role, setActivePageReset],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageCount,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 25 },
            manualPagination: true,
            pageCount: controlledPageCount,
            manualSortBy: true,
            autoResetPage: false,
            // we ponctually override table internal state to resetPage index with component SelectColumnFilter when needed
            useControlledState: (state) => {
                if (activePageReset) {
                    return { ...state, pageIndex: 0 };
                } else {
                    return state;
                }
            },
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
    );

    useEffect(() => {
        fetchData({
            pageIndex,
            pageSize,
            startDate,
            endDate,
            statusFilter,
        });
    }, [fetchData, pageIndex, pageSize, startDate, endDate, statusFilter]);

    return (
        <Box>
            <DaTable
                {...getTableProps()}
                blockWidth="none"
                isLoading={loading}
                loadingRowNumber={15}
                loadingMainCellNumber={3}
            >
                {headerGroups.map((headerGroup) => (
                    <DaTableHead
                        data-cy="datable-donations"
                        blockTitle={t('table.title')}
                        {...headerGroup.getHeaderGroupProps()}
                    >
                        {headerGroup.headers.map((column, index) => (
                            <DaTableHeadCell
                                key={index}
                                label={
                                    column.Header ? column.render('Header') : ''
                                }
                            >
                                {column.Filter ? column.render('Filter') : null}
                            </DaTableHeadCell>
                        ))}
                    </DaTableHead>
                ))}

                <DaTableBody {...getTableBodyProps()}>
                    {!loading && data.length ? (
                        page.map((row) => {
                            prepareRow(row);

                            return (
                                <DaTableRow {...row.getRowProps()} key={row.id}>
                                    <DaTableCell
                                        isMain={false}
                                        label={t(
                                            'shopDonations.table.heads.idDonation',
                                        )}
                                    >
                                        {row.values.idDonation}
                                    </DaTableCell>

                                    <DaTableCell
                                        label={t(
                                            'shopDonations.table.heads.createdAt',
                                        )}
                                    >
                                        {row.values.createdAt}
                                    </DaTableCell>

                                    <DaTableCell
                                        label={t(
                                            'shopDonations.table.heads.donationAmount',
                                        )}
                                    >
                                        <CarbonDataDisplay
                                            value={row.values.donationAmount}
                                            mode="price"
                                            hasZeroDisplayed={true}
                                        />
                                    </DaTableCell>

                                    <DaTableCell
                                        isMain={false}
                                        label={t(
                                            'shopDonations.table.heads.totalAmount',
                                        )}
                                    >
                                        <CarbonDataDisplay
                                            value={row.values.totalAmount}
                                            mode="price"
                                            hasZeroDisplayed={true}
                                        />
                                    </DaTableCell>

                                    <DaTableCell
                                        isMain={false}
                                        label={t(
                                            'shopDonations.table.heads.donationReference',
                                        )}
                                    >
                                        {row.values.donationReference ||
                                            t(
                                                'charityDonations.table.rows.notAvailable',
                                            )}
                                    </DaTableCell>

                                    {user.role === 'ASSOCIATION' ? (
                                        <DaTableCell
                                            isMain={false}
                                            label={t(
                                                'charityDonations.table.heads.idDonor',
                                            )}
                                        >
                                            {row.values.donorEmail ||
                                                t(
                                                    'charityDonations.table.rows.notAvailable',
                                                )}
                                        </DaTableCell>
                                    ) : (
                                        <DaTableCell
                                            isMain={false}
                                            label={t(
                                                'shopDonations.table.heads.idBuyer',
                                            )}
                                        >
                                            {row.values.buyerEmail}
                                        </DaTableCell>
                                    )}

                                    <DaTableCell
                                        style={{ ...statusCellboxStyle }}
                                    >
                                        <StatusDisplayTable
                                            status={row.values.status}
                                            statusOptions={statusOptions}
                                            i18TextBase="shopDonations.table.status"
                                        />
                                    </DaTableCell>
                                </DaTableRow>
                            );
                        })
                    ) : (
                        <Text
                            align="center"
                            marginTop="xl"
                            marginBottom="xl"
                            textSize="sm"
                        >
                            {t('noDataFound')}
                        </Text>
                    )}
                </DaTableBody>
            </DaTable>

            {totalItems > 25 ? (
                <PaginationTable
                    setPageSize={setPageSize}
                    pageCount={pageCount || 1}
                    pageIndex={pageIndex}
                    colorTheme="quaternary"
                    gotoPage={gotoPage}
                    setActivePageReset={setActivePageReset}
                />
            ) : null}
        </Box>
    );
};

export default withTranslation('common')(DonationsTable);
