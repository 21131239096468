import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    Card,
    DataLegend,
    InternalGrid,
    Text,
} from '@paygreen/paygreen-ui';

const EmptyModeDisplay = styled.div`
    & > * {
        filter: ${(props) => (props.isActive ? 'grayscale(1)' : null)};
        height: 100%;
    }

    .card-container {
        width: ${(props) => (props.hasMinWidth ? props.hasMinWidth : null)};
    }
`;

const SmallDataCard = (props) => {
    const {
        blockWidth,
        children,
        colorPallet,
        colorTheme,
        colorWab,
        colorStatus,
        icon,
        hasVerticalLegend,
        hasEmptyMode,
        padding,
        title,
        unit,
        value,
        hasMinWidth,
    } = props;

    let cardIcon = null;

    if (icon) {
        cardIcon = React.cloneElement(icon, {
            iconSize: 'lg',
            colorPallet: colorPallet,
            colorTheme: colorTheme,
            colorWab: colorWab,
            colorStatus: colorStatus,
            marginRight: 'sm',
        });
    }

    return (
        <EmptyModeDisplay
            isActive={!value && hasEmptyMode}
            hasMinWidth={hasMinWidth}
        >
            <Card
                className="card-container"
                colorPallet={colorPallet}
                colorTheme={colorTheme}
                colorWab={colorWab}
                colorStatus={colorStatus}
                radiusSize="sm"
                shadowSize="none"
                blockWidth={blockWidth}
                paddingLateral={padding}
                paddingBottom={padding}
                paddingTop={padding}
            >
                {title ? (
                    <Text
                        colorWab="grey40"
                        textSize="xxs"
                        hasUppercase={true}
                        align="center"
                    >
                        {title}
                    </Text>
                ) : null}

                <InternalGrid
                    justifyContent="center"
                    alignItems={hasVerticalLegend ? 'flex-start' : 'center'}
                    flexWrap="nowrap"
                    childrenMarginTop={title ? 'sm' : 'none'}
                >
                    {cardIcon || null}

                    <InternalGrid
                        displayType="grid"
                        gridTemplateColumns={
                            hasVerticalLegend ? '1fr' : 'auto auto'
                        }
                        justifyItems="start"
                        alignItems="baseline"
                    >
                        <DataLegend
                            textSize="sm"
                            value={value || 0}
                            unit={unit}
                            colorPallet={colorPallet}
                            colorTheme={colorTheme}
                            colorWab={colorWab}
                            colorStatus={colorStatus}
                        />

                        {children}
                    </InternalGrid>
                </InternalGrid>
            </Card>
        </EmptyModeDisplay>
    );
};

SmallDataCard.propTypes = {
    blockWidth: PropTypes.string,
    colorPallet: PropTypes.string,
    colorStatus: PropTypes.string,
    colorTheme: PropTypes.string,
    colorWab: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.element,
    hasVerticalLegend: PropTypes.bool,
    hasEmptyMode: PropTypes.bool,
    padding: PropTypes.string,
    unit: PropTypes.string,
    value: PropTypes.number,
    hasMinWidth: PropTypes.string
};

SmallDataCard.defaultProps = {
    blockWidth: 'md',
    colorPallet: 'status',
    colorStatus: 'default',
    colorTheme: 'primary',
    colorWab: 'white00',
    hasVerticalLegend: false,
    hasEmptyMode: false,
    padding: 'sm',
    unit: ' ',
    value: 0,
};

export default SmallDataCard;
