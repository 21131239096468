import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { InternalGrid, Box } from '@paygreen/paygreen-ui';
import { LogoPaygreenWithMode } from '../../../assets/logos';
import { IsDesktop } from '../../../utils/tools';
import { Loader } from '../../index';
import Footer from './Footer';
import FooterInfos from './FooterInfos';
import LoginOrSubscribeButton from './LoginOrSubscribeButton';
import MainLoginGrid from './MainLogin.styled';

const MainLogin = ({ isValidation, isLoading, helmetTitle, children }) => {
    const appMode = process.env.REACT_APP_ACTIVE_MODE;

    const pathname = useLocation().pathname;
    
    // subscription is not available in SANDBOX env
    const showButtons =
        appMode !== 'SANDBOX' &&
        (pathname === '/login' || pathname === '/subscription');
    const showInfosInsideFooter = IsDesktop() || !showButtons;
    const showInfosOutsideFooter = !IsDesktop() && showButtons;

    return (
        <>
            <MainLoginGrid isLoading={isLoading} isValidation={isValidation}>
                <Helmet>
                    <title>{helmetTitle}</title>
                </Helmet>

                {isValidation && isLoading ? null : (
                    <>
                        <Box padding="lg">
                            <InternalGrid
                                displayType="grid"
                                gridTemplateColumns="1fr"
                                alignItems="center"
                                justifyItems="center"
                                gridGap="lg"
                            >
                                {pathname !== '/subscription' && (
                                    <LogoPaygreenWithMode />
                                )}

                                {children}
                            </InternalGrid>
                        </Box>

                        <div>
                            {showInfosOutsideFooter && (
                                <Box marginBottom="lg">
                                    <FooterInfos isInFooter={false} />
                                </Box>
                            )}

                            <Footer showButtons={showButtons}>
                                <>
                                    {showButtons && <LoginOrSubscribeButton />}

                                    {showInfosInsideFooter && <FooterInfos />}
                                </>
                            </Footer>
                        </div>
                    </>
                )}
            </MainLoginGrid>
            <Loader isLoading={isLoading} />
        </>
    );
};

MainLogin.propTypes = {
    helmetTitle: PropTypes.string.isRequired,
    children: PropTypes.node,
    isValidation: PropTypes.bool,
    isLoading: PropTypes.bool,
};

MainLogin.defaultProps = {
    isValidation: false,
    isLoading: false,
};

export default withTranslation('common')(MainLogin);
