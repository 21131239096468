import React, { useMemo, useEffect, useRef } from 'react';
import {
    useExpanded,
    useFilters,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
} from 'react-table';
import { withTranslation } from 'react-i18next';
import {
    ArrowBottomIcon,
    Box,
    DaTable,
    DaTableBody,
    DaTableCell,
    DaTableHeadCell,
    DaTableHead,
    DaTableRow,
    Text,
    InternalGrid,
} from '@paygreen/paygreen-ui';
import { useUser, useAPIError } from '../../../context';
import { apiAuth } from '../../../utils/hooks';
import { IsBigDesktop, formatISODate, IsDesktop } from '../../../utils/tools';
import { CarbonDataDisplay, HideAndCopy } from '../../../components';
import ActionsCell from './ActionsCell';

const BillingTable = ({
    t,
    data,
    fetchData,
    loading,
    pageCount: controlledPageCount,
    setIsDownloading,
}) => {
    const { user } = useUser();
    const { addError } = useAPIError();

    // we create a ref to detect if component is mounted
    const isMounted = useRef(false);

    // needed to guarantee vertical alignment on tablet for last cell in table
    const actionCellboxProps = {
        style: {
            display: 'flex',
            justifyContent: IsBigDesktop() ? 'center' : 'flex-end',
        },
    };

    const columns = useMemo(
        () => [
            {
                Header: t('user.billing.table.billingNumber'),
                accessor: 'number',
            },
            {
                Header: t('user.billing.table.period'),
                id: 'period',
                accessor: (d) => [d.periodStart, d.periodEnd],
            },
            {
                Header: t('user.billing.table.subtotal'),
                accessor: 'subtotal',
            },
            {
                Header: t('user.billing.table.total'),
                accessor: 'total',
            },
            {
                Header: 'Actions',
                accessor: 'invoicePdf',
            },
        ],
        [t],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 25 },
            manualPagination: true,
            pageCount: controlledPageCount,
            manualSortBy: false,
            autoResetPage: false,
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
    );

    useEffect(() => {
        fetchData({
            pageIndex,
            pageSize,
        });
    }, [fetchData, pageIndex, pageSize]);

    /**
     * @description get pdf from API, generate link and launch download
     * @param {string} invoicePdf - base64 reference of download url
     * @param {string} number - invoice number reference
     */
    const getPdfInvoice = (invoicePdf, number) => {
        const link = document.createElement('a');
        link.target = '_blank';
        link.download = `CHARITYKIT_${number}`;

        setIsDownloading(true);

        apiAuth()
            .get(
                `/account/${user.accountId}/invoices/download?reference=${invoicePdf}`,
                {
                    responseType: 'blob',
                },
            )
            .then((res) => {
                link.href = URL.createObjectURL(
                    new Blob([res.data], { type: 'application/pdf' }), // create pdf
                );
                link.click(); // launch download
            })
            .catch((error) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const data = JSON.parse(reader.result);
                    addError(data.detail, data.status);
                };
                reader.readAsText(error.response.data);
            })
            .finally(() => {
                isMounted.current && setIsDownloading(false);
            });
    };

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <Box marginBottom={!IsDesktop() ? 'sm' : 'lg'}>
            <DaTable
                {...getTableProps()}
                blockWidth="none"
                isLoading={loading}
                loadingRowNumber={10}
                loadingMainCellNumber={2}
                data-cy="datable-billing"
            >
                {headerGroups.map((headerGroup) => (
                    <DaTableHead
                        blockTitle={t('user.billing.table.title')}
                        {...headerGroup.getHeaderGroupProps()}
                    >
                        {headerGroup.headers.map((column, index) => (
                            <DaTableHeadCell
                                key={index}
                                label={column.render('Header')}
                                sortIcon={
                                    index === 1 ? (
                                        <ArrowBottomIcon
                                            {...column.getSortByToggleProps()}
                                            isActive={column.isSorted}
                                            rotateSize={
                                                column.isSorted &&
                                                column.isSortedDesc
                                                    ? 'd0'
                                                    : 'd180'
                                            }
                                            title={
                                                column.isSortedDesc
                                                    ? t(
                                                          'user.billing.table.cancelSort',
                                                      )
                                                    : t(
                                                          'user.billing.table.sort',
                                                      ) +
                                                      (column.isSorted
                                                          ? 'DESC'
                                                          : 'ASC') +
                                                      t(
                                                          'user.billing.table.sample',
                                                      )
                                            }
                                        />
                                    ) : null
                                }
                            />
                        ))}
                    </DaTableHead>
                ))}

                <DaTableBody {...getTableBodyProps()}>
                    {!loading && data.length ? (
                        page.map((row) => {
                            prepareRow(row);

                            return (
                                <DaTableRow {...row.getRowProps()} key={row.id}>
                                    <DaTableCell
                                        isMain={false}
                                        label={t(
                                            'user.billing.table.billingNumber',
                                        )}
                                        title={row.values.number}
                                    >
                                        <InternalGrid
                                            displayType="grid"
                                            gridTemplateColumns="auto auto"
                                            alignItems="center"
                                        >
                                            {row.values.number}

                                            <HideAndCopy
                                                text={row.values.number}
                                                title={t(
                                                    'user.billing.table.copyNumber',
                                                )}
                                                hasHiddenText={false}
                                            />
                                        </InternalGrid>
                                    </DaTableCell>

                                    <DaTableCell
                                        label={t('user.billing.table.period')}
                                    >
                                        {/* we don't transform dates directly via the accessor to prevent errors when sorting dates */}
                                        {formatISODate(row.values.period[0]) +
                                            ' - ' +
                                            formatISODate(row.values.period[1])}
                                    </DaTableCell>

                                    <DaTableCell
                                        label={t('user.billing.table.subtotal')}
                                        isMain={false}
                                    >
                                        <CarbonDataDisplay
                                            value={row.values.subtotal}
                                            mode="price"
                                        />
                                    </DaTableCell>

                                    <DaTableCell
                                        label={t('user.billing.table.total')}
                                        isMain={false}
                                    >
                                        <CarbonDataDisplay
                                            value={row.values.total}
                                            mode="price"
                                        />
                                    </DaTableCell>

                                    <DaTableCell
                                        label="Actions"
                                        {...actionCellboxProps}
                                    >
                                        <ActionsCell
                                            onClick={() =>
                                                getPdfInvoice(
                                                    row.values.invoicePdf,
                                                    row.values.number,
                                                )
                                            }
                                        />
                                    </DaTableCell>
                                </DaTableRow>
                            );
                        })
                    ) : (
                        <Text
                            align="center"
                            marginTop="xl"
                            marginBottom="xl"
                            textSize="sm"
                        >
                            {t('noDataFound')}
                        </Text>
                    )}
                </DaTableBody>
            </DaTable>
        </Box>
    );
};

export default withTranslation('common')(BillingTable);
