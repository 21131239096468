import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { InternalGrid } from '@paygreen/paygreen-ui';
import { IsMobile } from '../../../utils/tools';
import { userInputList } from '../adminUsersUtils';
import {
    SpaceManagementCard,
    TeamManagementCard,
    AdministratorManagementCard,
    CompanyManagementCard,
} from './userCards';

const AdminUserDetailsCards = ({
    accountData,
    setAccountData,
    setIsLoading,
    isLoading,
    refresh,
}) => (
    <InternalGrid
        displayType="grid"
        gridTemplateColumns="1fr"
        gridGap="lg"
        hasStaticWidth={IsMobile()}
        alignItems="stretch"
        justifyItems="stretch"
    >
        <InternalGrid
            displayType="grid"
            gridTemplateColumns={IsMobile() ? '1fr' : '1fr 1fr'}
            gridGap="lg"
            alignItems="stretch"
            justifyItems="stretch"
        >
            <CompanyManagementCard
                accountData={accountData}
                isLoading={isLoading}
            />

            <AdministratorManagementCard
                accountData={accountData}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setAccountData={setAccountData}
            />
        </InternalGrid>

        <InternalGrid
            displayType="grid"
            gridTemplateColumns={IsMobile() ? '1fr' : '1fr 1fr 1fr'}
            gridGap="lg"
            alignItems="stretch"
            justifyItems="stretch"
        >
            <TeamManagementCard
                accountData={accountData}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setAccountData={setAccountData}
            />

            <SpaceManagementCard
                mode="charity"
                accountData={accountData}
                setAccountData={setAccountData}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                inputList={userInputList.slice(
                    7,
                    accountData?.usesArrondi === '0'
                        ? 8
                        : accountData?.charityBilling?.status === 'UNSIGNED'
                        ? 10
                        : 12,
                )}
                refresh={refresh}
            />

            <SpaceManagementCard
                mode="climate"
                accountData={accountData}
                setAccountData={setAccountData}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                inputList={userInputList.slice(
                    12,
                    accountData?.usesTree === '0'
                        ? 13
                        : accountData?.climateBilling?.status === 'UNSIGNED'
                        ? 15
                        : 17,
                )}
                refresh={refresh}
            />
        </InternalGrid>
    </InternalGrid>
);

AdminUserDetailsCards.propTypes = {
    accountData: PropTypes.object,
    setAccountData: PropTypes.func,
    isLoading: PropTypes.bool,
    setIsLoading: PropTypes.func,
    refresh: PropTypes.func,
};

AdminUserDetailsCards.defaultProps = {
    isLoading: true,
};

export default withTranslation('common')(AdminUserDetailsCards);
