/**
 * List of all regex used inside app for form validation
 * @example regexForm.email
 */
const regexForm = {
    accountUsername: /^[-+!?&\wÀ-ž]{1,80}$/,
    password: /^[-€$%^£¤°{|}/'"~!?@()#&*=+;:,.\wÀ-ž]{1,255}$/,
    organisationName: /^[\s-€$%/'"~!?@()#&*=+;:,.\wÀ-ž]{1,80}$/,
    name: /^[\s-€$%/'"~!?@()#&*=+;:,.\wÀ-ž]{1,100}$/,
    firstname: /^[\s-€$%/'"~!?@()#&*=+;:,.\wÀ-ž]{1,255}$/,
    lastname: /^[\s-€$%/'"~!?@()#&*=+;:,.\wÀ-ž]{1,255}$/,
    description: /^[\s-€$%^£¤°{|}/'"~!?@()#&*=+;:,.\wÀ-ž]{1,1000}$/,
    simpleText: /^.{1,255}$/,
    country: /^[A-Z]{2}$/,
    email: /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~!]+@[A-Z0-9-_]+\.[A-Z]{2,}$/i,
    fieldOfAction: /^[A-Z]+$/,
    phone: /(?:[+]33\s(0))?([1-9\s]{1}[\s]?\d{2}[\s]?\d{2}[\s]?\d{2}[\s]?\d{2})/,
    role: /^[A-Z]+$/,
    url: /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/,
    siret: /(\d{3}[\s]\d{3}[\s]\d{3}[\s]\d{5})/,
    iban: /FR[0-9]{2}[\s][0-9]{4}[\s][0-9]{4}[\s][0-9]{4}[\s][0-9]{4}[\s][0-9]{4}[\s][0-9]{3}$/,
    partnershipGroup: /^[-\wÀ-ž]{1,80}$/,
    zipCode: /^[A-Z0-9\s-]{1,10}$/,
    cityRegion: /^[\wÀ-ž]+([\s\wÀ-ž.:!?%&*()-]){0,80}$/,
    adress: /^[\wÀ-ž]+([\s\wÀ-ž.:,;!?%&*()-]){0,255}$/,
};

export default regexForm;
