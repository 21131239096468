import React from 'react';
import { withTranslation } from 'react-i18next';
import { Message, EmptyIcon, Text, InternalGrid } from '@paygreen/paygreen-ui';
import { IsMobile } from '../../../utils/tools';

const NoDataFoundMessage = ({ t }) => (
    <Message colorPallet="status" hasStaticWidth={true} blockWidth="md">
        <InternalGrid
            displayType="grid"
            gridTemplateColumns="auto 1fr"
            gridGap="md"
            alignItems={IsMobile() ? 'flex-start' : 'center'}
        >
            <EmptyIcon colorPallet="status" iconSize="md" />

            <Text colorPallet="status" textSize="xs" data-cy="no-data-sentence">
                {t('dashboard.noDataFound')}
            </Text>
        </InternalGrid>
    </Message>
);

export default withTranslation('common')(NoDataFoundMessage);
