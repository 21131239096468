import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@paygreen/paygreen-ui';
import { PartnershipCardGrid } from './PartnershipCard';

const PartnershipCardSkeleton = ({ lineNumber, blockWidth, ...rest }) => (
    <PartnershipCardGrid>
        <Skeleton
            blockWidth={blockWidth}
            backgroundColor="grey10"
            colorWab="grey20"
            lineNumber={lineNumber}
            shadowSize="none"
            hasResponsivePadding={true}
            padding="md"
            radiusSize="sm"
            {...rest}
        />

        <Skeleton
            blockWidth={blockWidth}
            backgroundColor="grey10"
            colorWab="grey20"
            lineNumber={lineNumber}
            shadowSize="none"
            hasResponsivePadding={true}
            padding="md"
            radiusSize="sm"
            {...rest}
        />
    </PartnershipCardGrid>
);

PartnershipCardSkeleton.propTypes = {
    lineNumber: PropTypes.number,
    blockWidth: PropTypes.string,
};

PartnershipCardSkeleton.defaultProps = {
    blockWidth: 'none',
    lineNumber: 1,
};

export default PartnershipCardSkeleton;
