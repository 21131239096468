import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    ButtonGroup,
    CheckBoldIcon,
    DaButton,
    Box,
    Checkbox,
    DaLabel,
    Link,
} from '@paygreen/paygreen-ui';
import { FormControlWrapper } from '../../../components';
import { IsDesktop } from '../../../utils/tools';

const UserSpaceForm = ({ t, errors, onSubmit, isLoading, register }) => (
    <form onSubmit={onSubmit} noValidate>
        <Box paddingTop={'xs'}>
            <FormControlWrapper
                inputName="cgu"
                fieldSize="sm"
                errors={errors}
                hasLabel={false}
                blockWidth="md"
                hasStaticWidth={IsDesktop() ? true :false}
            >
                <DaLabel>
                    {t('user.spaces.modal.cguLabel')}
                    <a
                        href="https://storage.googleapis.com/cck-pr-public/CGU%20ClimateKit%202021%20-%20MAJ%2008.06.2021.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Link colorTheme="secondary">
                            {t('user.spaces.modal.cguLink')}
                        </Link>
                    </a>
                </DaLabel>

                <Checkbox
                    id="cgu"
                    name="cgu"
                    label={t('user.spaces.modal.checkCgu')}
                    value="0"
                    fieldSize="md"
                    inputRef={register({
                        required: t('form.error.required'),
                    })}
                />
            </FormControlWrapper>
        </Box>

        <ButtonGroup marginTop="sm">
            <button type="submit">
                <DaButton
                    buttonSize="md"
                    gradient="theme"
                    icon={<CheckBoldIcon />}
                    isDisabled={isLoading}
                >
                    {t('user.spaces.activateClimateButton')}
                </DaButton>
            </button>
        </ButtonGroup>
    </form>
);

UserSpaceForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    errors: PropTypes.object,
    isLoading: PropTypes.bool,
};

UserSpaceForm.defaultProps = {
    isLoading: false,
};

export default withTranslation('common')(UserSpaceForm);
