import React, { useContext } from 'react';
import { withTranslation, I18nContext } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import dayjs from 'dayjs';
import {
    Box,
    InternalGrid,
    HR,
    OutIcon,
    Text,
    DaSelect,
    Link,
} from '@paygreen/paygreen-ui';
import Can from '../../utils/rules/Can';
import IconByName from '../../utils/IconByName';

const HRPopin = () => (
    <HR marginTop="sm" marginBottom="sm" colorPallet="wab" colorWab="grey10" />
);

const UnderlinedLink = (props) => (
    <Link style={{ width: '100%' }} hasUnderline={false} {...props}>
        <Box padding="md" paddingTop="sm" paddingBottom="sm">
            <InternalGrid justifyContent="flex-start">
                {props.children}
            </InternalGrid>
        </Box>
    </Link>
);

const PopinLink = ({ url, icon, text, setOpen, ...rest }) => (
    <RouterLink to={url} onClick={() => setOpen()} {...rest}>
        <UnderlinedLink colorPallet="wab">
            <IconByName
                name={icon}
                colorPallet="wab"
                colorWab="grey60"
                marginRight="sm"
            />
            <Text htmlTag="strong" textSize="sm">
                {text}
            </Text>
        </UnderlinedLink>
    </RouterLink>
);

const PopinUser = ({ t, i18n, user, errorUserMessage, setOpen, logOut }) => {
    const context = useContext(I18nContext);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        dayjs.locale(lng);
        setOpen(false);
    };

    return (
        <>
            <Box padding="md" paddingBottom="none">
                <Text align="center" textSize="xs" colorWab="grey50">
                    <strong>
                        {user.firstname} {user.lastname}
                    </strong>
                </Text>

                <Text
                    align="center"
                    textSize="xxs"
                    colorWab="grey40"
                    hasUppercase={true}
                >
                    {user.role}
                </Text>

                <Text align="center" textSize="xs" colorWab="grey40">
                    {user.accountId}
                </Text>

                {errorUserMessage && (
                    <span className="hideOnDesktop">{errorUserMessage}</span>
                )}

                <Box marginTop="md" marginBottom="md">
                    <DaSelect
                        options={[
                            {
                                value: 'fr',
                                text: t('langs.fr'),
                            },
                            {
                                value: 'en',
                                text: t('langs.en'),
                            },
                        ]}
                        value={context.i18n.language}
                        fieldSize="sm"
                        onChange={(e) => {
                            changeLanguage(e.target.value);
                        }}
                    />
                </Box>

                <HRPopin />
            </Box>

            <Box paddingTop="sm" paddingBottom="sm">
                <PopinLink
                    url="/user/profile"
                    setOpen={setOpen}
                    icon="UserIcon"
                    text={t('topbar.profile')}
                    data-cy="popin-profile"
                />

                <Can
                    condition={user?.role}
                    perform={['routes:shop', 'routes:admin']}
                    yes={() => (
                        <>
                            <PopinLink
                                url="/user/company"
                                setOpen={setOpen}
                                icon="ShopIcon"
                                text={t('topbar.company')}
                                data-cy="popin-company"
                            />

                            <PopinLink
                                url="/user/spaces"
                                setOpen={setOpen}
                                icon="PopinIcon"
                                text={t('topbar.spaces')}
                                data-cy="popin-spaces"
                            />

                            <PopinLink
                                url="/user/billing"
                                setOpen={setOpen}
                                icon="CoinsIcon"
                                text={t('topbar.billing')}
                            />
                        </>
                    )}
                />

                <a href="/#" onClick={logOut} data-cy="popin-logout">
                    <UnderlinedLink colorPallet="status" colorStatus="danger">
                        <OutIcon
                            colorPallet="status"
                            colorStatus="danger"
                            marginRight="sm"
                        />

                        <Text
                            colorPallet="status"
                            colorStatus="danger"
                            htmlTag="strong"
                            textSize="sm"
                        >
                            {t('topbar.logOut')}
                        </Text>
                    </UnderlinedLink>
                </a>
            </Box>
        </>
    );
};

PopinUser.propTypes = {
    user: PropTypes.object.isRequired,
    logOut: PropTypes.func.isRequired,
    errorUserMessage: PropTypes.string,
    /**
     * @description - hook received from DropdownControl component to close popin directly
     */
    setOpen: PropTypes.func.isRequired,
};

PopinUser.defaultTypes = {
    errorUserMessage: '',
};

export default withTranslation('common')(PopinUser);
