import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
    ArrowBottomIcon,
    Card,
    InternalGrid,
    ThemeDefault,
    Title,
} from '@paygreen/paygreen-ui';
import { IsDesktop } from '../../utils/tools';

const closedStyle = css`
    height: ${ThemeDefault.icon.size.md};
    overflow: hidden;
`;

const CollapsibleSection = styled.div`
    ${(props) => (props.isSectionOpen ? null : closedStyle)};
`;

const Content = styled.div`
    overflow-x: auto;
`;

const CollapsibleCard = (props) => {
    const { children, title, ...rest } = props;
    const [sectionOpen, setSectionOpen] = useState(true);

    return (
        <Card {...rest} isShadowWab={true} hasBackground={true} radiusSize="sm">
            {IsDesktop() ? (
                <>
                    <Title htmlTag="h3" textSize="md" colorWab="grey30">
                        {title}
                    </Title>

                    {children}
                </>
            ) : (
                <CollapsibleSection isSectionOpen={sectionOpen}>
                    <InternalGrid
                        onClick={() => setSectionOpen(!sectionOpen)}
                        style={{ cursor: 'pointer' }}
                        tabindex="0"
                    >
                        <Title textSize="md" colorWab="grey30">
                            {title}
                        </Title>

                        <ArrowBottomIcon
                            iconSize="md"
                            colorPallet="wab"
                            colorWab="grey60"
                            rotateSize={sectionOpen ? 'd180' : 'd0'}
                        />
                    </InternalGrid>

                    <Content>{children}</Content>
                </CollapsibleSection>
            )}
        </Card>
    );
};

CollapsibleCard.propTypes = {
    blockWidth: PropTypes.string,
    paddingTop: PropTypes.string,
    paddingBottom: PropTypes.string,
    paddingLateral: PropTypes.string,
    title: PropTypes.string,
};

CollapsibleCard.defaultProps = {
    blockWidth: 'md',
    paddingTop: 'xs',
    paddingBottom: 'xs',
    paddingLateral: 'xs',
};

export default CollapsibleCard;
