import React from 'react';
import PropTypes from 'prop-types';
import { Message as UIMessage, Text } from '@paygreen/paygreen-ui';
import IconByName from '../../utils/IconByName';

const Message = ({
    children,
    message,
    type,
    paddingLateral,
    hasStaticWidth,
    icon,
    iconSize,
    textSize,
    marginBottom,
    marginTop,
    alignText,
    ...rest
}) => {
    if (!message && !children) return null;

    return (
        <UIMessage
            hasStaticWidth={hasStaticWidth}
            colorStatus={type === 'error' ? 'danger' : type}
            marginTop={marginTop}
            marginBottom={marginBottom}
            {...rest}
        >
            {icon && (
                <IconByName name={icon} iconSize={iconSize} marginRight="sm" />
            )}

            {message ? (
                <Text
                    textSize={textSize}
                    align={alignText ? alignText : icon ? 'left' : 'center'}
                    paddingLateral={paddingLateral}
                >
                    {message}
                </Text>
            ) : (
                children
            )}
        </UIMessage>
    );
};

Message.propTypes = {
    type: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.node,
    ]),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    marginTop: PropTypes.string,
    marginBottom: PropTypes.string,
    paddingLateral: PropTypes.string,
    hasStaticWidth: PropTypes.bool,
    icon: PropTypes.string,
    textSize: PropTypes.string,
    alignText: PropTypes.string
};

Message.defaultProps = {
    type: 'default',
    marginTop: 'none',
    marginBottom: 'none',
    paddingLateral: 'sm',
    hasStaticWidth: true,
    textSize: 'xs',
    alignText: "center"
};

export default Message;
