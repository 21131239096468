import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Sdk } from 'api-green-node';
import { ModalControl, ModalProvider, Text } from '@paygreen/paygreen-ui';
import { useAuth } from '../../context';
import { CardClassic, MainLogin } from '../../components';
import { setBillingLocalStorage } from '../../utils/authentication';
import { apiPublic } from '../../utils/hooks';
import { accountErrorModes } from '../../utils/constants';
import ForgottenPasswordModal from '../password/ForgottenPasswordModal';
import LoginForm from './LoginForm';
import LocationMessage from './LocationMessage';
import LoginAcountErrorMessage from './LoginAcountErrorMessage';
import { getAccount } from './service';

const Login = ({ t }) => {
    const { updateAuthInformations, checkLocalTokens, getSdk, localConfig } =
        useAuth();

    const location = useLocation();
    const history = useHistory();
    const referer =
        location.state && location.state.referer !== undefined
            ? location.state.referer
            : '/';
    const locationStatus = location.state && location.state.status;

    const [errorMessage, setErrorMessage] = useState(null);
    const [accountErrorMessage, setAccountErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isAskPasswordSuccess, setIsAskPasswordSuccess] = useState(false);
    const [username, setUsername] = useState();

    // we create a ref to detect if component is mounted
    const isMounted = useRef(false);

    // react-hook-form
    const defaultValues = {
        accountId: (location.state && location.state.accountId) || '',
        username: (location.state && location.state.username) || '',
        password: '',
    };

    const { handleSubmit, register, errors, setValue, getValues } = useForm({
        defaultValues: defaultValues,
    });

    /**
     * @description to verify tokens and account validity before setting local storage and redirect to dashboard (since API doesn't detect it during login)
     * @param {object} data - data received from API after login request
     * @param {object} values - values from login form
     */
    const checkValidity = useCallback(
        (data, values) => {
            const tempConfig = {
                mode: localConfig.mode,
                host: localConfig.host,
                token: data.access_token,
                refreshToken: data.refresh_token,
            };
            const sdk = new Sdk(tempConfig);

            // we first check if user is a charity (because we can't use GET /account with ASSOCIATION role)
            sdk.user.getOne(values.username).then((res) => {
                if (res.success && res.dataInfo.role === 'ASSOCIATION') {
                    if (isMounted.current) {
                        setIsSuccess(true);
                        updateAuthInformations(data, values);

                        if (checkLocalTokens() === 'valid') {
                            history.push(referer);
                        }
                    }
                } else {
                    getAccount(data.access_token, values.accountId)
                        .then((res) => {
                            if (res.data.usesArrondi === '1') {
                                if (isMounted.current) {
                                    const {
                                        charityExpirationDate,
                                        usesTree,
                                        usesArrondi,
                                    } = res.data;
                                    const { status } = res.data.charityBilling;

                                    setIsSuccess(true);
                                    setBillingLocalStorage({
                                        sepaStatus: status || 'UNSIGNED',
                                        expirationDate: charityExpirationDate,
                                        isExpired:
                                            charityExpirationDate <
                                            Date.now() / 1000,
                                        usesArrondi: usesArrondi,
                                        usesTree: usesTree,
                                    });
                                    updateAuthInformations(data, values);
                                }
                            } else {
                                if (isMounted.current) {
                                    setErrorMessage();
                                    setAccountErrorMessage(
                                        accountErrorModes.notRegistered,
                                    ); // user hasn't subscribed to charitykit
                                }
                            }
                        })
                        .catch((error) => {
                            if (error?.response?.data.status === 403) {
                                if (isMounted.current) {
                                    setErrorMessage();
                                    setAccountErrorMessage(
                                        accountErrorModes.notValidated,
                                    ); // user hasn't validated his account
                                }
                            } else {
                                isMounted.current &&
                                    setErrorMessage(
                                        error?.response?.data.detail,
                                    );
                            }
                        })
                        .finally(() => {
                            isMounted.current && setIsLoading(false);

                            if (checkLocalTokens() === 'valid') {
                                history.push(referer);
                            }
                        });
                }
            });
        },
        [
            checkLocalTokens,
            history,
            referer,
            updateAuthInformations,
            localConfig,
        ],
    );

    const submitForm = (values) => {
        setIsLoading(true);

        // in case we have location and history states after email validation, we clean history on submit to remove data on first login
        if (location && history) {
            history.replace();
        }

        getSdk(true)
            .authentication.login(
                values.username,
                values.password,
                values.accountId,
            )
            .then((res) => {
                if (res.success === true) {
                    isMounted.current && checkValidity(res.dataInfo, values);
                } else {
                    if (isMounted.current) {
                        setErrorMessage(res.dataInfo.detail);
                        setIsLoading(false);
                    }
                }
            });
    };

    /**
     * Post request to API for forgotten password
     * @param {string} username
     */
    const askPassword = (username) => {
        setIsLoading(true);
        const usernameObject = { username: username };

        apiPublic()
            .post('/password/forgot', usernameObject)
            .then(() => {
                isMounted.current && setIsAskPasswordSuccess(true);
            })
            .catch(() => {
                // we validate this step even in case of error from API
                isMounted.current && setIsAskPasswordSuccess(true);
            })
            .finally(() => isMounted.current && setIsLoading(false));
    };

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    if (checkLocalTokens() === 'valid' && !isSuccess) {
        return <Redirect to={referer} />;
    }

    if (isAskPasswordSuccess) {
        return (
            <Redirect
                to={{
                    pathname: '/forgotten-password',
                    state: {
                        username: username,
                    },
                }}
            />
        );
    }

    return (
        <MainLogin
            helmetTitle={t('login.metaTitle') + ' - ' + t('main.metaTitle')}
            isLoading={isLoading}
        >
            <ModalProvider>
                <CardClassic
                    blockWidth="sm"
                    paddingLateral="lg"
                    paddingTop="md"
                    paddingBottom="md"
                    title={t('login.title')}
                    titleColorWab="grey30"
                    titleAlign="center"
                    titleSize="md"
                    isShadowWab={true}
                >
                    {locationStatus ? (
                        <LocationMessage
                            locationStatus={locationStatus}
                            location={location}
                        />
                    ) : null}

                    <LoginForm
                        errors={errors}
                        setValue={setValue}
                        register={register}
                        errorMessage={!isLoading ? errorMessage : null}
                        onSubmit={handleSubmit(submitForm)}
                        accountErrorMessage={
                            accountErrorMessage &&
                            !isLoading && (
                                <LoginAcountErrorMessage
                                    account={getValues().accountId}
                                    mode={accountErrorMessage}
                                />
                            )
                        }
                    />
                </CardClassic>

                {!isLoading && (
                    <ModalControl>
                        <ForgottenPasswordModal
                            askPassword={askPassword}
                            setUsername={setUsername}
                            title={t('forgottenPassword.sendMailModal.title')}
                            mode="send"
                        >
                            <Text textSize="sm">
                                <Trans
                                    i18nKey={
                                        'forgottenPassword.sendMailModal.introText'
                                    }
                                >
                                    ...
                                    <Text htmlTag="strong" textSize="sm">
                                        {{
                                            username: t(
                                                'forgottenPassword.sendMailModal.username',
                                            ),
                                        }}
                                    </Text>
                                    ...
                                </Trans>
                            </Text>
                        </ForgottenPasswordModal>
                    </ModalControl>
                )}
            </ModalProvider>
        </MainLogin>
    );
};

export default withTranslation('common')(Login);
