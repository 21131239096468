import React from 'react';
import PropTypes from 'prop-types';
import { Card, Title, InternalGrid } from '@paygreen/paygreen-ui';
import { IsMobile } from '../../utils/tools';

const CardClassic = ({
    t,
    title,
    titleColorWab,
    titleAlign,
    titleSize,
    paddingTop,
    paddingBottom,
    paddingLateral,
    blockWidth,
    actionIcon,
    radiusSize,
    children,
    ...rest
}) => {
    return (
        <Card
            blockWidth={blockWidth}
            paddingLateral={paddingLateral}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            radiusSize={radiusSize}
            {...rest}
        >
            {title ? (
                <InternalGrid
                    hasStaticWidth={false}
                    displayType={titleAlign === 'center' ? 'flex' : 'grid'}
                    gridTemplateColumns="1fr auto"
                    gridGap="md"
                    align={titleAlign}
                    alignItems={IsMobile() ? 'center' : 'flex-start'}
                >
                    <Title
                        htmlTag="h2"
                        marginBottom="sm"
                        colorWab={titleColorWab}
                        align={titleAlign}
                        textSize={titleSize}
                    >
                        {title}
                    </Title>

                    {actionIcon ? actionIcon : null}
                </InternalGrid>
            ) : null}
            {children}
        </Card>
    );
};

CardClassic.propTypes = {
    title: PropTypes.string,
    paddingTop: PropTypes.string,
    paddingBottom: PropTypes.string,
    paddingLateral: PropTypes.string,
    blockWidth: PropTypes.string,
    titleColorWab: PropTypes.string,
    titleAlign: PropTypes.string,
    titleSize: PropTypes.string,
    actionIcon: PropTypes.element,
    radiusSize: PropTypes.string,
};

CardClassic.defaultProps = {
    paddingTop: 'sm',
    paddingLateral: 'sm',
    paddingBottom: 'sm',
    blockWidth: 'md',
    titleColorWab: 'grey50',
    titleAlign: 'left',
    titleSize: 'base',
    radiusSize: 'sm',
};

export default CardClassic;
