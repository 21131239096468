import { Country } from 'api-green-node';

/**
 * @description - array of object with countries name and value for country selector for user profile
 * @param {function} t - from i18next
 */
const countryOptions = (t) => {
    return Object.keys(Country)
        .slice(0, Object.keys(Country).length / 2)
        .map((option) => {
            return {
                value: Country[option],
                text: t(`countries:${Country[option]}`),
            };
        });
};

/**
 * @description to adapt redirection link to back-office Climate for each env
 */
const climateEnvLink = () => {
    const env = process.env.REACT_APP_ACTIVE_MODE;

    switch (true) {
        case env === 'RECETTE':
            return 'https://rc-climatekit.paygreen.fr';
        case env === 'SANDBOX':
            return 'https://sb-climatekit.paygreen.fr';
        case env === 'PRODUCTION':
            return 'https://climatekit.paygreen.fr';
        default:
            return 'https://rc-climatekit.paygreen.fr'; // default is for local tests
    }
};

export { countryOptions, climateEnvLink };
