import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import {
    Card,
    InternalGrid,
    Text,
    DaButton,
    ArrowLongRightIcon,
    ButtonGroup,
    HeartIcon,
} from '@paygreen/paygreen-ui';
import { LogoArrondi, LogoPaygreenWithMode } from '../../assets/logos';
import { IsBigDesktop } from '../../utils/tools';
import { climateEnvLink } from '../user/shared';

const SubscriptionAlternatives = ({ t }) => {
    const cardProps = {
        colorWab: 'grey10',
        shadowSize: 'none',
        paddingLateral: 'xs',
        paddingBottom: 'xs',
        paddingTop: 'xs',
        radiusSize: 'none',
        blockWidth: 'lg',
    };

    return (
        <Card hasBackground={false} blockWidth="md">
            <InternalGrid
                displayType="grid"
                gridGap="lg"
                justifyItems="stretch"
                childrenMarginTop={IsBigDesktop() ? 'none' : 'md'}
            >
                {IsBigDesktop() && (
                    <LogoPaygreenWithMode justifyContent="flex-start" />
                )}

                <InternalGrid alignItems="center" flexWrap="nowrap">
                    <LogoArrondi blockWidth="60px" blockHeight="60px" />

                    <Text marginLateral="xs" textSize="sm" colorWab="grey50">
                        {t('subscription.arrondiInfo')}
                    </Text>
                </InternalGrid>

                <Card {...cardProps}>
                    <Text htmlTag="strong" colorWab="grey40">
                        {t(
                            'subscription.subscriptionAlternatives.alreadyClientClimate',
                        )}
                    </Text>

                    <Text textSize="xs">
                        <Trans
                            i18nKey={
                                'subscription.subscriptionAlternatives.activateSpace'
                            }
                        >
                            Activez Charity directement depuis votre page
                            <Text
                                htmlTag="strong"
                                textSize="xs"
                                style={{ fontStyle: 'italic' }}
                            >
                                {{
                                    mySpaces: t(
                                        'subscription.subscriptionAlternatives.mySpaces',
                                    ),
                                }}
                            </Text>
                            en quelques clics
                        </Trans>
                    </Text>

                    <ButtonGroup marginTop="xs" marginBottom="xs">
                        <a
                            href={climateEnvLink() + '/user/spaces'}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <DaButton
                                gradient="theme"
                                icon={<ArrowLongRightIcon />}
                            >
                                {t('login.button')}
                            </DaButton>
                        </a>
                    </ButtonGroup>
                </Card>

                <Card {...cardProps}>
                    <Text htmlTag="strong" colorWab="grey40">
                        {t(
                            'subscription.subscriptionAlternatives.isAssociation',
                        )}
                    </Text>

                    <Text textSize="xs">
                        {t('subscription.subscriptionAlternatives.charity')}
                    </Text>

                    <ButtonGroup marginTop="xs" marginBottom="xs">
                        <a href="https://share.hsforms.com/3792340/a65e883e-50f4-4d38-af45-c5106663f2f8">
                            <DaButton
                                gradient="theme"
                                colorTheme="quaternary"
                                icon={<HeartIcon />}
                            >
                                {t('subscription.button')}
                            </DaButton>
                        </a>
                    </ButtonGroup>
                </Card>
            </InternalGrid>
        </Card>
    );
};

export default withTranslation('common')(SubscriptionAlternatives);
