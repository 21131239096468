import React, { useEffect, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ButtonGroup } from '@paygreen/paygreen-ui';
import { useAPIError } from '../../../../context';
import { apiAuth } from '../../../../utils/hooks';
import {
    CancelButton,
    ValidateButton,
    ModalForm,
} from '../../../../components';
import AdministratorManagementForm from './AdministratorManagementForm';

const AdministratorManagementModal = ({
    t,
    setOpen,
    isOpen,
    accountData,
    setAccountData,
    setIsLoading,
}) => {
    const { addError } = useAPIError();

    // initializing all react-hook-form values and methods
    const defaultValues = {
        administrator:
            accountData?.administrator?.firstname +
            ' ' +
            accountData?.administrator?.lastname,
        email: accountData?.administrator?.contact.email,
        phone: accountData?.administrator?.contact.phone?.slice(4) || '', // the '' is necessary to avoid warning from useController in phone input that doesn't like undefined value
    };

    const { register, handleSubmit, setValue, errors, clearErrors, control } =
        useForm();

    const accountId = accountData?.administrator?.accountId;
    const username = accountData?.administrator?.username;

    // Watch for changes on inputs
    useEffect(() => {
        register({ name: 'email' });
        register({ name: 'phone' });
        register({ name: 'administrator' });
    }, [register]);

    /**
     * PATCH request to API to update user's account
     * @param {object} updatedEmail - object with email updated
     */
    const updateEmail = useCallback(
        (updatedEmail) => {
            setIsLoading(true);

            apiAuth()
                .patch(`/account/${accountId}/user/${username}`, updatedEmail)
                .then(() => {
                    setAccountData({
                        ...accountData,
                        administrator: {
                            ...accountData.administrator,
                            contact: {
                                ...accountData.administrator.contact,
                                email: updatedEmail.email,
                            },
                        },
                    });
                    toast(t('form.toastSuccess.adminUserEmailUpdate'));
                })
                .catch((error) =>
                    addError(
                        error?.response?.data.detail,
                        error?.response?.data.status,
                    ),
                )
                .finally(() => setIsLoading(false));
        },
        [
            addError,
            setIsLoading,
            accountId,
            username,
            setAccountData,
            accountData,
            t,
        ],
    );

    const submitForm = (data) => {
        setOpen(false);

        updateEmail({ email: data.email });
    };

    const cancelCloseModal = () => {
        setOpen(false);

        // we need the reset methods to wait until modal is closed
        setTimeout(() => {
            clearErrors();
            setValue('email', defaultValues.email);
        }, 500);
    };

    return (
        <ModalForm
            modalTitle={t('user.edit.title')}
            blockWidth="md"
            paddingLateral="md"
            isOpen={isOpen}
            actionOnClose={() => cancelCloseModal()}
        >
            <AdministratorManagementForm
                errors={errors}
                setValue={setValue}
                register={register}
                control={control}
                onSubmit={handleSubmit(submitForm)}
                defaultValues={defaultValues}
            >
                <ButtonGroup marginTop="sm" marginBottom="sm">
                    <CancelButton
                        onClick={() => cancelCloseModal()}
                        buttonSize="md"
                    >
                        {t('form.button.cancel')}
                    </CancelButton>

                    <ValidateButton buttonSize="md">
                        {t('form.button.save')}
                    </ValidateButton>
                </ButtonGroup>
            </AdministratorManagementForm>
        </ModalForm>
    );
};

AdministratorManagementModal.propTypes = {
    /**
     * @description setOpen and isOpen are automatically received from ModalControl parent
     */
    setOpen: PropTypes.func,
    isOpen: PropTypes.bool,
    accountData: PropTypes.object,
    setIsLoading: PropTypes.func.isRequired,
    setAccountData: PropTypes.func.isRequired,
};

export default withTranslation('common')(AdministratorManagementModal);
