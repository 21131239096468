const rules = {
    PAYGREEN: {
        static: ['routes:admin', 'sidebar:shop', 'editForm:shop', 'sidebar:admin'],
    },
    ADMIN: {
        static: ['routes:shop', 'sidebar:shop', 'editForm:shop'],
    },
    USER: {
        static: ['routes:shop', 'sidebar:shop', 'editForm:shop'],
    },
    ASSOCIATION: {
        static: ['routes:charity', 'sidebar:charity', 'editForm:charity'],
    },
    RECETTE: {
        static: ['routes:dev'],
    },
    SANDBOX: {
        static: ['routes:sandbox'],
    },
    PRODUCTION: {
        static: ['routes:prod'],
    },
};

export default rules;
