// Call Partnerships
import { apiAuth } from '../../utils/hooks';

const getAllCharities = (params, mode) => {
    return apiAuth(mode)
        .get('/association', { params: params })
        .then((data) => data)
        .catch((error) => {
            throw error;
        });
};

const getAllPartnerships = (mode) => {
    return apiAuth(mode)
        .get('/partnership')
        .then((data) => data)
        .catch((error) => {
            throw error;
        });
};

const createPartnership = (idAssociation) => {
    return apiAuth()
        .post(`/partnership`, { idAssociation: idAssociation })
        .then((data) => data)
        .catch((error) => {
            throw error;
        });
};

const updatePartnershipAsCharity = (idPartnership, status) => {
    return apiAuth()
        .put(`/partnership/${idPartnership}`, { associationStatus: status })
        .then((data) => data)
        .catch((error) => {
            throw error;
        });
};

const updatePartnershipAsShop = (idPartnership, status, mode) => {
    return apiAuth(mode)
        .put(`/partnership/${idPartnership}`, { accountStatus: status })
        .then((data) => data)
        .catch((error) => {
            throw error;
        });
};

const deletePartnership = (idPartnership, mode) => {
    return apiAuth(mode)
        .delete(`/partnership/${idPartnership}`)
        .then((data) => data)
        .catch((error) => {
            throw error;
        });
};

const getAllPartnershipGroups = (mode) => {
    return apiAuth(mode)
        .get('/partnership-group')
        .then((data) => data)
        .catch((error) => {
            throw error;
        });
};

const getPartnershipGroup = (idPartnershipGroup) => {
    return apiAuth()
        .get(`/partnership-group/${idPartnershipGroup}`)
        .then((data) => data)
        .catch((error) => {
            throw error;
        });
};

const createPartnershipGroup = (newPartnershipGroup, mode) => {
    return apiAuth(mode)
        .post('/partnership-group', newPartnershipGroup)
        .then((data) => data)
        .catch((error) => {
            throw error;
        });
};

const updatePartnershipGroup = (
    idPartnership,
    updatedPartnershipGroup,
    mode,
) => {
    return apiAuth(mode)
        .put(`/partnership-group/${idPartnership}`, updatedPartnershipGroup)
        .then((data) => data)
        .catch((error) => {
            throw error;
        });
};

const deletePartnershipGroup = (idPartnership, mode) => {
    return apiAuth(mode)
        .delete(`/partnership-group/${idPartnership}`)
        .then((data) => data)
        .catch((error) => {
            throw error;
        });
};

export {
    getAllCharities,
    getAllPartnerships,
    createPartnership,
    updatePartnershipAsCharity,
    updatePartnershipAsShop,
    deletePartnership,
    getAllPartnershipGroups,
    getPartnershipGroup,
    createPartnershipGroup,
    updatePartnershipGroup,
    deletePartnershipGroup,
};
