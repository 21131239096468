// Call account data
import { apiAuth } from '../../utils/hooks';

const getAccount = (token, idAccount, mode) => {
    return apiAuth(mode, token)
        .get(`/account/${idAccount}`)
        .then((data) => data)
        .catch((error) => {
            throw error;
        });
};

export { getAccount };
