import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    Box,
    DaSelect,
    Dot,
    InternalGrid,
    Text,
    Pagination,
} from '@paygreen/paygreen-ui';
import { IsMobile } from '../../../utils/tools';

/**
 * Pagination Table for ServerSide Table with react table
 */
const PaginationTable = ({
    setPageSize,
    pageCount,
    pageIndex,
    colorTheme,
    gotoPage,
    setActivePageReset,
    t,
}) => (
    <Box paddingTop="sm" paddingBottom="md">
        <InternalGrid
            alignItems="center"
            childrenMarginBottom="sm"
            childrenMarginLateral="sm"
            justifyContent="center"
        >
            <DaSelect
                fieldSize="sm"
                onChange={(e) => {
                    setPageSize(Number(e.target.value));
                }}
                options={[
                    { value: '25', text: '25' },
                    { value: '50', text: '50' },
                    { value: '75', text: '75' },
                    { value: '100', text: '100' },
                ]}
            />

            <Text htmlTag="strong" colorWab="grey30" textSize="xs">
                {t('table.resultByPage')}
            </Text>

            {IsMobile() ? null : (
                <Box>
                    <Dot
                        dotStyle="slashes"
                        colorPallet="wab"
                        colorWab="grey10"
                    />
                </Box>
            )}

            <Pagination
                pageCount={pageCount}
                pageIndex={pageIndex}
                colorTheme={colorTheme}
                gotoPage={gotoPage}
                onClick={() => setActivePageReset ? setActivePageReset(false) : null}
            />
        </InternalGrid>
    </Box>
);

PaginationTable.propTypes = {
    setPageSize: PropTypes.func.isRequired,
    pageCount: PropTypes.number,
    pageIndex: PropTypes.number,
    colorTheme: PropTypes.string,
    gotoPage: PropTypes.func,
    setActivePageReset: PropTypes.func,
};

export default withTranslation('common')(PaginationTable);
