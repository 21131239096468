import { useEffect, useState, useCallback } from 'react';
import { apiAuth } from '../../../utils/hooks';
import { useAPIError } from '../../../context';

/**
 * Call the api to get prices list for each space (Climate or Charity)
 * @returns spacePrices, 1 array with all prices
 */
const useSpacesPricing = (mode, isMounted) => {
    const [isLoading, setIsLoading] = useState(false);
    const [spacePrices, setSpacePrices] = useState([]);

    const { addError } = useAPIError();

    const fetchGlobalPricing = useCallback(() => {
        setIsLoading(true);

        apiAuth()
            .get('/gateway/billing/subscription-tariff', {
                params: { service: mode.toUpperCase() },
            })
            .then((res) => {
                isMounted.current && setSpacePrices(res.data.data);
            })
            .catch((error) => {
                addError(
                    error?.response?.data.detail,
                    error?.response?.data.status,
                );
            })
            .finally(() =>  isMounted.current && setIsLoading(false));
    }, [addError, mode, isMounted]);

    useEffect(() => {
        if (mode && isMounted) {
            fetchGlobalPricing();
        }
    }, [fetchGlobalPricing, mode, isMounted]);

    return {
        spacePrices,
        isLoading,
    };
};

export default useSpacesPricing;
