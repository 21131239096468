import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import { Text, Link } from '@paygreen/paygreen-ui';
import { accountErrorModes } from '../../utils/constants';
import { climateEnvLink } from '../user/shared';

const LoginAccountErrorMessage = ({ account, mode, t }) => (
    <>
        <Trans i18nKey={`login.accountErrorMessage.${mode}`}>
            ...
            <Text
                htmlTag="strong"
                colorPallet="status"
                colorStatus="warning"
                textSize="xs"
            >
                {{
                    account,
                }}
            </Text>
            ...
        </Trans>

        {mode === accountErrorModes.notRegistered ? (
            <>
                {t('subscription.activateSpaceMessage')}
                <a
                    href={climateEnvLink() + '/user/spaces'}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Link colorPallet="status" colorStatus="warning">
                        {t('subscription.mySpaces')}
                    </Link>
                </a>
            </>
        ) : null}
    </>
);

LoginAccountErrorMessage.propTypes = {
    account: PropTypes.object,
    mode: PropTypes.oneOf(Object.values(accountErrorModes)),
};

LoginAccountErrorMessage.defaultProps = {
    mode: 'notRegistered',
};

export default withTranslation('common')(LoginAccountErrorMessage);
