import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { DaSelect } from '@paygreen/paygreen-ui';
import { toast } from 'react-toastify';
import { CardInfo } from '../../shared';
import { apiAuth } from '../../../../utils/hooks';
import { useAPIError } from '../../../../context';
import useTeamMembers from '../../hooks/useTeamMembers';
import { LineKeyValue } from '../../../../components';
import { shortcutName } from '../../adminUsersUtils';

const TeamManagementCard = ({
    t,
    isLoading,
    accountData,
    setIsLoading,
    setAccountData,
}) => {
    const { addError } = useAPIError();
    const { teamSales, teamCSMs, teamAMs } = useTeamMembers();
    const accountId = accountData?.administrator?.accountId;

    const noOneValueForSelect = [
        {
            value: '0',
            text: t('admin.user.accountManagementInfos.noOne'),
        },
    ];

    /**
     * @param {object} tm
     * @returns {object} formatted value to be used in select
     */
    const selectTeamMap = (tm) => {
        return {
            value: tm.idAccountTeam + '',
            text: shortcutName(tm.displayName),
        };
    };

    /**
     *
     * @param {object} accountTeam
     */
    const updateManager = (accountTeam) => {
        setIsLoading(true);
        apiAuth()
            .patch(`/account/${accountId}`, accountTeam)
            .then((res) => {
                if (res.status === 200) {
                    toast(t('form.toastSuccess.accountManagementInfos'));
                    setAccountData({ ...accountData, ...accountTeam });
                }
            })
            .catch((error) =>
                addError(
                    error?.response?.data.detail,
                    error?.response?.data.status,
                ),
            )
            .finally(() => setIsLoading(false));
    };

    return (
        <CardInfo
            isLoading={isLoading}
            title={t('admin.user.accountManagementInfos.title')}
            hasInput={true}
        >
            <>
                <LineKeyValue
                    hasUppercase={false}
                    keyLabel={t('admin.users.table.accountManager')}
                    keyValue={accountData && teamAMs.length > 0}
                    textSize="xs"
                >
                    <DaSelect
                        fieldSize="sm"
                        onChange={(e) => {
                            if (e.target.value) {
                                updateManager({
                                    idAccountManager: Number(e.target.value),
                                });
                            }
                        }}
                        options={noOneValueForSelect.concat(
                            teamAMs.map(selectTeamMap),
                        )}
                        value={accountData?.idAccountManager || '0'}
                    />
                </LineKeyValue>

                <LineKeyValue
                    hasUppercase={false}
                    keyLabel={t('admin.users.table.commercial')}
                    keyValue={accountData && teamSales.length > 0}
                    textSize="xs"
                >
                    <DaSelect
                        fieldSize="sm"
                        onChange={(e) => {
                            if (e.target.value) {
                                updateManager({
                                    idSales: Number(e.target.value),
                                });
                            }
                        }}
                        options={noOneValueForSelect.concat(
                            teamSales.map(selectTeamMap),
                        )}
                        value={accountData?.idSales || '0'}
                    />
                </LineKeyValue>

                <LineKeyValue
                    hasUppercase={false}
                    keyLabel={t('admin.users.table.csm')}
                    keyValue={accountData && teamCSMs.length > 0}
                    textSize="xs"
                >
                    <DaSelect
                        fieldSize="sm"
                        onChange={(e) => {
                            if (e.target.value) {
                                updateManager({
                                    idCustomerSuccessManager: Number(
                                        e.target.value,
                                    ),
                                });
                            }
                        }}
                        options={noOneValueForSelect.concat(
                            teamCSMs.map(selectTeamMap),
                        )}
                        value={accountData?.idCustomerSuccessManager || '0'}
                    />
                </LineKeyValue>
            </>
        </CardInfo>
    );
};

TeamManagementCard.propTypes = {
    accountData: PropTypes.object,
    setAccountData: PropTypes.func,
    isLoading: PropTypes.bool,
    setIsLoading: PropTypes.func,
};

TeamManagementCard.defaultProps = {
    isLoading: false,
};

export default withTranslation('common')(TeamManagementCard);
