import React from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import { InternalGrid, Text } from '@paygreen/paygreen-ui';
import { ErrorBoundary } from '../../components';

const SubscriptionSuccessMessage = ({ newUser }) => {
    const {
        contact: { email },
        user: { firstname, lastname },
    } = newUser;

    return (
        <ErrorBoundary>
            <InternalGrid
                justifyContent="center"
                alignItems="center"
                childrenMarginBottom="md"
                childrenMarginTop="md"
                childrenMarginLateral="md"
            >
                <Text
                    textSize="md"
                    align="center"
                    colorPallet="theme"
                    colorTheme="quaternary"
                    htmlTag="strong"
                >
                    <span role="img" aria-label="beating heart emoji">
                        💓
                    </span>

                    <Trans i18nKey="subscription.success.thankYou">
                        ...
                        <Text
                            colorPallet="theme"
                            colorTheme="quaternary"
                            htmlTag="strong"
                            textSize="md"
                        >
                            {{
                                publicname: firstname + ' ' + lastname,
                            }}
                            ...
                        </Text>
                    </Trans>

                    <span
                        role="img"
                        aria-label="smiling Face with hearts emoji"
                    >
                        🥰
                    </span>
                </Text>

                <Text textSize="sm" align="center">
                    <Trans i18nKey="subscription.success.sentence">
                        ...
                        <Text textSize="sm" htmlTag="strong">
                            {{
                                email: email,
                            }}
                            ...
                        </Text>
                    </Trans>
                </Text>
            </InternalGrid>
        </ErrorBoundary>
    );
};

SubscriptionSuccessMessage.propTypes = {
    newUser: PropTypes.object.isRequired,
};

export default withTranslation('common')(SubscriptionSuccessMessage);
