import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import {
    DaInput,
    DaSelect,
    InternalGrid,
    Text,
    SkeletonItem,
} from '@paygreen/paygreen-ui';
import { ControlledDaInput, FormControlWrapper } from '../../../components';
import { IsMobile, IsDesktop } from '../../../utils/tools';
import regexForm from '../../../utils/regexForm';
import { userInputsPatterns, countryOptions } from '../shared';

const UserProfileForm = ({
    t,
    onSubmit,
    children,
    defaultValues,
    isLoading,
    setIsEditing,
}) => {
    const { register, errors, setValue, control } = useFormContext();

    const transversalInputProps = {
        errors: errors,
        i18labelBase: 'user',
        isRequired: true,
        hasStaticWidth: true,
    };

    return (
        <form onSubmit={onSubmit} noValidate>
            <FormControlWrapper
                inputName="role"
                marginBottom="md"
                errors={errors}
                i18labelBase="user"
            >
                <SkeletonItem isLoading={isLoading} colorWab="grey10">
                    <DaInput
                        name={'role'}
                        id={'role'}
                        type="text"
                        defaultValue={defaultValues['role']}
                        inputRef={register({
                            required: t('form.error.required'),
                            pattern: {
                                value: regexForm.simpleText,
                                message: t(`form.error.organisationName`),
                            },
                        })}
                        hasStaticWidth={true}
                        disabled
                    />
                </SkeletonItem>

                <Text
                    colorWab="grey40"
                    marginBottom="xs"
                    textSize={IsDesktop() ? 'xxs' : 'xs'}
                >
                    {t('user.edit.warningRole')}
                </Text>
            </FormControlWrapper>

            <InternalGrid
                displayType="grid"
                gridGap="md"
                alignItems="stretch"
                justifyItems="stretch"
                gridTemplateColumns={IsMobile() ? '1fr' : '1fr 1fr'}
            >
                {userInputsPatterns
                    .filter(
                        (elem) =>
                            elem.name === 'firstname' ||
                            elem.name === 'lastname',
                    )
                    .map((element) => (
                        <FormControlWrapper
                            key={element.name}
                            inputName={element.name}
                            {...transversalInputProps}
                        >
                            <SkeletonItem
                                isLoading={isLoading}
                                colorWab="grey10"
                            >
                                <DaInput
                                    onChange={(e) => {
                                        setIsEditing(true);
                                        setValue(element.name, e.target.value);
                                    }}
                                    name={element.name}
                                    id={element.name}
                                    type={element.type}
                                    defaultValue={defaultValues[element.name]}
                                    inputRef={register({
                                        required: t('form.error.required'),
                                        pattern: {
                                            value: element.pattern,
                                            message: t(
                                                `form.error.${element.name}`,
                                            ),
                                        },
                                    })}
                                    blockWidth="lg"
                                />
                            </SkeletonItem>
                        </FormControlWrapper>
                    ))}
            </InternalGrid>

            <FormControlWrapper
                inputName={'email'}
                marginTop={IsMobile() ? 'sm' : 'md'}
                marginBottom={IsMobile() ? 'sm' : 'md'}
                {...transversalInputProps}
            >
                <SkeletonItem isLoading={isLoading} colorWab="grey10">
                    <DaInput
                        onChange={(e) => {
                            setIsEditing(true);
                            setValue('email', e.target.value);
                        }}
                        name={'email'}
                        id={'email'}
                        type={'email'}
                        defaultValue={defaultValues['email']}
                        inputRef={register({
                            required: t('form.error.required'),
                            pattern: {
                                value: regexForm.email,
                                message: t(`form.error.email`),
                            },
                        })}
                        hasStaticWidth={true}
                    />
                </SkeletonItem>
            </FormControlWrapper>

            <InternalGrid
                displayType="grid"
                gridGap="md"
                alignItems="stretch"
                justifyItems="stretch"
                gridTemplateColumns={IsMobile() ? '1fr' : '1fr 1fr'}
            >
                <FormControlWrapper
                    inputName="phone"
                    marginTop={IsMobile() ? 'xs' : null}
                    {...transversalInputProps}
                >
                    <SkeletonItem isLoading={isLoading} colorWab="grey10">
                        <ControlledDaInput
                            inputName="phone"
                            inputPattern={regexForm.phone}
                            isRequired={true}
                            control={control}
                            defaultValue={defaultValues?.phone || ''}
                            onChange={(e) => {
                                setIsEditing(true);
                                setValue('phone', e.target.value);
                            }}
                            inputType="tel"
                            mask="+33 (0)9 99 99 99 99"
                            errorMessage={t(`form.error.phone`)}
                            hasStaticWidth={IsMobile()}
                        />
                    </SkeletonItem>
                </FormControlWrapper>

                <FormControlWrapper
                    inputName="country"
                    {...transversalInputProps}
                >
                    <SkeletonItem isLoading={isLoading} colorWab="grey10">
                        <DaSelect
                            blockWidth="lg"
                            name="country"
                            id="country"
                            inputRef={register}
                            options={countryOptions(t)}
                            onChange={(e) => {
                                setIsEditing(true);
                                setValue('country', e.target.value);
                            }}
                            defaultValue={defaultValues.country}
                        />
                    </SkeletonItem>
                </FormControlWrapper>
            </InternalGrid>

            {children}
        </form>
    );
};

UserProfileForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    defaultValues: PropTypes.object,
};

export default withTranslation('common', 'countries')(UserProfileForm);
