import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { PartnershipGroup } from 'api-green-node';
import { Text } from '@paygreen/paygreen-ui';
import { useAPIError, useMode } from '../../../../context';
import { partnershipStatus } from '../../../../utils/constants';
import { PartnershipCard } from '../../shared';
import { updatePartnershipGroup } from '../../service';

const PartnershipList = ({
    t,
    group,
    partnershipsData,
    getData,
    setIsPartnershipsGroupUpdating,
}) => {
    const { addError } = useAPIError();
    const { mode } = useMode();

    const removeFromPartnershipGroup = (
        groupExternalId,
        currentGroup,
        partnershipId,
    ) => {
        const updatedList = currentGroup.filter((item) => {
            return item.trim() !== partnershipId;
        });
        const updatedGroup = new PartnershipGroup(updatedList);

        setIsPartnershipsGroupUpdating(true);

        updatePartnershipGroup(groupExternalId, updatedGroup, mode)
            .then(() => {
                getData();
            })
            .catch((error) => {
                addError(
                    error?.response?.data.detail,
                    error?.response?.data.status,
                );
            })
            .finally(() => {
                setIsPartnershipsGroupUpdating(false);
            });
    };

    const trimedGroup = group.partnershipIds.map((id) => id.trim()); // we clean partnerships ids list to prevent errors because of possible whitespace

    return (
        <>
            {group.partnershipIds?.length ? (
                partnershipsData
                    ?.filter((partnership) =>
                        trimedGroup.includes(partnership.idPartnership.trim()),
                    )
                    .map((partnership, index, partnershipsData) => (
                        <PartnershipCard
                            key={index}
                            hasBorderBottom={false}
                            isLastPartnership={partnershipsData.length === 1}
                            isInPartnershipGroupCard={true}
                            partnershipStatus={partnershipStatus.ACTIVE}
                            data={partnership}
                            removeFromPartnershipGroup={() =>
                                removeFromPartnershipGroup(
                                    group.externalId,
                                    group.partnershipIds,
                                    partnership.idPartnership,
                                )
                            }
                            partnershipsGroupName={group.externalId}
                        />
                    ))
            ) : (
                <Text textSize="xs" align="center" marginBottom="sm">
                    {t('newPartnershipGroupButton.noPartnerInside')}
                </Text>
            )}
        </>
    );
};

PartnershipList.propTypes = {
    partnershipsData: PropTypes.array,
    group: PropTypes.object,
    getData: PropTypes.func,
    setIsPartnershipsGroupUpdating: PropTypes.func,
};

export default withTranslation('common')(PartnershipList);
