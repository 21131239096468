import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PGThemeProvider, ThemeDefault } from '@paygreen/paygreen-ui';
import './assets/css/fontStyle.css';
import { GlobalStyle } from './theme/GlobalStyle';
import i18n from './internationalization/i18n';
import dayjs from './utils/dayjsHelper';
import Can from './utils/rules/Can';
import { PrivateRoute } from './utils/authentication';
import { AuthenticatedRoutes } from './router';
import { APIErrorProvider, AuthProvider } from './context';
import { APIError } from './components';
import { frLangLocale } from './utils/constants';
import {
    Login,
    Subscription,
    Validation,
    ForgottenPassword,
    ResetPassword,
} from './domains';

const App = () => {
    const mode = process.env.REACT_APP_ACTIVE_MODE;

    if (!mode) {
        throw new Error('Mode is not defined');
    }

    // initializes dayjs language globally based on i18n language for all existing french locales in navigator
    dayjs.locale(frLangLocale.includes(i18n.language) ? 'fr' : 'en');

    return (
        <AuthProvider>
            <APIErrorProvider>
                <I18nextProvider i18n={i18n}>
                    <PGThemeProvider theme={ThemeDefault}>
                        <GlobalStyle />

                        <APIError />

                        <Router>
                            <Can
                                condition={mode}
                                perform={['routes:dev', 'routes:prod']}
                                yes={() => (
                                    <Switch>
                                        <Route
                                            exact
                                            path="/subscription"
                                            component={Subscription}
                                        />
                                        <Route
                                            exact
                                            path="/login"
                                            component={Login}
                                        />
                                        <Route
                                            exact
                                            path="/email-validation"
                                            component={Validation}
                                        />
                                        <Route
                                            exact
                                            path="/forgotten-password"
                                            component={ForgottenPassword}
                                        />
                                        <Route
                                            exact
                                            path="/reset-password"
                                            component={ResetPassword}
                                        />

                                        <PrivateRoute
                                            path="/"
                                            component={AuthenticatedRoutes}
                                        />
                                    </Switch>
                                )}
                                no={() => (
                                    <Switch>
                                        <Route
                                            exact
                                            path="/login"
                                            component={Login}
                                        />
                                        <Route
                                            exact
                                            path="/forgotten-password"
                                            component={ForgottenPassword}
                                        />
                                        <Route
                                            exact
                                            path="/reset-password"
                                            component={ResetPassword}
                                        />

                                        <PrivateRoute
                                            path="/"
                                            component={AuthenticatedRoutes}
                                        />
                                    </Switch>
                                )}
                            />
                        </Router>
                    </PGThemeProvider>
                </I18nextProvider>
            </APIErrorProvider>
        </AuthProvider>
    );
};

export default App;
