import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ThemeDefault } from '@paygreen/paygreen-ui';
import Illu from './Error404Style/Illu';
import { sun, trees, hiddenE } from './Error404Style/animations';

const Error404AnimationBase = styled.div`
    max-width: 400px;
    margin-bottom: 30px;

    svg {
        display: block;
    }

    .base {
        fill: #3f3d56;
    }

    .main {
        fill: ${(props) => props.color.main};
    }

    .light {
        fill: ${(props) => props.color.light};
    }

    .tree {
        pointer-events: none;

        path {
            transform-origin: center center;
            animation-iteration-count: infinite;
            animation-direction: alternate;
        }
    }

    ${sun};
    ${trees};
    ${hiddenE};
`;

const Error404Animation = (props) => {
    const color = ThemeDefault.color[props.colorTheme];

    return (
        <Error404AnimationBase {...props} color={color}>
            <Illu
                gradientBase={color.gradientBase}
                gradientShade={color.gradientShade}
            />
        </Error404AnimationBase>
    );
};

Error404Animation.propTypes = {
    colorTheme: PropTypes.oneOf([
        'primary',
        'secondary',
        'tertiary',
        'quaternary',
        'quinary',
    ]),
};

Error404Animation.defaultProps = {
    colorTheme: 'primary',
};

export default Error404Animation;
